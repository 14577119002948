<template>
  <!-- Profile Info Tab -->
  <div id="emp_profile" class="pro-overview tab-pane fade show active">
    <div class="row">
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Personal Informations
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                data-bs-target="#personal_info_modal"
                @click="$emit('set-emp-data')"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            <ul class="personal-info">
              <!-- <li>
                <div class="title">Passport No.</div>
                <div class="text"> {{ employeeData?.passport_number }}</div>
              </li>
              <li>
                <div class="title">Passport Exp Date.</div>
                <div class="text">  {{ formatDate(employeeData?.passport_expire_at) || employeeData?.passport_expire_at }}</div>
              </li> -->
              <li>
                <div class="title">Tel</div>
                <div class="text"><a href="javascript:;">{{ employeeData?.telephone_number }}</a></div>
              </li>
              <!-- <li>
                <div class="title">Nationality</div>
                <div class="text">{{ employeeData?.nationality }}</div>
              </li>
              <li>
                <div class="title">Religion</div>
                <div class="text">{{ employeeData?.religion }}</div>
              </li> -->
              <li>
                <div class="title">Marital status</div>
                <div class="text">{{ employeeData?.marital_status && employeeData?.marital_status == 1 ? 'Married' : employeeData?.marital_status == 0 ? 'Not Married' : '-' }}</div>
              </li>
              <!-- <li>
                <div class="title">Employment of spouse</div>
                <div class="text">{{ employeeData?.employment_of_spouse }}</div>
              </li> -->
              <li>
                <div class="title">No. of children</div>
                <div class="text">{{ employeeData?.no_of_child }}</div>
              </li>
              <li>
                <div class="title">SSN No.</div>
                <div class="text">{{ employeeData?.ssn_number }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Emergency Contact
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                @click="$emit('set-emp-data')"
                data-bs-target="#emergency_contact_modal"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            <h5 class="section-title">Primary</h5>
            <ul class="personal-info">
              <li>
                <div class="title">Name</div>
                <div class="text">{{ getEmployeePrimaryContactDetail?.name }}</div>
              </li>
              <li>
                <div class="title">Relationship</div>
                <div class="text">{{ getEmployeePrimaryContactDetail?.relationship }}</div>
              </li>
              <li>
                <div class="title">Phone</div>
                <div class="text">{{ getEmployeePrimaryContactDetail?.contact_number_1 }} {{ (getEmployeePrimaryContactDetail?.contact_number_2 != null) ? ' , ' : '' }} {{ getEmployeePrimaryContactDetail?.contact_number_2 }}</div>
              </li>
            </ul>
            <hr />
            <h5 class="section-title">Secondary</h5>
            <ul class="personal-info">
              <li>
                <div class="title">Name</div>
                <div class="text">{{ getEmployeeSecondaryContactDetail?.name }}</div>
              </li>
              <li>
                <div class="title">Relationship</div>
                <div class="text">{{ getEmployeeSecondaryContactDetail?.relationship }}</div>
              </li>
              <li>
                <div class="title">Phone</div>
                <div class="text">{{ getEmployeeSecondaryContactDetail?.contact_number_1 }} {{ (getEmployeeSecondaryContactDetail?.contact_number_2 != null) ? ' , ' : '' }} {{ getEmployeeSecondaryContactDetail?.contact_number_2 }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">Bank information
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                @click="$emit('set-emp-data')"
                data-bs-target="#employee_bank_modal"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            
            <ul class="personal-info">
              <li>
                <div class="title">Bank name</div>
                <div class="text">{{ getEmployeeBankDetail?.bank_name }}</div>
              </li>
              <li>
                <div class="title">Bank account No.</div>
                <div class="text">{{ getEmployeeBankDetail?.bank_account_number }}</div>
              </li>
              <li>
                <div class="title">IFSC Code</div>
                <div class="text">{{ getEmployeeBankDetail?.IFSC_code }}</div>
              </li>
              <li>
                <div class="title">PAN No</div>
                <div class="text">{{ getEmployeeBankDetail?.pan_number }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Family Informations
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                @click="$emit('set-emp-data')"
                data-bs-target="#family_info_modal"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            <div class="table-responsive">
              <table class="table table-nowrap">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Relationship</th>
                    <th>Date of Birth</th>
                    <th>Phone</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(emp_fmy_dtl,index) in getFamilyInformation" :key="emp_fmy_dtl?.id">
                    <td>{{ emp_fmy_dtl?.name }}</td>
                    <td>{{ emp_fmy_dtl?.relationship }}</td>
                    <td>{{ formatDate(emp_fmy_dtl?.birth_date) || emp_fmy_dtl?.birth_date }}</td>
                    <td>{{  emp_fmy_dtl?.contact_number }}</td>
                    <td class="text-end">
                      <div class="dropdown dropdown-action">
                        <a
                          aria-expanded="false"
                          data-bs-toggle="dropdown"
                          class="action-icon dropdown-toggle"
                          href="javascript:;"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a href="javascript:;" class="dropdown-item" data-bs-toggle="modal" @click="$emit('set-emp-data')" data-bs-target="#family_info_modal"
                            ><i class="fa fa-pencil m-r-5"></i> Edit</a
                          >
                          <a href="javascript:;" class="dropdown-item" @click="confirm_delete_family_data(emp_fmy_dtl?.id,index)"
                            ><i class="fa fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </td>

                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Education Informations
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                @click="$emit('set-emp-data')"
                data-bs-target="#education_info"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            <div class="experience-box">
              <ul class="experience-list">
                <li v-for="emp_edu_dtl in getEmployeeEducation" :key="emp_edu_dtl?.id">
                  <div class="experience-user">
                    <div class="before-circle"></div>
                  </div>
                  <div class="experience-content">
                    <div class="timeline-content">
                      <a href="#/" class="name"
                        >{{ emp_edu_dtl?.institution }}</a
                      >
                      <div>{{ emp_edu_dtl?.subject }} - {{ emp_edu_dtl?.degree }}</div>
                      <span class="time">{{ formatDate(emp_edu_dtl?.starting_date) || emp_edu_dtl?.starting_date }}  {{ (emp_edu_dtl?.starting_date != null) ? ' - ' : '' }}  {{ formatDate(emp_edu_dtl?.complete_date) || emp_edu_dtl?.complete_date }}</span>
                      <div v-if="emp_edu_dtl?.documents">
                        <div v-for="(document,index) in emp_edu_dtl.documents" :key="'edu_document_'+index">
                          <router-link to="#" @click="viewDoc(document?.file_path)"
                            >
                            <span><i class="fa fa-file" aria-hidden="true"></i> {{ document?.original_file_name }}</span>
                            </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Experience
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                @click="$emit('set-emp-data')"
                data-bs-target="#experience_info"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            <div class="experience-box">
              <ul class="experience-list">
                <li v-for="emp_exp in getEmployeeExperience" :key="emp_exp?.id" >
                  <div class="experience-user">
                    <div class="before-circle"></div>
                  </div>
                  <div class="experience-content">
                    <div class="timeline-content">
                      <a href="#/" class="name"
                        >{{ emp_exp?.company_name }}</a
                      >
                      <span class="time">{{  emp_exp?.job_period_from  }} {{ (emp_exp?.job_period_from != null) ? ' - ' : '' }} {{ emp_exp?.job_period_to  }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Documents Informations
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                data-bs-target="#upload_documents"
                @click="clearData"
                ><i class="fas fa-plus"></i
              ></a>
            </h3>
            <div class="raw">
              <div class="col-md-12">
                <div class="showentries mb-3">
                  <label
                    >Show
                    <select v-model="pagination.pageSize" @change="updatePagination">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    entries
                  </label>
                </div>
                <div class="table-responsive">
                  <a-table class="stripped table-hover" :columns="columns" :data-source="documentData" :pagination="pagination"
                  @change="handleTableChange">
                    <template #bodyCell="{ column, record }">

                      <template v-if="column.key === 'original_file_name'">

                        <a href="javascript:;" @click="viewDoc(record?.file_path)" > <i class="fa fa-file" aria-hidden="true"></i> {{  record.original_file_name}} </a>
                      </template>
                     
                      <template v-if="column.key === 'action'">
                        <div class="text-end">
                          <div class="dropdown dropdown-action">
                            <a
                              href="javascript:;"
                              class="action-icon dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              ><i class="material-icons">more_vert</i></a
                            >
                            <div class="dropdown-menu dropdown-menu-right">
                                <a href="javascript:;" class="dropdown-item" @click="viewDoc(record?.file_path)" > <i class="fa fa-eye m-r-5"></i> View </a>
                              
                              <a
                                class="dropdown-item"
                                href="javascript:;"
                                data-bs-toggle="modal"
                                data-bs-target="#delete_document"
                                @click="setDeleteID(record?.user_documents_id)"
                                ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Certificates
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                data-bs-target="#manage_certificates"
                @click="clearCertiData"
                ><i class="fas fa-plus"></i
              ></a>
            </h3>
            <div class="raw">
              <div class="col-md-12">
                <div class="showentries mb-3">
                  <label
                    >Show
                    <select v-model="certi_pagination.pageSize"  @change="updateCertiPagination">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    entries
                  </label>
                </div>
                <div class="table-responsive">
                  <a-table class="stripped table-hover" :columns="certiColumns" :data-source="CertiData" :pagination="certi_pagination" @change="handleCertiTableChange"
                   >
                    <template #bodyCell="{ column, record }">
                     
                      <template v-if="column.key === 'course_name'">
                        {{ record?.course_name  }} - {{ record?.company_name  }}
                      </template>

                      <template v-if="column.key === 'file_original_name'">

                        <a href="javascript:;" @click="viewDoc(record?.file_path)" > <i class="fa fa-file" aria-hidden="true"></i> {{  record.file_original_name}} </a>
                      </template>

                      <template v-if="column.key === 'action'">
                        <div class="text-end">
                          <div class="dropdown dropdown-action">
                            <a
                              href="javascript:;"
                              class="action-icon dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              ><i class="material-icons">more_vert</i></a
                            >
                            <div class="dropdown-menu dropdown-menu-right">
                                <a href="javascript:;" class="dropdown-item" @click="viewDoc(record?.file_path)" > <i class="fa fa-eye m-r-5"></i> View </a>
                                <a
                                  class="dropdown-item"
                                  href="javascript:;"
                                  data-bs-toggle="modal"
                                  data-bs-target="#manage_certificates"
                                  @click="editCertificateModal(record)"
                                  ><i class="fa-regular fa-edit m-r-5"></i> Edit</a
                                >
                              <a
                                class="dropdown-item"
                                href="javascript:;"
                                data-bs-toggle="modal"
                                data-bs-target="#delete_certi"
                                @click="deleteCertiID(record?.user_certificate_id)"
                                ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <!-- Add Documents -->
    <div
    id="upload_documents"
    class="modal custom-modal fade"
    role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Upload Documents</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="clearData"
              ref="upload_documents_close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <Form @submit="addNewDoc" :validation-schema="schema" v-slot="{ errors }">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Document Name <span class="text-danger">*</span></label
                    >
                    <Field type="text" name="document_title" class="form-control" v-model="document.document_title" :class="{ 'is-invalid': errors.document_title }"/>
                    <div class="invalid-feedback">{{ errors.document_title }}</div>
                    <div class="document_titlesshow text-danger" id="document_title"></div>
                  </div>
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Select Document <span class="text-danger">*</span></label>
                      <Field
                          type="file"
                          class="form-control"
                          data-buttontext="Choose File"
                          data-icon="false"
                          data-classbutton="btn btn-default"
                          data-classinput="form-control inline input-s"
                          name="document"
                          multiple
                          accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .xlsx, .xls"
                          ref="attachments" 
                          :class="{ 'is-invalid': errors.document }"
                          @change="handleImageChange($event)"
                      />
                      <div class="invalid-feedback">{{ errors.document }}</div>
                      <div class="documentsshow text-danger" id="document"></div>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn" :disabled="disableButton">Submit</button>
              </div>
            </Form>
            
          </div>
        </div>
      </div>
    </div>
  <!-- Add Documents -->

  <!-- Delete Asset Modal -->
  <div class="modal custom-modal fade" id="delete_document" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Document</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn"
                  @click="deleteDocument"
                  :disabled="disableButton"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  ref="close_document_delete_modal"
                  class="btn btn-primary cancel-btn"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Asset Modal -->

  <!-- Delete Certi Modal -->
  <div class="modal custom-modal fade" id="delete_certi" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Certificate</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn"
                  @click="deleteCertificate"
                  :disabled="disableButton"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  ref="close_certi_delete_modal"
                  class="btn btn-primary cancel-btn"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Certi Modal -->

  <!-- Add Certificate -->
  <div
  id="manage_certificates"
  class="modal custom-modal fade"
  role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ certi?.user_certificate_id ? 'Edit ' : 'Add '   }} Certificate</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearCertiData"
            ref="upload_certi_close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="addNewCerti" :validation-schema="certi?.user_certificate_id ? EditCertischema : Certischema " v-slot="{ errors }">
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Compnay Name <span class="text-danger">*</span></label
                  >
                  <Field type="text" name="company_name" class="form-control" v-model="certi.company_name" :class="{ 'is-invalid': errors.company_name }"/>
                  <div class="invalid-feedback">{{ errors.company_name }}</div>
                  <div class="company_namesshow text-danger" id="company_name"></div>
                </div>
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Course Name <span class="text-danger">*</span></label
                  >
                  <Field type="text" name="course_name" class="form-control" v-model="certi.course_name" :class="{ 'is-invalid': errors.course_name }"/>
                  <div class="invalid-feedback">{{ errors.course_name }}</div>
                  <div class="course_namesshow text-danger" id="course_name"></div>
                </div>
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >From : <span class="text-danger">*</span></label
                  >
                  <datepicker
                    v-model="certi.from"
                    placeholder="Choose Date"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                    name='from'
                  />
                  <div class="invalid-feedback">{{ errors.from }}</div>
                  <div class="fromsshow text-danger" id="from"></div>
                </div>
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >To : <span class="text-danger">*</span></label
                  >
                  <datepicker
                    v-model="certi.to"
                    placeholder="Choose Date"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                    name='to'
                  />
                  <div class="invalid-feedback">{{ errors.to }}</div>
                  <div class="tosshow text-danger" id="to"></div>
                </div>
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Upload Certificate <span class="text-danger">*</span></label>
                    <Field
                        type="file"
                        class="form-control"
                        data-buttontext="Choose File"
                        data-icon="false"
                        data-classbutton="btn btn-default"
                        data-classinput="form-control inline input-s"
                        name="certificate"
                        multiple
                        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                        ref="certi_attachments" 
                        :class="{ 'is-invalid': errors.certificate }"
                        @change="handleCertiImageChange($event)"
                    />
                    <small v-if="certi?.user_certificate_id">Provide the document only if you wish to replace the existing one</small>
                    <div class="invalid-feedback">{{ errors.certificate }}</div>
                    <div class="certificatesshow text-danger" id="certificate"></div>
                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" >Submit </button>
            </div>
          </Form>
          
        </div>
      </div>
    </div>
  </div>
<!-- Add Certificate -->
  </div>
  <!-- /Profile Info Tab -->
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { notification } from "ant-design-vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from 'axios';
import { ref } from "vue";
import moment from 'moment';
const currentDate = ref(new Date());
var pagination = {total: 0,
	    current: 1,
	    pageSize: 10};

      const columns = [
  {
    title: "Document Title",
    dataIndex: "document_title",
    key:"asset_user_name",
    sorter: {
      compare: (a, b) => {
        a = a.document_title.toLowerCase();
        b = b.document_title.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Original File Name",
    dataIndex: "original_file_name",
    key:"original_file_name",
    sorter: {
      compare: (a, b) => {
        a = a.original_file_name.toLowerCase();
        b = b.original_file_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "File Extension",
  //   dataIndex: "document_type",
  //   key:"document_type",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.document_type.toLowerCase();
  //       b = b.document_type.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  

  {
    title: "Action",
    key: "action",
    sorter: true,
    class: "text-end",
  },
];

const certiColumns = [
  // {
  //   title: "Company Name",
  //   dataIndex: "company_name",
  //   key:"company_name",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.company_name.toLowerCase();
  //       b = b.company_name.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Course Name",
    dataIndex: "course_name",
    key:"course_name",
    sorter: {
      compare: (a, b) => {
        a = a.course_name.toLowerCase();
        b = b.course_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Certificate",
    dataIndex: "file_original_name",
    key:"file_original_name",
    sorter: {
      compare: (a, b) => {
        a = a.file_original_name.toLowerCase();
        b = b.file_original_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
    class: "text-end",
  },

]

export default {
  emits:['reload-data','set-emp-data'],
  props:{
    employeeData: {
      type: Object,
      default: null,
    },
  },
  watch:{
    employeeData(newVal){
      this.documentData = newVal?.user_documents || []
      this.CertiData = newVal?.user_certificate || []
    }
  },
  data(){
    return {
      dateFormat: 'dd-MM-yyyy',
      document:{
        document_title:null,
        document:null
      },
      certi:{
        company_name:'',
        course_name:null,
        from:currentDate,
        to:currentDate,
        certificate:null
      },
      errors:null,
      disableButton:false,
      perpage: 10,
      pagination: pagination,
      certi_pagination: Object.assign({},pagination),
      documentData:[],
      columns,
      deleteID:null,
      certiColumns,
      CertiData:[],
      deleteCerti_ID:null

    }
  },
  components: {
    Form,
    Field,
  },
  computed:{
    getEmployeePrimaryContactDetail()
    {
      if(this?.employeeData?.employee_contact && this.employeeData.employee_contact != null )
      {
        return this.employeeData.employee_contact.find(contact => contact.contact_type === 'p') || null;
      }
    },
    getEmployeeSecondaryContactDetail()
    {
      if(this?.employeeData?.employee_contact && this.employeeData.employee_contact != null )
      {
        return this.employeeData.employee_contact.find(contact => contact.contact_type === 's') || null;
      }
    },
    getEmployeeBankDetail()
    {
      if(this?.employeeData?.employee_bank_detail && this.employeeData.employee_bank_detail != null )
      {
        return this.employeeData.employee_bank_detail[0];
      }
    },
    getFamilyInformation()
    {
      if(this?.employeeData?.employee_family_detail && this?.employeeData?.employee_family_detail != null )
      {
        return this.employeeData.employee_family_detail;
      }
    },
    getEmployeeEducation()
    {
      
      if(this?.employeeData?.education_information && this?.employeeData?.education_information != null )
      {
        return this.employeeData.education_information;
      }
    },
    getEmployeeExperience()
    {
      if(this?.employeeData?.employee_experience && this?.employeeData?.employee_experience != null )
      {
        return this.employeeData.employee_experience;
      }
    }
  },
  methods:{
    ...mapActions(['deleteFamilyDetails','fetchEmployeeProfileData','getDateFormate']),
    editCertificateModal(record)
    {
      this.certi = null;
      this.certi = Object.assign({}, record);
      this.certi.from = new Date(record?.from_date);
      this.certi.to = new Date(record?.to_date);
      this.certi.certificate = null
    },
    deleteCertiID(id){
      this.deleteCerti_ID = id;
    },
    addNewCerti()
    {
      this.errors = []
      this.disableButton = true;

      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: false
      });

      this.certi.from_date =moment(this?.certi?.from).format('YYYY-MM-DD')
      this.certi.to_date =moment(this?.certi?.to).format('YYYY-MM-DD')

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const config = {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
      };

      if(this.certi?.user_certificate_id)
      {
        axios.post('employee/profile/edit-certificate/'+this.certi?.user_certificate_id,this.certi,config).then(response => {

        loader.hide();
        this.disableButton = false;
        this.$emit('reload-data');
        notification.open({
          message: response?.data?.message || 'Successfully Added',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
            background: process.env.VUE_APP_SUCCESS_COLOR,
          },
        });

        this.$refs.upload_certi_close.click();

        }).catch(error => {
          this.disableButton = false;
          loader.hide();
          if(error.response){


            var response = (error.response);

            this.errors = response?.data?.error || []
              
            notification.open({
              message: response?.data?.message || 'Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
        })
      }
      else
      {
        axios.post('employee/profile/add-certificate/'+this?.$route?.params?.user_id,this.certi,config).then(response => {

          loader.hide();
          this.disableButton = false;
          this.$emit('reload-data');
          notification.open({
            message: response?.data?.message || 'Successfully Added',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });

          this.$refs.upload_certi_close.click();

          }).catch(error => {
            this.disableButton = false;
            loader.hide();
            if(error.response){


              var response = (error.response);

              this.errors = response?.data?.error || []
                
              notification.open({
                message: response?.data?.message || 'Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
              
            }else{
              
              notification.open({
                message: 'Server Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
            }
          })
      }

    },

    clearCertiData()
    {
      this.certi={
        company_name:'',
        course_name:null,
        from:currentDate,
        to:currentDate,
        certificate:null
      }

      if (this.$refs.certi_attachments && this.$refs.certi_attachments.$el) {
          this.$refs.certi_attachments.$el.value = '';
          this.$refs.certi_attachments.value = '';
      }
    },
    setDeleteID(deleteId)
    {
      this.deleteID = deleteId
    },
    handleTableChange(pagesize){
      var params = {
        params: { per_page: pagesize?.pageSize,page:pagesize?.current }
      };
      this.fetchData(params);
    },
    handleCertiTableChange(pagesize){
      var params = {
        params: { per_page: pagesize?.pageSize,page:pagesize?.current }
      };
      this.fetchCertiData(params);
    },
    updatePagination()
    {
      this.fetchData(null);
    },

    updateCertiPagination()
    {
      this.fetchCertiData(null);
    },

    handleCertiImageChange(event){
      const file = event.target.files[0] || null; 
      this.certi.certificate = file;
    },

    handleImageChange(event){
      const file = event.target.files[0] || null; 
      this.document.document = file;
    },

    async fetchData(params = null){
        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

        var param = null

        if(params != null)
        {
          param = params
        }
        else
        {
          param = {
            params: { per_page: this.pagination.pageSize }
          };
        }

      var token = window.localStorage.getItem("token");
	
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      await axios.get('/employee/profile/user-documents/'+this.$route.params.user_id,param).then(response => {
        loader.hide();

        this.documentData = response?.data?.data?.data || [];

        this.pagination.total = response?.data?.data?.total;
        this.pagination.current = response?.data?.data?.current_page;
        this.pagination.pageSize = response?.data?.data?.per_page;

      }).catch(error => {
        loader.hide();
          if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });

            
            this.$router.push({name: 'login'}).catch(error => {}) 
          }
          
          if(error?.response){
          
            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error During Fetching Overtime Records',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
      })
    },
    async fetchCertiData(params = null){
      let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

        var param = null

        if(params != null)
        {
          param = params
        }
        else
        {
          param = {
            params: { per_page: this.certi_pagination.pageSize }
          };
        }

      var token = window.localStorage.getItem("token");
	
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      await axios.get('/employee/profile/user-certificate/'+this.$route.params.user_id,param).then(response => {
        loader.hide();

        this.CertiData = response?.data?.data?.data || [];

        this.certi_pagination.total = response?.data?.data?.total;
        this.certi_pagination.current = response?.data?.data?.current_page;
        this.certi_pagination.pageSize = response?.data?.data?.per_page;

      }).catch(error => {
        loader.hide();
          if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });

            
            this.$router.push({name: 'login'}).catch(error => {}) 
          }
          
          if(error?.response){
          
            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error During Fetching Overtime Records',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
      })
    },
    clearData(){
      this.document = {
        document_title:null,
        document:null
      }
      if (this.$refs.attachments && this.$refs.attachments.$el) {
          this.$refs.attachments.$el.value = '';
          this.$refs.attachments.value = '';
      }
    },
    addNewDoc(){
      this.errors = []
      this.disableButton = true;

      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: false
      });

      var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };

        axios.post('employee/profile/upload-document/'+this?.$route?.params?.user_id,this.document,config).then(response => {

          loader.hide();
          this.disableButton = false;
			    this.$emit('reload-data');
          notification.open({
            message: response?.data?.message || 'Successfully uploaded',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });

          this.$refs.upload_documents_close.click();

        }).catch(error => {
          this.disableButton = false;
          loader.hide();
          if(error.response){

			
            var response = (error.response);

            this.errors = response?.data?.error || []
              
            notification.open({
              message: response?.data?.message || 'Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
        })

    },

    viewDoc(file)
    {
      window.open(`${process.env.VUE_APP_IMAGE_BASE_URL}${file}`, '_blank');
    },

    deleteDocument()
    {
        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        axios.delete('employee/profile/user-documents/'+this?.deleteID).then(response => {

        this.deleteID = null

        loader.hide();
        this.disableButton = false;
        this.$emit('reload-data');
        notification.open({
          message: response?.data?.message || 'Successfully Deleted',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
            background: process.env.VUE_APP_SUCCESS_COLOR,
          },
        });

        this.$refs.close_document_delete_modal.click();

        }).catch(error => {
        this.disableButton = false;
        loader.hide();
        if(error.response){


          var response = (error.response);

          this.errors = response?.data?.error || []
            
          notification.open({
            message: response?.data?.message || 'Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
          
        }else{
          
          notification.open({
            message: 'Server Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
        })
    },

    deleteCertificate(){
      let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        axios.delete('employee/profile/user-certificate/'+this?.deleteCerti_ID).then(response => {

        this.deleteCerti_ID = null

        loader.hide();
        this.disableButton = false;
        this.$emit('reload-data');
        notification.open({
          message: response?.data?.message || 'Successfully Deleted',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
            background: process.env.VUE_APP_SUCCESS_COLOR,
          },
        });

        this.$refs.close_certi_delete_modal.click();

        }).catch(error => {
        this.disableButton = false;
        loader.hide();
        if(error.response){


          var response = (error.response);

          this.errors = response?.data?.error || []
            
          notification.open({
            message: response?.data?.message || 'Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
          
        }else{
          
          notification.open({
            message: 'Server Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
        })
    },
    async confirm_delete_family_data(emp_family_id,index){
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async (t) =>  {

        if(t.value)
        {
          if(emp_family_id && emp_family_id != null)
          {
            const response = await this.deleteFamilyDetails(emp_family_id);
            if(response.status == 200)
            {
              await this.fetchEmployeeProfileData(this?.employeeData?.id);
              notification.open({
                  message: 'Successfully Data deleted.',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                  },
                });
            }
            else
            {
              this.selectedImage = null;
              notification.open({
                  message: response?.message ?  response?.message : 'Something went wrong, Please try again later',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                  },
                });
            }
          }
        }
      });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      document_title: Yup.string().required("Title is required"),
      document: Yup.mixed().required("File is required"),
    });

    const Certischema = Yup.object().shape({
      company_name: Yup.string().required("Company Name is required"),
      course_name: Yup.string().required("Course Name is required"),
      certificate: Yup.mixed().required("Certificate File is required"),
    });

    const EditCertischema = Yup.object().shape({
      company_name: Yup.string().required("Company Name is required"),
      course_name: Yup.string().required("Course Name is required"),
    });
    return {
      schema,
      Certischema,
      EditCertischema
    }
  }
}
</script>
