<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { requestPermission } from './firebase';
export default {
  components: {},
  data() {
    return {};
  },
  mounted()
  {
    requestPermission().then(() => {
    }).catch((error) => {
      console.error('Error requesting notification permission:', error);
    });
  }
};
</script>
<style>
select.form-select-lg {
    padding-top: 0.9rem !important;
    padding-bottom: 0.6rem !important;
    font-size:1rem !important
}
</style>
