<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <!-- Page Header -->
        <div class="page-header">
          <div class="content-page-header">
            <h5>Alert</h5>
          </div>
        </div>
        <!-- /Page Header -->

        <!-- Alerts -->
        <div class="card bg-white">
          <div class="card-body">
            <div class="alert alert-primary alert-dismissible fade show" role="alert">
              <strong>Holy guacamole!</strong> You should check in on some of those fields
              below.
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div class="alert alert-secondary alert-dismissible fade show" role="alert">
              <strong>Holy guacamole!</strong> You should check in on some of those fields
              below.
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div class="alert alert-success alert-dismissible fade show" role="alert">
              <strong>Holy guacamole!</strong> You should check in on some of those fields
              below.
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>Holy guacamole!</strong> You should check in on some of those fields
              below.
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div class="alert alert-warning alert-dismissible fade show" role="alert">
              <strong>Holy guacamole!</strong> You should check in on some of those fields
              below.
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div class="alert alert-info alert-dismissible fade show" role="alert">
              <strong>Holy guacamole!</strong> You should check in on some of those fields
              below.
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div class="alert alert-light alert-dismissible fade show" role="alert">
              <strong>Holy guacamole!</strong> You should check in on some of those fields
              below.
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div class="alert alert-dark alert-dismissible fade show mb-0" role="alert">
              <strong>Holy guacamole!</strong> You should check in on some of those fields
              below.
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <!-- /Alerts -->
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
