<template>
  <div class="col-lg-4 col-xl-3">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title m-b-15">Project details</h6>
		<table class="table table-striped table-border">
          <tbody>
            <tr>
              <td>Cost:</td>
              <td class="text-end">${{projectdetail.rate}}</td>
            </tr>
           <!-- <tr>
              <td>Total Hours:</td>
              <td class="text-end">100 Hours</td>
            </tr>-->
            <tr>
              <td>Created:</td>
              <td class="text-end">{{projectdetail.start_date}}</td>
            </tr>
            <tr>
              <td>Deadline:</td>
              <td class="text-end">{{ formatDate(projectdetail?.end_date) || projectdetail.end_date }}</td>
            </tr>
            <tr>
              <td>Priority:</td>
              <td class="text-end">
                <div class="btn-group">
                  <a
                    href="javascript:void(0);"
                    class="badge badge-danger dropdown-toggle"
                    data-bs-toggle="dropdown" v-if="projectdetail.priority == 1"
                    >High Priority
                  </a>
				  <a
                    href="javascript:void(0);"
                    class="badge badge-info dropdown-toggle"
                    data-bs-toggle="dropdown" v-if="projectdetail.priority == 2"
                    >Medium Priority
                  </a> 
				  <a
                    href="javascript:void(0);"
                    class="badge badge-success dropdown-toggle"
                    data-bs-toggle="dropdown" v-if="projectdetail.priority == 3"
                    >Low Priority
                  </a>
				  <a
                    href="javascript:void(0);"
                    class="badge badge-success dropdown-toggle"
                    data-bs-toggle="dropdown" v-if="!projectdetail.priority"
                    >Low
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="javascript:void(0);" v-if="projectdetail.priority != 1" @click="updatePriority(projectdetail,1)"
                      ><i class="fa-regular fa-circle-dot text-danger"></i>
                      High priority</a
                    ><!--
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="fa-regular fa-circle-dot text-info"></i> High
                      priority</a
                    >-->
                    <a class="dropdown-item" href="javascript:void(0);" v-if="projectdetail.priority != 2" @click="updatePriority(projectdetail,2)"
                      ><i class="fa-regular fa-circle-dot text-primary"></i>
                      Medium priority</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);" v-if="projectdetail.priority != 3" @click="updatePriority(projectdetail,3)"
                      ><i class="fa-regular fa-circle-dot text-success"></i> Low
                      priority</a
                    >
                  </div>
                </div>
              </td>
            </tr>
            <!--<tr>
              <td>Created by:</td>
              <td class="text-end">
                <router-link to="profile"></router-link>
              </td>
            </tr> -->
            <tr>
              <td>Status:</td>
              <td class="text-end" v-if="projectdetail.status == 1">Working</td>
			  <td class="text-end" v-if="projectdetail.status == 2">Completed</td>
			  <td class="text-end" v-if="projectdetail.status == 0">Pending</td>
            </tr>
          </tbody>
        </table>
        <p class="m-b-5">
          Progress <span class="text-success float-end">40%</span>
        </p>
        <div class="progress progress-xs mb-0">
          <div
            class="progress-bar bg-success w-40"
            role="progressbarouter-link"
            data-bs-toggle="tooltip"
            title="40%"
          ></div>
        </div>
      </div>
    </div>
    <div class="card project-user">
      <div class="card-body">
        <h6 class="card-title m-b-20">
          Assigned Leader
          <!--<button
            type="button"
            class="float-end btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#assign_leader"
          >
            <i class="fa-solid fa-plus"></i> Add
          </button> -->
        </h6>
		
        <ul class="list-box" v-if="projectdetail.leaderdetails">
		  <li>
            <router-link :to="{ path: '/profile/'+projectdetail.leaderdetails.id}" >
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar"
                    ><img
                      src="@/assets/img/profiles/avatar-11.jpg"
                      alt="User Image"
                  /></span>
                </div>
                <div class="list-body">
                  <span class="message-author">{{projectdetail.leaderdetails.first_name}} {{projectdetail.leaderdetails.last_name}}</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Team Leader</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--<li>
            <router-link to="profile">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar"
                    ><img
                      src="@/assets/img/profiles/avatar-01.jpg"
                      alt="User Image"
                  /></span>
                </div>
                <div class="list-body">
                  <span class="message-author">Lesley Grauer</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Team Leader</span>
                </div>
              </div>
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="card project-user">
      <div class="card-body">
        <h6 class="card-title m-b-20">
          Assigned users
		  <!--
          <button
            type="button"
            class="float-end btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#assign_user"
          >
            <i class="fa-solid fa-plus"></i> Add
          </button> -->
        </h6>
		<ul class="list-box" v-if="projectdetail.teamdetails && projectdetail.teamdetails.members">
		  <li v-for="item in projectdetail.teamdetails.members" :key="item.id">
			<router-link :to="{ path: '/profile/'+item.details.id}" >
		 	 <div class="list-item" v-if="item.details">
                <div class="list-left">
                  <span class="avatar"
                    ><img
                      src="@/assets/img/profiles/avatar-02.jpg"
                      alt="User Image"
                  /></span>
                </div>
                <div class="list-body">
                  <span class="message-author">{{item.details.first_name}} {{item.details.last_name}}</span>
                  <div class="clearfix"></div>
                  <span class="message-content" v-if="item.details.designation">{{item.details.designation.name}}</span>
                </div>
			  </div>
			  </router-link>
		  </li>	
		  <!--
          <li>
            <router-link to="profile">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar"
                    ><img
                      src="@/assets/img/profiles/avatar-02.jpg"
                      alt="User Image"
                  /></span>
                </div>
                <div class="list-body">
                  <span class="message-author">John Doe</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Web Designer</span>
                </div>
              </div>
            </router-link>
          </li> -->
          <!--<li>
            <router-link to="profile">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar"
                    ><img
                      src="@/assets/img/profiles/avatar-09.jpg"
                      alt="User Image"
                  /></span>
                </div>
                <div class="list-body">
                  <span class="message-author">Richard Miles</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Web Developer</span>
                </div>
              </div>
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	emits: ["updatePriority"],
	props: {
	  projectdetail: [Array, Object],
	},
    methods: {
		
		updatePriority(projectdetail,typepriority){
			console.log(projectdetail);
			console.log(typepriority);
			
			this.$emit("updatePriority",projectdetail,typepriority);
			
		}
	}	
}

</script>