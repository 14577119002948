<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <leaves-admin-header :title="title" :path="path" :text="text" :text1="text1" :hasCreatePermission="hasCreatePermission" :hasImportPermission="hasImportPermission" @reset-data="resetData" />
        <!-- /Page Header -->
		
		<Form @submit="onSearch">
			<div class="row filter-row">
				<div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-12">
				  <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
					<input
					  type="text"
					  class="form-control floating"
					  v-model="searchform.name"
					  @focus="isFocused = true"
					  @blur="isFocused = searchform.name !== ''"
					/>
					<label class="focus-label"> Name</label>
				  </div>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <button class="btn btn-success w-100 submit-btn">Search</button>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" style="margin-left: 10px;">
				  <a href="javascript:;" class="btn btn-danger w-100 submit-btn" @click="clearForm"> Clear </a>
				</div>
			  </div>
		</Form>  
		
        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label
              >
            </div>
            <div>
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Name'">
                    <div>{{ record.Name }}</div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end">
                      <div class="dropdown dropdown-action" v-if="hasEditPermission || hasDeletePermission">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a  v-if="hasEditPermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_department"
							@click="EditDept(record)"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a v-if="hasDeletePermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_department"
							@click="DeleteDept(record.id)"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
	
      <departments-model :err="err" :form="create_form" :editform="edit_form" :deldep_id="deldepid" @create-department="createDepartment" @update-department="updateDepartment" @delete-department="deleteDepartment" ref="departmentsmodel" ></departments-model>
	  
	  <!-- Import Department Modal -->
	<div id="import_department" class="modal custom-modal fade" role="dialog">
		<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
			<h5 class="modal-title">Import Department</h5>
			<button
				type="button"
				class="btn-close"
				data-bs-dismiss="modal"
				aria-label="Close"
				ref="impdeptclose"
			>
				<span aria-hidden="true">&times;</span>
			</button>
			</div>
			<div class="modal-body">
			<Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
				<div class="input-block mb-3">
				<label class="col-form-label"
					>File <span class="text-danger">*</span>
				</label>
				<Field
						name="department_import_file"
						type="file"
						class="form-control"
						:class="{ 'is-invalid': errors.department_import_file }"
						@change="handleFileUpload($event)"
						accept=".xlsx,.xls"	
						ref="importFile"
					/>
					<div class="invalid-feedback">{{ errors.department_import_file }}</div>
					<div class="filesshow text-danger" id="file"></div>
				<p> <a :href="department_demo_file" target="_blank">Click here </a> for download Demo file </p>
				</div>
				<div class="submit-section">
				<button class="btn btn-primary submit-btn">Submit</button>
				</div>
			</Form>
			</div>
		</div>
		</div>
	</div>
	<!-- /Import Department Modal -->
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};

const columns = [
  {
	  title: "#",
	  dataIndex: "id",
	  key: "id",
	  customRender: ({ index }) => {
		return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
	
    /*sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },*/
  },
  
];
const data = [];

import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from 'axios';
import { notification } from "ant-design-vue";

export default {
  data() {
    return {
	  editfile:null,
	  searchform: {name: ""},
      title: "Department",
      path: "Dashboard",
      text: "Department",
      text1: "Add Department",
      columns:columns,
      data,
	  perpage: 10,
	  create_form: { department_name: ''},
	  edit_form: { },
	  pagination: pagination,
	  deldepid: 0,
	  hasReadPermission:null,
	  hasEditPermission:null,
      hasDeletePermission:null,
	  hasCreatePermission:null,
	  hasImportPermission:null,
	  err:null
    };
  },
  components: {
    Form,
    Field,
  },
  methods: {
	resetData()
	{
		this.$nextTick(()=>{
			this.err  = null;
			this.create_form = { department_name: ''};
		})
	},
	handleFileUpload(event){
		this.editfile = event.target.files[0];
	},
	clearForm(){
		
		this.searchform = {name: ""};
		
		var params = {
		   params: { per_page: this.pagination.pageSize }
		};	
		this.loadCommonData(params);
	
	},
	onSearch(){
		console.log('testing...');
		
		var params = {
		   params: { per_page: this.pagination.pageSize,page:this.pagination.current, name: this.searchform.name}
		};	
		
		this.loadCommonData(params);
		
	},
	onSubmit()
	{
		this.err = null
		var token = window.localStorage.getItem("token");
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
			container: this.$refs.formContainer,
			canCancel: false
		});

		var form = new FormData();

		form.append('department_import_file', this.editfile);

		const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };

		axios.post("/imports/departmentimport", form,config)
          .then( (response) => {


			this.editfile = null
			this.$refs.importFile.value = ''
				
			  loader.hide();
			  
			  notification.open({
					message: response?.data?.message || 'Successfully Imported',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.impdeptclose.click();
			 this.edit_form ={};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {

			this.editfile = null
			this.$refs.importFile.value = ''
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);

				this.err = response?.data?.error || null;
					
				notification.open({
					message: response?.data?.message || 'Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
	},
	handleTableChange(pagesize){
		
		console.log('adad');
		console.log(pagesize);
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current, name: this.searchform.name }
		};
		
		this.loadCommonData(params);
		
	},
	EditDept(record){
		this.err = null
		this.edit_form = Object.assign({}, record);
	},
	DeleteDept(deptid){
		this.deldepid = deptid;
	},
	deleteDepartment(depid){
		console.log('Parent Dept Called');
		console.log(depid);
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.delete("/departments/"+depid, [])
          .then( (response) => {
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.departmentsmodel.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	updateDepartment(formval){
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
			
		var postform = new FormData();
		postform.append('department_name',formval.name);

		this.err = null
		
		axios.post("/departments/"+formval.id, postform)
          .then( (response) => {
				
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.departmentsmodel.closeDialog();
			 
			 this.create_form.department_name = '';
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
				this.err = response?.data?.error || null;		
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	createDepartment(formval){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		this.err = null;
		
		axios.post("/departments", formval)
          .then( (response) => {
				
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.departmentsmodel.closeDialog();
			 
			 this.create_form.department_name = '';
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
				this.err = response?.data?.error || null;		
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	updatePerPage(){
		
		var params = {
          params: { per_page: this.pagination.pageSize }
        };
        this.loadCommonData(params);
		
	},
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/departments", params)
			.then((response) => {
				
				
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				this.data = response.data.data.data;
				
				loader.hide();
				
				
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	}
  },
  mounted() {

	this.$store.dispatch('userPermissions', { module: 'department', action: 'read' }).then(response =>{
      this.hasReadPermission=null;
      this.$nextTick(()=>{
        this.hasReadPermission = response;
		if(response)
			{
				this.columns = [...columns,{
					title: "Action",
					sorter: false,
					key: "action",
					class: "text-end",
				}];
			}
      })
    })

	this.$store.dispatch('userPermissions', { module: 'department', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'department', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })

	this.$store.dispatch('userPermissions', { module: 'department', action: 'create' }).then(response =>{
      this.hasCreatePermission=null;
      this.$nextTick(()=>{
        this.hasCreatePermission = response;
      })
    })

	this.$store.dispatch('userPermissions', { module: 'department', action: 'import' }).then(response =>{
      this.hasImportPermission=null;
      this.$nextTick(()=>{
        this.hasImportPermission = response;
      })
    })
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };
	
	this.loadCommonData(params);
	
	
  },
  setup() {
    const schema = Yup.object().shape({
		department_import_file: Yup.string().required("File is required"),
    });
	return {
      schema
	}
  },
  computed:{
    department_demo_file()
    {
      return `${process.env.VUE_APP_IMAGE_BASE_URL}sample/departments.xlsx` 
    }
  },
};
</script>
