<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <employees-header :title="title" :path="path" :text="text" :text1="text1" @clearFormData="clearFormData" @clearEmployeeData="clearEmployeeData" /> 
        <!-- /Page Header -->

        <!-- Search Filter -->
        <search-filter @reload-data="searchData"></search-filter>
        <!-- /Search Filter -->

        <div class="row staff-grid-row">
          <div
            class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
            v-for="item in Employees"
            :key="item?.id"
          >
            <div class="profile-widget">
              <div class="profile-img" >
                <router-link :to="'profile/'+item?.id" class="avatar"
                  ><img v-if="item?.avatar && item?.avatar != null"
                    :src="getEmployeeAvatar(item?.avatar)"
                    alt="User Image"
                    @error="handleImageError(item)"
                />
                <img v-else
                :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                    alt="User Image"
                />
              </router-link>
              </div>
              <div class="dropdown profile-action" v-if="hasReadPermission">
                <a
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="material-icons">more_vert</i></a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a v-if="hasEditPermission"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_employee"
                    @click="employee(item)"
                    ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                  >
                  <a v-if="hasDeletePermission"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_employee"
                    @click="employee(item)"
                    ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                  >
                </div>
              </div>
              <h4 class="user-name m-t-10 mb-0 text-ellipsis">
                <router-link :to="'profile/'+item?.id">{{ item?.first_name }} {{ item?.last_name }}</router-link>
              </h4>
              <div class="small text-muted">{{ (item?.designation && item?.designation.name) ? item?.designation.name : '-' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="raw">
        <div class="col-12 text-center">
          <a-pagination @change="onChange" v-model:current="pagination.current"
          v-model:pageSize="pagination.pageSize" :total="pagination.total" show-less-items></a-pagination>
        </div>
      </div>
      <!-- /Page Content -->

      <employee-model :employeeData="employeeData" @reloadEmp="loadCommonData" :clearForm="clearForm" :clearEMP="clearEMP"></employee-model>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { notification } from "ant-design-vue";
import axios from 'axios';

export default {
  data() {
    return {
      clearEMP:false,
      clearForm:false,
      title: "Employee",
      path: "Dashboard",
      text: "Employee",
      text1: "Add Employee",
      employeeData:null,
      defaultAvatar:'avatar-02.jpg',
      refreshModel:true,
      hasEditPermission:null,
      hasDeletePermission:null,
      hasReadPermission:null,
      pagination:{
        total:0,
        current:0,
        pageSize:12
      },
      Employees:[],
      current:1,    
    };
  },
  methods:{
    ...mapActions(['fetchEmployees','setEmployeeData']),
    clearEmployeeData()
    {
      this.clearEMP = false;
      this.$nextTick(()=>{
        this.clearEMP = true;
      })
    },
    clearFormData()
    {
      this.clearForm = false;
      this.$nextTick(()=>{
        this.clearForm = true;
      })
    },
     employee(employee){
      this.refreshModel = false;
      this.setEmployeeData(employee);

      this.$nextTick(()=>{
        this.employeeData = employee;
        this.refreshModel = true;
      })
    },
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(item) {
      item.avatar = null;
      item.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
    searchData(searchData)
    {
      var params = {
        params: { per_page: this.pagination.pageSize }
      };
      this.loadCommonData(params,searchData);
    },
    loadCommonData(params,searchData = null)
    {

      var token = window.localStorage.getItem("token");
	
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.formContainer,
        canCancel: false
      });

      var getQuery = '/employee';

      if(searchData != null)
      {
        getQuery = '/employee?name='+searchData.name+'&employee_id='+searchData.employee_id+'&designation_id='+searchData.designation_id;
      }

      axios.get(getQuery, params)
			.then((response) => {
        loader.hide();
				this.pagination.total = response.data.data.record.total;
				this.pagination.current = response.data.data.record.current_page;
				this.pagination.pageSize = response.data.data.record.per_page;
				
				this.Employees = response.data.data.record.data || [];
			}).catch((error) => {
			  
			  loader.hide();
          
          var response = (error.response);
          
          if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
          
          localStorage.clear();
          
          notification.open({
              message: 'Please Login',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          this.$router.push({name: 'login'}).catch(error => {}) 
          
          }else{
          
          this.errorMessage = error.message;
          notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }
          
        });

    },
    onChange(current)
    {
      this.pagination.current = current;

      var params = {
        params: { per_page: this.pagination.pageSize,page:current }
      };
      
      this.loadCommonData(params);
    }
  },
  async created() {

    this.$store.dispatch('userPermissions', { module: 'employee', action: 'read' }).then(response =>{
      this.hasReadPermission=null;
      this.$nextTick(()=>{
        this.hasReadPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'employee', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'employee', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })

    var params = {
      params: { per_page: this.pagination.pageSize }
    };

    this.loadCommonData(params)
  }
  
};
</script>
