<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <tasks-sidebar :projects="projects"></tasks-sidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="pageWrapper">
      <div class="chat-main-row">
        <div class="chat-main-wrapper">
          <!--<tasks-view /> -->
		   <div class="col-lg-7 message-view">
			 <div class="chat-window">
				<div class="chat-contents" @click="toggleCloseMenu">
					<div class="chat-content-wrap">
					  <div class="chat-wrap-inner">
						<div class="chat-box">
						  <div class="task-wrapper">
							<div class="task-list-container">
							  <div class="task-list-body">
								<ul id="task-list">
								  <li>Please select a project for details</li>
								</ul>
							  </div>
							</div>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				</div>
		   </div>
        </div>
      </div>
      <tasks-model />
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>
<script>
var pagination = {total: 0,current: 1,pageSize: 10};
import axios from 'axios';
import { notification } from "ant-design-vue";
export default {
  data() {
    return {
      pagination: pagination,
	  projects: []
	};
  },
  mounted() {
    const pageWrapper = this.$refs.pageWrapper;
    this.setMinHeight(pageWrapper);
    window.addEventListener("resize", () => {
      this.setMinHeight(pageWrapper);
    });
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };	
	this.loadProjectData(params);
  },
  methods: {
    setMinHeight(element) {
      const windowHeight = window.innerHeight;
      element.style.minHeight = `${windowHeight}px`;
    },
	loadProjectData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.pageWrapper,
						canCancel: false
					});
		
		axios.get("/project/all", params)
			.then((response) => {
				
			
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				this.projects = response.data.data;
				
				loader.hide();
				
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	}
  },
};
</script>
