<template>
  <div class="col-xl-6">
    <div id="accordion" class="custom-faq mb-3">
      <div class="card mb-1">
        <div class="card-header" id="headingOne">
          <h5 class="accordion-faq m-0">
            <a
              class="text-dark"
              data-bs-toggle="collapse"
              href="#collapseOne"
              aria-expanded="true"
            >
              <i class="mdi mdi-help-circle me-1 text-primary"></i>
              What is Vakal text here?
            </a>
          </h5>
        </div>

        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordion"
        >
          <div class="card-body">
            Lorem ipsum is placeholder text commonly used in the graphic, print, and
            publishing industries for previewing layouts and visual mockups. Lorem ipsum
            is placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.
          </div>
        </div>
      </div>
      <div class="card mb-1">
        <div class="card-header" id="headingTwo">
          <h5 class="accordion-faq m-0">
            <a
              class="text-dark"
              data-bs-toggle="collapse"
              href="#collapseTwo"
              aria-expanded="false"
            >
              <i class="mdi mdi-help-circle me-1 text-primary"></i>
              Why use Vakal text here?
            </a>
          </h5>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordion"
        >
          <div class="card-body">
            Lorem ipsum is placeholder text commonly used in the graphic, print, and
            publishing industries for previewing layouts and visual mockups. Lorem ipsum
            is placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.
          </div>
        </div>
      </div>
      <div class="card mb-1">
        <div class="card-header" id="headingThree">
          <h5 class="accordion-faq m-0">
            <a
              class="text-dark"
              data-bs-toggle="collapse"
              href="#collapseThree"
              aria-expanded="false"
            >
              <i class="mdi mdi-help-circle me-1 text-primary"></i>
              How many variations exist?
            </a>
          </h5>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-bs-parent="#accordion"
        >
          <div class="card-body">
            Lorem ipsum is placeholder text commonly used in the graphic, print, and
            publishing industries for previewing layouts and visual mockups. Lorem ipsum
            is placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.
          </div>
        </div>
      </div>

      <div class="card mb-1">
        <div class="card-header" id="headingFour">
          <h5 class="accordion-faq m-0">
            <a
              class="text-dark"
              data-bs-toggle="collapse"
              href="#collapseFour"
              aria-expanded="false"
            >
              <i class="mdi mdi-help-circle me-1 text-primary"></i>
              What is Vakal text here?
            </a>
          </h5>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="collapseFour"
          data-bs-parent="#accordion"
        >
          <div class="card-body">
            Lorem ipsum is placeholder text commonly used in the graphic, print, and
            publishing industries for previewing layouts and visual mockups. Lorem ipsum
            is placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups.
          </div>
        </div>
      </div>
    </div>
    <!-- end #accordions-->
  </div>
</template>
