<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <breadcrumb :title="title" :path="path" :text="text" />
        <!-- /Page Header -->

        <div class="row">
          <div class="col-md-12">
		  
			 <ul class="nav nav-tabs nav-tabs-bottom" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Visibility Settings
                </a>
              </li>
			  <!--
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  >Leave Approval</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="messages-tab"
                  data-bs-toggle="tab"
                  href="#messages"
                  role="tab"
                  aria-controls="messages"
                  aria-selected="false"
                  >Offer Approval</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="settings-tab"
                  data-bs-toggle="tab"
                  href="#settings"
                  role="tab"
                  aria-controls="settings"
                  aria-selected="false"
                  >Resignation Notice</a
                >
              </li>-->
            </ul>
			
			<!-- Tab panes -->
            <div class="tab-content">
				 <visibility-setting></visibility-setting>
			</div>
			
			<!--<form>
				
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Cron Key <span class="text-danger">*</span></label
                >
                <input type="text" class="form-control" value="" />
              </div>
			  <div class="input-block mb-3">
                <label class="col-form-label"
                  >Holiday Visible <span class="text-danger">*</span></label
                ><br />
                <label class="switch">
                  <input type="hidden" name="holiday_visible" v-model="form.holiday_visible" />
                  <input type="checkbox" name="holiday_visible" v-model="form.holiday_visible" />
                  <span></span>
                </label>
              </div>
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Birthday Visible <span class="text-danger">*</span></label
                ><br />
				<label class="switch">
                  <input type="hidden" name="birthday_visible" v-model="form.birthday_visible" />
                  <input type="checkbox"  name="birthday_visible" v-model="form.birthday_visible" />
                  <span></span>
                </label>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Save Changes</button>
              </div>
            </form> -->
		  </div>	
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <!-- /Page Wrapper -->
    
  </div>
</template>

<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
export default {
  data() {
    return {
      title: "Global Settings",
      path: "Dashboard",
      text: "Global Settings",
      leaveTypeData : [],
      editPolicyId:null,
      editPolicyData:null,
	  form:{holiday_visible:false,birthday_visible:false}
    };
  },
  methods:{
    onEditPolicyID(val){
      this.editPolicyId = null;
      this.$nextTick(()=>{
        this.editPolicyId = val;
      })
    },
    onEditLeaveTypePolicyData(val){
      this.editPolicyData = null;
      this.$nextTick(()=>{
        this.editPolicyData = val;
      })
    },
    reloadLeaveTypeData()
    {
      this.fetchData();
    },
    async fetchData(){
      var token = window.localStorage.getItem("token");
		  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
      await axios.get('/leave-settings').then(response => {
        this.leaveTypeData = [];
        this.leaveTypeData = response?.data?.data || [];
        loader.hide();
      }).catch(error => {
        loader.hide();

        if(error.response){
        
          var response = (error.response);
            
          notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
          
        }else{
          
          notification.open({
            message: 'Server Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
       })

       
    },
  },
  created()
  {
    this.fetchData();
  }

};
</script>
