<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <div class="content container-fluid">
        <!-- Page Header -->
        <breadcrumb :title="title" :path="path" :text="text" />
        <!-- /Page Header -->
		<!--
		<div class="row">
			<div class="col-md-6 col-sm-6 col-lg-6 col-xl-2">
				<div class="stats-info">
					<h6>Full Day</h6>
					<h5>FD</h5>
				</div>
			</div>
			<div class="col-md-6 col-sm-6 col-lg-6 col-xl-2">
				<div class="stats-info">
					<h6>Half Day</h6>
					<h5>HD</h5>
				</div>
			</div>
			<div class="col-md-6 col-sm-6 col-lg-6 col-xl-2">
				<div class="stats-info">
					<h6>Absent</h6>
					<h5>A</h5>
				</div>
			</div>
			<div class="col-md-6 col-sm-6 col-lg-6 col-xl-2">
				<div class="stats-info">
					<h6>Holiday</h6>
					<h5>HL</h5>
				</div>
			</div>
			<div class="col-md-6 col-sm-6 col-lg-6 col-xl-2" v-for="singleleavetye in leavetype_list" :key="singleleavetye.id">
				<div class="stats-info">
					<h5>{{singleleavetye.type}}</h5>
					<h5>{{singleleavetye.short_code}}</h5>
				</div>
			</div>
			
		</div> -->
		<!--
		<div class="row">
			<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
				<p>Half Day (HD), Half Day (HD)</p>
			</div>
		</div>	-->
		 <div class="table-responsive mb-2">
              <table class="table table-striped custom-table table-nowrap mb-0">
					<tr>			
					  <td v-for="singleleavetye in leavetype_list" :key="singleleavetye.id">{{singleleavetye.short_code}}</td>
					  <td>FD</td>
					  <td>HD</td>
					  <td>A</td>
					  <td>HL</td>
					</tr>
					<tr>			
					  <td v-for="singleleavetye in leavetype_list" :key="singleleavetye.id">{{singleleavetye.type}}</td>
					  <td>Full Day</td>
					  <td>Half Day</td>
					  <td>Absent</td>
					  <td>Holiday</td>
					</tr>
		      </table>			
		 </div>
		<!-- Search Filter -->
       <!-- <attendance-filter></attendance-filter> -->
        <!-- /Search Filter -->
		<Form @submit="onSearch" class="mt-3">
		 <div class="row filter-row">
			<div class="col-sm-6 col-md-2">
			  <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
				<input
				  type="text"
				  class="form-control floating"
				  v-model="searchform.employeename"
				  @focus="isFocused = true"
				  @blur="isFocused = employeeID !== ''"
				/>
				<label class="focus-label">Employee Name</label>
			  </div>
			</div>
			<div class="col-sm-6 col-md-3">
			  <div class="input-block mb-3 form-focus select-focus">
				<vue-select :options="Month" id="month" placeholder="-" v-model="searchform.month" />
				<label class="focus-label">Select Month</label>
			  </div>
			</div>
			<div class="col-sm-6 col-md-3">
			  <div class="input-block mb-3 form-focus select-focus">
				<vue-select :options="Year" id="year" placeholder="-" v-model="searchform.year" />
				<label class="focus-label">Select Year</label>
			  </div>
			</div>
			<div class="col-sm-6 col-md-2">
				<button class="btn w-100 btn-success submit-btn">Search</button>
			</div>
      <div class="col-sm-6 col-md-2">
				<button @click.prevent="clearFilter" class="btn w-100 btn-danger  submit-btn">Clear</button>
			</div>
    </div>
		</Form>

        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-striped custom-table table-nowrap mb-0">
			 
                <thead>
                  <tr>
					<th>Employee</th>
					<th v-for="n in totaldays">{{ n }}</th>
                  </tr>
                </thead>
                <tbody>
				  <tr v-for="item in data" :key="item.id">
					<td>
						<h2 class="table-avatar">
                        
                        <router-link :to="{ path: '/profile/'+item.id}">{{ item.first_name }} {{ item.last_name }}</router-link>
                      </h2>
					</td>
					<td v-for="inneritem in item.monthlyreport.dates" :key="item.id">
						<a
                        href="javascript:void(0);"
						data-bs-toggle="modal"
                        data-bs-target="#attendance_info3"
						@click="AttendanceDetail(inneritem)"
                        v-if="inneritem.is_holiday == 1"
						style="color:green;"
						title="Holiday"
                        >HL<!--<i class="fa-solid fa-check text-success"></i 
						>-->
						
						</a>
						<a
                        href="javascript:void(0);"
						data-bs-toggle="modal"
                        data-bs-target="#attendance_info2"
						@click="AttendanceDetail(inneritem)"
                        v-else-if="inneritem.is_leave == 1"
						style="color:green;"
						v-bind:title="inneritem.leave_type"
                        >{{ getFirstLetters(inneritem.leave_type)}}<!--<i class="fa-solid fa-check text-success"></i
						>-->
						</a>
						<a
                        href="javascript:void(0);"
                        v-else-if="inneritem.isfuture == 1"
                        ></a>
						<a
                        href="javascript:void(0);"
                        v-else-if="inneritem.total_hours <= 0"
						style="color:red;"
						title="Absent"
                        >A<!--<i class="fa fa-close text-danger"></i
						>--></a>
						<a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
						@click="AttendanceDetail(inneritem)"
						v-else-if="inneritem.total_hours >= 5"
						style="color:green;"
						title="Full Day"
                        >FD<!--<i class="fa-solid fa-check text-success"></i
						>--></a>
						<a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
						data-bs-target="#attendance_info"
						@click="AttendanceDetail(inneritem)"
						style="color:green;"
						title="Half Day"
						v-else-if="inneritem.total_hours > 0 && inneritem.total_hours < 5"
                        >
						HD
						<!-- <div class="half-day">
                        <span class="first-off"
                          ><a
                            href="javascript:void(0);"
                            data-bs-toggle="modal"
                            data-bs-target="#attendance_info"
                            ><i class="fa-solid fa-check text-success"></i></a
                        ></span>
                        <span class="first-off"><i class="fa fa-close text-danger"></i></span>
                      </div>
					  -->
					  </a>
						<!--
						<a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i class="fa-solid fa-check text-success"></i
                      ></a>
					  
					 
					  
					  -->
					  
					  
					  
					  
					</td>
				  </tr>
                  <!--<tr v-for="item in Attendance" :key="item.id">
                    <td>
                      <h2 class="table-avatar">
                        <router-link class="avatar avatar-xs" to="profile"
                          ><img
                            :src="require(`@/assets/img/profiles/${item.Image}`)"
                            alt="User Image"
                        /></router-link>
                        <router-link to="profile">{{ item.Employee }}</router-link>
                      </h2>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class1"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class2"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class3"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class4"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class5"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class6"></i
                      ></a>
                    </td>
                    <td>
                      <div class="half-day">
                        <span class="first-off"
                          ><a
                            href="javascript:void(0);"
                            data-bs-toggle="modal"
                            data-bs-target="#attendance_info"
                            ><i :class="item.Class7"></i></a
                        ></span>
                        <span class="first-off"><i :class="item.Classa"></i></span>
                      </div>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class9"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i class="fa-solid fa-check text-success"></i
                      ></a>
                    </td>
                    <td><i :class="item.Class10"></i></td>
                    <td><i :class="item.Class11"></i></td>
                    <td><i :class="item.Class12"></i></td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class13"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class14"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class15"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class16"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class17"></i
                      ></a>
                    </td>
                    <td><i class="fa fa-close text-danger"></i></td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class18"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class19"></i
                      ></a>
                    </td>
                    <td>
                      <div class="half-day">
                        <span class="first-off"><i :class="item.Class20"></i></span>
                        <span class="first-off"
                          ><a
                            href="javascript:void(0);"
                            data-bs-toggle="modal"
                            data-bs-target="#attendance_info"
                            ><i :class="item.Classb"></i></a
                        ></span>
                      </div>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class22"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class22"></i
                      ></a>
                    </td>
                    <td><i :class="item.Class23"></i></td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class23"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class24"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class25"></i
                      ></a>
                    </td>
                    <td><i :class="item.Class26"></i></td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class27"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#attendance_info"
                        ><i :class="item.Class28"></i
                      ></a>
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <attendance-model :selecteddatedata="selecteddatedata"></attendance-model>
    </div>
    <!-- Page Wrapper -->
  </div>
</template>

<script>
import Attendance from "@/assets/json/attendance.json";

import axios from 'axios';
import { notification } from "ant-design-vue";

import { Form, Field } from "vee-validate";

export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      Attendance: Attendance,
      title: "Attendance",
      path: "Dashboard",
      text: "Attendance",
	  data: [],
	  totaldays: 30,
	  select2: null,
      Month: [
        "-",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      Year: ["-","2024", "2023", "2022", "2021", "2020", "2019"],
      employeeID: "",
      isFocused: false,
	  searchform: {employeename: "",month: "", year:""},
	  selecteddatedata : [],
	  leavetype_list: {},
    };
  },
  methods: {
    clearFilter()
    {
      this.searchform = {employeename: "",month: "", year:""};
      this.onSearch()
    },
    getFirstLetters(getstring) {
          var words = getstring.split(" ");
          var rs = words.map(word => word.charAt(0)).join("");
		  
		  return rs.toUpperCase();
    },
    AttendanceDetail(details){
	
		this.selecteddatedata = details;
	},
	onSearch(){
	
		var params = {
		   params: { employeename: this.searchform.employeename,month:this.searchform.month, year: this.searchform.year }
		};	
		
		this.loadCommonData(params);
		
	},
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/employeeattendance/adminattendance", params)
			.then((response) => {
				
				this.totaldays = response.data.days;
				
				this.data = response.data.monthlyreport;
				
				loader.hide();
				
				
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
	loadAllLeaveTypes(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/leavetypes/all", params)
			.then((response) => {
				this.leavetype_list = response.data.data;
				
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	}
  },
  mounted() {
	
	this.loadCommonData([]);
	this.loadAllLeaveTypes([]);
  }
};
</script>
