<template>
  <div class="col-lg-7 message-view task-view task-left-sidebar">
    <div class="chat-window">
      <div class="fixed-header">
        <div class="navbar">
          <div class="float-start me-auto">
            <div class="add-task-btn-wrapper">
              <button @click="toggleTaskInput" class="add-task-btn btn btn-white btn-sm">
                Add Task
              </button>
            </div>
          </div>
          <a
            class="task-chat profile-rightbar float-end"
            id="task_chat"
            @click="toggleTaskWindow"
            ><i class="fa fa fa-comment"></i
          ></a>
          <ul class="nav float-end custom-menu">
            <li class="nav-item dropdown dropdown-action">
              <a
                href="javascript:;"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa-solid fa-gear"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-end custom-dropdown-menu task-filter-status">
                <a class="dropdown-item" :class="{'active': taskFilterStatus == 'pending'}" href="javascript:void(0)" @click="filterStatus('pending')">Pending Tasks</a>
                <a class="dropdown-item" :class="{'active': taskFilterStatus == 'Completed'}" href="javascript:void(0)" @click="filterStatus('Completed')">Completed Tasks</a>
                <a class="dropdown-item" :class="{'active': taskFilterStatus == null}" href="javascript:void(0)" @click="filterStatus(null)">All Tasks</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="chat-contents" @click="toggleCloseMenu">
        <div class="chat-content-wrap">
          <div class="chat-wrap-inner">
            <div class="chat-box">
              <div class="task-wrapper" v-if="tasks?.length" >
                <div class="task-list-container">
                  <div class="task-list-body">
                    <ul id="task-list">
                      <li
                        class="task"
                        v-for="(task, index) in tasks"
                        :key="index"
                        :class="{ completed: task.task_status == 'Completed' ? true : false,'active': active_task_id == task.task_id}"
                        @click="selectTask(task.task_id)"
                      >
                        <div class="task-container">
                          <span class="task-action-btn task-check">
                            <span
                              :class="[
                                'action-circle',
                                'large',
                                'complete-btn',
                                { completed: task.task_status == 'Completed' ? true : false},
                              ]"
                              title="Mark Complete"
                              @click="markComplete(task)"
                            >
                              <i class="material-icons">check</i>
                            </span>
                          </span>
                          <span class="task-label">{{ task.task_title }}</span>
                          <span class="task-action-btn task-btn-right">
                            <span class="action-circle large me-1" title="Assign">
                              <i class="material-icons">person_add</i>
                            </span>
                            <span
                              class="action-circle large delete-btn"
                              title="Delete Task"
                              @click="delete_task_id = task?.task_id"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_task"
                            >
                              <i class="material-icons">delete</i>
                            </span>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  
                </div>
              </div>
              <div class="task-wrapper">
                <div class="task-list-container">
                  <div class="task-list-body">
                    <div class="task-list-footer">
                      <div v-if="showInput" class="new-task">
                        <textarea
                          v-model="newTask"
                          id="new-task"
                          placeholder="Enter new task here..."
                        ></textarea>
                        <span class="error-message" v-if="newTask.trim() === ''">
                          You need to enter a task first
                        </span>
                        <button @click="addTask" class="add-new-task-btn btn" :disabled="disabled">
                          Add Task
                        </button>
                        <button @click="closeTaskPanel" class="btn">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="notification-popup hide">
                <p>
                  <span class="task"></span>
                  <span class="notification-text"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="col-lg-5 message-view task-chat-view task-right-sidebar"
    id="task_window"
    :class="{ opened: taskWindowOpen }"
  >
    <tasks-right :setFollowers="followers" :fetchMessages__="fetch_messages" :task_id="active_task_id" :assignemp="assignEMp" @mark-complete="markComplete" @set-delete-task-id="setDeleteTaskID" @reset-value="resetValue"></tasks-right>
    <tasks-model :task_id="active_task_id" @assign-employee="assignEmployee" :reset_value="reset_value" @set-follower="setFollowers" @fetch-messages="fetchMessages_" />
    <!-- Delete Task Modal -->
    <div class="modal custom-modal fade" id="delete_task" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Task</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    @click="deleteTask"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    ref="delete_task_close"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Task Modal -->
    
  </div>
</template>
<script>
import axios from 'axios'
import { notification } from "ant-design-vue";
import "vue-toast-notification/dist/theme-default.css";
export default {
  data() {
    return {
      newTask: "",
      tasks: [],
      showInput: false,
      taskWindowOpen: false,
      taskChatOpen: false,
      taskFilterStatus:null,
      active_task_id:null,
      delete_task_id:null,
      assignEMp:null,
      reset_value:false,
      followers:null,
      fetch_messages:false,
      disabled:false
    };
  },

  methods: {
    fetchMessages_()
    {
      this.fetch_messages = false
      this.$nextTick(()=>{
        this.fetch_messages = true
      })
    },
    setFollowers(followers)
    {
      this.followers = null;
      this.$nextTick(()=>{
        this.followers = followers
      })
      this.fetch_messages = false
      this.$nextTick(()=>{
        this.fetch_messages = true
      })
    },
    resetValue()
    {
      this.reset_value = false

      this.$nextTick(()=>{
        this.reset_value = true
      })
      this.fetch_messages = false
      this.$nextTick(()=>{
        this.fetch_messages = true
      })
    },
    assignEmployee(employee)
    {
      this.assignEMp = null;
      this.$nextTick(()=>{
        this.assignEMp = employee
      })
      this.fetch_messages = false
      this.$nextTick(()=>{
        this.fetch_messages = true
      })
    },
    setDeleteTaskID(task_id = null)
    {
      this.delete_task_id = task_id
    },
    selectTask(task_id = null)
    {
      this.active_task_id = null;
      this.$nextTick(()=>{
        this.active_task_id = task_id
      })
    },
    filterStatus(status = null)
    {

      if(this.taskFilterStatus != status)
      {
        if(!status)
        {
          this.fetchTasks()
        }
        else
        {
          this.fetchTasks(status)
        }
      }

      this.taskFilterStatus= status
     
    },
    toggleTaskInput() {
      this.showInput = !this.showInput;
      if (!this.showInput) {
        this.newTask = "";
      }
    },
    deleteTask(){
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      axios.delete(`project/task/`+this?.delete_task_id).then((response) => {

        this.$refs?.delete_task_close?.click()

          notification.open({
                message: response?.data?.message || 'Successfull',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
                },
            });

            const task = this.tasks.filter(element => {
                return element.task_id !== this.delete_task_id;
            });

            this.tasks = task;

            if(this.active_task_id == this.delete_task_id)
            {
              this.active_task_id = null
            }

            this.delete_task_id = null

           

         

          

        }).catch(error=>{
          this.delete_task_id = null
          this.$refs?.delete_task_close?.click()
          if(error.response){
              var response = (error.response);
              notification.open({
                  message: response.data.message,
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                  },
              });
              }else{
              notification.open({
                  message: 'Server Error',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                  },
              });
          }
        })
    },
    addTask() {

      this.disabled = true;

      if (this.newTask.trim() === "") {
        this.$toast.open({
          message: `Please enter task title.`,
          type: "danger",
        });
        return;
      }

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      var project_id = this?.$route?.params?.id || null

      if(project_id)
      {

        const formData = new FormData();

        formData.append('task_title',this.newTask.trim());

        axios.post(`project/${project_id}/task/create`,formData).then((response) => {
          this.tasks.push({ text: this.newTask, completed: false });
          this.newTask = "";
          this.showInput = false;
          
          this.disabled = false;

          this.fetchTasks()

          notification.open({
                message: response?.data?.message || 'Successfull',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
                },
            });

        }).catch(error =>{
          this.disabled = false;
          if(error.response){
            var response = (error.response);
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            }else{
            notification.open({
                message: 'Server Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            }
        })
      }
      else
      {
        notification.open({
                message: 'Project Invalid',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
      }
    },

    fetchTasks(status = null)
    {
      var project_id = this?.$route?.params?.id || null
      if(project_id)
      {

      var params = null; 
      if(status)
      {
        params = {
          params:{'status':status}
				};
      }

      this.active_task_id = null

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        axios.get(`project/${project_id}/task`,params).then((response) => {


          this.tasks = response?.data?.data || []

        }).catch(error=>{
          if(error.response){
            var response = (error.response);
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            }else{
            notification.open({
                message: 'Server Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            }
        })
      }
      else
      {
        notification.open({
                message: 'Project Invalid',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
      }
    },
    closeTaskPanel() {
      this.showInput = false;
      this.newTask = "";
    },
    markComplete(task) {


      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;


      const formData = new FormData();

      const task_status = task.task_status == 'Pending' ? 'Completed' : 'Pending';

      formData.append('task_status',task_status);

      axios.post(`project/task/${task?.task_id}/status-update`,formData).then((response) => {

        task.task_status = task_status

        this.tasks.filter(task_value => {
          if(task_value.task_id == task.task_id)
          {
            task_value.task_status = task_status
          }
        })

        notification.open({
              message: response?.data?.message || 'Successfull',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
              background: process.env.VUE_APP_WARNING_COLOR,
              },
          });

        }).catch(error =>{
          if(error.response){
            var response = (error.response);
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            }else{
            notification.open({
                message: 'Server Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            }
        })
    },
    toggleTaskWindow() {
      this.taskWindowOpen = true;
    },
    toggleCloseMenu() {
      this.taskWindowOpen = false;
    },
  },
  created()
  {
    this.fetchTasks()
  },
  watch:{
    '$route.params.id': {
      handler(newId, oldId) {
          this.fetchTasks();
      },
      immediate: true
    }
  }
};
</script>
<style>
.complete-btn.completed {
  background-color: green !important;
  color: white !important;
}
.v-toast__text {
  z-index: 1051;
  max-width: 300px;
  word-break: break-word;
  display: inline-block;
  background: #1f8fef;
  border: 1px solid #1082e4;
  color: #ffffff;
  opacity: 0.8;
  border-radius: 2px;
  padding: 20px;
  box-sizing: border-box;
}
</style>
