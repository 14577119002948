<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <leaves-admin-header :title="title" :path="path" :text="text" :text1="text1" @AddEvent="AddEvent" :hasCreatePermission="hasCreatePermission" />

        <!-- /Page Header -->

        <!-- Leave Statistics -->
		<!--
        <div class="row">
          <div class="col-md-3 d-flex">
            <div class="stats-info w-100">
              <h6>Today Presents</h6>
              <h4>12 / 60</h4>
            </div>
          </div>
          <div class="col-md-3 d-flex">
            <div class="stats-info w-100">
              <h6>Planned Leaves</h6>
              <h4>8 <span>Today</span></h4>
            </div>
          </div>
          <div class="col-md-3 d-flex">
            <div class="stats-info w-100">
              <h6>Unplanned Leaves</h6>
              <h4>0 <span>Today</span></h4>
            </div>
          </div>
          <div class="col-md-3 d-flex">
            <div class="stats-info w-100">
              <h6>Pending Requests</h6>
              <h4>12</h4>
            </div>
          </div>
        </div>  -->
        <!-- /Leave Statistics -->

        <!-- Search Filter -->
       <!-- <leaves-search-filter></leaves-search-filter> -->
        <!-- /Search Filter -->
		
		<Form @submit="onSearch">
			<div class="row filter-row">
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <div class="input-block mb-3 form-focus focused">
					<!--
					<input
					  type="text"
					  class="form-control floating"
					  v-model="searchform.s_employee_id"
					  @focus="isFocused = true"
					  @blur="isFocused = searchform.s_employee_id !== ''"
					/>
					-->
					<vue-select
					  :options="employee_list2"
					  id="projectreportname"
					  v-model="searchform.s_employee_id"
					  placeholder="-"
					/>
				
					<label class="focus-label">Employee Name</label>
				  </div>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <div class="input-block mb-3 form-focus select-focus">
					<!--<vue-select :options="SelectOne" id="casual-leave" placeholder="-- Select --"  v-model="searchform.name" />
					<label class="focus-label">Leave Type</label> -->
					
					<Field
                    name="s_leave_type"
                    as="select"
                    class="form-control"
					v-model="searchform.s_leave_type"
				  >
				  <option value="">Select Leave Type</option>
				  <option v-for="leavetype in leavetype_list" :key="leavetype.id" :value="leavetype.id">{{ leavetype.type }}</option>
				</Field>
			  
				  </div>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <div class="input-block mb-3 form-focus select-focus">
				<!--	<vue-select :options="SelectTwo" id="pending-leave" placeholder="-- Select --" v-model="searchform.status" />
					<label class="focus-label">Leave Status</label> -->
					<Field
                    name="s_status"
                    as="select"
                    class="form-control"
					v-model="searchform.s_status"
				  >
				  <option value="">Select Leave Status</option>
				  <option value="Pending">Pending</option>
				  <option value="Approved">Approved</option>
				  <option value="Declined">Declined</option>
				</Field>
				  </div>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <div class="input-block mb-3 form-focus">
					<div class="cal-icon">
					  <datepicker
						v-model="searchform.s_from"
						placeholder="From"
						class="form-control floating datetimepicker"
						:editable="true"
						:clearable="false"
						 :input-format="dateFormat"
					  />
					</div>
				  </div>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <div class="input-block mb-3 form-focus">
					<div class="cal-icon">
					  <datepicker
						v-model="searchform.s_to"
						placeholder="To"
						class="form-control floating datetimepicker"
						:editable="true"
						:clearable="false"
						 :input-format="dateFormat"
					  />
					</div>
				  </div>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <!--<a href="javascript:;" class="btn btn-success w-100"> Search </a> -->
				  <button class="btn btn-success w-100 submit-btn">Search</button>
				   <a href="javascript:;" class="btn btn-danger w-100 submit-btn mt-2" @click="clearForm"> Clear </a>
				</div>
			  </div>
		</Form>  
  

        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
				  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Employee'">
                    <h2 class="table-avatar">
					  <!--
                      <router-link to="profile" class="avatar"
                        ><img
                          :src="require(`@/assets/img/profiles/${record.Image}`)"
                          alt="User Image"
                      /></router-link> -->
                      <a href="javascript:;"
                        >{{ record?.employee?.first_name }} {{ record?.employee?.last_name }}</a
                      >
                    </h2>
                  </template>
				  <template v-else-if="column.key === 'from'">
					{{ formatDate(record?.from) || record?.from }}
				  </template>
				  <template v-else-if="column.key === 'to'">
					{{ formatDate(record?.to) || record?.to }}
				</template>
                  <template v-else-if="column.key === 'Status'">
                    <div class="text-left">
                      <div class="dropdown action-label">
                        <a
                          class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                          href="javascript:;"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i :class="record.Class"></i> {{ record.status }}
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item" href="javascript:;" data-bs-toggle="modal"
                            data-bs-target="#approve_leave"
							@click="updateStatus(record.id,record.leave_type_id,'Pending')"
                            ><i class="fa-regular fa-circle-dot text-info"></i> Pending</a>
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#approve_leave"
							@click="updateStatus(record.id,record.leave_type_id,'Approved')"
                            ><i class="fa-regular fa-circle-dot text-success"></i>
                            Approved</a
                          >
                          <a class="dropdown-item" href="javascript:;" data-bs-toggle="modal"
                            data-bs-target="#approve_leave" @click="updateStatus(record.id,record.leave_type_id,'Declined')"
                            ><i class="fa-regular fa-circle-dot text-danger"></i>
                            Declined</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end">
						<!--<div class="dropdown dropdown-action" v-if="hasEditPermission || hasDeletePermission"> -->
					   <div class="dropdown dropdown-action">	
                        <a
						 
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a
						  
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_leave"
							@click="EditLeave(record)"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a
						 
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_approve"
							@click="DeleteLeaved(record.id)"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <leaves-admin-model :form="create_form" :leavetypelist="leavetype_list" :employeelist="employee_list" @create-leavedates="createLeaveDates" @edit-leavedates="editLeaveDates" @create-leave="createLeave" :editform="edit_form" @update-leave="updateLeave" :update_id="updateid" :updateleavetype_id="updateleavetypeid" :update_status="updatestatus" @update-leavestatus="updateLeavestatus" :delleave_id="delleaveid" @delete-leave="deleteLeave" ref="leavesmodel"></leaves-admin-model>
    </div>
    <!-- /Page Wrapper -->
	  
  </div>
</template>

<script>

var pagination = {total: 0,current: 1,pageSize: 10};

const columns = [
  {
    title: "Employee",
    dataIndex: "Employee",
    key: "Employee",
    sorter: false,
  },
  {
    title: "Leave Type",
    dataIndex: ["leave_type","type"],
    sorter: false,
  },
  {
    title: "From",
    dataIndex: "from",
	key: "from",
    sorter: false,
  },
  {
    title: "To",
    dataIndex: "to",
	key: "to",
    sorter: false,
  },
  /*{
    title: "No of Days",
    dataIndex: "NoofDays",
    sorter: {
      compare: (a, b) => {
        a = a.NoofDays.toLowerCase();
        b = b.NoofDays.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },*/
  {
    title: "Reason",
    dataIndex: "reason",
    sorter: false,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "Status",
    sorter: false,
  },
  
];

const data = [
 
];

import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import { Form, Field } from "vee-validate";

export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      columns:columns,
      data,
      title: "Leaves",
      path: "Dashboard",
      text: "Leaves",
      text1: "Add Leave",
	  pagination: pagination,
	  create_form: { "employee_id":"", "leave_type_id": "", "from": currentDate, "to": currentDateOne,"reason": "",leavedetails:[],status:"Approved"},
	  leavetype_list: {},
	  employee_list: {},
	  employee_list2: [],
	  edit_form: {},
	  selectededitobj: {},
	  updateid: "",
	  updatestatus: "",
	  updateleavetypeid: "",
	  delleaveid: "",
	  select2: null,
      SelectOne: [],
      SelectTwo: ["-- Select --", "Pending", "Approved", "Rejected"],
      startdate: currentDate,
      dateFormat: 'dd-MM-yyyy',
      startdateone: currentDateOne,
      employeeID: "",
      isFocused: false,
	  searchform: {s_employee_id: "",s_leave_type: "", s_status:"",s_from:"",s_to:""},
	  editobjto : "",
	  editobjfrom : "",
	  hasReadPermission:null,
	  hasCreatePermission:null,
	  hasEditPermission:null,
	  hasDeletePermission:null
    };
  },
  methods: {
	clearForm(){
		
		this.searchform = {name: ""};
		
		var params = {
		   params: { per_page: this.pagination.pageSize }
		};	
		this.loadCommonData(params);
		
	},
	loadAllEmployees2(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.fullPage ? null : this.$refs.formContainer,
						canCancel: false
					});
					
		axios.get("/employees/allemployees", params)
			.then((response) => {
				
			//	console.log(response.data.data.data);
				
				//this.employee_list = response.data.data;
				
				for(let i=0;i<response.data.data.length;i++){
					
					this.employee_list2.push({"id":response.data.data[i].id,"text":response.data.data[i].first_name+" "+response.data.data[i].last_name});
				}
					
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});			
					
	},
	AddEvent(){
		// console.log('parent Leaves called');
	},
	editLeaveDates(startdate,enddate){
	
		if(startdate > enddate){
			
			notification.open({
					message: 'To date must be greater than From',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			return;
				
		}
		
		var getdatesarr = this.editDates(startdate,enddate);
	
		// console.log('dates arr response..');
		// console.log(getdatesarr);
		
		this.edit_form.leavedetails = getdatesarr;
		
	},
	createLeaveDates(startdate,enddate){
		// console.log('parent createLeaveDates called');
		// console.log('startdate'+startdate);
		// console.log('enddate'+enddate);
		
		if(startdate > enddate){
			
			notification.open({
					message: 'To date must be greater than From',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			return;
				
		}
		
		
		var getdatesarr = this.createDates(startdate,enddate);
	
		// console.log('dates arr response..');
		// console.log(getdatesarr);
		
		this.create_form.leavedetails = getdatesarr;
	
		
	},
	editDates(startdate,enddate){
		// console.log('create dates called');
		// console.log(startdate);
		// console.log(enddate);
		
		if(startdate == "" || enddate == ""){
			return;
		}
		
		var cFrom = new Date(startdate);
		var cTo = new Date(enddate);
		
		if(cFrom > cTo){
			return;
		}
		
		let daysArr = [{'leave_details_date':moment(new Date(cFrom)).format('YYYY-MM-DD'),'leave_details_type':'FULLDAY'}];
		let tempDate = cFrom;
	
		while (tempDate < cTo) {
			tempDate.setUTCDate(tempDate.getUTCDate() + 1);
			
			var getdate = moment(new Date(tempDate)).format('YYYY-MM-DD');
			var getdate2 = moment(new Date(enddate)).format('YYYY-MM-DD');
			
			if(getdate <= getdate2){
			
				// console.log(this.selectededitobj.leavedetails);
				var getleavetype = "FULLDAY";  //default will be full day if matched then halfday/fullday accordingly
				
				for(let i=0;i<this.selectededitobj.leavedetails.length;i++){
					// console.log(this.selectededitobj.leavedetails[0]);
					// console.log(this.selectededitobj.leavedetails[i]);
					
					if(this.selectededitobj.leavedetails[i].leave_details_date == getdate){
						getleavetype = this.selectededitobj.leavedetails[i].leave_details_type;
					}
					
				}
				
				daysArr.push({'leave_details_date':moment(new Date(tempDate)).format('YYYY-MM-DD'),'leave_details_type':getleavetype});
			}
			
		}

		// console.log(daysArr);
		
		return daysArr;
		
	},
	createDates(startdate,enddate){
		// console.log('create dates called');
		// console.log(startdate);
		// console.log(enddate);
		
		if(startdate == "" || enddate == ""){
			return;
		}
		
		var cFrom = new Date(startdate);
		var cTo = new Date(enddate);
		
		if(cFrom > cTo){
			return;
		}
		
		let daysArr = [{'leave_details_date':moment(new Date(cFrom)).format('YYYY-MM-DD'),'leave_details_type':'FULLDAY'}];
		let tempDate = cFrom;
	
		while (tempDate < cTo) {
			tempDate.setUTCDate(tempDate.getUTCDate() + 1);
			
			var getdate = moment(new Date(tempDate)).format('YYYY-MM-DD');
			var getdate2 = moment(new Date(enddate)).format('YYYY-MM-DD');
			
			if(getdate <= getdate2){
			
				daysArr.push({'leave_details_date':moment(new Date(tempDate)).format('YYYY-MM-DD'),'leave_details_type':'FULLDAY'});
			}
			
		}

		// console.log(daysArr);
		
		return daysArr;
		
	},
	updatePerPage(){
		
		// console.log(this.pagination.pageSize);
		
		var s_from = this.searchform.s_from;
		
		if(s_from != ""){
			s_from = moment(s_from).format('YYYY-MM-DD');
		}
		
		var s_to = this.searchform.s_to;
		if(s_to != ""){
			s_to = moment(s_to).format('YYYY-MM-DD');
		}
		
		var params = {
          params: { per_page: this.pagination.pageSize,page:this.pagination.current, s_employee_id: this.searchform.s_employee_id, s_leave_type: this.searchform.s_leave_type, s_status: this.searchform.s_status,s_from:s_from, s_to: s_to }
        };
        this.loadCommonData(params);
		
	},
	handleTableChange(pagesize){
		
		// console.log('adad');
		// console.log(pagesize);
		
		var s_from = this.searchform.s_from;
		
		if(s_from != ""){
			s_from = moment(s_from).format('YYYY-MM-DD');
		}
		
		var s_to = this.searchform.s_to;
		if(s_to != ""){
			s_to = moment(s_to).format('YYYY-MM-DD');
		}
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current, s_employee_id: this.searchform.s_employee_id, s_leave_type: this.searchform.s_leave_type, s_status: this.searchform.s_status,s_from:s_from, s_to: s_to }
		};
		
		this.loadCommonData(params);
		
	},
	onSearch(){
		// console.log('search called');
		// console.log(this.searchform);
		
		var s_from = this.searchform.s_from;
		
		if(s_from != ""){
			s_from = moment(s_from).format('YYYY-MM-DD');
		}
		
		var s_to = this.searchform.s_to;
		if(s_to != ""){
			s_to = moment(s_to).format('YYYY-MM-DD');
		}
		
		var params = {
		   params: { per_page: this.pagination.pageSize,page:this.pagination.current, s_employee_id: this.searchform.s_employee_id, s_leave_type: this.searchform.s_leave_type, s_status: this.searchform.s_status,s_from:s_from, s_to: s_to }
		};	
		
		this.loadCommonData(params);
	
	},
	deleteLeave(leaveid){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.delete("/leaves/"+leaveid, [])
          .then( (response) => {
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.leavesmodel.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			// this.loadCommonData(params);
			
			this.onSearch();
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	DeleteLeaved(leaveid){
		this.delleaveid = leaveid;
	},
	updateLeavestatus(leaveid,updateleavetype_id,status){
		
		// console.log('updateLeavestatus');
		// console.log(leaveid);
		// console.log(status);
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		var postform = new FormData();		
		postform.append('status',status);
		postform.append('leave_type_id',updateleavetype_id);
		
		axios.put("/leaves/"+leaveid, postform)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.leavesmodel.closeDialog();
			 this.edit_form ={};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 //this.loadCommonData(params);
			 
			 this.onSearch();
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	updateStatus(leaveid,updateleavetypeid,status){
		
		this.updateid = leaveid;
		this.updateleavetypeid = updateleavetypeid;
		this.updatestatus = status;
		
	},
	updateLeave(formval){
		
		// console.log('update leave called');
		// console.log(formval);
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		var postform = new FormData();		
		postform.append('employee_id',formval.employee_id);
		postform.append('from',moment(formval.from).format('YYYY-MM-DD'));
		postform.append('to',moment(formval.to).format('YYYY-MM-DD'));
		postform.append('leave_type_id',formval.leave_type_id);
		postform.append('reason',formval.reason);
		postform.append('status',formval.status);
		postform.append('leave_data',JSON.stringify(formval.leave_data));
		
		
		axios.put("/leaves/"+formval.id, formval)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.leavesmodel.closeDialog();
			 this.edit_form ={};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 //this.loadCommonData(params);
			 
			 this.onSearch();
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
		
		
	},
	EditLeave(record){
		
		var editobj = Object.assign({}, record);
		
		if(editobj.from != ""){
			editobj.from = new Date(editobj.from);
			
			//this.editobjfrom = editobj.from;
		}
		
		if(editobj.end_date != ""){
			editobj.to = new Date(editobj.to);
			
			//this.editobjto = editobj.to;
		}
		
		this.$refs.leavesmodel.seteditdates(editobj.from,editobj.to);
		
		this.edit_form = editobj;
		
		this.selectededitobj = record;
		
	},
	createLeave(formval){
		
		// console.log('create leave called!!');
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.post("/leaves", formval)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.leavesmodel.closeDialog();
			 
			 this.create_form =  { "employee_id":"", "leave_type_id": "", "from": currentDate, "to": currentDate,"reason": "",leavedetails:[],status:'Approved'};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 //this.loadCommonData(params);
			 
			 this.onSearch();
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	loadAllEmployee(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/employees/allemployees", params)
			.then((response) => {
				
				// console.log('all employee loaded');
				// console.log(response.data.record);
				
				//this.employee_list = response.data.record.data;
				this.employee_list = response.data.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadAllLeaveTypes(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/leavetypes/all", params)
			.then((response) => {
				this.leavetype_list = response.data.data;
				this.SelectOne = response.data.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/leaves", params)
			.then((response) => {
							
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				this.data = response.data.data.data;
				
				loader.hide();
				
				
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
  },
  beforeMount() {
	
	var getdatesarr = this.createDates(this.create_form.from,this.create_form.to);
	
	// console.log('dates arr response..');
	// console.log(getdatesarr);
	
	this.create_form.leavedetails = getdatesarr;
  
  },
  mounted() {

  this.$store.dispatch('userPermissions', { module: 'leave', action: 'read' }).then(response =>{
		this.hasReadPermission=null;
		this.$nextTick(()=>{
			this.hasReadPermission = response;
			if(response)
			{
				this.columns = [...columns,{
					title: "Action",
					sorter: false,
					key: "action",
					class: "text-end",
				}];
			}
		})
	})

	this.$store.dispatch('userPermissions', { module: 'leave', action: 'create' }).then(response =>{
		this.hasCreatePermission=null;
		this.$nextTick(()=>{
			this.hasCreatePermission = response;
		})
	})

	this.$store.dispatch('userPermissions', { module: 'leave', action: 'write' }).then(response =>{
		this.hasEditPermission=null;
		this.$nextTick(()=>{
			this.hasEditPermission = response;
		})
	})

	this.$store.dispatch('userPermissions', { module: 'leave', action: 'delete' }).then(response =>{
		this.hasDeletePermission=null;
		this.$nextTick(()=>{
			this.hasDeletePermission = response;
		})
	})
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };	
	this.loadCommonData(params);
	
	this.loadAllLeaveTypes([]);
	
	this.loadAllEmployee([]);
	
	this.loadAllEmployees2([]);
	
  }
};
</script>
