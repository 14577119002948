<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <div class="sidebar-inner slimscroll">
      <div id="sidebar-menu" class="sidebar-menu">
	<!--	{{projects}} -->
        <ul>
		  <li>
            <a @click="redirectToHome()"
              ><i class="la la-home"></i> <span>Back to Home</span></a
            >
          </li>
		  <li class="menu-title">
            Projects
            <!-- <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#create_project"
              ><i class="fa-solid fa-plus"></i
            ></a> -->
          </li>
		  <li v-for="singleproject in projects" v-bind:class="{ active: currentPath == '/tasks/'+singleproject.project_id }">
			
			<router-link :to="{ path: '/tasks/'+singleproject.project_id}"  >{{ singleproject.project_name }}</router-link>
			
		  </li>
		  <!--
          <li>
            <router-link to="tasks">Project Management</router-link>
          </li>
          <li class="active">
            <router-link to="tasks">Hospital Administration</router-link>
          </li>
          <li>
            <router-link to="tasks">Video Calling App</router-link>
          </li>
          <li>
            <router-link to="tasks">Office Management</router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
  <!-- /Sidebar -->
</template>

<script>
export default {
  components: {},
  props: {
    projects: [Array, Object], 
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
 },
 methods:{
  redirectToHome() {
  let role = localStorage.getItem('role');
  if (role && role !== null) {
  //  role = JSON.parse(role); // Parse the JSON string to access the role
    if (role == 'Employee' || (role && role.includes('Employee'))) {
      this.$router.push({ name: 'employee-dashboard' }).catch(error => {});
    } else {
      this.$router.push({ name: 'admin-dashboard' }).catch(error => {});
    }
  }
}
 }	
};
</script>
