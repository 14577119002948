<template>
<div>
  <div class="row">
    <project-card
      v-for="project in projects"
      :key="project.id"
      :project="project"
      @edit-project="editProject"
      @delete-project="deleteProject"
    />
  </div>
  <project-list-model :form="create_form" :clients_list="clients_list" :teams_list="teams_list" :team_leads="team_leads" :wages_list="wages_list" @create-project="createProject" :delprojid="delprojid" @update-project="updateProject" :editform="edit_form" ref="projectmodel" @delete-proj="deleteProj"/>
  <div class="row" v-if="!projects.length">
    <div class="col-md-12 text-center pt-2 pb-2" >
      No records fould !
    </div>
  </div>
</div>
</template>
<script>
import ProjectCard from "@/views/pages/projects/ProjectsContent.vue"; // ProjectsList
import { mapActions } from 'vuex';
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import moment from 'moment';
import { notification } from "ant-design-vue";
import axios from 'axios';

var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};
export default {
  emits:['reload-data'],
  components: {
    ProjectCard,
  },
  props:{
    employeeID: {
      type: Number,
      default: null,
      required:false
    },
    projects:{
      required:false
    }
  },
  data() {
    return {
      create_form: { "project_name": "","client_id":"","wages_id":"","start_date":currentDate,"end_date":currentDateOne,"rate":"","priority":"","leader":"","description":""},
      clients_list: {},
      teams_list: {},
      team_leads: {},
	    wages_list: {},
      delprojid: "",
      edit_form:{},
      pagination: pagination,
    };
  },
  methods:{
    ...mapActions(['getEmployeeProject']),
    
  updateProject(formval){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		var postform = new FormData();		
		postform.append('project_name',formval.project_name);
		postform.append('start_date',moment(formval.start_date).format('YYYY-MM-DD'));
		postform.append('end_date',moment(formval.end_date).format('YYYY-MM-DD'));
		postform.append('client_id',formval.client_id);
		postform.append('rate',formval.rate);
		postform.append('wages_id',formval.wages_id);
		postform.append('priority',formval.priority);
		postform.append('description',formval.description);
		postform.append('leader',formval.leader);
		postform.append('teams',formval.teams);
		
		
		if(formval.file){
	    	postform.append('uploadfile',formval.file);
		}
		
		axios.post("/project/"+formval.project_id, postform, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
					})
          .then( (response) => {

            this.$emit('reload-data');
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.projectmodel.closeDialog();
			 this.edit_form ={};
			 
			 
			var params = {
			   params: { per_page: this.pagination.pageSize }
			};
			this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
		
	},
  editProject(formval){
		
		console.log('edit project callled');
		console.log(formval);
		
		if(formval.start_date != ""){
			formval.start_date = new Date(formval.start_date);
		}
		
		if(formval.end_date != ""){
			formval.end_date = new Date(formval.end_date);
		}
		
		this.edit_form = formval;
	},
  deleteProj(id){
		console.log('delete project id');
		console.log(id);
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.delete("/project/"+id, [])
          .then( (response) => {

            this.$emit('reload-data');
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.projectmodel.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
  searchProjectf(formval){
		console.log('search project called');
		console.log(formval);
		
		var params = {
          params: { projectName: formval.projectName,employeeName: formval.employeeName,designation: formval.designation }
        };
		
		this.loadCommonData(params);
	},
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
					
		axios.get("/project"+(this.employeeID && this?.employeeID != null ? '?employee_id='+this.employeeID : ''), params)
			.then((response) => {
				

				
				// this.projects = response.data.data.data;
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});			
					
	},
	loadAllDesignations(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/designations/all", params)
			.then((response) => {
				
			
				this.designations_list = response.data.data;
				
				if(response.data.data.length){
					
					for(let i=0;i<response.data.data.length;i++){
						
						this.designations_list[i].text = response.data.data[i].name;
					}
				}
				
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});			
	},
	loadAllTeams(params){
	
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/team/all", params)
			.then((response) => {
			
				
				this.teams_list = response.data.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadAllClients(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/client", params)
			.then((response) => {
				
				console.log('all clients loaded');
				//console.log(response.data.record.data);
				
				this.clients_list = response.data.record.data;
				
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadAllTeamLeads(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/employee", params)
			.then((response) => {
				
				console.log('all clients loaded');
				//console.log(response.data.record.data);
				this.team_leads = response.data.record.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadAllWages(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/wages/all", params)
			.then((response) => {
				
				console.log('all wages loaded');
			
				this.wages_list = response?.data?.data || [];
					  
			}).catch((error) => {
			  
			  console.log('wages error');
			  console.log(error);
			  var response = error.response;
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	}
  },
  async mounted(){
    await this.loadCommonData(this.params);
	
    await this.loadAllDesignations([]);
    
    await this.loadAllTeams([]);
    
    await this.loadAllClients([]);
    
    await this.loadAllTeamLeads([]);
    
    await this.loadAllWages([]);
  }
};
</script>
