<template>
  <div class="main-wrapper" ref="formContainer">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
	
	 <!-- Delete Project File Modal -->
  <div class="modal custom-modal fade" id="delete_projectfile" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Project File</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn" @click="deleteProjectFile"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn" ref="deleteprojectfileclose"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Project File Modal -->
  
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <project-view-header :title="title" :path="path" :text="text" :text1="text1" />
        <!-- /Page Header -->
        <div class="row">
          <div class="col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-body">
                <div class="project-title">
                  <h5 class="card-title">{{projectdetail.project_name}}</h5>
                  <!--<small class="block text-ellipsis m-b-15"
                    ><span class="text-xs">2</span>
                    <span class="text-muted ms-1">open tasks, </span
                    ><span class="text-xs">5</span>
                    <span class="text-muted ms-1">tasks completed</span></small
                  > -->
                </div>
                <p v-html="projectdetail.description">
				</p>
              </div>
            </div>
            <div class="card" style="display:none;">
              <div class="card-body">
                <h5 class="card-title m-b-20">Uploaded image files</h5>
                <div class="row">
                  <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                    <div class="uploaded-box">
                      <div class="uploaded-img">
                        <img
                          src="@/assets/img/placeholder.jpg"
                          class="img-fluid"
                          alt="Placeholder Image"
                        />
                      </div>
                      <div class="uploaded-img-name">demo.png</div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                    <div class="uploaded-box">
                      <div class="uploaded-img">
                        <img
                          src="@/assets/img/placeholder.jpg"
                          class="img-fluid"
                          alt="Placeholder Image"
                        />
                      </div>
                      <div class="uploaded-img-name">demo.png</div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                    <div class="uploaded-box">
                      <div class="uploaded-img">
                        <img
                          src="@/assets/img/placeholder.jpg"
                          class="img-fluid"
                          alt="Placeholder Image"
                        />
                      </div>
                      <div class="uploaded-img-name">demo.png</div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                    <div class="uploaded-box">
                      <div class="uploaded-img">
                        <img
                          src="@/assets/img/placeholder.jpg"
                          class="img-fluid"
                          alt="Placeholder Image"
                        />
                      </div>
                      <div class="uploaded-img-name">demo.png</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title m-b-20">Uploaded files</h5>
				<!--
				<ul class="files-list">
					<li>
						<div class="files-cont">
							<div class="file-type">
								<span class="files-icon"><i class="fa-regular fa-file-pdf"></i></span>
							</div>
							<div class="files-info">
								<span class="file-name text-ellipsis"><a href="#">AHA Selfcare Mobile Application
										Test-Cases.xls</a></span>
								<span class="file-author"><a href="#">John Doe</a></span> <span class="file-date">May 31st at 6:53 PM</span>
								<div class="file-size">Size: 14.8Mb</div>
							</div>
							<ul class="files-action">
								<li class="dropdown dropdown-action">
									<a href="" class="dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_horiz</i></a>
									<div class="dropdown-menu dropdown-menu-right">
										<a class="dropdown-item" href="javascript:void(0)">Download</a>
										<a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#share_files">Share</a>
										<a class="dropdown-item" href="javascript:void(0)">Delete</a>
									</div>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<div class="files-cont">
							<div class="file-type">
								<span class="files-icon"><i class="fa-regular fa-file-pdf"></i></span>
							</div>
							<div class="files-info">
								<span class="file-name text-ellipsis"><a href="#">AHA Selfcare Mobile Application
										Test-Cases.xls</a></span>
								<span class="file-author"><a href="#">Richard Miles</a></span> <span class="file-date">May 31st at 6:53 PM</span>
								<div class="file-size">Size: 14.8Mb</div>
							</div>
							<ul class="files-action">
								<li class="dropdown dropdown-action">
									<a href="" class="dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_horiz</i></a>
									<div class="dropdown-menu dropdown-menu-right">
										<a class="dropdown-item" href="javascript:void(0)">Download</a>
										<a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#share_files">Share</a>
										<a class="dropdown-item" href="javascript:void(0)">Delete</a>
									</div>
								</li>
							</ul>
						</div>
					</li>
				</ul> -->
					
				<ul class="files-list">
					<li v-for="item in projectdetail.files" :key="item.projectfile_id">
						<div class="files-cont">
							<div class="file-type">
								<span class="files-icon"><i class="fa-regular fa-file"></i></span>
							</div>
							<div class="files-info">
								<span class="file-name text-ellipsis"><a target="_blank" :href="'https://api.peoplekrafthr.com/public/storage/'+item.file_path">{{item.project_filename}}</a></span>
								<span class="file-author" v-if="item.owner">
									<router-link :to="{ path: '/profile/'+item.user_id}" >{{item.owner[0]['first_name']}} {{item.owner[0]['last_name']}}
									</router-link>
								</span> 
								<span class="file-date">{{ showTime(item.created_at) }}</span>
								<!--<span class="file-date">May 31st at 6:53 PM</span> -->
								<div class="file-size">Size: {{item.file_size}}Mb</div>
							</div>
							<ul class="files-action">
								<li class="dropdown dropdown-action">
									<a href="" class="dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_horiz</i></a>
									<div class="dropdown-menu dropdown-menu-right">
										<a class="dropdown-item" href="javascript:void(0)">Download</a>
										<!--<a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#share_files">Share</a>-->
										<a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_projectfile" @click="deleteProjectFileSelect(item)">Delete</a>
									</div>
								</li>
							</ul>
						</div>
					</li>
				</ul>
				
				<!--
				<ul class="files-list">
					<li v-for="(item, index) in projectdetail.files" :key="item.projectfile_id">
					 <div class="file-type">
                        <span class="files-icon"
                          ><i class="fa-regular fa-file"></i>
						  
						  <i class="fa-regular fa-file-pdf"></i>
						  <i class="fa-regular fa-image"></i>
						  </span>
                      </div>
					  <div class="files-info">
						<span class="file-name text-ellipsis"
                          ><a target="_blank" :href="'https://api.peoplekrafthr.com/public/storage/'+item.file_path"
                            >
							File{{index+1}}</a
						  >
						 </span>
						  <span class="file-author" v-if="item.owner"
                          ><a href="javascript:void(0);">{{item.owner.first_name}}</a></span
                        ><br/>
                        <span class="file-date">{{item.created_at}}</span>
                      </div>
				  </li>
				  
					<li>
                    <div class="files-cont">
                      <div class="file-type">
                        <span class="files-icon"
                          ><i class="fa-regular fa-file-pdf"></i
                        ></span>
                      </div>
                      <div class="files-info">
                        <span class="file-name text-ellipsis"
                          ><a href="javascript:void(0);"
                            >AHA Selfcare Mobile Application Test-Cases.xls</a
                          ></span
                        >
                        <span class="file-author"
                          ><a href="javascript:void(0);">John Doe</a></span
                        >
                        <span class="file-date">May 31st at 6:53 PM</span>
                        <div class="file-size">Size: 14.8Mb</div>
                      </div>
                      <ul class="files-action">
                        <li class="dropdown dropdown-action">
                          <a
                            href="javascript:;"
                            class="dropdown-toggle btn btn-link"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_horiz</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              >Download</a
                            >
                            <a
                              class="dropdown-item"
                              href="javascript:void(0);"
                              data-bs-toggle="modal"
                              data-bs-target="#share_files"
                              >Share</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div class="files-cont">
                      <div class="file-type">
                        <span class="files-icon"
                          ><i class="fa-regular fa-file-pdf"></i
                        ></span>
                      </div>
                      <div class="files-info">
                        <span class="file-name text-ellipsis"
                          ><a href="javascript:void(0);"
                            >AHA Selfcare Mobile Application Test-Cases.xls</a
                          ></span
                        >
                        <span class="file-author"
                          ><a href="javascript:void(0);">Richard Miles</a></span
                        >
                        <span class="file-date">May 31st at 6:53 PM</span>
                        <div class="file-size">Size: 14.8Mb</div>
                      </div>
                      <ul class="files-action">
                        <li class="dropdown dropdown-action">
                          <a
                            href="javascript:;"
                            class="dropdown-toggle btn btn-link"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_horiz</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)"
                              >Download</a
                            >
                            <a
                              class="dropdown-item"
                              href="javascript:void(0);"
                              data-bs-toggle="modal"
                              data-bs-target="#share_files"
                              >Share</a
                            >
                            <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li> 
                </ul> -->
              </div>
            </div>
            <!--<project-tasks /> -->
          </div>
          <project-details  :projectdetail="projectdetail" @updatePriority="updatePriority" />
        </div>
      </div>
      <project-view-model />
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { notification } from "ant-design-vue";
export default {
  data() {
    return {
      title: "Hospital Admin",
      path: "Dashboard",
      text: "Project",
      text1: "Edit Project",
	  projectdetail:{},
	  deleteprojectfileid : 0,
    };
  },
  methods: {
	deleteProjectFileSelect(item){
		this.deleteprojectfileid = item.projectfile_id;
	},
	deleteProjectFile(){
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.delete("/project/"+this.deleteprojectfileid+"/file", [])
			.then((response) => {
				
				
				
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});
				
				loader.hide();
				
				this.$refs.deleteprojectfileclose.click();
				
				this.loadCommonData(this.$route.params.id);
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
			  }
			});
			
	},
	updatePriority(projectdetail,typepriority){
		console.log('Parent Called');
		console.log(projectdetail);
		console.log(typepriority);
		
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		var formdata = new FormData();
		
		formdata.append('priority',typepriority);
		formdata.append('project_id',projectdetail.project_id);
		formdata.append('project_name',projectdetail.project_name);
		formdata.append('client_id',projectdetail.client_id);
		formdata.append('start_date',projectdetail.start_date);
		formdata.append('end_date',projectdetail.end_date);
		formdata.append('rate',projectdetail.rate);
		formdata.append('wages_id',projectdetail.wages_id);
		formdata.append('description',projectdetail.description);
		formdata.append('leader',projectdetail.leader);
		
		axios.put("/project/"+projectdetail.project_id, formdata)
			.then((response) => {
				
				
				
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});
				
				loader.hide();
				
			    this.loadCommonData(this.$route.params.id);
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
			  }
			});
			
	},
	showTime(gettime){
		
		console.log(gettime);
		
		return moment(gettime).format('Do MMM, h:mm a');
	},
	loadCommonData(id){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/project/"+id+"/detail", [])
			.then((response) => {
				
			
				this.projectdetail = response.data.data.record;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
			  }
			});
	}
  },
   mounted() {
	
	console.log(this.$route.params.id);
	
	this.loadCommonData(this.$route.params.id);
	
	
   }
};
</script>
