<template>
  <div class="table-responsive">
    <table class="table custom-table mb-0">
      <thead>
        <tr>
          <th>Project Name</th>
          <th>Progress</th>
          <th class="text-end">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,key) in projects" :key="key">
          <td>
            <h2>
              <router-link :to="'project-view/'+item?.project_id">{{ item.project_name }}</router-link>
            </h2>
            <small class="block text-ellipsis">
              <span>{{ getOpenTask(item?.task_details) }}</span> <span class="text-muted">open tasks, </span>
              <span>{{ getCompletedTask(item?.task_details) }}</span>
              <span class="text-muted ms-1">tasks completed</span>
            </small>
          </td>
          <td>
            <div class="progress progress-xs progress-striped">
              <div
                :class="'progress-bar w-'+getCompletedTaskPersentage(item?.task_details)"
                role="progressbar"
                data-bs-toggle="tooltip"
                :title="getCompletedTaskPersentage(item?.task_details)+'%'"
              ></div>
            </div>
          </td>
          <td class="text-end">
            <div class="dropdown dropdown-action">
              <a
                href="javascript:;"
                class="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="material-icons">more_vert</i></a
              >
              <div class="dropdown-menu dropdown-menu-right">
                <router-link class="dropdown-item" :to="'project-view/'+item?.project_id"><i class="fa-solid fa-eye m-r-5"></i> view</router-link>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  data() {
  },
  props:{
    project: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed:{
    projects(){
      return this.project.slice(0, 5) || [];
    }
  },
  methods:{
    getCompletedTask(task)
    {
      return task?.filter(task => task.task_status === "Completed")?.length || 0;
    },
    getOpenTask(task)
    {
      return task?.filter(task => task.task_status != "Completed")?.length || 0;
    },
    getCompletedTaskPersentage(task)
    {
      if (task === 0) return 0;
      return Math.round(((this.getCompletedTask(task) / task?.length) * 100));
    }
  }
};
</script>
