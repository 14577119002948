<template>
    <!-- Page Header -->
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">{{ title }}</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="admin-dashboard">{{ path }}</router-link>
            </li>
            <li class="breadcrumb-item active">{{ text }}</li>
          </ul>
        </div>
        <div class="col-auto float-end ms-auto" v-show="text1 != null">
          <a v-if="hasCreatePermission"
            @click="$emit('clean-wage-data')"
            href="javascript:;"
            class="btn add-btn"
            data-bs-toggle="modal"
            data-bs-target="#wage_update_modal"
  
            ><i class="fa-solid fa-plus"></i> {{ text1 }}</a
          >
          <a v-if="hasImportPermission"
            @click="$emit('clearImportForm')"
            href="javascript:;"
            class="btn add-btn mr-3"
            data-bs-toggle="modal"
            style="margin-right:5px"
            data-bs-target="#import_Wages"
            ><i class="fa-solid fa-upload"></i> Upload</a
          >
        </div>
      </div>
    </div>
    <!-- /Page Header -->
  </template> 
  <script>
  export default {
    emits: ["clean-wage-data",'clearImportForm'],
    props: {
      title: {
        type: String,
        default: "",
      },
      path: {
        type: String,
        default: "",
      },
      text: {
        type: String,
        default: "",
      },
      text1: {
        type: String,
        default: "",
        require:false
      },
    },
    data()
    {
      return {
        hasCreatePermission:null,
        hasImportPermission:null
      }
    },
    mounted()
    {
      this.$store.dispatch('userPermissions', { module: 'wages', action: 'create' }).then(response =>{
        this.hasCreatePermission=null;
        this.$nextTick(()=>{
          this.hasCreatePermission = response;
        })
      })

      this.$store.dispatch('userPermissions', { module: 'wages', action: 'import' }).then(response =>{
        this.hasImportPermission=null;
        this.$nextTick(()=>{
          this.hasImportPermission = response;
        })
      })
    }
  };
  </script>
  