<template>
  <!-- Page Header -->
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="page-title">{{ title }}</h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="admin-dashboard">{{ path }}</router-link>
          </li>
          <li class="breadcrumb-item active">{{ text }}</li>
        </ul>
      </div>
      <div class="col-auto float-end ms-auto">
        <a v-if="hasCreatePermission"
          href="javascript:;"
          class="btn add-btn"
          data-bs-toggle="modal"
          data-bs-target="#add_employee"
          @click="$emit('clearEmployeeData')"
          ><i class="fa-solid fa-plus"></i> {{ text1 }}</a
        >
        <a v-if="hasImportPermission"
          href="javascript:;"
          class="btn add-btn mr-3 import-emp-button"
          data-bs-toggle="modal"
          style="margin-right:5px"
          data-bs-target="#import_employee"
          ref="OpenImportModal"
          @click="$emit('clearFormData')"
          ><i class="fa-solid fa-upload"></i> Upload</a
        >
        <div class="view-icons">
          <router-link to="employees" class="grid-view btn btn-link active"
            ><i class="fa fa-th"></i
          ></router-link>
          <router-link to="employees-list" class="list-view btn btn-link"
            ><i class="fa-solid fa-bars"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Header -->
</template>

<script>
export default {
  emits:['clearFormData','clearEmployeeData'],
  props: {
    title: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    text1: {
      type: String,
      default: "",
    },
  },
  data()
    {
      return {
        hasCreatePermission:null,
        hasImportPermission:null
      }
    },
    mounted()
    {
      this.$store.dispatch('userPermissions', { module: 'employee', action: 'create' }).then(response =>{
        this.hasCreatePermission=null;
        this.$nextTick(()=>{
          this.hasCreatePermission = response;
        })
      })
      this.$store.dispatch('userPermissions', { module: 'employee', action: 'import' }).then(response =>{
        this.hasImportPermission=null;
        this.$nextTick(()=>{
          this.hasImportPermission = response;
        })
      })
    }
};
</script>

<style>
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px !important;
}
</style>


