<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <clients-header :title="title" :path="path" :text="text" :text1="text1" />
        <!-- /Page Header -->
        <!-- Search Filter -->
        <clients-filter @reload-data="searchData" :Companies="Companies" :searchform="searchform"></clients-filter>
        <!-- Search Filter -->
        <div class="row">
          <div class="col-md-12">
			<div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
				  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label
              >
            </div>
            <div class="table-responsive">
		      <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data2"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Name'">
				  
					<div class="table-avatar">
					   <router-link :to="{ path: '/clientprofile/'+record.id}" class="avatar"
							><img v-if="record?.avatar != null"
						  :src="getEmployeeAvatar(record?.avatar)"
						  @error="handleImageError(record)"
							alt="User Image"
						/>
						<img v-else
						:src="require(`@/assets/img/profiles/${defaultAvatar}`)"
							alt="User Image"
						/></router-link>
					
						
						  <router-link to="profile"
							>{{ record.first_name }} {{ record.last_name }}<span></span></router-link
						  >
						</div>
						
						
				  </template>
                  <template v-else-if="column.key === 'Status'">
					<div>
                      <div class="dropdown action-label">
                        <a
						  v-if="record.status== 1"
                          href="javascript:void(0);"
                          class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa-regular fa-circle-dot text-success"></i>
                          Active
                        </a>
						<a
						  v-else
                          href="javascript:void(0);"
                          class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa-regular fa-circle-dot text-danger"></i>
                          Inactive
                        </a>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="fa-regular fa-circle-dot text-success"></i>
                            Active</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="fa-regular fa-circle-dot text-danger"></i>
                            Inactive</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'Action'">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:void(0);"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ><i class="material-icons">more_vert</i></a
                      >
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_client"
						  @click="employee(record)"
                          ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                        >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_client"
						  @click="employee(record)"
                          ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                        >
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <clients-list-model :employeeData="employeeData" :createform="createform" :Companies="Companies" @refreshClients="refreshClients" ref="clientmodel" />
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: false
  },
  {
    title: "Client Id",
    dataIndex: "client_id",
    sorter: false,
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: false,
  },
  {
    title: "Phone",
    dataIndex: "contact_number",
    sorter: false,
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: false,
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "Action",
    sorter: false,
  },
];
const data = [
  {
    Name: "Carlson Tech",
    ClientId: "CLT-0008",
    ContactPerson: "Betty Carlson",
    Email: "bettycarlson@example.com",
    Mobile: "9876543210",
    Class: "fa-regular fa-circle-dot text-danger",
    Status: "Inactive",
    Image: "avatar-22.jpg",
  },
  {
    Name: "Cream Inc",
    ClientId: "CLT-0003",
    ContactPerson: "Ruby Bartlett",
    Email: "rubybartlett@example.com",
    Mobile: "9876543210",
    Class: "fa-regular fa-circle-dot text-success",
    Status: "Active",
    Image: "avatar-07.jpg",
  },
  {
    Name: "Delta Infotech",
    ClientId: "CLT-0002",
    ContactPerson: "Tressa Wexler",
    Email: "tressawexler@example.com",
    Mobile: "9876543210",
    Class: "fa-regular fa-circle-dot text-danger",
    Status: "Inactive",
    Image: "avatar-29.jpg",
  },
  {
    Name: "Global Technologies",
    ClientId: "CLT-0001",
    ContactPerson: "Barry Cuda",
    Email: "barrycuda@example.com",
    Mobile: "9876543210",
    Class: "fa-regular fa-circle-dot text-success",
    Status: "Active",
    Image: "avatar-19.jpg",
  },
  {
    Name: "International Software Inc",
    ClientId: "CLT-0006",
    ContactPerson: "Walter Weaver",
    Email: "walterweaver@example.com",
    Mobile: "9876543210",
    Class: "fa-regular fa-circle-dot text-success",
    Status: "Active",
    Image: "avatar-18.jpg",
  },
  {
    Name: "Mercury Software Inc",
    ClientId: "CLT-0007",
    ContactPerson: "Amanda Warren",
    Email: "amandawarren@example.com",
    Mobile: "9876543210",
    Class: "fa-regular fa-circle-dot text-success",
    Status: "Active",
    Image: "avatar-28.jpg",
  },
  {
    Name: "Mustang Technologies",
    ClientId: "CLT-0005",
    ContactPerson: "Daniel Deacon",
    Email: "danieldeacon@example.com",
    Mobile: "9876543210",
    Class: "fa-regular fa-circle-dot text-success",
    Status: "Active",
    Image: "avatar-14.jpg",
  },
  {
    Name: "Wellware Company",
    ClientId: "CLT-0004",
    ContactPerson: "Misty Tison",
    Email: "mistytison@example.com",
    Mobile: "9876543210",
    Class: "fa-regular fa-circle-dot text-success",
    Status: "Active",
    Image: "avatar-06.jpg",
  },
];

import axios from 'axios';
import { notification } from "ant-design-vue";
export default {
  data() {
    return {
      title: "Clients",
      path: "Dashboard",
      text: "Clients",
      text1: "Add Client",
      columns,
      data,
	  pagination: {total: 0,current: 1,pageSize: 10},
	  data2:[],
	  defaultAvatar:'avatar-02.jpg',
	  avatar: "avatar-02.jpg",
	  Companies: [],
	  searchform:{clientId:"",clientName:"",companyid:""},
	  employeeData:null,
	  createform : [],
    };
  },
  name: "clients-list",
  methods: {
	employee(employee){
       this.employeeData = employee;
    },
	refreshClients(){
		
    	var params = {
        params: { per_page: this.pagination.pageSize }
      };
      
      this.loadCommonData(params);
	},
	searchData(searchData){
	
	  console.log(searchData);
	
      var params = {
        params: { per_page: this.pagination.pageSize,clientId:this.searchform.clientId,clientName:this.searchform.clientName,companyid:this.searchform.companyid }
      };
      this.loadCommonData(params);
	  
    },
	updatePerPage(){
		console.log(this.pagination.pageSize);
		var params = {
          params: { per_page: this.pagination.pageSize,clientId:this.searchform.clientId,clientName:this.searchform.clientName,companyid:this.searchform.companyid}
        };
        this.loadCommonData(params);
		
	},
	handleTableChange(pagesize){
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current,clientId:this.searchform.clientId,clientName:this.searchform.clientName,companyid:this.searchform.companyid }
		};
		
		this.loadCommonData(params);
		
	},
	handleImageError(emp) {
      emp.avatar = null;
      emp.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
	getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
	loadCompanies(){
	
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/company", [])
			.then((response) => {
				
				console.log(response.data.data.record);
				
				this.Companies = response.data.data.record;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
	loadCommonData(params){
		console.log('common data loading called..');
		
			var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.fullPage ? null : this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/client", params)
			.then((response) => {
				
				console.log(response.data.record);
				
				this.data2 = response.data.record.data;
			
				this.pagination.total = response.data.record.total;
				this.pagination.current = response.data.record.current_page;
				this.pagination.pageSize = response.data.record.per_page;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	}
	
  },
  mounted() {
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };
	this.loadCommonData(params);
	
	this.loadCompanies([]);
	
  },
};
</script>
