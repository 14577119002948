<template>
    <div class="tab-pane fade" id="userOffices" ref="formContainer">
        <div v-if="activeTab == 'userOffices'">
            <div class="row">
                <div class="col-6">
                    <div class="showentries mb-3">
                        <label
                        >Show
                        <select v-model="pagination.pageSize" @change="updatePerPage()">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        entries
                        </label>
                    </div>
                </div>
                <div class="col-auto float-end ms-auto">
                    <a
                    href="javascript:;"
                    class="btn add-btn mr-3"
                    style="margin-right:5px"
                    data-bs-toggle="modal"
                    data-bs-target="#user_office_modal"
                    @click="searchOfficeClick"
                    > Assign Office</a
                    >
                    <div class="d-flex" style="padding-right: 10px;">
                        <label class="col-form-label ">WFH :</label>
                        <div class="form-switch">
                            <input
                            @change="handleChangeEvent()"
                            v-model="work_from"
                            type="checkbox"
                            class="form-check-input"
                            id="customSwitch1"
                            value="true"
                            :checked="work_from"
                            />
                            <span class="text-danger" v-if="errors && errors?.work_from && errors?.work_from != null">{{ errors?.work_from[0]  }}</span>

                            <label class="form-check-label" for="customSwitch1"></label>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="row">
                <div :class="work_from ? 'col-md-6 border-rigth' : 'col-md-12'">
                    <a-table
                        class="stripped table-hover"
                        :columns="columns"
                        :data-source="data"
                        :pagination="pagination"
                        @change="handleTableChange"
                    >
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'office_address'">
                                <div class="text-truncate" style="max-width: 250px;">{{ record?.office_address }} {{ record?.office_city }} {{ record?.office_state }}</div>
                                </template>
                                <template v-else-if="column.key === 'action'">
                                <div class="text-end">
                                    <div class="dropdown dropdown-action">
                                        <a
                                            href="javascript:;"
                                            class="action-icon dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            ><i class="material-icons">more_vert</i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a
                                                class="dropdown-item"
                                                href="javascript:;"
                                                @click="DeleteOfficeUser(record?.office_id)"
                                                ><i class="fa-regular fa-trash-can m-r-5"></i> Inactive Office for user</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                </template>
                        </template>
                    </a-table>
                    <div v-if="work_from">
                        <br>
                        <br>    
                        <br>    
                        <br>    

                    </div>
                </div>
                <div v-if="work_from" class="col-md-6 col-sm-12 border-right row"> 
                    <div class="col-md-12 pb-1 pt-3">
                        <p>Please select here days for that want to do Work From Home.</p>
                    </div>
                    <div class="d-flex col-md-4 col-sm-6" style="padding-right: 10px;">
                        <label class="col-form-label ">Monday : </label>
                        <div class="form-switch">
                            <input
                            @change="handleWeekChangeEvent()"
                            v-model="wfh.monday"
                            type="checkbox"
                            class="form-check-input"
                            id="customSwitch1"
                            value="true"
                            :checked="wfh.monday"
                            />
                            <label class="form-check-label" for="customSwitch1"></label>
                        </div>
                    </div>
                    <div class="d-flex col-md-4 col-sm-6" style="padding-right: 10px;">
                        <label class="col-form-label ">Tuesday : </label>
                        <div class="form-switch">
                            <input
                            @change="handleWeekChangeEvent()"
                            v-model="wfh.tuesday"
                            type="checkbox"
                            class="form-check-input"
                            id="customSwitch2"
                            value="true"
                            :checked="wfh.tuesday"
                            />
                            <label class="form-check-label" for="customSwitch2"></label>
                        </div>
                    </div>
                    <div class="d-flex col-md-4 col-sm-6" style="padding-right: 10px;">
                        <label class="col-form-label ">Wednesday : </label>
                        <div class="form-switch">
                            <input
                            @change="handleWeekChangeEvent()"
                            v-model="wfh.wednesday"
                            type="checkbox"
                            class="form-check-input"
                            id="customSwitch3"
                            value="true"
                            :checked="wfh.wednesday"
                            />
                            <label class="form-check-label" for="customSwitch3"></label>
                        </div>
                    </div>
                    <div class="d-flex col-md-4 col-sm-6" style="padding-right: 10px;">
                        <label class="col-form-label ">Thursday : </label>
                        <div class="form-switch">
                            <input
                            @change="handleWeekChangeEvent()"
                            v-model="wfh.thursday"
                            type="checkbox"
                            class="form-check-input"
                            id="customSwitch5"
                            value="true"
                            :checked="wfh.thursday"
                            />
                            <label class="form-check-label" for="customSwitch5"></label>
                        </div>
                    </div>
                    <div class="d-flex col-md-4 col-sm-6" style="padding-right: 10px;">
                        <label class="col-form-label ">Friday : </label>
                        <div class="form-switch">
                            <input
                            @change="handleWeekChangeEvent()"
                            v-model="wfh.friday"
                            type="checkbox"
                            class="form-check-input"
                            id="customSwitch6"
                            value="true"
                            :checked="wfh.friday"
                            />
                            <label class="form-check-label" for="customSwitch6"></label>
                        </div>
                    </div>
                    <div class="d-flex col-md-4 col-sm-6" style="padding-right: 10px;">
                        <label class="col-form-label ">Saturday : </label>
                        <div class="form-switch">
                            <input
                            @change="handleWeekChangeEvent()"
                            v-model="wfh.saturday"
                            type="checkbox"
                            class="form-check-input"
                            id="customSwitch7"
                            value="true"
                            :checked="wfh.saturday"
                            />
                            <label class="form-check-label" for="customSwitch7"></label>
                        </div>
                    </div>
                    <div class="d-flex col-md-4 col-sm-6" style="padding-right: 10px;">
                        <label class="col-form-label ">Sunday : </label>
                        <div class="form-switch">
                            <input
                            @change="handleWeekChangeEvent()"
                            v-model="wfh.sunday"
                            type="checkbox"
                            class="form-check-input"
                            id="customSwitch4"
                            value="true"
                            :checked="wfh.sunday"
                            />
                            <label class="form-check-label" for="customSwitch4"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Task Followers Modal -->
        <div id="user_office_modal" class="modal custom-modal fade" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title">Assign Office to user</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <div class="input-group m-b-30">
                    <input
                    v-model="searchOffice"
                    placeholder="Search to add"
                    class="form-control search-input"
                    type="text"
                    />
                    <button class="btn btn-primary" @click="searchOfficeClick">Search</button>
                </div>
                <div class="scrollable-content">
                    <ul class="chat-user-list">
                        <li v-for="offic in offices" :key="'office_'+offic.office_id">
                            <a  @click="assignOffice(offic?.office_id)">
                            <div class="chat-block d-flex">
                                <div class="media-body media-middle text-nowrap">
                                <div class="user-name">{{ offic?.office_name }}</div>
                                <span class="designation">{{ offic?.office_address }} {{ offic?.office_city }} {{ offic?.office_state }}</span>
                                </div>
                            </div>
                            </a>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
            </div>
        </div>
        <!-- /Task Followers Modal -->
    </div>
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
var pagination = {total: 0,
            current: 1,
            pageSize: 10};
const columns = [
  {
	  title: "#",
	  dataIndex: "id",
	  key: "id",
	  customRender: ({ index }) => {
		return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
  },
  {
    title: "Office Name",
    dataIndex: "office_name",
    key: "office_name",
    sorter: {
      compare: (a, b) => {
        a = a.office_name.toLowerCase();
        b = b.office_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Office Address",
    dataIndex: "office_address",
    key: "office_address",
    sorter: {
      compare: (a, b) => {
        a = a.office_address.toLowerCase();
        b = b.office_address.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    sorter: false,
    key: "action",
    class: "text-end",
  },

]

export default {
    emits:['fetchEmp','reload-data'],
    components: {
        Form,
        Field,
    },
    props:{
        employeeID: {
            type: Number,
            default: null,
            required:false
        },
        activeTab:{
            type: String,
            default: null,
            required:false 
        },
        employeeData: {
        type: Object,
        default: null,
        },
    },
    data(){
        return {
            pagination,
            data:[],
            work_from:null,
            columns,
            errors:[],
            offices:[],
            wfh:{
                monday:null,
                tuesday:null,
                wednesday:null,
                thursday:null,
                friday:null,
                saturday:null,
                sunday:null
            }
        }
    },
    watch:{
        employeeData(newVal){
            
            this.work_from = newVal?.work_from == 2 ? true : false;
         
            this.wfh = newVal?.wfh_days ? JSON.parse(newVal?.wfh_days) : this.wfh;
        }
    },
    methods:{
        handleWeekChangeEvent()
        {
            var token = window.localStorage.getItem("token");
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            var user_id = this?.$route?.params?.user_id || '';

            var formData = new FormData()

          
            formData.append('wfh',JSON.stringify(this.wfh))

            axios.post("employee/update-wfh/"+user_id,formData)
			.then((response) => {
                loader.hide();
                this.$emit('reload-data');

            }).catch(error => {

                loader.hide();
			  
                var response = (error.response);
                
                if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
                    
                    localStorage.clear();
                    
                    notification.open({
                            message: 'Please Login',
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                        
                    this.$router.push({name: 'login'}).catch(error => {}) 
                    
                }else{
                    notification.open({
                            message: response.data.message,
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                }


            })

        },
        assignOffice(office_id)
        {
            var token = window.localStorage.getItem("token");
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            var user_id = this?.$route?.params?.user_id || '';

            axios.put("user/assign-office/"+user_id+'/'+office_id)
			.then((response) => {

                loader.hide();

                notification.open({
                    message: response?.data?.message || 'Successful',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                });

                var params = {
                    params: { per_page: this.pagination.pageSize }
                };

                this.fetchData(params)

            }).catch(error => {

                loader.hide();
			  
                var response = (error.response);
                
                if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
                    
                    localStorage.clear();
                    
                    notification.open({
                            message: 'Please Login',
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                        
                    this.$router.push({name: 'login'}).catch(error => {}) 
                    
                }else{
                    notification.open({
                            message: response.data.message,
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                }


            })

        },
        searchOfficeClick()
        {
            this.offices = []
            var token = window.localStorage.getItem("token");
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            var params = {
                    params: { search: this.searchOffice }
                };
            
            axios.get("office/all", params)
			.then((response) => {
                loader.hide();
				

                this.offices = response?.data?.data || []

            }).catch(error => {

                this.offices = []

                loader.hide();
			  
                var response = (error.response);
                
                if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
                    
                    localStorage.clear();
                    
                    notification.open({
                            message: 'Please Login',
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                        
                    this.$router.push({name: 'login'}).catch(error => {}) 
                    
                }else{
                    notification.open({
                            message: response.data.message,
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                }


            })
        },
        DeleteOfficeUser(office_id)
        {
            var token = window.localStorage.getItem("token");
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            var user_id = this?.$route?.params?.user_id || '';

            axios.put("user/office-status/"+user_id+'/'+office_id)
			.then((response) => {

                loader.hide();

                notification.open({
                    message: response?.data?.message || 'Successful',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                });

                var params = {
                    params: { per_page: this.pagination.pageSize }
                };

                this.fetchData(params)

            }).catch(error => {

                loader.hide();
			  
                var response = (error.response);
                
                if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
                    
                    localStorage.clear();
                    
                    notification.open({
                            message: 'Please Login',
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                        
                    this.$router.push({name: 'login'}).catch(error => {}) 
                    
                }else{
                    notification.open({
                            message: response.data.message,
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                }


            })
        },
        handleTableChange(pagesize){
		
            var params = {
            params: { per_page: pagesize.pageSize,page:pagesize.current }
            };
            
            this.fetchData(params);
            
        },
        updatePerPage(){
		
            var params = {
            params: { per_page: this.pagination.pageSize }
            };
            this.fetchData(params);
            
        },
        fetchData(params){

            var token = window.localStorage.getItem("token");
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            var user_id = this?.$route?.params?.user_id || '';

            axios.get("user/office/"+user_id, params)
			.then((response) => {
                loader.hide();
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;

                this.data = response.data.data.data;

                

            }).catch(error => {

                loader.hide();
			  
                var response = (error.response);
                
                if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
                    
                    localStorage.clear();
                    
                    notification.open({
                            message: 'Please Login',
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                        
                    this.$router.push({name: 'login'}).catch(error => {}) 
                    
                }else{
                    notification.open({
                            message: response.data.message,
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                }


            })

        },
        handleChangeEvent()
        {
            let work_from = (this?.work_from === true) ? 2 : this.work_from === false ? 1 : null

            if(!work_from)
            {
                notification.open({
                    message: 'Work From status is blank !',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
            }

            var token = window.localStorage.getItem("token");
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            var user_id = this?.$route?.params?.user_id || '';

            axios.put("user/work-from-status", {'work_from' : work_from,'user_id':user_id})
			.then((response) => {

                loader.hide();

                this.$emit('reload-data');

                notification.open({
                    message: response?.data?.message || 'Successful',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                });

                var params = {
                    params: { per_page: this.pagination.pageSize }
                };

                this.fetchData(params)

                this.$emit('fetchEmp');

            }).catch(error => {

                this.work_from = !this?.work_from

                loader.hide();
			  
                var response = (error.response);
                
                if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
                    
                    localStorage.clear();
                    
                    notification.open({
                            message: 'Please Login',
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                        
                    this.$router.push({name: 'login'}).catch(error => {}) 
                    
                }else{

                    notification.open({
                            message: response.data.message,
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                }

            })
        }
    },
    mounted()
    {
        var params = {
            params: { per_page: this.pagination.pageSize }
        };

        this.fetchData(params)

    }
}
</script>
<style>
.form-check-input
{
    padding: 20px 20px 0px 0px !important;
    margin-top: 8px;
    width: 2.5rem !important
}

.form-switch
{
    padding-left: 3em !important;
}

.scrollable-content {
    max-height: 50vh; 
    min-height:30vh;
    overflow-y: auto; 
}

.border-rigth
{
    border-right: 1px solid #d9d0d0;
}
</style>
