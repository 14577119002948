<template>
  <!-- Search Filter -->
  <div class="row filter-row">
    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
        <input
          type="text"
          class="form-control floating"
          v-model="search.employee_name"
          @focus="isFocused = true"
          @blur="isFocused = name !== ''"
          @keyup.enter="searchAsset"
        />
        <label class="focus-label">Employee Name</label>
      </div>
    </div>
    <!-- <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus select-focus">
        <vue-select :options="assets" id="assets" placeholder="-- Select --" />
        <label class="focus-label">Status</label>
      </div>
    </div> -->
    <div class="col-sm-12 col-md-4">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <div class="input-block mb-3 form-focus">
            <div class="cal-icon">
              <datepicker
                v-model="startdate"
                placeholder="From"
                class="form-control floating datetimepicker"
                :editable="true"
                :clearable="false"
                :input-format="dateFormat"
                @update:model-value="searchAsset"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="input-block mb-3 form-focus">
            <div class="cal-icon">
              <datepicker
                v-model="startdateone"
                placeholder="To"
                class="form-control floating datetimepicker"
                :editable="true"
                :clearable="false"
                :input-format="dateFormat"
                @update:model-value="searchAsset"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="d-grid">
        <a href="javascript:;" class="btn btn-success submit-btn" @click="searchAsset()"> Search </a>
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="d-grid">
        <a href="javascript:;" class="btn btn-danger submit-btn" style="margin-left:10px" @click="clerSearchAsset()"> Clear </a>
      </div>
    </div>
  </div>
  <!-- /Search Filter -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  emits:['search-asset'],
  data() {
    return {
      select2: null,
      assets: ["-- Select --", "Pending", "Approved", "Returned"],
      startdate: null,
      startdateone: null,
      dateFormat: 'dd-MM-yyyy',
      name: "",
      isFocused: false,
      search:{
        employee_name:'',
        start_date:'',
        end_date:''
      }
    };
  },
  methods:{
    clerSearchAsset()
    {
      this.startdate = null;
      this.startdateone = null;
      this.search.employee_name = ''

      this.$emit('search-asset',null);
    },
    searchAsset()
    {
      let formData = {};

      if(this?.search?.employee_name != '')
      {
        formData.employee_name = this?.search?.employee_name
      }

      if(this?.startdate != '' && this?.startdate != null )
      {
        if (!isNaN(this.startdate.getTime())) {
        var dd = this.startdate;
        dd.setMonth(dd.getMonth() + 1);

        const year = dd.getFullYear();
        const month = dd.getMonth().toString().padStart(2, '0');
        const day = dd.getDate().toString().padStart(2, '0');
        formData.start_date = `${year}-${month}-${day}`
        }
      }

      if(this?.startdateone != '' && this?.startdateone != null)
      {
        if (!isNaN(this.startdateone.getTime())) {
        var dd = this.startdateone;
        dd.setMonth(dd.getMonth() + 1);

        const year = dd.getFullYear();
        const month = dd.getMonth().toString().padStart(2, '0');
        const day = dd.getDate().toString().padStart(2, '0');
        formData.end_date = `${year}-${month}-${day}`
        }
      }

      this.$emit('search-asset',formData);
    }
  },
  created(){
    this.startdate = null;
    this.startdateone = null;
    this.search.employee_name = ''

  }
};
</script>
