<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <reports-header :title="title" :path="path" :text="text" :text1="text1" :search_data="searchform" :columns="originalColumn" />
        <!-- /Page Header -->
        <!-- Search Filter -->
        <!--<employee-reports-filter /> -->
		
		<Form @submit="onSearch">
		  <div class="row filter-row mb-4">
        <div class="col-sm-6 col-md-2">
          <div class="input-block mb-3 form-focus focused">
          <vue-select
            :options="employee_list"
            id="projectreportname"
            v-model="searchform.s_employee_id"
            placeholder="-"
          />

          <label class="focus-label">Employee Name</label>
          </div>
        </div>
        <div class="col-sm-6 col-md-2">
          <div class="input-block mb-3 form-focus focused">
          <vue-select
            :options="department_list"
            id="departmentname"
            v-model="searchform.department_id"
            placeholder="-"
          />

          <label class="focus-label">Department Name</label>
          </div>
        </div>
        <div class="col-sm-6 col-md-2">
          <div class="input-block mb-3 form-focus">
          <div class="cal-icon">
            <datepicker
            v-model="searchform.start_date"
            placeholder="From"
            class="form-control floating datetimepicker"
            :editable="true"
            :clearable="false"
            :input-format="dateFormat"
            />
          </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-2">
          <div class="input-block mb-3 form-focus">
          <div class="cal-icon">
            <datepicker
            v-model="searchform.end_date"
            placeholder="To"
            class="form-control floating datetimepicker"
            :editable="true"
            :clearable="false"
            :input-format="dateFormat"
            />
          </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-2">
          <div class="d-grid">
          <!--<a href="javascript:;" class="btn btn-success" @click="filterData"> Search </a> -->
          <button class="btn btn-success w-100 submit-btn">Search</button>
          </div>
        </div>
        <div class="col-sm-6 col-md-2">
          <div class="d-grid">
            <button class="btn btn-danger w-100 submit-btn" @click="clearFilter">Clear</button>
          </div>
        </div>
		  </div>
		</Form>  
		
        <!-- /Search Filter -->
        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
            <div class="table-responsive">
			  <a-table
                class="stripped table-hover"
                :columns="originalColumn"
                :data-source="data2"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  
				  <template v-if="column.key === 'name'">
                    <div class="table-avatar">
				   <router-link :to="{ path: '/profile/'+record.id}" class="avatar"
                        ><img v-if="record?.avatar != null"
                      :src="getEmployeeAvatar(record?.avatar)"
                      @error="handleImageError(record)"
                        alt="User Image"
                    />
                    <img v-else
                    :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                        alt="User Image"
                    /></router-link>
				
					
					  <router-link to="profile"
                        >{{ record.first_name }} {{ record.last_name }}<span></span></router-link
                      >
                    </div>
                  </template>
				  <template v-else-if="column.key === 'department'">
                    <div class="text-center">
						<p v-if="record.department">{{ record.department.name }}</p>
                    </div>
                  </template>
				  <!--
                  <template v-else-if="column.key === 'leaveType'">
                    <div class="text-center">
                      <button :class="record.leaveTypeClass">
                        {{ record.leaveType }}
                      </button>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'numberOfDays'">
                    <div class="text-center">
                      <span :class="record.numberOfDaysClass">{{
                        record.numberOfDays
                      }}</span>
                    </div>
                  </template>-->
                  <template v-else-if="column.key === 'remainingLeave'">
                    <div class="text-center">
                      <span :class="record.remainingLeaveClass"
                        ><b>{{ totalleaves - record.user_leaves_count}}</b></span
                      >
                    </div>
                  </template>
                  <template v-else-if="column.key === 'totalLeaves'">
                    <div class="text-center">
                      <span :class="record.totalLeavesClass"
                        ><b>{{totalleaves}}</b></span
                      >
                    </div>
                  </template>
				 
                  <template v-else-if="column.key === 'totalLeaveTaken'">
                    <div class="text-center">{{ record.user_leaves_count }}</div>
                  </template>
				  
                  <template v-else-if="column.key === 'leaveCarryForward'">
				    <div class="text-center" v-if="totalmonth > 0">
                      {{ totalmonth-record.user_leaves_count }}
                    </div>
					<div class="text-center" v-else>
                      {{ totalmonth }}
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
	
	 <div
     id="leave_report_manage_colvis"
     class="modal custom-modal fade"
     role="dialog"
   >
     <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title">Manage Column Visibility</h5>
           <!-- <small>select the column from the default that you want to hide</small> -->
           <button
             type="button"
             class="btn-close"
             data-bs-dismiss="modal"
             aria-label="Close"
             ref="modal_team_information_close"
           >
             <i class="fas fa-times"></i>
           </button>
         </div>
         <div class="modal-body">
           <div class="row">
                 <div class="col-md-6" v-for="(column,key) in columns_" :key="column.key">
                  <div class="input-block mb-3 d-flex">
                    
                    <div class="form-switch" style="margin-right: 5px;">
                      <input
                        v-model="column.visibility"
                        type="checkbox"
                        class="form-check-input"
                        :id="'customSwitch_'+column.key"
                        value="false"
                        :checked="column.visibility"
                      />
                    </div>
                    <label class="col-form-label"> {{column?.title}} </label>
                  </div>
                 </div>
           </div>
         </div>
       </div>
     </div>
   </div>
  <!-- Update leave_report_manage_colvis Modal -->
  
    <!-- /Page Wrapper -->
  </div>
</template>
<script>
var pagination = {total: 0,current: 1,pageSize: 10};

import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import axios from 'axios';
import { notification } from "ant-design-vue";
import { Form } from "vee-validate";

const columns = [
  {
    title: "Employee",
    dataIndex: "name",
    key: "name",
    sorter: false,
	visibility:true
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
    sorter: false,
	visibility:true
  },
  {
    title: "Remaining Leave",
    dataIndex: "remainingLeave",
    key: "remainingLeave",
    sorter: false,
	visibility:true
  },
  {
    title: "Total Leaves",
    dataIndex: "totalLeaves",
    key: "totalLeaves",
    sorter: false,
	visibility:true
  },
  {
    title: "Total Leave Taken",
    dataIndex: "totalLeaveTaken",
    key: "totalLeaveTaken",
    sorter: false,
	visibility:true
  },
  {
    title: "Leave Carry Forward",
    dataIndex: "leaveCarryForward",
    key: "leaveCarryForward",
    sorter: false,
	visibility:true
  },
];
const data = [
  {
    name: "John Doe",
    avatar: "avatar-02.jpg",
    id: "#0001",
    date: "20 Dec 2022",
    department: "Design",
    leaveType: "Sick Leave",
    leaveTypeClass: "btn btn-outline-info btn-sm",
    numberOfDays: "05",
    numberOfDaysClass: "btn btn-danger btn-sm",
    remainingLeave: "08",
    remainingLeaveClass: "btn btn-warning btn-sm",
    totalLeaves: "20",
    totalLeavesClass: "btn btn-success btn-sm",
    totalLeaveTaken: "12",
    leaveCarryForward: "08",
  },
  {
    name: "John Smith",
    avatar: "avatar-10.jpg",
    id: "#003",
    date: "22 Dec 2022",
    department: "Android Developer",
    leaveTypeClass: "btn btn-outline-danger btn-sm",
    leaveType: "Emergency Leave",
    numberOfDaysClass: "btn btn-danger btn-sm",
    numberOfDays: "01",
    remainingLeaveClass: "btn btn-warning btn-sm",
    remainingLeave: "09",
    totalLeavesClass: "btn btn-success btn-sm",
    totalLeaves: "20",
    totalLeaveTaken: "17",
    leaveCarryForward: "03",
  },
  {
    name: "Mike Litorus",
    avatar: "avatar-05.jpg",
    id: "#004",
    date: "23 Dec 2022",
    department: "IOS Developer",
    leaveTypeClass: "btn btn-outline-info btn-sm",
    leaveType: "Sick Leave",
    numberOfDaysClass: "btn btn-danger btn-sm",
    numberOfDays: "15",
    remainingLeaveClass: "btn btn-warning btn-sm",
    remainingLeave: "05",
    totalLeavesClass: "btn btn-success btn-sm",
    totalLeaves: "20",
    totalLeaveTaken: "15",
    leaveCarryForward: "05",
  },
  {
    name: "Richard Miles",
    avatar: "avatar-09.jpg",
    id: "#0002",
    date: "21 Dec 2022",
    department: "Web Developer",
    leaveTypeClass: "btn btn-outline-warning btn-sm",
    leaveType: "Parenting Leave",
    numberOfDaysClass: "btn btn-danger btn-sm",
    numberOfDays: "03",
    remainingLeaveClass: "btn btn-warning btn-sm",
    remainingLeave: "08",
    totalLeavesClass: "btn btn-success btn-sm",
    totalLeaves: "20",
    totalLeaveTaken: "12",
    leaveCarryForward: "05",
  },
  {
    name: "Wilmer Deluna",
    avatar: "avatar-11.jpg",
    id: "#005",
    date: "24 Dec 2022",
    department: "Team Leader",
    leaveTypeClass: "btn btn-outline-info btn-sm",
    leaveType: "Sick Leave",
    numberOfDaysClass: "btn btn-danger btn-sm",
    numberOfDays: "10",
    remainingLeaveClass: "btn btn-warning btn-sm",
    remainingLeave: "07",
    totalLeavesClass: "btn btn-success btn-sm",
    totalLeaves: "20",
    totalLeaveTaken: "18",
    leaveCarryForward: "2",
  },
];
export default {
  components: {
    Form
  },
  data() {
    return {
      title: "Leave Report",
      path: "Dashboard",
      text: "Leave Report",
      text1: "Export Excel",
      columns,
      data,
	  pagination: pagination,
	  employee_list: [],
	  department_list: [],
	  data2: [],
	  defaultAvatar:'avatar-02.jpg',
	  totalleaves: 0,
	  select2: null,
      employeereportdepartment: [
        "Select Department",
        "Designing",
        "Development",
        "Finance",
        "Hr & Finance",
      ],
      startdate: currentDate,
      dateFormat: 'dd-MM-yyyy',
      startdateone: currentDateOne,
      employee: "",
      isFocused: false,
      departments:[],
      optionsEmpDepartment:[],
      value:null,
      department_id:null,
      employee_id:null,
      start_date:null,
      end_date:null,
	  searchform: {s_employee_id: "",department_id:"",start_date: null, end_date:null},
	  totalmonth: 0,
	  columns_:columns,
    };
  },
  name: "leave-reports",
  methods: {
	handleImageError(emp) {
      emp.avatar = null;
      emp.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
	getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
  clearFilter()
  {
    this.searchform = {s_employee_id: "",department_id:"",start_date: null, end_date:null} ;
    this.onSearch();
  },

	onSearch(){
		var params = {
		   params: { per_page: this.pagination.pageSize,page:this.pagination.current, s_employee_id: this.searchform.s_employee_id, department_id: this.searchform.department_id,start_date: this.searchform.start_date,end_date: this.searchform.end_date }
		};	
		
		this.loadCommonData(params);
	
	},
	handleTableChange(pagesize){
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current }
		};
		
		this.loadCommonData(params);
		
	},
	updatePerPage(){
		console.log(this.pagination.pageSize);
		var params = {
          params: { per_page: this.pagination.pageSize,s_employee_id: this.searchform.s_employee_id, department_id: this.searchform.department_id,start_date: this.searchform.start_date,end_date: this.searchform.end_date }
        };
        this.loadCommonData(params);
		
	},
	loadAllDepartments(params){
	
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.fullPage ? null : this.$refs.formContainer,
						canCancel: false
					});
					
		axios.get("/departments/all", params)
			.then((response) => {
				
				console.log('all departments load');
				
				//this.department_list = response.data.data;
				
				console.log(response.data.data);
				
				for(let i=0;i<response.data.data.length;i++){
					
					this.department_list.push({"id":response.data.data[i].id,"text":response.data.data[i].name});
				}
					
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
	loadAllEmployees(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.fullPage ? null : this.$refs.formContainer,
						canCancel: false
					});
					
		axios.get("/employees/allemployees", params)
			.then((response) => {
				
			//	console.log(response.data.data.data);
				
				//this.employee_list = response.data.data;
				
				console.log(response.data.data);
				
				for(let i=0;i<response.data.data.length;i++){
					
					this.employee_list.push({"id":response.data.data[i].id,"text":response.data.data[i].first_name+" "+response.data.data[i].last_name});
				}
					
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});			
					
	},
	loadCommonData(params){
		console.log('common data loading called..');
		
			var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.fullPage ? null : this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/leaves/employeereport", params)
			.then((response) => {
				
				//this.totaldays = response.data.days;
				console.log('leave reports response');
				console.log(response);
				
				this.data2 = response.data.data.data;
				
				this.totalleaves = response.data.totalleaves;
				this.totalmonth = response.data.totalmonth;
				
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
  },
  mounted() {
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };
	this.loadCommonData(params);
	
	this.loadAllEmployees([]);
	
	this.loadAllDepartments([]);
  },
  computed:{
    originalColumn()
    {
      return this.columns_.filter(column => column.visibility);
    }
  },
};
</script>
