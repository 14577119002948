<template>
    <div class="account-page" ref="formContainer">
      <div class="main-wrapper">
        <div class="account-content">
          <div class="container">
            <!-- Account Logo -->
            <div class="account-logo">
              <router-link to="/"
                ><img src="@/assets/img/logo2.png" alt="Workwise"
              /></router-link>
            </div>
            <!-- /Account Logo -->
  
            <div class="account-box">
              <div class="account-wrapper">
                <h3 class="account-title">PIN</h3>
                <p class="account-subtitle">Reset your PIN</p>
  
                <!-- Account Form -->
                <form @submit.prevent="submitForm">
                  <div class="otp-wrap">
                    <input v-model="pin_1" type="text" placeholder="0" maxlength="1" class="otp-input" />
                    <input v-model="pin_2" type="text" placeholder="0" maxlength="1" class="otp-input" />
                    <input v-model="pin_3" type="text" placeholder="0" maxlength="1" class="otp-input" />
                    <input v-model="pin_4" type="text" placeholder="0" maxlength="1" class="otp-input" />
                  </div>
                 
                  <p class="text-danger" v-if="error"> {{ error }}</p>

                  <div class="input-block mb-4 text-center">
                    <button class="btn btn-primary account-btn" type="submit">Enter</button>
                  </div>
                  <!-- <div class="account-footer">
                    <p>Not yet received? <a href="javascript:void(0);">Resend OTP</a></p>
                  </div> -->
                </form>
                <!-- /Account Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
import axios from 'axios';
import { notification } from "ant-design-vue";
  export default {
    data() {
      return {
        pine_1:null,
        pine_2:null,
        pine_3:null,
        pine_4:null,
        error:null
      }
    },
    methods: {
      submitForm() {
        this.error = null;
       
            var token = window.localStorage.getItem("token");
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            
            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            var form = new FormData();

            form.append('pin', this.pin_1+''+this.pin_2+''+this.pin_3+''+this.pin_4);
            form.append('token',this?.$route?.params?.token);
            axios.post("/forgot-pin-update", form).then( (response) => {
                loader.hide();
                notification.open({
                    message: response?.data?.message || 'Successfully PIN reset!',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                        background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                });

                this.$router.push({name: 'login'}).catch(error => {}) 

            }).catch(error => {
                loader.hide();
                if(error.response){
                
                    var response = (error.response);

                    this.error = response?.data?.message || 'Please enter valid PIN number'
                        
                    notification.open({
                        message: response?.data?.message || 'Error',
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                    
                }else{
                    
                    notification.open({
                        message: 'Server Error',
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                }
            });
       
      },
    },
    created()
    {
        if(!this?.$route?.params?.token || this?.$route?.params?.token == null)
        {
            notification.open({
              message: 'Invalid URL',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });

            this.$router.push({ name: "login" }).catch((error) => {});
        }
        else{

            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            axios.get("/check-pin-expiration/"+this?.$route?.params?.token)
            .then( (response) => {
                loader.hide();
            }).catch(error => {
                loader.hide();
                if(error.response){
                var response = (error.response);
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
                }else{
                notification.open({
                    message: 'Server Error',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
                }

                this.$router.push({ name: "login" }).catch((error) => {});
            });

        }
    }
  }
  </script>