<template>
    <div class="main-wrapper">
        <layout-header></layout-header>
        <layout-sidebar></layout-sidebar>
        <!-- Page Wrapper -->
        <div class="page-wrapper" ref="formContainer">
            <!-- Page Content -->
            <div class="content container-fluid">
                <!-- Page Header -->
                <leaves-admin-header :title="title" :path="path" :text="text" :text1="text1" :hasCreatePermission="hasCreatePermission" :hasImportPermission="hasImportPermission" @reset-data="resetFormData" />

                <div class="row">
                    <div class="col-md-12">
                        <div class="showentries mb-3">
                            <label>
                                Show
                                <select v-model="pagination.pageSize" @change="updatePerPage">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                entries
                            </label>
                        </div>
                        <div>
                            <a-table
                                class="stripped table-hover"
                                :columns="columns"
                                :data-source="data"
                                :pagination="pagination"
                                @change="handleTableChange">
                                <template #bodyCell="{ column, record }">
                                  <template v-if="column.key === 'file_name'">
                                    <a  class="text-decoration-underline" :href="docPath( record?.file_path )" target="_blank"> {{ record?.file_name }} </a> 
                                  </template>

                                    <template v-if="column.key === 'first_name'">

                                      <h2 class="table-avatar">
                                        <router-link :to="'profile/'+record?.user?.id" class="avatar"
                                          >
                                          <img v-if="record?.user?.avatar"
                                          :src="getEmployeeAvatar(record?.user?.avatar)"
                                          alt="User Image"
                                          @error="handleImageError(record?.user)"
                                          />
                                          <img v-else
                                          :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                                              alt="User Image" 
                                          />
                                 </router-link>
                                    <router-link :to="'profile/'+record?.user?.id"
                                      >{{ record?.user?.first_name }} {{ record?.user?.last_name }}</router-link
                                    >
                                  </h2>
                                        <!-- <div>{{ record?.user?.first_name }}</div><div>{{ record?.user?.last_name }}</div> -->
                                    </template>  
                                    <template v-if="column.key === 'created_at'">
                                      <div>{{ formatDate(record?.created_at) || record?.created_at }}</div>
                                  </template> 
                                  <template v-else-if="column.key === 'action'">
                                    <td class="text-end" v-if="hasEditPermission || hasDeletePermission">
                                      <div class="dropdown dropdown-action">
                                        <a 
                                          href="javascript:;"
                                          class="action-icon dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          ><i class="material-icons">more_vert</i></a
                                        >
                                        <div class="dropdown-menu dropdown-menu-right">
                                          <a v-if="hasEditPermission"
                                            class="dropdown-item"
                                            href="javascript:;"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteDocumentConfirmationModal"
                                            @click="deleteDocumentID(record?.organization_document_id)"
                                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                                          >
                                          </div>
                                        </div>
                                      </td>
                                      </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div> <!-- Closing for .content.container-fluid -->
        </div> <!-- Closing for .page-wrapper -->
        <div id="upload_document" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title">Upload Document</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="impdeptclose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="input-block mb-3">
                <label class="col-form-label"
                        >File Name <span class="text-danger">*</span></label
                      >
                <Field
                      name="file_name"
                      type="text"
                      value=""
                      class="form-control"
                      v-model="form.file_name"
                      :class="{ 'is-invalid': errors.file_name }"
                />
                <div class="invalid-feedback">{{ errors.file_name }}</div>
                <div class="file_nameshow text-danger" id="file_name"></div>
              </div>
              <div class="input-block mb-3">
              <label class="col-form-label"
                >File <span class="text-danger">*</span>
              </label>
              <Field
                  name="document"
                  type="file"
                  class="form-control"
                  :class="{ 'is-invalid': errors.document }"
                  @change="handleFileUpload($event)"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.rtf,.odt,.ods,.jpg,.jpeg,.png,.gif,.bmp,.tiff,.csv,.zip,.html,.htm"	
                  ref="importFile"
                />
                <div class="invalid-feedback">{{ errors.document }}</div>
              </div>
              
              <div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </Form>
            </div>
          </div>
          </div>
        </div>
        <!-- Delete Overtime Modal -->
        <div class="modal custom-modal fade" id="deleteDocumentConfirmationModal" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form-header">
                  <h3>Delete Document</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                  <div class="row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="deleteDocument"
                        >Delete</a
                      >
                    </div>
                    <div class="col-6">
                      <a
                        href="javascript:void(0);"
                        data-bs-dismiss="modal"
                        class="btn btn-primary cancel-btn"
                        @click="cancleDelete"
                        ref="departmentsmodel"
                        >Cancel</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Delete Overtime Modal -->
    </div> <!-- Closing for .main-wrapper -->
</template>

<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import moment from 'moment';

var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};

const columns = [
    {
	  title: "#",
	  dataIndex: "id",
	  key: "id",
	  customRender: ({ index }) => {
		return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
    },
    {
    title: "Document Name",
    dataIndex: "file_name",
    key: "file_name",
    sorter: {
      compare: (a, b) => {
        a = a.file_name.toLowerCase();
        b = b.file_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Uploaded By",
    dataIndex: "first_name",
    key: "first_name",
    sorter: {
      compare: (a, b) => {
        a = a.first_name.toLowerCase();
        b = b.first_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Uploaded At",
    dataIndex: "created_at",
    key: "created_at",
    sorter: {
      compare: (a, b) => {
        a = a.created_at.toLowerCase();
        b = b.created_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    sorter: false,
    key: "action",
  },
];

const data = []

export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      title: "Organization Documents",
      path: "Dashboard",
      text: "Organization Documents",
      text1:' Add Document',
      hasEditPermission:true,
      hasDeletePermission:true,
	    hasCreatePermission:true,
	    hasImportPermission:true,
      pagination,
      columns,
      data,
      editfile:null,
      form:{
        file_name:null
      },
      delete_document_id:null
    };
  },
  methods:{
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(record) {
      record.avatar = null;
      record.avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    resetFormData()
    {
      this.form.file_name = null;
      this.editfile = null;
      this.$refs.importFile.value = null
    },
    deleteDocumentID(delete_document_id)
    {
      this.delete_document_id = delete_document_id;
    },
    cancleDelete()
    {
      this.delete_document_id = null
    },
    deleteDocument(){
      var token = window.localStorage.getItem("token");
    
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		  let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		  axios.delete("/organization-document/"+this.delete_document_id, [])
          .then( (response) => {
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs?.departmentsmodel?.click();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		  }).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
      });
		
	  },
    dateFormate(date)
    {
      return moment(date).format('YYYY-MM-DD')
    },
    handleFileUpload(event){
      this.editfile = event.target.files[0];
    },
    onSubmit()
	{

		var token = window.localStorage.getItem("token");
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
			container: this.$refs.formContainer,
			canCancel: false
		});

		var form = new FormData();

		form.append('document', this.editfile);
    form.append('file_name',this.form.file_name);

		const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };

		axios.post("/organization-document/upload", form,config)
          .then( (response) => {

			this.editfile = null
			this.$refs.importFile.value = ''
				
			  loader.hide();
			  
			  notification.open({
					message: response?.data?.message || 'Successfully Uplaoded',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.impdeptclose.click();
			 this.edit_form ={};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {

			this.editfile = null
			this.$refs.importFile.value = ''
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response?.data?.message || 'Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
	  },
    loadCommonData(params){
        var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/organization-document", params)
			.then((response) => {
				
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				this.data = response.data.data.data;
				
				loader.hide();
				
				
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
    },
    updatePerPage(){
		
		var params = {
          params: { per_page: this.pagination.pageSize }
        };

        this.loadCommonData(params);
	},
    handleTableChange(pagesize){
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current }
		};
		this.loadCommonData(params);
	},
  docPath(path)
  {
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${path}`
  },

  },
  created()
  {
    var params = {
       params: { per_page: this.pagination.pageSize }
    };
	
	  this.loadCommonData(params);
  },
  setup() {
    const schema = Yup.object().shape({
      document: Yup.string().required("File is required"),
      file_name:Yup.string().required("File Name is required")
    });
	return {
      schema
	}
  },

};
</script>