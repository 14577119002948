<template>
  <!-- Add Modal -->
  <div class="modal custom-modal fade" id="add_categories" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Expenses</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="input-block mb-3 row">
              <label class="col-lg-12 control-label col-form-label"
                >Amount <span class="text-danger">*</span></label
              >
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="800.00"
                  name="amount"
                />
              </div>
              <div class="col-lg-6">
                <vue-select
                  :options="AddExpensesamount"
                  id="select-add-expensesamount"
                  placeholder="$ - Australian Dollar"
                />
              </div>
            </div>
            <div class="input-block mb-3 row">
              <label class="col-lg-12 control-label col-form-label"
                >Notes <span class="text-danger">*</span></label
              >
              <div class="col-lg-12">
                <textarea class="form-control ta" name="notes"></textarea>
              </div>
            </div>
            <div class="input-block mb-3 row">
              <label class="col-lg-12 control-label col-form-label"
                >Expense Date <span class="text-danger">*</span></label
              >
              <div class="col-lg-12">
                <datepicker
                  v-model="startdate"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
            </div>
            <div class="input-block mb-3 row">
              <label class="col-lg-12 control-label col-form-label"
                >Category <span class="text-danger">*</span></label
              >
              <div class="col-lg-12">
                <vue-select
                  :options="AddExpensescategory"
                  id="select-add-expensescategory"
                  placeholder="Choose Category"
                />
              </div>
            </div>
            <div class="input-block mb-3 row">
              <label class="col-lg-12 control-label col-form-label"
                >Sub Category <span class="text-danger">*</span></label
              >
              <div class="col-lg-12">
                <vue-select
                  :options="AddExpensessubcategory"
                  id="select-add-expensessubcategory"
                  placeholder="Choose Sub-Category"
                />
              </div>
            </div>
            <div class="input-block mb-3 row position-relative">
              <label class="col-lg-12 control-label col-form-label">Attach File</label>
              <div class="col-lg-12">
                <input
                  type="file"
                  class="form-control"
                  data-buttontext="Choose File"
                  data-icon="false"
                  data-classbutton="btn btn-default"
                  data-classinput="form-control inline input-s"
                  name="receipt"
                />
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Modal -->

  <!-- Edit Modal -->
  <div class="modal custom-modal fade" id="edit_categories" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Expenses</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="input-block mb-3 row">
              <label class="col-lg-12 control-label col-form-label"
                >Amount <span class="text-danger">*</span></label
              >
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="800.00"
                  name="amount"
                />
              </div>
              <div class="col-lg-6">
                <vue-select
                  :options="EditExpensesamount"
                  id="select-edit-expensesamount"
                  placeholder="$ - Australian Dollar"
                />
              </div>
            </div>
            <div class="input-block mb-3 row">
              <label class="col-lg-12 control-label col-form-label"
                >Notes <span class="text-danger">*</span></label
              >
              <div class="col-lg-12">
                <textarea class="form-control ta" name="notes"></textarea>
              </div>
            </div>
            <div class="input-block mb-3 row">
              <label class="col-lg-12 control-label col-form-label"
                >Expense Date <span class="text-danger">*</span></label
              >
              <div class="col-lg-12">
                <datepicker
                  v-model="startdateone"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
            </div>
            <div class="input-block mb-3 row">
              <label class="col-lg-12 control-label col-form-label"
                >Category <span class="text-danger">*</span></label
              >
              <div class="col-lg-12">
                <vue-select
                  :options="EditExpensescategory"
                  id="select-edit-expensescategory"
                  placeholder="Choose Category"
                />
              </div>
            </div>
            <div class="input-block mb-3 row">
              <label class="col-lg-12 control-label col-form-label"
                >Sub Category <span class="text-danger">*</span></label
              >
              <div class="col-lg-12">
                <vue-select
                  :options="EditExpensessubcategory"
                  id="select-edit-expensessubcategory"
                  placeholder="Choose Sub-Category"
                />
              </div>
            </div>
            <div class="input-block mb-3 row position-relative">
              <label class="col-lg-12 control-label col-form-label">Attach File</label>
              <div class="col-lg-12">
                <input
                  type="file"
                  class="form-control"
                  data-buttontext="Choose File"
                  data-icon="false"
                  data-classbutton="btn btn-default"
                  data-classinput="form-control inline input-s"
                  name="receipt"
                />
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Modal -->

  <!-- Delete Holiday Modal -->
  <div class="modal custom-modal fade" id="delete" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:void(0);" class="btn btn-primary continue-btn"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Holiday Modal -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  data() {
    return {
      select2: null,
      AddExpensesamount: [
        "$ - Australian Dollar",
        "Bs. - Bolívar Fuerte",
        "R$ - Brazilian Real",
        "£ - British Pound",
        "$ - Canadian Dollar",
      ],
      AddExpensescategory: [
        "Choose Category",
        "project1",
        "test category",
        "Hardware",
        "Material",
        "Vehicle",
        "TestctrE",
        "Twocatr",
        "fesferwf",
      ],
      AddExpensessubcategory: ["Choose Sub-Category"],
      EditExpensesamount: [
        "$ - Australian Dollar",
        "Bs. - Bolívar Fuerte",
        "R$ - Brazilian Real",
        "£ - British Pound",
        "$ - Canadian Dollar",
      ],
      EditExpensescategory: [
        "Choose Category",
        "project1",
        "test category",
        "Hardware",
        "Material",
        "Vehicle",
        "TestctrE",
        "Twocatr",
        "fesferwf",
      ],
      EditExpensessubcategory: ["Choose Sub-Category"],
      startdate: currentDate,
      dateFormat: 'dd-MM-yyyy',
      startdateone: currentDateOne,
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/budget-expenses");
    },
  },
};
</script>
