<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-bordered review-table mb-0">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Signature</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Employee</td>
              <td><input type="text" class="form-control" /></td>
              <td><input type="text" class="form-control" /></td>
              <td><input type="text" class="form-control" /></td>
            </tr>
            <tr>
              <td>Reporting Officer</td>
              <td><input type="text" class="form-control" /></td>
              <td><input type="text" class="form-control" /></td>
              <td><input type="text" class="form-control" /></td>
            </tr>
            <tr>
              <td>HOD</td>
              <td><input type="text" class="form-control" /></td>
              <td><input type="text" class="form-control" /></td>
              <td><input type="text" class="form-control" /></td>
            </tr>
            <tr>
              <td>HRD</td>
              <td><input type="text" class="form-control" /></td>
              <td><input type="text" class="form-control" /></td>
              <td><input type="text" class="form-control" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
