<template>
  <nav class="greedys sidebar-horizantal"> 
    <ul class="list-inline-item list-unstyled links">
      <li class="menu-title">
        <span>Main</span>
      </li>
      <li class="submenu" v-bind:class="{
                active: currentPath == 'admin-dashboard' || currentPath == 'employee-dashboard',
              }">
        <a href="javascript:;" @click="toggleVisibilityDashboard" >
          <i class="la la-dashboard"></i> <span> Dashboard</span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleDashboard ? 'block' : 'none' }">
          <li v-if="isAdmin" v-bind:class="{
                active: currentPath == 'admin-dashboard',
              }">
            <router-link to="/admin-dashboard">Dashboard</router-link>
          </li>
          <li v-else v-bind:class="{
                active: currentPath == 'employee-dashboard',
              }">
            <router-link to="/employee-dashboard">Dashboard</router-link>
          </li>
        </ul>
      </li>
      <!-- <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityApps">
          <i class="la la-cube"></i> <span> Apps</span> <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleApps ? 'block' : 'none' }">
          <li><router-link to="/chat">Chat</router-link></li>
          <li class="submenu">
            <a href="javascript:;" @click="toggleVisibilityCalls">
              <span> Calls</span> <span class="menu-arrow"></span
            ></a>
            <ul :style="{ display: isVisibleCalls ? 'block' : 'none' }">
              <li><router-link to="/voice-call">Voice Call</router-link></li>
              <li><router-link to="/video-call">Video Call</router-link></li>
              <li>
                <router-link to="/outgoing-call">Outgoing Call</router-link>
              </li>
              <li>
                <router-link to="/incoming-call">Incoming Call</router-link>
              </li>
            </ul>
          </li>
          <li><router-link to="/events">Calendar</router-link></li>
          <li><router-link to="/contacts">Contacts</router-link></li>
          <li><router-link to="/inbox">Email</router-link></li>
          <li><router-link to="/file-manager">File Manager</router-link></li>
        </ul>
      </li> -->
      <li class="menu-title">
        <span>Employees</span>
      </li>
      <li class="submenu">
        <a href="javascript:;" class="noti-dot" @click="toggleVisibilityEmployees" :class="{ active: EmployeesMenu, subdrop: EmployeesMenu }">
          <i class="la la-user"></i> <span> Employees</span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleEmployees ? 'block' : 'none' }">
          <li ><router-link v-bind:class="{
                      active:
                        currentPath == 'employees' || currentPath == 'employees-list' || currentPath == 'profile',
                    }" to="/employees">All Employees</router-link></li>
          <li><router-link v-bind:class="{ active: currentPath == 'holidays' }" to="/holidays">Holidays</router-link></li>
          <li v-if="isAdmin" >
            <router-link to="/leaves" v-bind:class="{ active: currentPath == 'leaves' }"
              >Leaves
              <span class="badge rounded-pill bg-primary float-end">1</span></router-link
            >
          </li>
          <li v-else>
            <router-link v-bind:class="{ active: currentPath == 'leaves-employee' }" to="/leaves-employee">Leaves</router-link>
          </li>
         
          <li v-if="isAdmin"><router-link v-bind:class="{ active: currentPath == 'attendance' }" to="/attendance">Attendance</router-link></li>
          <li v-else>
            <router-link v-bind:class="{
                      active: currentPath == 'attendance-employee',
                    }" to="/attendance-employee">Attendance</router-link>
          </li>
          <li v-if="isAdmin"><router-link v-bind:class="{ active: currentPath == 'departments' }" to="/departments">Departments</router-link></li>
          <li v-if="isAdmin"><router-link  v-bind:class="{ active: currentPath == 'designations' }" to="/designations">Designations</router-link></li>
          <li v-if="isAdmin" ><router-link v-bind:class="{ active: currentPath == 'wages' }" to="/wages">Wages</router-link></li>

          <li><router-link v-bind:class="{ active: currentPath == 'timesheet' }" to="/timesheet">Timesheet</router-link></li>
          <li>
            <router-link v-bind:class="{ active: currentPath == 'shift-scheduling' || currentPath == 'shift-list' }" to="/shift-scheduling">Shift & Schedule</router-link>
          </li>
          <li><router-link v-bind:class="{ active: currentPath == 'overtime' }" to="/overtime">Overtime</router-link></li>
        </ul>
      </li>
      <!-- <li>
        <router-link to="/clients"
          ><i class="la la-users"></i> <span>Clients</span></router-link
        >
      </li> -->
      <li class="submenu">
        <a :class="{ active: ProjectsMenu, subdrop: ProjectsMenu }" href="javascript:;" @click="toggleVisibilityProjects">
          <i class="la la-rocket"></i> <span> Projects</span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleProjects ? 'block' : 'none' }">
          <li><router-link to="/projects" v-bind:class="{
                      active:
                        currentPath == 'projects' ||
                        currentPath == 'project-list' ||
                        currentPath == 'project-view',
                    }">Projects</router-link></li>
          <li><router-link v-bind:class="{ active: currentPath == 'tasks' }" to="/tasks">Tasks</router-link></li>
          <!-- <li><router-link to="/task-board">Task Board</router-link></li> -->
        </ul>
      </li>
      <li class="submenu">
        <a :class="{ active: ReportsMenu, subdrop: ReportsMenu }" href="javascript:;" @click="toggleVisibilityReports">
          <i class="la la-pie-chart"></i> <span> Reports</span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleReports ? 'block' : 'none' }">
          <li><router-link to="/employee-reports" v-bind:class="{
                      active:
                        currentPath == 'employee-reports'
                    }">Employee Report</router-link></li>
                    <li><router-link to="/daily-reports" v-bind:class="{
                      active:
                        currentPath == 'daily-reports'
                    }">Daily Report</router-link></li>
                    <li><router-link to="/export-attendance" v-bind:class="{
                      active:
                        currentPath == 'export-attendance'
                    }">Export Attendance</router-link></li>
          <!-- <li><router-link v-bind:class="{ active: currentPath == 'tasks' }" to="/tasks">Tasks</router-link></li> -->
          <!-- <li><router-link to="/task-board">Task Board</router-link></li> -->
        </ul>
      </li>
      <!-- <li>
        <router-link to="/leads"
          ><i class="la la-user-secret"></i> <span>Leads</span></router-link
        >
      </li>
      <li>
        <router-link to="/tickets"
          ><i class="la la-ticket"></i> <span>Tickets</span></router-link
        >
      </li> -->
      <!-- <li class="menu-title">
        <span>HR</span>
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilitySales">
          <i class="la la-files-o"></i> <span> Sales </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleSales ? 'block' : 'none' }">
          <li><router-link to="/estimates">Estimates</router-link></li>
          <li><router-link to="/invoices">Invoices</router-link></li>
          <li><router-link to="/payments">Payments</router-link></li>
          <li><router-link to="/expenses">Expenses</router-link></li>
          <li><router-link to="/provident-fund">Provident Fund</router-link></li>
          <li><router-link to="/taxes">Taxes</router-link></li>
        </ul>
      </li> -->
      <!-- <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityAccounting">
          <i class="la la-files-o"></i> <span> Accounting </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleAccounting ? 'block' : 'none' }">
          <li><router-link to="/categories">Categories</router-link></li>
          <li><router-link to="/budgets">Budgets</router-link></li>
          <li>
            <router-link to="/budget-expenses">Budget Expenses</router-link>
          </li>
          <li>
            <router-link to="/budget-revenues">Budget Revenues</router-link>
          </li>
        </ul>
      </li> -->

      <li class="submenu" v-if="isAdmin">
        <router-link to="/assets" v-bind:class="{
                active: currentPath == 'assets' || currentPath == 'assets-details',
              }"
          ><i class="la la-object-ungroup"></i> <span>Assets</span></router-link
        >
      </li>
      <li class="submenu" v-if="isAdmin">
        <router-link to="/incidents" v-bind:class="{
                active: currentPath == 'incidents',
              }"  >
          <i class="la la-ticket"></i> <span>Incidents</span>
        </router-link>
      </li>
      <li class="submenu" v-if="isAdmin">
        <router-link to="/office-list"  v-bind:class="{
                active: currentPath == 'office-list',
              }">
          <i class="la la-home"></i> <span>Office</span>
        </router-link>
      </li>
      <li class="menu-title" v-if="isAdmin">
        <span>Setting</span>
      </li>
      <li class="submenu"  v-if="isAdmin">
        <a href="javascript:;" @click="toggleVisibilitySettings" :class="{ active: SettingsMenu, subdrop: SettingsMenu }">
          <i class="la la-files-o"></i> <span> Settings </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleSettings ? 'block' : 'none' }">
          <li ><router-link  v-bind:class="{
                    active:
                      currentPath == 'leave-settings',
                  }" to="/leave-settings">Leave Settings</router-link></li>
          <li ><router-link  v-bind:class="{
            active:
              currentPath == 'organization-documents',
          }" to="/organization-documents">Organization Documents</router-link></li>
        </ul>
      </li>
     
    </ul>
    
    <!-- <button class="viewmoremenu" id="viewMoreButton">More Menu</button>
    <ul class="hidden-links hidden" id="hiddenMenu">
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityPayroll">
          <i class="la la-money"></i> <span> Payroll </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisiblePayroll ? 'block' : 'none' }">
          <li><router-link to="/salary"> Employee Salary </router-link></li>
          <li><router-link to="/salary-view"> Payslip </router-link></li>
          <li><router-link to="/payroll-items"> Payroll Items </router-link></li>
        </ul>
      </li>
      <li>
        <router-link to="/policies"
          ><i class="la la-file-pdf-o"></i> <span>Policies</span></router-link
        >
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityReports">
          <i class="la la-pie-chart"></i> <span> Reports </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleReports ? 'block' : 'none' }">
          <li>
            <router-link to="/expense-reports"> Expense Report </router-link>
          </li>
          <li>
            <router-link to="/invoice-reports"> Invoice Report </router-link>
          </li>
          <li>
            <router-link to="/payments-reports"> Payments Report </router-link>
          </li>
          <li>
            <router-link to="/project-reports"> Project Report </router-link>
          </li>
          <li><router-link to="/task-reports"> Task Report </router-link></li>
          <li><router-link to="/user-reports"> User Report </router-link></li>
          <li>
            <router-link to="/employee-reports"> Employee Report </router-link>
          </li>
          <li>
            <router-link to="/payslip-reports"> Payslip Report </router-link>
          </li>
          <li>
            <router-link to="/attendance-reports"> Attendance Report </router-link>
          </li>
          <li><router-link to="/leave-reports"> Leave Report </router-link></li>
          <li><router-link to="/daily-reports"> Daily Report </router-link></li>
        </ul>
      </li>
      <li class="menu-title">
        <span>Performance</span>
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityPerformance">
          <i class="la la-graduation-cap"></i> <span> Performance </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisiblePerformance ? 'block' : 'none' }">
          <li>
            <router-link to="/performance-indicator"> Performance Indicator </router-link>
          </li>
          <li>
            <router-link to="/performance"> Performance Review </router-link>
          </li>
          <li>
            <router-link to="/performance-appraisal"> Performance Appraisal </router-link>
          </li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityGoals">
          <i class="la la-crosshairs"></i> <span> Goals </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleGoals ? 'block' : 'none' }">
          <li><router-link to="/goal-tracking"> Goal List </router-link></li>
          <li><router-link to="/goal-type"> Goal Type </router-link></li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityTraining">
          <i class="la la-edit"></i> <span> Training </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleTraining ? 'block' : 'none' }">
          <li><router-link to="/training"> Training List </router-link></li>
          <li><router-link to="/trainers"> Trainers</router-link></li>
          <li><router-link to="/training-type"> Training Type </router-link></li>
        </ul>
      </li>
      <li>
        <router-link to="/promotion"
          ><i class="la la-bullhorn"></i> <span>Promotion</span></router-link
        >
      </li>
      <li>
        <router-link to="/resignation"
          ><i class="la la-external-link-square"></i>
          <span>Resignation</span></router-link
        >
      </li>
      <li>
        <router-link to="/termination"
          ><i class="la la-times-circle"></i> <span>Termination</span></router-link
        >
      </li>
      <li class="menu-title">
        <span>Administration</span>
      </li>
      <li>
        <router-link to="/assets"
          ><i class="la la-object-ungroup"></i> <span>Assets</span></router-link
        >
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityJobs">
          <i class="la la-briefcase"></i> <span> Jobs </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleJobs ? 'block' : 'none' }">
          <li><router-link to="/user-dashboard"> User Dasboard </router-link></li>
          <li><router-link to="/jobs-dashboard"> Jobs Dasboard </router-link></li>
          <li><router-link to="/jobs"> Manage Jobs </router-link></li>
          <li>
            <router-link to="/manage-resumes"> Manage Resumes </router-link>
          </li>
          <li>
            <router-link to="/shortlist-candidates"> Shortlist Candidates </router-link>
          </li>
          <li>
            <router-link to="/interview-questions"> Interview Questions </router-link>
          </li>
          <li>
            <router-link to="/offer-approvals"> Offer Approvals </router-link>
          </li>
          <li>
            <router-link to="/experiance-level"> Experience Level </router-link>
          </li>
          <li><router-link to="/candidates"> Candidates List </router-link></li>
          <li>
            <router-link to="/schedule-timing"> Schedule timing </router-link>
          </li>
          <li>
            <router-link to="/apptitude-result"> Aptitude Results </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/knowledgebase"
          ><i class="la la-question"></i> <span>Knowledgebase</span></router-link
        >
      </li>
      <li>
        <router-link to="/activities"
          ><i class="la la-bell"></i> <span>Activities</span></router-link
        >
      </li>
      <li>
        <router-link to="/users"
          ><i class="la la-user-plus"></i> <span>Users</span></router-link
        >
      </li>
      <li>
        <router-link to="/settings"
          ><i class="la la-cog"></i> <span>Settings</span></router-link
        >
      </li>
      <li class="menu-title">
        <span>Pages</span>
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityProfile">
          <i class="la la-user"></i> <span> Profile </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleProfile ? 'block' : 'none' }">
          <li><router-link to="/profile"> Employee Profile </router-link></li>
          <li>
            <router-link to="/client-profile"> Client Profile </router-link>
          </li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityAuthentication">
          <i class="la la-key"></i> <span> Authentication </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleAuthentication ? 'block' : 'none' }">
          <li><router-link to="/"> Login </router-link></li>
          <li><router-link to="/register"> Register </router-link></li>
          <li>
            <router-link to="/forgot-password"> Forgot Password </router-link>
          </li>
          <li><router-link to="/otp"> OTP </router-link></li>
          <li><router-link to="/lock-screen"> Lock Screen </router-link></li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityError">
          <i class="la la-exclamation-triangle"></i> <span> Error Pages </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleError ? 'block' : 'none' }">
          <li><router-link to="/error-404">404 Error </router-link></li>
          <li><router-link to="/error-500">500 Error </router-link></li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilitySubscriptions">
          <i class="la la-hand-o-up"></i> <span> Subscriptions </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleSubscriptions ? 'block' : 'none' }">
          <li>
            <router-link to="/subscriptions"> Subscriptions (Admin) </router-link>
          </li>
          <li>
            <router-link to="/subscriptions-company">
              Subscriptions (Company)
            </router-link>
          </li>
          <li>
            <router-link to="/subscribed-companies"> Subscribed Companies</router-link>
          </li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:;" @click="toggleVisibilityPages">
          <i class="la la-columns"></i> <span> Pages </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisiblePages ? 'block' : 'none' }">
          <li><router-link to="/search"> Search </router-link></li>
          <li><router-link to="/faq"> FAQ </router-link></li>
          <li><router-link to="/terms"> Terms </router-link></li>
          <li>
            <router-link to="/privacy-policy"> Privacy Policy </router-link>
          </li>
          <li><router-link to="/blank-page"> Blank Page </router-link></li>
        </ul>
      </li>
      <li class="menu-title">
        <span>UI Interface</span>
      </li>
      <li class="submenu">
        <a href="javascript:void(0);" @click="toggleVisibilityUI"
          ><i class="feather feather-pocket"></i> <span>Base UI </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleUI ? 'block' : 'none' }">
          <li><router-link to="alerts">Alerts</router-link></li>
          <li><router-link to="accordions">Accordions</router-link></li>
          <li><router-link to="avatar">Avatar</router-link></li>
          <li><router-link to="badges">Badges</router-link></li>
          <li><router-link to="buttons">Buttons</router-link></li>
          <li><router-link to="buttongroup">Button Group</router-link></li>
          <li><router-link to="breadcrumbs">Breadcrumb</router-link></li>
          <li><router-link to="cards">Cards</router-link></li>
          <li><router-link to="carousel">Carousel</router-link></li>
          <li><router-link to="dropdowns">Dropdowns</router-link></li>
          <li><router-link to="grid">Grid</router-link></li>
          <li><router-link to="images">Images</router-link></li>
          <li><router-link to="lightbox">Lightbox</router-link></li>
          <li><router-link to="media">Media</router-link></li>
          <li><router-link to="modal">Modals</router-link></li>
          <li><router-link to="offcanvas">Offcanvas</router-link></li>
          <li><router-link to="pagination">Pagination</router-link></li>
          <li><router-link to="popover">Popover</router-link></li>
          <li><router-link to="progress">Progress Bars</router-link></li>
          <li><router-link to="placeholders">Placeholders</router-link></li>
          <li><router-link to="rangeslider">Range Slider</router-link></li>
          <li><router-link to="spinners">Spinner</router-link></li>
          <li><router-link to="sweetalerts">Sweet Alerts</router-link></li>
          <li><router-link to="tab">Tabs</router-link></li>
          <li><router-link to="tooltip">Tooltip</router-link></li>
          <li><router-link to="typography">Typography</router-link></li>
          <li><router-link to="video">Video</router-link></li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:void(0);" @click="toggleVisibilityElements"
          ><i class="feather feather-box"></i> <span>Elements </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleElements ? 'block' : 'none' }">
          <li><router-link to="ribbon">Ribbon</router-link></li>
          <li><router-link to="clipboard">Clipboard</router-link></li>
          <li><router-link to="drag-drop">Drag & Drop</router-link></li>
          <li><router-link to="text-editor">Text Editor</router-link></li>
          <li><router-link to="counter">Counter</router-link></li>
          <li><router-link to="scrollbar">Scrollbar</router-link></li>
          <li><router-link to="notification">Notification</router-link></li>
          <li><router-link to="stickynote">Sticky Note</router-link></li>
          <li><router-link to="timeline">Timeline</router-link></li>
          <li><router-link to="horizontal-timeline">Horizontal Timeline</router-link></li>
          <li><router-link to="form-wizard">Form Wizard</router-link></li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:void(0);" @click="toggleVisibilityCharts"
          ><i class="feather feather-bar-chart"></i> <span> Charts </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleCharts ? 'block' : 'none' }">
          <li><router-link to="chart-apex">Apex Charts</router-link></li>
          <li><router-link to="chart-js">Chart Js</router-link></li>
          <li><router-link to="chart-morris">Morris Charts</router-link></li>
          <li><router-link to="chart-flot">Flot Charts</router-link></li>
          <li><router-link to="chart-c3">C3 Charts</router-link></li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:void(0);" @click="toggleVisibilityIcons"
          ><i class="feather feather-award"></i> <span> Icons </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleIcons ? 'block' : 'none' }">
          <li><router-link to="icon-fontawesome">Fontawesome Icons</router-link></li>
          <li><router-link to="icon-feather">feather Icons</router-link></li>
          <li><router-link to="icon-ionic">Ionic Icons</router-link></li>
          <li><router-link to="icon-material">Material Icons</router-link></li>
          <li><router-link to="icon-pe7">Pe7 Icons</router-link></li>
          <li><router-link to="icon-simpleline">Simpleline Icons</router-link></li>
          <li><router-link to="icon-themify">Themify Icons</router-link></li>
          <li><router-link to="icon-weather">Weather Icons</router-link></li>
          <li><router-link to="icon-typicon">Typicon Icons</router-link></li>
          <li><router-link to="icon-flag">Flag Icons</router-link></li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:void(0);" @click="toggleVisibilityForms"
          ><i class="feather feather-sidebar"></i> <span> Forms </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleForms ? 'block' : 'none' }">
          <li><router-link to="form-basic-inputs">Basic Inputs </router-link></li>
          <li><router-link to="form-input-groups">Input Groups </router-link></li>
          <li><router-link to="form-horizontal">Horizontal Form </router-link></li>
          <li><router-link to="form-vertical"> Vertical Form </router-link></li>
          <li><router-link to="form-mask">Form Mask </router-link></li>
          <li><router-link to="form-validation">Form Validation </router-link></li>
          <li><router-link to="form-select2">Form Select2 </router-link></li>
          <li><router-link to="form-fileupload">File Upload </router-link></li>
        </ul>
      </li>
      <li class="submenu">
        <a href="javascript:void(0);" @click="toggleVisibilityTables"
          ><i class="feather feather-layout"></i> <span> Tables </span>
          <span class="menu-arrow"></span
        ></a>
        <ul :style="{ display: isVisibleTables ? 'block' : 'none' }">
          <li><router-link to="tables-basic">Basic Tables </router-link></li>
          <li><router-link to="data-tables">Data Table </router-link></li>
        </ul>
      </li>
      <li class="menu-title">
        <span>Extras</span>
      </li>
      <li>
        <a href="javascript:;"
          ><i class="la la-file-text"></i> <span>Documentation</span></a
        >
      </li>
      <li>
        <a href="javascript:void(0);"
          ><i class="la la-info"></i> <span>Change Log</span>
          <span class="badge badge-primary ms-auto">v3.4</span></a
        >
      </li>
      <li class="submenu">
        <a href="javascript:void(0);"
          ><i class="la la-share-alt"></i> <span>Multi Level</span>
          <span class="menu-arrow"></span
        ></a>
        <ul>
          <li class="submenu">
            <a href="javascript:void(0);">
              <span>Level 1</span> <span class="menu-arrow"></span
            ></a>
            <ul>
              <li>
                <a href="javascript:void(0);"><span>Level 2</span></a>
              </li>
              <li class="submenu">
                <a href="javascript:void(0);">
                  <span> Level 2</span> <span class="menu-arrow"></span
                ></a>
                <ul>
                  <li><a href="javascript:void(0);">Level 3</a></li>
                  <li><a href="javascript:void(0);">Level 3</a></li>
                </ul>
              </li>
              <li>
                <a href="javascript:void(0);"> <span>Level 2</span></a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);"> <span>Level 1</span></a>
          </li>
        </ul>
      </li>
    </ul> -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isVisibleDashboard: false,
      isVisibleApps: false,
      isVisibleCalls: false,
      isVisibleEmployees: false,
      isVisibleProjects: false,
      isVisibleSales: false,
      isVisibleAccounting: false,
      isVisiblePayroll: false,
      isVisibleReports: false,
      isVisiblePerformance: false,
      isVisibleGoals: false,
      isVisibleTraining: false,
      isVisibleJobs: false,
      isVisibleProfile: false,
      isVisibleAuthentication: false,
      isVisibleError: false,
      isVisibleSubscriptions: false,
      isVisiblePages: false,
      isVisibleUI: false,
      isVisibleElements: false,
      isVisibleCharts: false,
      isVisibleIcons: false,
      isVisibleForms: false,
      isVisibleTables: false,
      isVisibleSettings:false,
      isAdmin:false
    };
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", function () {
      const viewMoreButton = document.getElementById("viewMoreButton");
      const hiddenMenu = document.getElementById("hiddenMenu");
      const pageId = document.body.id; // Get the current page's ID

      if(viewMoreButton)
      {
        viewMoreButton.addEventListener("click", function () {

          if(hiddenMenu)
          {
            hiddenMenu.classList.toggle("hidden");
          }
          
        });
      }

      // Hide the menu by default on pages other than the one specified
      if (pageId !== "currentPageId") {
        if(hiddenMenu)
          {
            hiddenMenu.classList.add("hidden");
          }
      }
    });
  },
  methods: {
    toggleVisibilityDashboard() {
      this.isVisibleDashboard = !this.isVisibleDashboard;
    },
    toggleVisibilityApps() {
      this.isVisibleApps = !this.isVisibleApps;
    },
    toggleVisibilityCalls() {
      this.isVisibleCalls = !this.isVisibleCalls;
    },
    toggleVisibilityEmployees() {
      this.isVisibleEmployees = !this.isVisibleEmployees;
    },
    toggleVisibilityProjects() {
      this.isVisibleProjects = !this.isVisibleProjects;
    },
    toggleVisibilitySales() {
      this.isVisibleSales = !this.isVisibleSales;
    },
    toggleVisibilityAccounting() {
      this.isVisibleAccounting = !this.isVisibleAccounting;
    },
    toggleVisibilityPayroll() {
      this.isVisiblePayroll = !this.isVisiblePayroll;
    },
    toggleVisibilityReports() {
      this.isVisibleReports = !this.isVisibleReports;
    },
    toggleVisibilityPerformance() {
      this.isVisiblePerformance = !this.isVisiblePerformance;
    },
    toggleVisibilityGoals() {
      this.isVisibleGoals = !this.isVisibleGoals;
    },
    toggleVisibilityTraining() {
      this.isVisibleTraining = !this.isVisibleTraining;
    },
    toggleVisibilityJobs() {
      this.isVisibleJobs = !this.isVisibleJobs;
    },
    toggleVisibilityProfile() {
      this.isVisibleProfile = !this.isVisibleProfile;
    },
    toggleVisibilityAuthentication() {
      this.isVisibleAuthentication = !this.isVisibleAuthentication;
    },
    toggleVisibilityError() {
      this.isVisibleError = !this.isVisibleError;
    },
    toggleVisibilitySubscriptions() {
      this.isVisibleSubscriptions = !this.isVisibleSubscriptions;
    },
    toggleVisibilityPages() {
      this.isVisiblePages = !this.isVisiblePages;
    },
    toggleVisibilityUI() {
      this.isVisibleUI = !this.isVisibleUI;
    },
    toggleVisibilityElements() {
      this.isVisibleElements = !this.isVisibleElements;
    },
    toggleVisibilityCharts() {
      this.isVisibleCharts = !this.isVisibleCharts;
    },
    toggleVisibilityIcons() {
      this.isVisibleIcons = !this.isVisibleIcons;
    },
    toggleVisibilityForms() {
      this.isVisibleForms = !this.isVisibleForms;
    },
    toggleVisibilityTables() {
      this.isVisibleTables = !this.isVisibleTables;
    },
    toggleVisibilitySettings(){
      this.isVisibleSettings = !this.isVisibleSettings
    }
  },
  created(){
    let role = localStorage.getItem('role');

    if(role && role != null)
    {
      if(Array.isArray(role) && role != null)
      {
        if(role.includes('Administrative / Super Admin'))
        {
          this.isAdmin = true;
        }
      }
      else if(role == 'Administrative / Super Admin')
      {
        this.isAdmin = true;
      }
    }
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    DashboardMenu() {
      return (
        this.$route.name == "admin-dashboard" || this.$route.name == "employee-dashboard"
      );
    },
    AppsMenu() {
      return (
        this.$route.name == "chat" ||
        this.$route.name == "events" ||
        this.$route.name == "contacts" ||
        this.$route.name == "inbox" ||
        this.$route.name == "file-manager"
      );
    },
    CallMenu() {
      return (
        this.$route.name == "voice-call" ||
        this.$route.name == "video-call" ||
        this.$route.name == "outgoing-call" ||
        this.$route.name == "incoming-call"
      );
    },
    EmployeesMenu() {
      return (
        this.$route.name == "employees" ||
        this.$route.name == "employees-list" ||
        this.$route.name == "holidays" ||
        this.$route.name == "leaves" ||
        this.$route.name == "leaves-employee" ||
        this.$route.name == "attendance" ||
        this.$route.name == "attendance-employee" ||
        this.$route.name == "departments" ||
        this.$route.name == "designations" ||
        this.$route.name == "timesheet" ||
        this.$route.name == "shift-scheduling" ||
        this.$route.name == "shift-list" ||
        this.$route.name == "overtime" ||
        this.$route.name == 'profile' ||
        this.$route.name == 'assets-details' ||
        this.$route.name == 'wages'
      );
    },
    ProjectsMenu() {
      return (
        this.$route.name == "projects" ||
        this.$route.name == "tasks" ||
        this.$route.name == "task-board" ||
        this.$route.name == "project-list" ||
        this.$route.name == "project-view"
      );
    },
    SalesMenu() {
      return (
        this.$route.name == "estimates" ||
        this.$route.name == "create-estimate" ||
        this.$route.name == "edit-estimate" ||
        this.$route.name == "estimate-view" ||
        this.$route.name == "invoices" ||
        this.$route.name == "create-invoice" ||
        this.$route.name == "edit-invoice" ||
        this.$route.name == "invoice-view" ||
        this.$route.name == "payments" ||
        this.$route.name == "expenses" ||
        this.$route.name == "provident-fund" ||
        this.$route.name == "taxes"
      );
    },
    AccountingMenu() {
      return (
        this.$route.name == "categories" ||
        this.$route.name == "budgets" ||
        this.$route.name == "budget-expenses" ||
        this.$route.name == "budget-revenues" ||
        this.$route.name == "sub-category"
      );
    },
    PayrollMenu() {
      return (
        this.$route.name == "salary" ||
        this.$route.name == "salary-view" ||
        this.$route.name == "payroll-items"
      );
    },
    ReportsMenu() {
      return (
        this.$route.name == "expense-reports" ||
        this.$route.name == "invoice-reports" ||
        this.$route.name == "payments-reports" ||
        this.$route.name == "project-reports" ||
        this.$route.name == "task-reports" ||
        this.$route.name == "user-reports" ||
        this.$route.name == "employee-reports" ||
        this.$route.name == "payslip-reports" ||
        this.$route.name == "attendance-reports" ||
        this.$route.name == "leave-reports" ||
        this.$route.name == "daily-reports"
      );
    },
    PerformanceMenu() {
      return (
        this.$route.name == "performance-indicator" ||
        this.$route.name == "performance" ||
        this.$route.name == "performance-appraisal"
      );
    },
    GoalsMenu() {
      return this.$route.name == "goal-tracking" || this.$route.name == "goal-type";
    },
    TrainingMenu() {
      return (
        this.$route.name == "training" ||
        this.$route.name == "trainers" ||
        this.$route.name == "training-type"
      );
    },
    JobsMenu() {
      return (
        this.$route.name == "user-dashboard" ||
        this.$route.name == "user-all-jobs" ||
        this.$route.name == "saved-jobs" ||
        this.$route.name == "applied-jobs" ||
        this.$route.name == "interviewing" ||
        this.$route.name == "job-aptitude" ||
        this.$route.name == "questions" ||
        this.$route.name == "offered-jobs" ||
        this.$route.name == "visited-jobs" ||
        this.$route.name == "archived-jobs" ||
        this.$route.name == "jobs-dashboard" ||
        this.$route.name == "jobs" ||
        this.$route.name == "manage-resumes" ||
        this.$route.name == "shortlist-candidates" ||
        this.$route.name == "interview-questions" ||
        this.$route.name == "offer-approvals" ||
        this.$route.name == "experiance-level" ||
        this.$route.name == "candidates" ||
        this.$route.name == "schedule-timing" ||
        this.$route.name == "apptitude-result"
      );
    },
    ProfileMenu() {
      return (
        this.$route.name == "profile" ||
        this.$route.name == "client-profile" ||
        this.$route.name == "user-asset-details"
      );
    },
    AuthenticationMenu() {
      return (
        this.$route.name == "/" ||
        this.$route.name == "register" ||
        this.$route.name == "forgot-password" ||
        this.$route.name == "otp" ||
        this.$route.name == "lock-screen"
      );
    },
    ErrorMenu() {
      return this.$route.name == "error-404" || this.$route.name == "error-500";
    },
    SubscriptionsMenu() {
      return (
        this.$route.name == "subscriptions" ||
        this.$route.name == "subscriptions-company" ||
        this.$route.name == "subscribed-companies"
      );
    },
    FormsMenu() {
      return (
        this.$route.name == "form-basic-inputs" ||
        this.$route.name == "form-input-groups" ||
        this.$route.name == "form-horizontal" ||
        this.$route.name == "form-vertical" ||
        this.$route.name == "form-mask" ||
        this.$route.name == "form-validation"
      );
    },
    TablesMenu() {
      return this.$route.name == "tables-basic" || this.$route.name == "data-tables";
    },
    PagesMenu() {
      return (
        this.$route.name == "search" ||
        this.$route.name == "faq" ||
        this.$route.name == "terms" ||
        this.$route.name == "privacy-policy" ||
        this.$route.name == "blank-page"
      );
    },
    ElementsMenu() {
      return (
        this.$route.name == "ribbon" ||
        this.$route.name == "clipboard" ||
        this.$route.name == "drag-drop" ||
        this.$route.name == "rating" ||
        this.$route.name == "text-editor" ||
        this.$route.name == "counter" ||
        this.$route.name == "scrollbar" ||
        this.$route.name == "notification" ||
        this.$route.name == "stickynote" ||
        this.$route.name == "timeline" ||
        this.$route.name == "horizontal-timeline" ||
        this.$route.name == "form-wizard"
      );
    },
    BaseUIMenu() {
      return (
        this.$route.name == "alerts" ||
        this.$route.name == "accordions" ||
        this.$route.name == "avatar" ||
        this.$route.name == "badges" ||
        this.$route.name == "buttons" ||
        this.$route.name == "buttongroup" ||
        this.$route.name == "breadcrumbs" ||
        this.$route.name == "cards" ||
        this.$route.name == "carousel" ||
        this.$route.name == "dropdowns" ||
        this.$route.name == "grid" ||
        this.$route.name == "images" ||
        this.$route.name == "lightbox" ||
        this.$route.name == "media" ||
        this.$route.name == "modal" ||
        this.$route.name == "offcanvas" ||
        this.$route.name == "pagination" ||
        this.$route.name == "popover" ||
        this.$route.name == "progress" ||
        this.$route.name == "placeholders" ||
        this.$route.name == "rangeslider" ||
        this.$route.name == "spinners" ||
        this.$route.name == "sweetalerts" ||
        this.$route.name == "tab" ||
        this.$route.name == "tooltip" ||
        this.$route.name == "typography" ||
        this.$route.name == "video"
      );
    },
    ChartsMenu() {
      return (
        this.$route.name == "chart-apex" ||
        this.$route.name == "chart-js" ||
        this.$route.name == "chart-morris" ||
        this.$route.name == "chart-flot" ||
        this.$route.name == "chart-c3"
      );
    },
    IconsMenu() {
      return (
        this.$route.name == "icon-fontawesome" ||
        this.$route.name == "icon-feather" ||
        this.$route.name == "icon-ionic" ||
        this.$route.name == "icon-material" ||
        this.$route.name == "icon-pe7" ||
        this.$route.name == "icon-simpleline" ||
        this.$route.name == "icon-themify" ||
        this.$route.name == "icon-weather" ||
        this.$route.name == "icon-typicon" ||
        this.$route.name == "icon-flag"
      );
    },
    FormsMenu() {
      return (
        this.$route.name == "form-basic-inputs" ||
        this.$route.name == "form-input-groups" ||
        this.$route.name == "form-horizontal" ||
        this.$route.name == "form-vertical" ||
        this.$route.name == "form-mask" ||
        this.$route.name == "form-validation" ||
        this.$route.name == "form-select2" ||
        this.$route.name == "form-fileupload"
      );
    },
    TablesMenu() {
      return this.$route.name == "tables-basic" || this.$route.name == "data-tables";
    },
    SettingsMenu(){
      return (this.$route.name == 'leave-settings' || this.$route.name == 'organization-documents')
    }
  },
};
</script>
