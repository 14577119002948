<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <inbox-header :title="title" :path="path" :text="text" :text1="text1" :hasCreatePermission="hasCreatePermission" :hasImportPermission="hasImportPermission" @clearImportForm="clearImportForm" @reset-data="onResetData" />
        <!-- /Page Header -->

		<Form @submit="onSearch">
			<div class="row filter-row">
				<div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-12">
				  <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
					<input
					  type="text"
					  class="form-control floating"
					  v-model="searchform.name"
					  @focus="isFocused = true"
					  @blur="isFocused = searchform.name !== ''"
					/>
					<label class="focus-label"> Designation</label>
				  </div>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <button class="btn btn-success w-100 submit-btn">Search</button>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" style="margin-left: 10px;">
				  <a href="javascript:;" class="btn btn-danger w-100 submit-btn" @click="clearForm"> Clear </a>
				</div>
			  </div>
		</Form>  
		
        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
				  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label
              >
            </div>
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Designation'">
                    <div>{{ record.Designation }}</div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end" v-if="hasEditPermission || hasDeletePermission">
                      <div class="dropdown dropdown-action">
                        <a v-if="hasEditPermission"
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_designation"
							@click="EditDesignation(record)"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a v-if="hasDeletePermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_designation"
							@click="DeleteDes(record.id)"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
		
      </div>
      <!-- /Page Content -->
      <designation-model :form="create_form" :editform="edit_form" :departmentlist="department_list" @create-designation="createDesignation" @update-designation="updateDesignation" @delete-designation="deleteDesignation" :deldes_id="deldesid" ref="designationsmodel" :error="errors" ></designation-model>

	  <!-- Import designation Modal -->
	  <div id="import_Designation" class="modal custom-modal fade" role="dialog">
		<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
		  <div class="modal-header">
		  <h5 class="modal-title">Import Designations</h5>
		  <button
			type="button"
			class="btn-close"
			data-bs-dismiss="modal"
			aria-label="Close"
			ref="impdesignationclose"
		  >
			<span aria-hidden="true">&times;</span>
		  </button>
		  </div>
		  <div class="modal-body">
		  <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
			<div class="input-block mb-3">
			<label class="col-form-label"
			  >File <span class="text-danger">*</span>
			</label>
			<Field
				name="designation_import_file"
				type="file"
				class="form-control"
				:class="{ 'is-invalid': errors && errors?.designation_import_file }"
				@change="handleFileUpload($event)"
				accept=".xlsx,.xls"	
				ref="importFile"
			  />
			  <div class="invalid-feedback">{{ errors && errors?.designation_import_file }}</div>
			  <div class="filesshow text-danger" id="file"></div>
			  <p> <a :href="user_demo_file" target="_blank">Click here </a> for download Demo file </p>
			</div>
			<div class="submit-section">
			<button class="btn btn-primary submit-btn">Submit</button>
			</div>
		  </Form>
		  </div>
		</div>
		</div>
	  </div>
	  <!-- /Import designation Modal -->
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>

var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};
		
const columns = [
  {
    title: "#",
    dataIndex: "id",
	key: "id",
	customRender: ({ index }) => {
		return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	},
  },
  {
    title: "Designation",
    dataIndex: "name",
    key: "name",
	sorter: false,
    /*sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },*/
  },
  /*{
    title: "Department",
    dataIndex: ["department","name"],
	sorter: false,
	
  },*/
];

const data = [];

import axios from 'axios';
import { notification } from "ant-design-vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

export default {
  data() {
    return {
	  searchform: {name: ""},
	  columns:columns,
      data,
      title: "Designations",
      path: "Dashboard",
      text: "Designations",
      text1: "Add Designation",
	  pagination: pagination,
	  create_form: { "designation_name": "", "department": "" },
	  department_list : {},
	  edit_form: {},
	  deldesid: 0,
	  hasReadPermission:null,
	  hasEditPermission:null,
      hasDeletePermission:null,
	  hasCreatePermission:null,
	  hasImportPermission:null,
	  editfile:null,
	  errors:null,
    };
  },
  components:{Form,Field},
  computed:{
	user_demo_file(){
		return `${process.env.VUE_APP_IMAGE_BASE_URL}sample/designation.xlsx` 
	}
  },
  methods: {
	onResetData()
	{
		this.errors = null;
		this.create_form = { "designation_name": "", "department": "" };
	},
	clearImportForm()
    {
		const fileInput = document.querySelector('input[name="designation_import_file"]');
		if (fileInput) {
		fileInput.value = '';
		}
		this.$refs.importFile.value = ''
		this.editfile = null
    },
	clearForm(){
		
		this.searchform = {name: ""};
		
		var params = {
		   params: { per_page: this.pagination.pageSize }
		};	
		this.loadCommonData(params);
	
	},
	onSearch(){
		console.log('testing...');
		
		var params = {
		   params: { per_page: this.pagination.pageSize,page:this.pagination.current, name: this.searchform.name}
		};	
		
		this.loadCommonData(params);
		
	},
	handleFileUpload(event){
		this.editfile = event.target.files[0];
		},
		onSubmit()
		{
		var token = window.localStorage.getItem("token");
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;

		let loader = this.$loading.show({
		container: this.$refs.formContainer,
		canCancel: false
		});

		var form = new FormData();
		form.append('designation_import_file', this.editfile);

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
		};

		axios.post("/designations/import", form,config).then(response => {

			this.editfile = null
			this.$refs.importFile.value = ''
			loader.hide();

			this.updatePerPage()
			
			notification.open({
			message: response?.data?.message || 'Successfully Imported',
			placement: "topRight",
			duration: process.env.VUE_APP_NOTIFICATION_DURATION,
			style: {
				background: process.env.VUE_APP_SUCCESS_COLOR,
			},
			});

			this.$refs.impdesignationclose.click();

		}).catch(error =>{
			
			loader.hide();
			if(error.response){
		
				var response = (error.response);
					
				notification.open({
					message: response?.data?.message || 'Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
				}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
		})

		},
	handleTableChange(pagesize){
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current, name: this.searchform.name }
		};
		
		this.loadCommonData(params);
		
	},
	deleteDesignation(depid){
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.delete("/designations/"+depid, [])
          .then( (response) => {
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.designationsmodel.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	DeleteDes(desid){
		this.deldesid = desid;
	},
	updateDesignation(formval){
		this.errors = null;
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		var postform = new FormData();
		postform.append('designation_name',formval.name);
		postform.append('department',formval.department_id);
		postform.append('permissions',JSON.stringify(formval.permissions));
		postform.append('is_reporter',formval.is_reporter == 'true' || formval.is_reporter == true ? 1 : 0 );

		
		axios.put("/designations/"+formval.id, postform)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.designationsmodel.closeDialog();
			 this.edit_form ={};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
				this.errors = response?.data?.error || null
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
	},
    EditDesignation(record){
		this.edit_form = Object.assign({}, record);
	},
	createDesignation(formval){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		this.errors = null
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});

		formval.is_reporter = formval.is_reporter == 'true' || formval.is_reporter == true ? 1 : 0;
		
		axios.post("/designations", formval)
          .then( (response) => {
				
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.designationsmodel.closeDialog();
			 
			 this.create_form = { "designation_name": "", "department": "" };
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
				this.errors = response?.data?.error || null
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
	},
	updatePerPage(){
		var params = {
          params: { per_page: this.pagination.pageSize }
        };
        this.loadCommonData(params);
		
	},
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/designations", params)
			.then((response) => {
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				this.data = response.data.data.data;
				
				loader.hide();
				
				
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
	loadAllDepartments(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/departments/all", params)
			.then((response) => {
				
				this.department_list = response.data.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response); 
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	}
  },
  mounted() {

	this.$store.dispatch('userPermissions', { module: 'designation', action: 'read' }).then(response =>{
      this.hasReadPermission=null;
      this.$nextTick(()=>{
        this.hasReadPermission = response;
		if(response)
		{
			this.columns = [...columns,{
				title: "Action",
				sorter: false,
				key: "action",
				class: "text-end",
			}];
		}
      })
    })

	this.$store.dispatch('userPermissions', { module: 'designation', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'designation', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })

	this.$store.dispatch('userPermissions', { module: 'designation', action: 'create' }).then(response =>{
      this.hasCreatePermission=null;
      this.$nextTick(()=>{
        this.hasCreatePermission = response;
      })
    })

	this.$store.dispatch('userPermissions', { module: 'designation', action: 'import' }).then(response =>{
      this.hasImportPermission=null;
      this.$nextTick(()=>{
        this.hasImportPermission = response;
      })
    })
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };
	
	this.loadCommonData(params);
	
	this.loadAllDepartments([]);
	
  },
  setup() {
    const schema = Yup.object().shape({
		designation_import_file: Yup.string().required("File is required"),
    });
	return {
      schema
	}
  },
};
</script>
