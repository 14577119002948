<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <employees-header :title="title" :path="path" :text="text" :text1="text1" @clearFormData="clearFormData" @clearEmployeeData="clearEmployeeData" />
        <!-- /Page Header -->

        <!-- Search Filter -->
        <search-filter @reload-data="searchData"></search-filter>
        <!-- /Search Filter -->

        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label
              >
            </div>
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="Employees"
                :pagination="pagination"
				        @change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'first_name'">
                    <h2 class="table-avatar">
                          <router-link :to="'profile/'+record?.id" class="avatar"
                            >
                            <img v-if="record?.avatar"
                            :src="getEmployeeAvatar(record.avatar)"
                            alt="User Image"
                            @error="handleImageError(record)"
                            />
                            <img v-else
                            :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                                alt="User Image" 
                            />
                   </router-link>
                      <router-link :to="'profile/'+record?.id"
                        >{{ record?.first_name }} {{ record?.last_name }} <span>{{ (record?.designation && record?.designation?.name) ? '- '+record?.designation?.name : '' }}</span></router-link
                      >
                    </h2>
                  </template>
                  <template v-else-if="column.key === 'joining_date'">
                      {{ formatDate(record?.joining_date) || record?.joining_date }}
                  </template>
                  <template v-else-if="column.key === 'Role'">
                    <div class="dropdown">
                      <a
                        href="javascript:;"
                        class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        > {{ (record?.designation && record.designation?.name) ?  record?.designation?.name : '-'}}
                      </a>
                      <div class="dropdown-menu">
                        <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                        <a class="dropdown-item" @click="updateDesignation(desgntn?.id,record?.id)" v-for="desgntn in Designations" :key="desgntn.id">
                          <span :class="{'isActive':(record?.designation && record?.designation?.id && record?.designation?.id == desgntn?.id)}">{{ desgntn?.name }}</span>
                        </a>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end">
                      <div class="dropdown dropdown-action" v-if="hasReadPermission">
                        <a 
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a v-if="hasEditPermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_employee"
                            @click="employee(record)"
                            ><i class="fa-solid fa-pencil m-r-5" ></i> Edit</a
                          >
                          <a v-if="hasDeletePermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_employee"
                            @click="employee(item)"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <employee-model :employeeData="employeeData" :clearForm="clearForm" :clearEMP="clearEMP"></employee-model>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { notification } from "ant-design-vue";
import axios from 'axios';

var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};

const columns = [
  {
    title: "Name",
    dataIndex: "first_name",
    key: "first_name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Employee ID",
    dataIndex: "employee_id",
    sorter: {
      compare: (a, b) => {
        a = a.EmployeeID.toLowerCase();
        b = b.EmployeeID.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Mobile",
    dataIndex: "contact_number",
    sorter: {
      compare: (a, b) => {
        a = a.Mobile.toLowerCase();
        b = b.Mobile.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Join Date",
    dataIndex: "joining_date",
    key:'joining_date',
    sorter: {
      compare: (a, b) => {
        a = a.JoinDate.toLowerCase();
        b = b.JoinDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Role",
    dataIndex: "Role",
    key: "Role",
    sorter: true,
  },
  // {
  //   title: "Action",
  //   sorter: true,
  //   key: "action",
  // },
];

export default {
  data() {
    return {
      clearForm:false,
      clearEMP:false,
      columns:columns,
      // data,
      title: "Employee",
      path: "Dashboard",
      text: "Employee",
      text1: "Add Employee",
      defaultAvatar:'avatar-02.jpg',
      employeeData:null,
      hasEditPermission:null,
      hasDeletePermission:null,
      hasReadPermission:null,
      perpage: 10,
      pagination: pagination,
      Employees:[],
      Designations:[]
    };
  },
  computed: {
    ...mapGetters(['getEmployees']),
  },
  methods:{
    ...mapActions(['fetchEmployees','setEmployeeData']),
    clearEmployeeData()
    {
      this.clearEMP = false;
      this.$nextTick(()=>{
        this.clearEMP = true;
      })
    },
    clearFormData()
    {
      this.clearForm = false;
      this.$nextTick(()=>{
        this.clearForm = true;
      })
    },
    updateDesignation(designation_id,user_id)
    {
      var token = window.localStorage.getItem("token");
	
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.formContainer,
        canCancel: false
      });

      axios.get('designations/user-update/'+user_id+'/'+designation_id).then(response => {
        loader.hide();
        var params = {
          params: { per_page: this.pagination.pageSize }
        };
        this.loadCommonData(params)

      }).catch(error => {
        loader.hide();
          
          var response = (error.response);
          
          if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
          
          localStorage.clear();
          
          notification.open({
              message: 'Please Login',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          this.$router.push({name: 'login'}).catch(error => {}) 
          
          }else{
          
          this.errorMessage = error.message;
          notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }
      })

    },
    searchData(searchData)
    {
      var params = {
        params: { per_page: this.pagination.pageSize }
      };
      this.loadCommonData(params,searchData);
    },
    loadCommonData(params,searchData = null){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
    var getQuery = '/employee';
    if(searchData != null)
    {
      getQuery = '/employee?name='+searchData.name+'&employee_id='+searchData.employee_id+'&designation_id='+searchData.designation_id;
    }
		axios.get(getQuery, params)
			.then((response) => {
        loader.hide();
				this.pagination.total = response.data.data.record.total;
				this.pagination.current = response.data.data.record.current_page;
				this.pagination.pageSize = response.data.data.record.per_page;
				
				this.Employees = response.data.data.record.data || [];
			}).catch((error) => {
			  
			  loader.hide();
          
          var response = (error.response);
          
          if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
          
          localStorage.clear();
          
          notification.open({
              message: 'Please Login',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          this.$router.push({name: 'login'}).catch(error => {}) 
          
          }else{
          
          this.errorMessage = error.message;
          notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }
          
        });
    },
    handleTableChange(pagesize){
        var params = {
          params: { per_page: pagesize.pageSize,page:pagesize.current }
        };
        
        this.loadCommonData(params);
        
    },
    updatePerPage(){
      var params = {
            params: { per_page: this.pagination.pageSize }
          };
      this.loadCommonData(params);
      
    },
    employee(employee){
     
      this.setEmployeeData(employee);
      this.$nextTick(()=>{
        this.employeeData = employee;
      })
    },
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(record) {
      record.avatar = null;
      record.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
    getDesignation()
    {
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      axios.get('designations/all').then(response =>{
      
          this.Designations = response?.data?.data || [];

      }).catch(error=>{
        console.log('error during getting designation : ',error)
      })

    }

  },
  async created() {

  
    this.$store.dispatch('userPermissions', { module: 'employee', action: 'read' }).then(response =>{
      this.hasReadPermission=null;
      this.$nextTick(()=>{
        this.hasReadPermission = response;
        if(response)
        {
          this.columns = [...columns,{
            title: "Action",
            sorter: false,
            key: "action",
            class: "text-end",
          }];
        }
      })
    })

    

    this.$store.dispatch('userPermissions', { module: 'employee', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'employee', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })

    

    let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
    this.updatePerPage();
    this.getDesignation()
    loader.hide()
  }
};
</script>
