<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <h3 class="page-title">{{ title }}</h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="admin-dashboard">{{ path }}</router-link>
          </li>
          <li class="breadcrumb-item active">{{ text }}</li>
        </ul>
      </div>
      <div class="col-auto" v-if="isEmployeeReportsRoute">
        <a class="list-view btn btn-link btn-primary mr-3" data-bs-toggle="modal"
        data-bs-target="#employee_manage_colvis" style="margin-right: 5px;" title="colvis"><i class="fa-solid fa-bars"></i></a>
        <a @click="exportReport('export-empoyee-report')"  class="btn btn-primary ">{{ text1 }}</a>
      </div>
      <div class="col-auto" v-if="isLeaveReportsRoute">
		<a class="list-view btn btn-link btn-primary mr-3" data-bs-toggle="modal"
        data-bs-target="#leave_report_manage_colvis" style="margin-right: 5px;" title="colvis"><i class="fa-solid fa-bars"></i></a>
        <a @click="exportReport('export-leave-report')" class="btn btn-primary">{{ text1 }}</a>
      </div>
      <div class="col-auto" v-if="isDailyReportsRoute">
        <a class="list-view btn btn-link btn-primary mr-3" data-bs-toggle="modal"
        data-bs-target="#daily_report_manage_colvis" style="margin-right: 5px;" title="colvis"><i class="fa-solid fa-bars"></i></a>
        <a @click="exportReport('export-daily-report')" href="javascript:void(0);" class="btn btn-primary">{{ text1 }}</a>
      </div>
	  <div class="col-auto" v-if="isAttendanceReportsRoute">
        <a class="list-view btn btn-link btn-primary mr-3" data-bs-toggle="modal"
        data-bs-target="#attendance_report_manage_colvis" style="margin-right: 5px;" title="colvis"><i class="fa-solid fa-bars"></i></a>
        <a @click="exportReport('export-attendance-report')" href="javascript:void(0);" class="btn btn-primary">{{ text1 }}</a>
      </div>
      <div class="col-auto" v-if="isAttendanceExportReportsRoute">
        <!-- <a class="list-view btn btn-link btn-primary mr-3" data-bs-toggle="modal"
        data-bs-target="#attendance_report_manage_colvis" style="margin-right: 5px;" title="colvis"><i class="fa-solid fa-bars"></i></a> -->
        <a @click="exportReport('attendance-export-report')" href="javascript:void(0);" class="btn btn-primary">{{ text1 }}</a>
      </div>
	  <div class="col-auto" v-if="isProjectReportsRoute">
        <a class="list-view btn btn-link btn-primary mr-3" data-bs-toggle="modal"
        data-bs-target="#project_report_manage_colvis" style="margin-right: 5px;" title="colvis"><i class="fa-solid fa-bars"></i></a>
        <a @click="exportReport('export-project-report')" href="javascript:void(0);" class="btn btn-primary">{{ text1 }}</a>
      </div>
	  <div class="col-auto float-end ms-auto" v-if="isSubscriptionRoute">
        <a
          href="javascript:void(0);"
          class="btn add-btn"
          data-bs-toggle="modal"
          data-bs-target="#add_plan"
          ><i class="fa-solid fa-plus"></i>{{ text1 }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { notification } from "ant-design-vue";
export default {
  props: {
    title: { type: String, default: "" },
    path: { type: String, default: "" },
    text: { type: String, default: "" },
    text1: { type: String, default: "" },
    search_data: { type: Object, default: null, required :false },
    columns: { type: Array, default: null, required :false },

  },
  computed: {
    isEmployeeReportsRoute() {
      return this.$route.path === "/employee-reports";
    },
    isLeaveReportsRoute() {
      return this.$route.path === "/leave-reports";
    },
    isDailyReportsRoute() {
      return this.$route.path === "/daily-reports";
    },
    isSubscriptionRoute() {
      return this.$route.path === "/subscriptions";
    },
	isAttendanceReportsRoute() {
      return this.$route.path === "/attendance-reports";
    },
	isProjectReportsRoute() {
      return this.$route.path === "/project-reports";
    },
    isAttendanceExportReportsRoute(){
      return this.$route.path === "/export-attendance";
    } 
  },
  methods:{
    exportReport(report)
    {
		
	  if(report == 'export-attendance-report' && this.search_data.s_employee_id == ''){
	  
		notification.open({
						message: 'Please Select An Employee',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
			return;		
	  }
	  
      var searchData = null;

      var col_params = null;

      var url = `${process.env.VUE_APP_API_BASE_URL}report/${report}`;

      if(this.search_data && this.search_data != null)
      {
        searchData = Object.keys(this.search_data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.search_data[key])}`)
        .join('&');
      }

      if(this.columns?.length)
      {
        var columns_keys = this.columns.map(column => column.key);
        col_params = new URLSearchParams({ keys: columns_keys.join(',') }).toString();
      }
	  

      if(searchData != null)
      {
        url = `${process.env.VUE_APP_API_BASE_URL}report/${report}?${searchData}`;

        if(col_params != null)
        {
          url+=`&${col_params}`
        }
      }
      else 
      {
        if((col_params != null))
        {
          url = `${process.env.VUE_APP_API_BASE_URL}report/${report}?${col_params}`;  
        }
      }
      window.location.href = url
    }
  }
};
</script>
