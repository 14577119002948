<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <reports-header :title="title" :path="path" :text="text" :text1="text1" :search_data="search_data" :columns="originalColumn"/>
        <!-- /Page Header -->
        <!-- Content Starts -->
        <div class="row justify-content-center">
          <div class="col-md-3 col-sm-6">
            <div class="card">
              <div class="card-body text-center">
                <h3><b>{{ todatDate }}</b></h3>
                <p>Today's  Date</p>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-sm-6">
            <div class="card">
              <div class="card-body text-center">
                <h3><b>{{ total }}</b></h3>
                <p>Total Employees</p>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-sm-6">
            <div class="card">
              <div class="card-body text-center">
                <h3 class="text-success"><b>{{ present }}</b></h3>
                <p>Today Present</p>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-sm-6">
            <div class="card">
              <div class="card-body text-center">
                <h3 class="text-danger"><b>{{ absent }}</b></h3>
                <p>Today Absent</p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="card">
              <div class="card-body text-center">
                <h3><b>{{ left }}</b></h3>
                <p>Today Left</p>
              </div>
          </div>
          </div>
        </div>
        <!-- Search Filter -->
        <employee-reports-filter @filter-data="filterData" :hasEmployeeStatus="true" />
        <!-- /Search Filter -->
        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="originalColumn"
                :data-source="data"
                :pagination="pagination"
				        @change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Name'">
                    <h2 class="table-avatar">
                      <router-link :to="'profile/'+record?.id" class="avatar"
                        >
                            <img v-if="record?.avatar"
                            :src="getEmployeeAvatar(record.avatar)"
                            alt="User Image"
                            @error="handleImageError(record)"
                            />
                            <img v-else
                            :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                                alt="User Image" 
                            />
                      </router-link>
                      <router-link :to="'profile/'+record?.id"
                        >{{ record?.first_name }} {{ record?.last_name }} <span>{{ (record?.employee_id) ? '- #'+record?.employee_id : '' }}</span></router-link
                      >
                    </h2>
                  </template>
                  <template v-else-if="column.key === 'Status'">
                    <div class="text-center">
                      <button v-if="record?.is_present == 1" class="btn btn-outline-success btn-sm">Present</button>
                      <button v-if="record?.is_present == 0" class="btn btn-outline-danger btn-sm">Absent</button>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'JoiningDate'">
                    {{ formatDate(record?.joining_date) || record?.joining_date }}
                  </template>
                  <template v-else-if="column.key === 'Department'">
                    {{ record?.department?.name }}
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>

        <!-- /Content End -->
      </div>
      <!-- /Page Content -->
    </div>
     <!-- Update daily_report_manage_colvis Modal -->
     <div
     id="daily_report_manage_colvis"
     class="modal custom-modal fade"
     role="dialog"
   >
     <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title">Manage Column Visibility</h5>
           <!-- <small>select the column from the default that you want to hide</small> -->
           <button
             type="button"
             class="btn-close"
             data-bs-dismiss="modal"
             aria-label="Close"
             ref="modal_team_information_close"
           >
             <i class="fas fa-times"></i>
           </button>
         </div>
         <div class="modal-body">
           <div class="row">
		         <div class="col-md-6" v-for="(column,key) in columns_" :key="column.key">
                  <div class="input-block mb-3 d-flex">
                    
                    <div class="form-switch" style="margin-right: 5px;">
                      <input
                        v-model="column.visibility"
                        type="checkbox"
                        class="form-check-input"
                        :id="'customSwitch_'+column.key"
                        value="false"
                        :checked="column.visibility"
                      />
                    </div>
                    <label class="col-form-label"> {{column?.title}} </label>
                  </div>
                 </div>
           </div>
         </div>
       </div>
     </div>
   </div>
  <!-- Update daily_report_manage_colvis Modal -->
    <!-- /Page Wrapper -->
  </div>
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';
const columns = [
  {
    title: "Employee",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    hidden:false,
    visibility:true
  },
  {
    title: "Joining Date",
    dataIndex: "Date",
    key:'JoiningDate',
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    hidden:false,
    visibility:true
  },
  {
    title: "Department",
    dataIndex: "Department",
    key:'Department',
    sorter: {
      compare: (a, b) => {
        a = a.Department.toLowerCase();
        b = b.Department.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    hidden:false,
    visibility:true
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    class: "text-center",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    hidden:false,
    visibility:true
  },
];

var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};
export default {
  data() {
    return {
      title: "Daily Report",
      path: "Dashboard",
      text: "Daily Report",
      text1: "Export Excel",
      columns_:columns,
      data:[],
      pagination,
      defaultAvatar:'avatar-02.jpg',
      total:0,
      present:0,
      absent:0,
      left:0,
      search_data:null
    };
  },
  name: "daily-reports",
  methods:{
    updatePerPage(){
      var params = {
            params: { per_page: this.pagination.pageSize }
          };
      this.fetchData(params);
      
    },
    date_(date)
    {
      return moment(date).format('DD MMM YYYY') || '-';
    },
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(record) {
      record.avatar = null;
      record.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
    handleTableChange(pagesize){
      var params = {
        params: { per_page: pagesize.pageSize,page:pagesize.current }
      };
      this.fetchData(params);
    },
    filterData(searchData = null)
    {
      let params = null;
      if(searchData)
      {

        this.search_data = null;
        this.$nextTick(()=>{
          this.search_data = searchData;
        })
        params = {
          params: { 
            per_page: this.pagination.pageSize,
            ...searchData
          }
        };
      }

      this.fetchData(params);
      
    },
    fetchData(params = null ){
      var token = window.localStorage.getItem("token");
		  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		  axios.defaults.headers.common["Authorization"] = "Bearer " + token;


      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: false
      });

      if(params == null)
      {
        params = {
          params: { per_page: this.pagination.pageSize }
        };
      }

          axios.get('/report/daily-report',params).then(response => {
            this.pagination.total = response?.data?.data?.record?.total;
            this.pagination.current = response?.data?.data?.record?.current_page;
            this.pagination.pageSize = response?.data?.data?.record?.per_page;

            this.total = response?.data?.data?.total || 0;
            this.present = response?.data?.data?.today_present || 0;
            this.absent = response?.data?.data?.today_absent || 0;
            this.left = response?.data?.data?.today_left || 0;


       
            this.data = response?.data?.data?.record?.data || [];

            loader.hide();

          }).catch((error) => {

            loader.hide();
			  
            var response = (error.response);
            
            if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
            
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
              
            this.$router.push({name: 'login'}).catch(error => {}) 
            
            }else{
            
            this.errorMessage = error.message;
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
              
            }
          });
    }
  },
  mounted()
  {
    this.fetchData()
  },
  computed:{
    originalColumn()
    {
      return this.columns_.filter(column => column.visibility);
    },
    todatDate()
    {
      return moment().format('D MMM YYYY');
    }

  },
};
</script>
