<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="welcome-box">
              <div class="welcome-img">
                <img src="@/assets/img/profiles/avatar-02.jpg" alt="User Image" />
              </div>
              <div class="welcome-det">
                <h3>Welcome, {{ user?.first_name }} {{ user?.last_name }}</h3>
                <p>{{ today }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8 col-md-8">
            <section class="dash-section" v-if="todayData?.length">
              <h1 class="dash-sec-title">Today</h1>
              <div class="dash-sec-content">
                <div class="dash-info-list" v-for="(today,index) in todayData" :key="'today_'+index">
                  <a href="javascript:;" class="dash-card text-danger">
                    <div class="dash-card-container">
                      <div class="dash-card-icon">
                        <i class="fa-regular fa-hourglass"></i>
                      </div>
                      <div class="dash-card-content">
                        <p>{{ today }}</p>
                      </div>
                      <div class="dash-card-avatars">
                        <div class="e-avatar">
                          <img
                            src="@/assets/img/profiles/avatar-09.jpg"
                            alt="User Image"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <!-- <div class="dash-info-list">
                  <a href="javascript:;" class="dash-card">
                    <div class="dash-card-container">
                      <div class="dash-card-icon">
                        <i class="fa-solid fa-suitcase"></i>
                      </div>
                      <div class="dash-card-content">
                        <p>You are away today</p>
                      </div>
                      <div class="dash-card-avatars">
                        <div class="e-avatar">
                          <img
                            src="@/assets/img/profiles/avatar-02.jpg"
                            alt="User Image"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="dash-info-list">
                  <a href="javascript:;" class="dash-card">
                    <div class="dash-card-container">
                      <div class="dash-card-icon">
                        <i class="fa-regular fa-building"></i>
                      </div>
                      <div class="dash-card-content">
                        <p>You are working from home today</p>
                      </div>
                      <div class="dash-card-avatars">
                        <div class="e-avatar">
                          <img
                            src="@/assets/img/profiles/avatar-02.jpg"
                            alt="User Image"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div> -->
              </div>
            </section>

            <section class="dash-section" v-if="tomorrowData?.length">
              <h1 class="dash-sec-title">Tomorrow</h1>
              <div class="dash-sec-content">
                <div class="dash-info-list" v-for="(tomorrow,index) in tomorrowData" :key="'tomorrow_'+index">
                  <div class="dash-card">
                    <div class="dash-card-container">
                      <div class="dash-card-icon">
                        <i class="fa-solid fa-suitcase"></i>
                      </div>
                      <div class="dash-card-content">
                        <p>{{ tomorrow }}</p>
                      </div>
                      <div class="dash-card-avatars">
                        <a href="javascript:;" class="e-avatar"
                          ><img
                            src="@/assets/img/profiles/avatar-04.jpg"
                            alt="User Image"
                        /></a>
                        <a href="javascript:;" class="e-avatar"
                          ><img
                            src="@/assets/img/profiles/avatar-08.jpg"
                            alt="User Image"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="dash-section" v-if="nextSevenData?.length">
              <h1 class="dash-sec-title">Next seven days</h1>
              <div class="dash-sec-content">
                <div class="dash-info-list" v-for="(sevenData,index) in nextSevenData" :key="'sven_data_'+index">
                  <div class="dash-card">
                    <div class="dash-card-container">
                      <div class="dash-card-icon">
                        <i class="fa-solid fa-suitcase"></i>
                      </div>
                      <div class="dash-card-content">
                        <p>{{ sevenData }}</p>
                      </div>
                      <div class="dash-card-avatars">
                        <a href="javascript:;" class="e-avatar"
                          ><img
                            src="@/assets/img/profiles/avatar-05.jpg"
                            alt="User Image"
                        /></a>
                        <a href="javascript:;" class="e-avatar"
                          ><img
                            src="@/assets/img/profiles/avatar-07.jpg"
                            alt="User Image"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="dash-info-list">
                  <div class="dash-card">
                    <div class="dash-card-container">
                      <div class="dash-card-icon">
                        <i class="fa-solid fa-user-plus"></i>
                      </div>
                      <div class="dash-card-content">
                        <p>Your first day is going to be on Thursday</p>
                      </div>
                      <div class="dash-card-avatars">
                        <div class="e-avatar">
                          <img
                            src="@/assets/img/profiles/avatar-02.jpg"
                            alt="User Image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dash-info-list">
                  <a href="javascript:;" class="dash-card">
                    <div class="dash-card-container">
                      <div class="dash-card-icon">
                        <i class="fa-regular fa-calendar-days"></i>
                      </div>
                      <div class="dash-card-content">
                        <p>It's Spring Bank Holiday on Monday</p>
                      </div>
                    </div>
                  </a>
                </div> -->
              </div>
            </section>
          </div>

          <div class="col-lg-4 col-md-4">
            <div class="dash-sidebar">
              <section>
                <h5 class="dash-title">Projects</h5>
                <div class="card">
                  <div class="card-body">
                    <div class="time-list">
                      <div class="dash-stats-list">
                        <h4>71</h4>
                        <p>Total Tasks</p>
                      </div>
                      <div class="dash-stats-list">
                        <h4>14</h4>
                        <p>Pending Tasks</p>
                      </div>
                    </div>
                    <div class="request-btn">
                      <div class="dash-stats-list">
                        <h4>{{ projects.length }}</h4>
                        <p>Total Projects</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <h5 class="dash-title">Your Leave</h5>
                <div class="card">
                  <div class="card-body">
                    <div class="time-list">
                      <div class="dash-stats-list">
                        <h4>{{leaveCount}}</h4>
                        <p>Leave Taken</p>
                      </div>
                      <div class="dash-stats-list">
                        <h4>{{ remainLeaveCount }}</h4>
                        <p>Remaining</p>
                      </div>
                    </div>
                    <div class="request-btn">
                      <a class="btn btn-primary" href="javascript:;" @click="redirectTo('leaves-employee')">Apply Leave</a>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <h5 class="dash-title">Your time off allowance</h5>
                <div class="card">
                  <div class="card-body">
                    <div class="time-list">
                      <div class="dash-stats-list">
                        <h4>5.0 Hours</h4>
                        <p>Approved</p>
                      </div>
                      <div class="dash-stats-list">
                        <h4>15 Hours</h4>
                        <p>Remaining</p>
                      </div>
                    </div>
                    <div class="request-btn">
                      <a class="btn btn-primary" href="javascript:;">Apply Time Off</a>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <h5 class="dash-title">Upcoming Holiday</h5>
                <div class="card" v-for="(holiday,index) in upcomingHolidays" :key="'holidays_'+index">
                  <div class="card-body text-center">
                    <h5 class="holiday-title mb-0">{{ holiday?.name }} - {{ getDateFromat(holiday?.holiday_date) }} To {{ getDateFromat(holiday?.end_date) }} </h5>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import { notification } from "ant-design-vue";

export default {
  data(){
    return {
      dashboardData:null
    }
  },
  computed:{
    user()
    {
      let user = localStorage.getItem('user');
      if(user && user != null)
      {
        user = JSON.parse(user);
      }

      return user ? user : null
    },
    today()
    {
      const today = moment();
      return today.format('dddd, DD MMMM YYYY');
    },
    upcomingHolidays()
    {
      return this.dashboardData?.holidays ? this.dashboardData?.holidays : []
    },
    remainLeaveCount()
    {
      return this.dashboardData?.remaining_leaves ? this.dashboardData?.remaining_leaves : 0;
    },
    leaveCount()
    {
      return this.dashboardData?.leaves_count ? this.dashboardData?.leaves_count : 0;
    },
    projects()
    {
      return this.dashboardData?.projects ? this.dashboardData?.projects : []
    },
    todayData()
    {
      return this.dashboardData?.today_data ? this.dashboardData?.today_data : []
    },
    tomorrowData()
    {
      return this.dashboardData?.tomorrow_data ? this.dashboardData?.tomorrow_data : []
    },
    nextSevenData()
    {
      return this.dashboardData?.next7days_data ? this.dashboardData?.next7days_data : []
    }
  },
  methods:{
    fetchData(){
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.formContainer,
        canCancel: false
      });

      axios.get('/dashboard').then(response => {
        loader.hide();
        this.dashboardData = response?.data?.data;
      }).catch(error =>{
        loader.hide();
        if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
            
            this.$router.push({name: 'login'}).catch(error => {}) 
        }

        if(error.response)
        {
          var response = (error.response);
              
          notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
              background: process.env.VUE_APP_WARNING_COLOR,
              },
          });

        }
        else
        {
            
            notification.open({
                message: 'Server Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
        }
      })
    },
    getDateFromat(date)
    {
      return moment(date).format("ddd DD MMM YYYY");
    },
    redirectTo(route)
    {
      this.$router.push({path: route}).catch(error => {}) 

    }

  },
  mounted(){

    const hasPermissionChange = this?.$route?.params?.hasPermissionChange;

    if(hasPermissionChange)
    {
      notification.open({
						message: 'Your permission has been change.',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
    }
    this.fetchData()
  }
}
</script>
