<template>
    <div class="tab-pane fade" id="emp_bvg" ref="formContainer">
        <div v-if="activeTab == 'bvg'">
            <div class="row">
                <div class="col-6">
                    <div class="showentries mb-3">
                        <label
                        >Show
                        <select v-model="pagination.pageSize" @change="fetchData()">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        entries
                        </label>
                    </div>
                </div>
                <div class="col-auto float-end ms-auto">
                    <a
                    href="javascript:;"
                    class="btn add-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#manage_bvg"
                    ><i class="fa-solid fa-plus"></i> Add </a
                    >
                </div>
            </div>
            <div class="table-responsive">
                <a-table class="stripped table-hover" :columns="columns" :data-source="data" :pagination="pagination"
                @change="handleTableChange">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'status'">
                            {{ record.status ? 'Approved' : 'Not Approved' }}
                        </template>
                        <template v-else-if="column.key === 'action'">
                        <template v-if="column.key === 'joining_date'">
                            {{ formatDate(record?.joining_date) || record?.joining_date }}
                        </template>
                    <div class="text-end">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#manage_bvg"
							@click="EditBVG(record)"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_bvg"
							@click="dlt_bvg = record?.bvg_id"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                    </template>
                </a-table>
            </div>

    <!-- Update Wage Modal -->
    <div
    id="manage_bvg"
    class="modal custom-modal fade"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">BGV Information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="update_bvg_information_close"
            @click="resetFormData"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
        <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
          <div class="row">
            <div class="col-md-12">
                <div class="input-block mb-3">
                    <label class="col-form-label"
                    >Company Name<span class="text-danger">*</span></label
                    >
                    <Field
                        name="company_name"
                        type="text"
                        value=""
                        class="form-control"
                        v-model="bvg.company_name"
                        :class="{ 'is-invalid': errors.company_name }"
                    />
                    <div class="invalid-feedback">{{ errors.company_name }}</div>
                    <div class="company_namesshow text-danger" id="company_name"></div>
                    <span class="text-danger" v-if="error && error?.company_name && error?.company_name != null">{{ error?.company_name[0]  }}</span>
                </div>

                <div class="input-block mb-3">
                    <label class="col-form-label"
                    >Status<span class="text-danger">*</span></label>
                    <Field name="status" as="select" v-model="bvg.status" class="form-control form-select" :class="{ 'is-invalid': errors.status }">
                        <option value="1">Approved</option>
                        <option value="0">Not Approved</option>
                    </Field>
                    <div class="invalid-feedback">{{ errors.status }}</div>
                    <div class="statussshow text-danger" id="status"></div>
                    <span class="text-danger" v-if="error && error?.status && error?.status != null">{{ error?.status[0]  }}</span>
                </div>
             
                <div class="input-block mb-5 form-focus">
                    <label class="col-form-label"
                    >Date<span class="text-danger">*</span></label>
                    <div class="cal-icon">
                        <datepicker
                            name="date"
                            v-model="bvg.date_"
                            placeholder="Date"
                            class="form-control floating datetimepicker"
                            :editable="true"
                            :clearable="false"
                            input-format="dd-MM-yyyy"
                        />
                    </div>
                    <div class="invalid-feedback">{{ errors.date }}</div>
                    <div class="datesshow text-danger" id="date"></div>
                    <span class="text-danger" v-if="error && error?.date && error?.date != null">{{ error?.date[0]  }}</span>

                </div>
              
                <div class="input-block mb-5 form-focus">
                    
                    <label class="col-form-label"
                    >Joining Date<span class="text-danger">*</span></label>
                    <div class="cal-icon">
                        <datepicker
                            name="joining_date"
                            v-model="bvg.joining_date_"
                            placeholder="Date"
                            class="form-control floating datetimepicker"
                            :editable="true"
                            :clearable="false"
                            input-format="dd-MM-yyyy"
                        />
                    </div>
                    <div class="invalid-feedback">{{ errors.joining_date }}</div>
                    <div class="joining_datesshow text-danger" id="joining_date"></div>
                    <span class="text-danger" v-if="error && error?.joining_date && error?.joining_date != null">{{ error?.joining_date[0]  }}</span>

                </div>

                <div class="input-block mb-3">
                    <label class="col-form-label"
                    >Comments<span class="text-danger">*</span></label>
                    <br>
                    <Field style="width: 100%;" name="comments" as="textarea" v-model="bvg.comments" :class="{ 'is-invalid': errors.comments }" >
                    </Field>
                    <div class="invalid-feedback">{{ errors.comments }}</div>
                    <div class="commentssshow text-danger" id="comments"></div>
                    <span class="text-danger" v-if="error && error?.comments && error?.comments != null">{{ error?.comments[0]  }}</span>

                </div>
            </div>
          </div>
          <div class="submit-section">
            <button class="btn btn-primary submit-btn" :disabled="disableButton">Submit</button>
          </div>
        </Form>
        </div>
      </div>
    </div>
  </div>
 <!-- Update Wage Modal -->
 <!-- Delete Project Modal -->
  <div class="modal custom-modal fade" id="delete_bvg" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete BGV </h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn"
				  @click="deleteBvgAction"
                  :disabled="disableButton"
				  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
                  ref="delete_bvg_ref"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Wages Modal -->

        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

var pagination = {total: 0,
            current: 1,
            pageSize: 10};
export default {
    components: {
        Form,
        Field,
    },
    props:{
        employeeID: {
            type: Number,
            default: null,
            required:false
        },
        activeTab:{
            type: String,
            default: null,
            required:false 
        }
    },
    data() {
        return {
            columns:[
                {
                    title: "Compnay Name",
                    dataIndex: "company_name",
                    key:'company_name', 
                    sorter: {
                        compare: (a, b) => {
                            a = a.company_name;
                            b = b.company_name;
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Joining Date",
                    dataIndex: "joining_date",
                    key:'joining_date',
                    sorter: {
                        compare: (a, b) => {
                            a = a.joining_date;
                            b = b.joining_date;
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Status",
                    dataIndex: "status",
                    key:'status',
                    sorter: {
                        compare: (a, b) => {
                            a = a.status;
                            b = b.status;
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Action",
                    sorter: false,
                    key: "action",
                    class: "text-end",
                },
            ],
            perpage: 10,
            pagination: pagination,
            data:[],
            bvg:{
                company_name:null,
                bvg_id:null,
                joining_date_:null,
                date_:null,
                comments:null,
                status:0
            },
            cc:false,
            error:[],
            dlt_bvg:null,
            disableButton:false
        }
    },
    watch:{
        async employeeID(newVal)
        {
          if(newVal)
          {
            await this.fetchData();
          }
        },
    },
    methods:{

        EditBVG(record)
        {
            this.bvg = Object.assign({}, record);
            this.bvg.date_ = new Date(record?.date) || null;
            this.bvg.joining_date_ = new Date(record?.joining_date) || null;

        },
        
        resetFormData()
        {
            this.bvg={
                company_name:null,
                bvg_id:null,
                joining_date_:null,
                date_:null,
                comments:null,
                status:0
            }
        },
        handleTableChange(pagesize){
            var params = {
                params: { per_page: pagesize.pageSize,page:pagesize.current }
            };
            this.fetchData(params,null);
        },
        async fetchData(params = null)
        {
            var param = null
    
            if(!params)
            {
              param = {
                params: { per_page: this.pagination.pageSize }
                };
            }
            else
            {
                param = params
            }
    
            const empId = this.employeeID;
            
            if(empId)
            {
                var token = window.localStorage.getItem("token");
        
                axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                
                let loader = this.$loading.show({
                    container: this.$refs.formContainer,
                    canCancel: false
                });
    
                await axios.get('/bgv/emp/'+this.employeeID,param).then(response => {
                    this.data = response?.data?.data?.data || [];
    
                    this.pagination.total = response?.data?.data?.total;
                    this.pagination.current = response?.data?.data?.current_page;
                    this.pagination.pageSize = response?.data?.data?.per_page;
    
                    loader.hide();
                }).catch(error => {
                    loader.hide();
                    if(error.response){
                
                    var response = (error.response);
                        
                    notification.open({
                        message: response.data.message,
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                        
                    }else{
                        
                        notification.open({
                            message: 'Server Error',
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                    }
                })
            }
        },
        onSubmit(){
            this.disableButton = true;

            this.error = []
            this.bvg.joining_date = this?.bvg?.joining_date_ ? moment(this?.bvg?.joining_date_).format('YYYY-MM-DD') : null;
            this.bvg.date = this?.bvg?.date_ ? moment(this?.bvg?.date_).format('YYYY-MM-DD') : null;

            if(!this.bvg.joining_date)
            {
                this.error.joining_date = []
                this.error.joining_date.push('Joining Date is required');
            }

            if(!this.bvg.date)
            {
                this.error.date = []
                this.error.date.push('Date is required');
            }

            if(!this.error.length)
            {
                var token = window.localStorage.getItem("token");
                axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                
                let loader = this.$loading.show({
                    container: this.$refs.formContainer,
                    canCancel: false
                });

                if(!this.bvg.bvg_id)
                {
                    delete this.bvg.bvg_id;
                }

                axios.post("/bgv/emp/"+this.employeeID, this.bvg).then((response) => {

                    loader.hide();

                    this.$refs.update_bvg_information_close.click()

                    this.fetchData();

            this.disableButton = false;

			  
                    notification.open({
                        message: response.data.message,
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_SUCCESS_COLOR,
                        },
                    });

                }).catch(error => {
                    loader.hide();
            this.disableButton = false;
                    
                    if(error.response){
                    
                        var response = (error.response);
                            
                        notification.open({
                            message: response.data.message,
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                        
                    }else{
                        
                        notification.open({
                            message: 'Server Error',
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                    }
                })
            }

            this.disableButton = false;

            return false;
        },
        deleteBvgAction()
        {

            this.disableButton = true;
            var token = window.localStorage.getItem("token");
        
                axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                
                let loader = this.$loading.show({
                    container: this.$refs.formContainer,
                    canCancel: false
                });
    
                axios.delete('/bgv/emp/'+this.dlt_bvg).then(response => {
            this.disableButton = false;

                    this.dlt_bvg = null;
                    this.$refs.delete_bvg_ref.click()
                    this.fetchData()
    
                    loader.hide();
                }).catch(error => {
            this.disableButton = false;

                    loader.hide();
                    if(error.response){
                
                    var response = (error.response);
                        
                    notification.open({
                        message: response.data.message,
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                        
                    }else{
                        
                        notification.open({
                            message: 'Server Error',
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                    }
                })
        }
    },
    created()
    {
        this.fetchData()
    },
    setup() {
        const schema = Yup.object().shape({
         company_name: Yup.string().required("Company Name is required"),
         comments: Yup.string().required("Comments is required"),
         status: Yup.string().required("Status is required")
        });

        return {
            schema
        }
    }

    
}   
</script>