<template>
  <div class="account-page" ref="formContainer">
    <div class="main-wrapper">
      <div class="account-content">
        <div class="container">
          <!-- Account Logo -->
          <div class="account-logo">
            <router-link to="admin-dashboard"
              ><img src="@/assets/img/logo2.png" alt="Workwise"
            /></router-link>
          </div>
          <!-- /Account Logo -->

          <div class="account-box">
            <div class="account-wrapper">
              <h3 class="account-title">Forgot Password?</h3>
              <p class="account-subtitle">
                Enter your email to get a password reset link
              </p>

              <p class="text-success font-bold" v-if="form.email == null || form.email == ''"> {{ successMessage }}</p>

              <!-- Account Form -->
              <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                <div class="input-block mb-4">
                  <label for="email" class="col-form-label">Email</label>
                    <Field type="text" class="form-control" v-model="form.email" name="email" id="email" :class="{ 'is-invalid': errors.email }" />
                    <div class="invalid-feedback">{{ errors.email }}</div>
                    <div class="emailshow text-danger" id="email"></div>
                </div>
                <div class="input-block mb-4 text-center">
                  <button class="btn btn-primary account-btn" type="submit" :disabled="disabledButton">
                    Reset Password
                  </button>
                </div>
                <div class="account-footer">
                  <p>Remember your password? <router-link to="/">Login</router-link></p>
                </div>
              </Form>
              <!-- /Account Form -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";
import { Form, Field } from "vee-validate";
import axios from "axios";
import { notification } from "ant-design-vue";
export default {
  components:{
    Form,Field
  },
  data(){
    return {
      form:{
        email:null
      },
      successMessage:null,
      disabledButton:false
    }
  },
  setup() {
    const schema = Yup.object().shape({
      email:Yup.string().required('Email is required'),
    })

    return {
        schema
    }
  },
  methods:{
    onSubmit()
    {

      this.disabledButton = true;

        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

      axios.post("/forgot-password-send-email",this.form)
        .then( (response) => {

          this.successMessage = response?.data?.message;

            loader.hide();
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

          //  this.$router.push({ name: "login" }).catch((error) => {});

        }).catch(error => {
          loader.hide();
          if(error.response){
            var response = (error.response);
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }else{
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
        });

        this.disabledButton = false;
    }
  }
}
</script>
