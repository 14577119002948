<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid pb-1">
        <!-- Page Header -->
        <breadcrumb :title="title" :path="path" :text="text" /> 
        <!-- /Page Header -->

        <!-- Search Filter -->
        <div class="row">
          <div class="col-lg-12">
            <div class="assign-head">
              <div class="assign-content">
                <img class="asset-image" v-if="AssetData && AssetData.files && AssetData.files.length > 0 && get_image_path(AssetData.files) != null" :src="get_image_path(AssetData.files)" />
                <img v-else src="@/assets/img/keyboard.png" alt="Keyboard Image" />
                <h6>{{ AssetData?.name  }}</h6>
              </div>
              <div class="assign-content">
                <!-- <a
                  href="javascript:void(0);"
                  class="btn btn-assign"
                  data-bs-toggle="modal"
                  data-bs-target="#add-assign"
                  ><i class="fa-solid fa-plus"></i> Assign
                </a> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="assets-info">
              <h2>Asset Info </h2>
              <ul>
                <li>
                  <span>Type</span>
                  <p> {{ AssetData?.type || '-' }}</p>
                </li>
                <li>
                  <span>Brand</span>
                  <p>  {{ AssetData?.brand  || '-' }} </p>
                </li>
                <li>
                  <span>Model</span>
                  <p> {{ AssetData?.model || '-'}}</p>
                </li>
                <li>
                  <span>Serial Number</span>
                  <p>{{ AssetData?.serial_number || '-' }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="assets-info">
              <h2>Asset History</h2>
              <ul>
                <li>
                  <span>Vendor</span>
                  <p>{{ AssetData?.supplier }}</p>
                </li>
                <li>
                  <span>Category</span>
                  <p>-</p>
                </li>
                <li>
                  <span>Cost</span>
                  <p>₹ {{ AssetData?.value }}</p>
                </li>
                <li>
                  <span>Location</span>
                  <p>-</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-12 d-flex">
            <div class="assets-info">
              <h2>Warranty</h2>
              <ul>
                <li>
                  <span>Start Date</span>
                  <p>{{ AssetData?.purchase_date }}</p>
                </li>
                <li>
                  <span>End Date</span>
                  <p>{{ warrantyEndDate }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-12 d-flex">
            <div class="assets-info assets-image">
              <h2>Asset Documents</h2>
              <div class="table-responsive">
                <a-table class="stripped table-hover" :columns="columns" :data-source="AssetData?.files">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'original_file_name'">
                      <span class="table-avatar">
                          <router-link to="#" 
                            >
                            <img 
                            v-if="imageType.includes(record?.document_type)"
                            :src="getImages(record)"
                            alt="Asset Image"
                            @error="handleImageError(record)"
                            class="asset-image-sm"
                            />
                            <span v-else><i class="fa fa-file" style="font-size:20px"></i></span>
                           
                        </router-link>
                      </span>
                    {{ record?.original_file_name }}
                    </template>
                    <template v-if="column.key === 'action'">
                      <div class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="javascript:;"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="material-icons">more_vert</i></a
                          >
                          <div class="dropdown-menu dropdown-menu-right">
                            <a
                              class="dropdown-item"
                              href="javascript:;"
                              @click="viewDoc(record?.file_path)"
                              ><i class="fa fa-eye m-r-5"></i> View</a
                            >
                            <a
                              class="dropdown-item"
                              href="javascript:;"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_asset"
                              @click="deleteImage(record?.assetfile_id)"
                              ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-if="column.key === 'status'">
                      <div class="text-center">
                        <div class="dropdown action-label">
                          <a
                            class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                            href="javascript:;"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {{ record?.admin_only ? ' For Administartion ' : 'For All'}}
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a v-if="record?.admin_only" class="dropdown-item" href="javascript:;" @click="changeStatus(record,0)"
                              > For All</a
                            >
                            <a v-if="!record?.admin_only" class="dropdown-item" href="javascript:;" @click="changeStatus(record,1)"
                              > For Administartion Only</a
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

       <profilemodal></profilemodal>

      <!-- Delete Asset Modal -->
      <div class="modal custom-modal fade" id="delete_asset" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form-header">
                  <h3>Delete Asset Document</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                  <div class="row">
                    <div class="col-6">
                      <a href="javascript:void(0);" @click="deleteDocument"  class="btn btn-primary continue-btn"
                        >Delete</a
                      >
                    </div>
                    <div class="col-6">
                      <a
                        href="javascript:void(0);"
                        data-bs-dismiss="modal"
                        class="btn btn-primary cancel-btn"
                        ref="close_asset_doc_delete_modal"
                        >Cancel</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- /Delete Performance Appraisal Modal -->
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
const columns =  [
  {
    title: "Index",
    dataIndex: "index",
    key:"index",
    customRender: ({ index }) => {
		  return (index + 1);
	  },
  },
  {
    title: "File Name",
    dataIndex: "original_file_name",
    key:"original_file_name",
  },
  {
    title: "Doc Type",
    dataIndex: "document_type",
    key:"document_type",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    class: "text-center",
    sorter: {
      compare: (a, b) => {
        a = a.status;
        b = b.status;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
    class: "text-end",
  },
]
export default {
  data() {
    return {
      title: "Assets",
      path: "Dashboard",
      text: "Assets",
      AssetData:null,
      deleteAsset:null,
      columns,
      imageType:['jpg', 'jpeg', 'png']
    };
  },
  name: "profile",
 
  methods:{
    handleImageError(record) {
      record.file_path = null;
      record.file_path = `assets/img/profiles/avatar-02.jpg`;
    },
    async deleteDocument(){
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      if(this.deleteAsset)
      {
        await axios.delete('/asset/asset-doc-delete/'+this.deleteAsset).then(response=>{

          this.$refs.close_asset_doc_delete_modal.click()

          notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });

          this.fetchAssetData();

          this.deleteAsset = null

        }).catch(error => {
          if(error?.response){
            var response = (error.response);
              
            notification.open({
              message: response?.data?.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
        })
      
      }
    },
    async changeStatus(record = null,status = null){

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const formData= new FormData();
      formData.append('admin_only',status);

      await axios.post('asset/asset-update-status/'+record.assetfile_id,formData).then((response)=>{

        record.admin_only = status;

        notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

      }).catch(error => {
        if(error.response){
          var response = (error.response);
            
          notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
          
        }else{
          
          notification.open({
            message: 'Server Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
      });

    },
    deleteImage(assetfile_id = null)
    {
      this.deleteAsset = assetfile_id;
    },
    viewDoc(file)
    {
      window.open(`${process.env.VUE_APP_IMAGE_BASE_URL}${file}`, '_blank');
    },
    get_image_path(images)
    {
      if(images && images.length)
      {
        const image = images.find(image => {
        const documentType = image?.document_type?.toLowerCase() || '';
            return documentType.includes('jpg') || documentType.includes('jpeg') || documentType.includes('png');
        });

        if(image?.file_path && image?.file_path != null)
        {
          return `${process.env.VUE_APP_IMAGE_BASE_URL}${image?.file_path}`
        }
        else
        {
          return null
        }
        
      }
      else{
        return null
      }
    },
    getImages(image){
      if(image && image != null && image?.file_path != null)
      {
        return `${process.env.VUE_APP_IMAGE_BASE_URL}${image?.file_path}`
      }
      else{
        return null
      }
    
    },
    async fetchAssetData()
    {
        // let loader = this.$loading.show({
        //   // Optional parameters
        //   container: this.$refs.formContainer,
        //   canCancel: false
        // });
        if(this?.$route?.params?.id)
        {
          var token = window.localStorage.getItem("token");
          axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          await axios.get('asset/detail/'+this?.$route?.params?.id).then(response => {

        
            // loader.hide()
            this.AssetData = response?.data?.data;
          
          }).catch(error => {
            // loader.hide()
            console.log(error);
          });
        }
        // loader.hide()
    }
  },
  async created(){
     await this.fetchAssetData();
  },
  computed:{
    warrantyEndDate()
    {
      if(this?.AssetData?.purchase_date != null && this?.AssetData?.warranty != null){
        
          const date = new Date(this?.AssetData?.purchase_date);

          if(date && Number.isInteger(this?.AssetData?.warranty))
          {
            date.setMonth(date.getMonth() + this?.AssetData?.warranty);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
          }
      }

      return '-';
    }
  }
};
</script>
<style scoped>
.asset-image
{
  max-width: 100px;
  max-height: 100px;
}
.asset-images i 
{
  cursor: pointer;
}
.asset-image-sm
{
  max-width: 40px;
  max-height: 40px;
}

</style>
