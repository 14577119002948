<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="title" :path="path" :text="text" />
        <div class="row">
          <div class="col-md-12">
            <div class="activity">
              <div class="activity-box">
                <ul class="activity-list">
                  <li v-for="(activity, index) in data" :key="index">
                    <div class="activity-user">
                      <router-link :to="'profile/' + activity?.message_by"
                        :title="activity?.first_name + ' ' + activity?.last_name" data-bs-toggle="tooltip" class="avatar">
                        <img v-if="activity?.avatar && activity?.avatar != null"
                          :src="getEmployeeAvatar(activity?.avatar)" alt="User Image"
                          @error="handleImageError(activity)" />
                        <img v-else :src="require(`@/assets/img/profiles/${defaultAvatar}`)" alt="User Image" />
                        By {{ activity?.first_name + ' ' + activity?.last_name }}
                      </router-link>
                    </div>
                    <div class="activity-content">
                      <div class="timeline-content">
                        <span>
                          {{ activity?.message }} By {{ activity?.first_name + ' ' + activity?.last_name }}
                        </span>
                        <span class="time">{{ convertDate(activity.created_at) }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="raw">
          <div class="col-12 text-center">
            <a-pagination @change="onChange" v-model:current="pagination.current" v-model:pageSize="pagination.pageSize"
              :total="pagination.total" show-less-items></a-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notification } from "ant-design-vue";
import axios from 'axios';
import Activities from "@/assets/json/activities.json";
import moment from 'moment';
export default {
  data() {
    return {
      title: "Activities",
      path: "Dashboard",
      text: "Activities",
      Activities: Activities,
      pagination: {
        total: 0,
        current: 0,
        pageSize: 15
      },
      data: [],
      defaultAvatar: 'avatar-02.jpg',
    };
  },
  methods: {
    convertDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },
    getEmployeeAvatar(avatar) {
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(item) {
      item.avatar = null;
      item.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
    onChange(current) {
      this.pagination.current = current;

      var params = {
        params: { per_page: this.pagination.pageSize, page: current }
      };

      this.loadCommonData(params);
    },
    loadCommonData(params, searchData = null) {

      var token = window.localStorage.getItem("token");

      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.formContainer,
        canCancel: false
      });

      axios.get('/fetch-global-notificaiton', params)
        .then((response) => {
          loader.hide();

          this.pagination.total = response.data.data.total;
          this.pagination.current = response.data.data.current_page;
          this.pagination.pageSize = response.data.data.per_page;

          this.data = response?.data?.data?.data || [];

        }).catch((error) => {

          loader.hide();

          var response = (error?.response);

          if (response?.status == 401 && response?.data?.message == 'Unauthenticated.') {

            localStorage.clear();

            notification.open({
              message: 'Please Login',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });

            this.$router.push({ name: 'login' }).catch(error => { })

          } else {

            this.errorMessage = error.message;
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });

          }

        });

    },
  },
  created() {
    var params = {
      params: { per_page: this.pagination.pageSize }
    };

    this.loadCommonData(params)
  }
};
</script>
