<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <breadcrumb :title="title" :path="path" :text="text" />
        <!-- /Page Header -->
        <!-- /Page Header -->

        <!-- Content Starts -->
        <div class="row">
          <div class="col-12">
            <!-- Search Form -->
            <div class="main-search">
              <form action="javascript:;">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="search" />
                  <button class="btn btn-primary" type="button" @click="submit">Search</button>
                </div>
              </form>
            </div>
            <!-- /Search Form -->

            <div class="search-result">
              <h3>Search Result Found For: <u> {{searchedValue}} </u></h3>
              <p>{{  ( project_c + client_c + user_c) }} Results found</p>
            </div>

            <div class="search-lists">
              <ul class="nav nav-tabs nav-tabs-solid">
                <li class="nav-item">
                  <a class="nav-link active" href="#results_projects" data-bs-toggle="tab"
                    >Projects <span class="total-count" v-if="project_c > 0"> {{ project_c }} </span></a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#results_clients" data-bs-toggle="tab"
                    >Clients <span class="total-count" v-if="client_c > 0"> {{ client_c }} </span></a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#results_users" data-bs-toggle="tab">Users <span class="total-count" v-if="user_c > 0"> {{ user_c }} </span></a>
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane show active" id="results_projects">
                  <projectsearch :projects="projects" @reload-data="reloadData" />
                </div>

                <div class="tab-pane" id="results_clients">
                  <clientsearch  :clients="clients" />
                </div>

                <div class="tab-pane" id="results_users">
                  <userssearch :users="users" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Content End -->
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { notification } from "ant-design-vue";
import axios from 'axios';
export default {
  data() {
    return {
      title: "Search",
      path: "Dashboard",
      text: "Search",
      search:null,
      searchedValue:null,
      projects:[],
      users:[],
      clients:[],
      project_c:0,
      client_c:0,
      user_c:0
    };
  },
  created(){
    console.log('this.$route.params', this.$route.query.search)
    this.search = this.$route.query.search || ''
    this.searchedValue = this.$route.query.search || ''
  },
  watch:{
    '$route.query.search': function (newSearch, oldSearch) {
      this.search = newSearch || '';
      this.searchedValue = this.$route.query.search || ''
    },
    searchedValue(newSearch) {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: false
      });

      var params = {
        params: { search: newSearch }
      };

      axios.get('global-search', params)
			.then((response) => {
        loader.hide();
        this.total = 0
        this.$nextTick(()=>{
          
          this.projects = response?.data?.data?.project?.data || []
          this.project_c =this.projects.length;
          
          this.users = response?.data?.data?.user || []
          this.user_c = this.users.length;
          
          this.clients = response?.data?.data?.client || []
          this.client_c = this.clients.length;
        })
        

      }).catch((error) => {
        this.projects = []
        this.users = []
        this.clients = []
        
        loader.hide();

          var response = (error.response);
          
          if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
          
          localStorage.clear();
          
          notification.open({
              message: 'Please Login',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          this.$router.push({name: 'login'}).catch(error => {}) 
          
          }else{
          
          // this.errorMessage = error.message;
          // notification.open({
          //     message: response.data.message,
          //     placement: "topRight",
          //     duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          //     style: {
          //       background: process.env.VUE_APP_WARNING_COLOR,
          //     },
          //   });
            
          }
      })
    }
  },
  methods:{
    submit()
    {
      this.$router.push({ 
        query: { search: this.search } 
      });
    },
    reloadData()
    {
      this.searchedValue = null;
      this.$nextTick(()=>{
        this.searchedValue = this.search
      })
    }
  },
 
};
</script>
<style scoped>

.nav-link.active span
{
  background: white;
  border-radius: 100%;
  width: auto;
  color: black;
  padding: 1px 4px;
}

.nav-link span
{
  background: #ff9b44;
  border-radius: 100%;
  width: auto;
  color: white;
  padding: 1px 4px;
}

</style>
