<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid pb-0">
        <breadcrumb :title="title" :path="path" :text="text" />
		<clientprofilehead :clientdata="clientdata" />
		
		
        <div class="card tab-box">
          <div class="row user-tabs">
            <div class="col-lg-12 col-md-12 col-sm-12 line-tabs pt-3 pb-2">
              <ul class="nav nav-tabs nav-tabs-bottom">
                <li class="nav-item col-sm-3">
                  <a class="nav-link active" data-bs-toggle="tab" href="#myprojects"
                    >Projects</a
                  >
                </li>
                <!--<li class="nav-item col-sm-3">
                  <a class="nav-link" data-bs-toggle="tab" href="#tasks">Tasks</a>
                </li>-->
              </ul>
            </div>
          </div>
        </div> 
		
        <div class="row">
          <div class="col-lg-12">
            <div class="tab-content profile-tab-content">
				<div class="row">
					<project-card
					v-for="project in projects"
					:key="project.id"
					:project="project"
					@edit-project="editProject"
					@delete-project="deleteProject"
				  />
				</div>
            <!--  <clientprofiletab /> -->
				<div class="raw">
					<div class="col-12 text-center">
					  <a-pagination @change="onChange" v-model:current="pagination.current"
					  v-model:pageSize="pagination.pageSize" :total="pagination.total" show-less-items></a-pagination>
					</div>
				</div>
              <!--<clienttaskstab /> -->
            </div>
          </div>
        </div> 
      </div>
	  <clientprofilemodal :employeeData="clientdata" @reload-data="loadCommonData" />
	  <project-list-model :form="create_form" :clients_list="clients_list" :teams_list="teams_list" :team_leads="team_leads" :wages_list="wages_list" @create-project="createProject" :delprojid="delprojid" @update-project="updateProject" :editform="edit_form" ref="projectmodel" @delete-proj="deleteProj"/>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import ProjectCard from "@/views/pages/projects/ProjectsContent.vue";
import { ref } from "vue";		
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());

export default {
  components: {
    ProjectCard,
  },
  data() {
    return {
      title: "Profile",
      path: "Dashboard",
      text: "Profile",
	  clientdata: [],
	  projects: [],
	  create_form: { "project_name": "","client_id":"","wages_id":"","start_date":currentDate,"end_date":currentDateOne,"rate":"","priority":"","leader":"","description":""},
	  clients_list: {},
	  teams_list: {},
	  team_leads: {},
	  wages_list: {},
	  delprojid: "",
	  edit_form: {},
	  pagination:{
        total:0,
        current:0,
        pageSize:12
      },
    };
  },
  name: "client-profile",
  methods: {
	deleteProject(id){
		this.delprojid = id;
	},
	onChange(current)
    {
      this.pagination.current = current;

      var params = {
        params: { per_page: this.pagination.pageSize,page:current }
      };
      
      this.loadProjectData(params);
    },
	editProject(formval){
		
		if(formval.start_date != ""){
			formval.start_date = new Date(formval.start_date);
		}
		
		if(formval.end_date != ""){
			formval.end_date = new Date(formval.end_date);
		}
		
		this.edit_form = formval;
	},
	deleteProj(id){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.delete("/project/"+id, [])
          .then( (response) => {
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.projectmodel.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize,client_id: this?.$route?.params?.user_id }
				};
				
			 this.loadProjectData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	updateProject(formval){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		var postform = new FormData();		
		postform.append('project_name',formval.project_name);
		postform.append('start_date',moment(formval.start_date).format('YYYY-MM-DD'));
		postform.append('end_date',moment(formval.end_date).format('YYYY-MM-DD'));
		postform.append('client_id',formval.client_id);
		postform.append('rate',formval.rate);
		postform.append('wages_id',formval.wages_id);
		postform.append('priority',formval.priority);
		postform.append('description',formval.description);
		postform.append('leader',formval.leader);
		postform.append('teams',formval.teams);
		
		
		if(formval.file){
	    	postform.append('uploadfile',formval.file);
		}
		
		axios.post("/project/"+formval.project_id, postform, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
					})
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.projectmodel.closeDialog();
			 this.edit_form ={};
			 
			 
			var params = {
			   params: { per_page: this.pagination.pageSize,client_id: this?.$route?.params?.user_id }
			};
			this.loadProjectData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
		
	},
	createProject(formval){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.post("/project", formval)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.projectmodel.closeDialog();
			 
			 this.create_form =  { "project_name": "","client_id":"","start_date":currentDate,"end_date":currentDateOne,"rate":"","priority":"High","leader":"","description":"","fee_type":"Hourly"};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize,client_id: this?.$route?.params?.user_id }
				};
				
			 this.loadProjectData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	loadCommonData(){
		console.log('common data loading called..');
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.fullPage ? null : this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/client/"+this?.$route?.params?.user_id, [])
			.then((response) => {
				
				console.log(response.data.data);
				
				this.clientdata = response.data.data;
			
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadProjectData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
					
		axios.get("/project", params)
			.then((response) => {
				
				this.projects = response.data.data.data;
				
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});			
					
	},
	loadAllClients(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/client", params)
			.then((response) => {
				
				this.clients_list = response.data.record.data;
				
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadAllTeams(params){
	
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/team/all", params)
			.then((response) => {
				
				this.teams_list = response.data.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadAllTeamLeads(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/employees/all", params)
			.then((response) => {
				this.team_leads = response?.data?.data || [];
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadAllWages(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/wages/all", params)
			.then((response) => {
				
				this.wages_list = response?.data?.data || [];
					  
			}).catch((error) => {
			  
			  console.log('wages error');
			  console.log(error);
			  var response = error.response;
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	}
  },
  mounted() {
	
	
	var params = {
       params: { client_id: this?.$route?.params?.user_id,per_page: this.pagination.pageSize }
    };
	this.loadCommonData([]);
	
	this.loadProjectData(params);
	
	this.loadAllClients([]);
	
	this.loadAllTeams([]);
	
	this.loadAllTeamLeads([]);
	
	this.loadAllWages([]);
	
  },
};
</script>
