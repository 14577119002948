<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <shift-header :title="title" :path="path" :text="text" :text1="text1" />
        <!-- /Page Header -->

        <div class="row">
          <div
            class="col-md-6 col-sm-6 col-lg-6 col-xl-3"
            v-for="item in JobDashboardGrid"
            :key="item.id"
          >
            <div class="card dash-widget">
              <div class="card-body">
                <span class="dash-widget-icon"><i :class="item.Class"></i></span>
                <div class="dash-widget-info">
                  <h3>{{ item.Count }}</h3>
                  <span>{{ item.Title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6 text-center d-flex">
                <div class="card flex-fill">
                  <div class="card-body">
                    <h3 class="card-title">Overview</h3>
                    <apexchart
                      type="line"
                      height="300"
                      :options="lineChart.lineChartOptions"
                      :series="lineChart.series"
                    ></apexchart>
                  </div>
                </div>
              </div>
              <div class="col-md-6 d-flex">
                <div class="card flex-fill">
                  <div class="card-body">
                    <h3 class="card-title text-center">Latest Jobs</h3>
                    <ul class="list-group">
                      <li
                        class="list-group-item list-group-item-action"
                        v-for="item in JobsLatest"
                        :key="item.id"
                      >
                        {{ item.Title }}
                        <span class="float-end text-sm text-muted">{{ item.Hours }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-table">
              <div class="card-header">
                <h3 class="card-title mb-0">Applicants List</h3>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-nowrap custom-table mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Job Title</th>
                        <th>Departments</th>
                        <th>Start Date</th>
                        <th>Expire Date</th>
                        <th class="text-center">Job Types</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Resume</th>
                        <th class="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in ApplicationList" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>
                          <h2 class="table-avatar">
                            <router-link to="profile" class="avatar"
                              ><img
                                :src="require(`@/assets/img/profiles/${item.Image}`)"
                                alt="User Image"
                            /></router-link>
                            <router-link to="profile"
                              >{{ item.Name }}<span>{{ item.Role }}</span></router-link
                            >
                          </h2>
                        </td>
                        <td>
                          <router-link to="job-details">{{ item.JobTitle }}</router-link>
                        </td>
                        <td>{{ item.Departments }}</td>
                        <td>{{ item.StartDate }}</td>
                        <td>{{ item.ExpireDate }}</td>
                        <td class="text-center">
                          <div class="dropdown action-label">
                            <a
                              class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                              href="javascript:;"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i :class="item.Class"></i> {{ item.JobTypes }}
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="javascript:;"
                                ><i class="fa-regular fa-circle-dot text-info"></i> Full
                                Time</a
                              >
                              <a class="dropdown-item" href="javascript:;"
                                ><i class="fa-regular fa-circle-dot text-success"></i>
                                Part Time</a
                              >
                              <a class="dropdown-item" href="javascript:;"
                                ><i class="fa-regular fa-circle-dot text-danger"></i>
                                Internship</a
                              >
                              <a class="dropdown-item" href="javascript:;"
                                ><i class="fa-regular fa-circle-dot text-warning"></i>
                                Temporary</a
                              >
                              <a class="dropdown-item" href="javascript:;"
                                ><i class="fa-regular fa-circle-dot text-warning"></i>
                                Other</a
                              >
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="dropdown action-label">
                            <a
                              class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                              href="javascript:;"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i :class="item.Class"></i> {{ item.Status }}
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="javascript:;"
                                ><i class="fa-regular fa-circle-dot text-info"></i>
                                Open</a
                              >
                              <a class="dropdown-item" href="javascript:;"
                                ><i class="fa-regular fa-circle-dot text-success"></i>
                                Closed</a
                              >
                              <a class="dropdown-item" href="javascript:;"
                                ><i class="fa-regular fa-circle-dot text-danger"></i>
                                Cancelled</a
                              >
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          <a href="javascript:void(0);" class="btn btn-sm btn-primary"
                            ><i class="fa-solid fa-download me-1"></i> Download</a
                          >
                        </td>
                        <td class="text-center">
                          <div class="dropdown dropdown-action">
                            <a
                              href="javascript:;"
                              class="action-icon dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              ><i class="material-icons">more_vert</i></a
                            >
                            <div class="dropdown-menu dropdown-menu-right">
                              <a href="javascript:;" class="dropdown-item"
                                ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                              >
                              <a href="javascript:;" class="dropdown-item"
                                ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                              >
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-table">
              <div class="card-header">
                <h3 class="card-title mb-0">Shortlist Candidates</h3>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-nowrap custom-table mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Job Title</th>
                        <th>Departments</th>
                        <th class="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in Shortlist" :key="item.id">
                        <td>
                          {{ item.id }}
                        </td>
                        <td>
                          <h2 class="table-avatar">
                            <router-link to="profile" class="avatar"
                              ><img
                                :src="require(`@/assets/img/profiles/${item.Image}`)"
                                alt="User Image"
                            /></router-link>
                            <router-link to="profile"
                              >{{ item.Name }} <span>{{ item.Role }}</span></router-link
                            >
                          </h2>
                        </td>
                        <td>
                          <router-link to="job-details">{{ item.JobTitle }}</router-link>
                        </td>
                        <td>{{ item.Departments }}</td>
                        <td class="text-center">
                          <div class="action-label">
                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:;">
                              <i class="fa-regular fa-circle-dot text-danger me-1"></i
                              >{{ item.Status }}
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
import { lineChart } from "./data";
import JobDashboardGrid from "@/assets/json/jobs-dashboard-grid.json";
import JobsLatest from "@/assets/json/jobs-latest.json";
import ApplicationList from "@/assets/json/application-list.json";
import Shortlist from "@/assets/json/shortlist.json";
export default {
  data() {
    return {
      title: "Job Dashboard",
      path: "Dashboard",
      text: "Jobs",
      text1: "Job Dashboard",
      JobDashboardGrid: JobDashboardGrid,
      JobsLatest: JobsLatest,
      ApplicationList: ApplicationList,
      Shortlist: Shortlist,
      lineChart: lineChart,
    };
  },
};
</script>
