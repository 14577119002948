<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper" ref="formContainer">
      <div class="account-page">
        <!-- Main Wrapper -->

        <div class="main-wrapper">
          <div class="account-content">
            <!-- Account Logo -->
            <div class="account-logo">
              <router-link to="admin-dashboard"
                ><img src="@/assets/img/logo2.png" alt="Workwise"
              /></router-link>
            </div>
            <div class="account-box">
              <div class="account-wrapper">
                <h3 class="account-title">Change Password</h3>
                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <div class="input-block mb-3">
                    <label for="old_password" class="col-form-label">Old password</label>
                    <Field type="password" class="form-control" v-model="form.old_password" name="old_password" id="old_password" :class="{ 'is-invalid': errors.old_password }" />
                    <div class="invalid-feedback">{{ errors.old_password }}</div>
                    <div class="old_passwordshow text-danger" id="old_password"></div>
                  </div>
                  <div class="input-block mb-3">
                    <label for="new_password" class="col-form-label">New password</label>
                    <Field type="password" class="form-control" v-model="form.new_password" name="new_password" id="new_password" :class="{ 'is-invalid': errors.new_password }" />
                    <div class="invalid-feedback">{{ errors.new_password }}</div>
                    <div class="new_passwordshow text-danger" id="new_password"></div>
                  </div>
                  <div class="input-block mb-3">
                    <label for="confirm_password" class="col-form-label">Confirm password</label>
                    <Field type="password" class="form-control" v-model="form.confirm_password" name="confirm_password" id="confirm_password" :class="{ 'is-invalid': errors.confirm_password }" />
                    <div class="invalid-feedback"></div>
                    <div class="confirm_passwordshow text-danger" id="confirm_password"> {{ form.new_password != null && form.new_password != form.confirm_password ? 'confirm password must match' : '' }}</div>
                  </div>
                  <div class="submit-section mb-4">
                    <button class="btn btn-primary submit-btn">Update Password</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";
import { Form, Field } from "vee-validate";
import axios from "axios";
import { notification } from "ant-design-vue";
export default {
  components:{
    Form,Field
  },
  data(){
    return {
      form:{
        old_password:null,
        new_password:null,
        confirm_password:null
      }
    }
  },
  setup() {
    const schema = Yup.object().shape({
      old_password:Yup.string().required('Old Password is required'),
      new_password:Yup.string().required('New Password is required').min(6, 'New Password must be at least 6 characters'),
      confirm_password: Yup.string().required('Confirm Password is required')
    })

    return {
        schema
    }
  },
  methods:{
    onSubmit()
    {

      if(this.form.new_password == this.form.confirm_password)
      {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

        axios.post("/password-update",this.form)
        .then( (response) => {
            loader.hide();
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

            localStorage.clear();
            this.$router.push({ name: "login" }).catch((error) => {});
            return false;

        }).catch(error => {
          loader.hide();
          if(error.response){
            var response = (error.response);
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }else{
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
        });
      }
      
    }
  }
}
</script>
