<template>
  <!-- Add Custom Policy Modal -->
  <div id="add_custom_policy" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Custom Policy</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearFormData"
            ref="close_policy_ad_model"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Policy Name <span class="text-danger">*</span></label
              >
              <Field
                    name="leave_policy_name"
                    type="text"
                    value=""
                    class="form-control"
                    v-model="form.leave_policy_name"
                    :class="{ 'is-invalid': errors.leave_policy_name }"
                  />
                  <div class="invalid-feedback">{{ errors.leave_policy_name }}</div>
                  <div class="leave_policy_namesshow text-danger" id="leave_policy_name"></div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Days <span class="text-danger">*</span></label
              >
              <Field
                    name="leave_policy_day"
                    type="number"
                    min="0"
                    value=""
                    class="form-control"
                    v-model="form.leave_policy_day"
                    :class="{ 'is-invalid': errors.leave_policy_day }"
                  />
                  <div class="invalid-feedback">{{ errors.leave_policy_day }}</div>
                  <div class="leave_policy_daysshow text-danger" id="leave_policy_day"></div>
            </div>
            <div class="input-block mb-3 leave-duallist">
              <label class="col-form-label">Add employee</label>
              <div class="row">
                <div class="col-lg-5 col-sm-5">
                  <select
                    v-model="selectedFrom"
                    class="form-control form-select"
                    size="5"
                    multiple
                  >
                
                    <option
                      v-for="option in employees"
                      :value="option.id"
                      :key="option.id"
                    >
                      {{ option.first_name }} {{ option.last_name }}
                    </option>
                  </select>
                </div>
                <div class="multiselect-controls col-lg-2 col-sm-2 d-grid gap-2">
                  <button type="button" @click="moveRightAll" class="btn w-100 btn-white">
                    <i class="fa fa-forward"></i>
                  </button>
                  <button
                    type="button"
                    @click="moveRightSelected"
                    class="btn w-100 btn-white"
                  >
                    <i class="fa fa-chevron-right"></i>
                  </button>
                  <button
                    type="button"
                    @click="moveLeftSelected"
                    class="btn w-100 btn-white"
                  >
                    <i class="fa fa-chevron-left"></i>
                  </button>
                  <button type="button" @click="moveLeftAll" class="btn w-100 btn-white">
                    <i class="fa fa-backward"></i>
                  </button>
                </div>
                <div class="col-lg-5 col-sm-5">
                  <select
                    v-model="selectedTo"
                    class="form-control form-select"
                    size="8"
                    multiple
                  >
                    <option
                      v-for="option in optionsTo"
                      :value="option.id"
                      :key="option.id"
                    >
                      {{ option.first_name }} {{ option.last_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Custom Policy Modal -->

  <!-- Delete Custom Policy Modal -->
  <div class="modal custom-modal fade" id="delete_custom_policy"  role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Custom Policy</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:void(0);" class="btn btn-primary continue-btn"
                @click="$emit('deleteLeavePolicy')"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
                  ref="ref_delete_custom_policy"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Custom Policy Modal -->
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from 'axios';
import { notification } from "ant-design-vue";
export default {
  emits:['reloadData','deleteLeavePolicy'],
  props:{
    addLeaveTypePolicyID: {
      type: Number,
      required: false,
      default: () => null,
    },
    editFormData: {
      type: Object,
      required: false,
      default: () => null,
    },
    closeModel: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    clearData:{
      type: Boolean,
      required: false,
      default: () => false,
    }
  },
  data() {
    return {
      employees:[],
      selectedFrom: [],
      selectedTo: [],
      optionsTo: [],
      form:{
        leave_policy_name:'',
        leave_policy_day:'',
        leave_policy_employees:''
      },
      employeesCopy:[],
      errors:null
    };
  },
  components: {
    Form,
    Field,
  },
  methods: {
    clearFormData()
    {
      this.form = {
        leave_policy_name:'',
        leave_policy_day:'',
        leave_policy_employees:''
      }

      this.selectedFrom = [];
      this.selectedTo = [];
      this.optionsTo = [];
      this.employees = [...this.employeesCopy];

      this.errors = null
    },
    async onSubmit() {

        const leavePolicyEmployees = this.optionsTo.map(obj => obj.id).join(', ');
        this.form.leave_policy_employees = leavePolicyEmployees;

        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;


        if(this.form?.leave_type_policy_id && this.form?.leave_type_policy_id != null)
        {
          await axios.put('/leave-settings/policy/' + this.form?.leave_type_policy_id, this.form).then(response => {

            this.$refs.close_policy_ad_model.click();

            this.clearFormData();

            this.$emit('reloadData');

            notification.open({
                  message: response?.data?.message || 'Successful',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                      background: process.env.VUE_APP_SUCCESS_COLOR,
                  },
              });
            }).catch(error => {
            if (error?.response) {
                  const response = error.response;

                  notification.open({
                      message: response?.data?.message,
                      placement: "topRight",
                      duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                      style: {
                          background: process.env.VUE_APP_WARNING_COLOR,
                      },
                  });
              } else {

                console.log('error',error);
                  notification.open({
                      message: 'Server Error',
                      placement: "topRight",
                      duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                      style: {
                          background: process.env.VUE_APP_WARNING_COLOR,
                      },
                  });
              }
            })
        }
        else
        {
          await axios.post('/leave-settings/policy/' + this.addLeaveTypePolicyID, this.form).then(response => {

            this.$refs.close_policy_ad_model.click();

            this.clearFormData();

            this.$emit('reloadData');

            notification.open({
                  message: response?.data?.message || 'Successful',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                      background: process.env.VUE_APP_SUCCESS_COLOR,
                  },
              });
            }).catch(error => {
            if (error?.response) {
                  const response = error.response;

                  notification.open({
                      message: response?.data?.message,
                      placement: "topRight",
                      duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                      style: {
                          background: process.env.VUE_APP_WARNING_COLOR,
                      },
                  });
              } else {

                console.log('error',error);
                  notification.open({
                      message: 'Server Error',
                      placement: "topRight",
                      duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                      style: {
                          background: process.env.VUE_APP_WARNING_COLOR,
                      },
                  });
              }
          })
        }

        
    },
    async fetchEmployeeList(){

      var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        
      await axios.get('/employees/all').then((response)=>{
  
        

        this.employeesCopy = response?.data?.data || [];

        this.employees = [...this.employeesCopy];

      }).catch(error =>{
        console.log('error in fetching employee',error);
      })
    },
    moveRightAll() {
      this.optionsTo = this.optionsTo.concat(this.employees); // Add selected options to optionsTo
      this.employees = []; // Clear optionsFrom
    },
    moveRightSelected() {
      // Move selected options from optionsFrom to optionsTo
      const selectedValues = this.selectedFrom.slice();

      this.optionsTo = this.optionsTo.concat(
        this.employees.filter((option) => selectedValues.includes(option.id))
      );
      // Remove selected options from employees
      this.employees = this.employees.filter(
        (option) => !selectedValues.includes(option.id)
      );
      // Clear selectedFrom
      this.selectedFrom = [];
    },

    moveLeftSelected() {
      // Move selected options from optionsTo to employees
      const selectedValues = this.selectedTo.slice();
      this.employees = this.employees.concat(
        this.optionsTo.filter((option) => selectedValues.includes(option.id))
      );
      // Remove selected options from optionsTo
      this.optionsTo = this.optionsTo.filter(
        (option) => !selectedValues.includes(option.id)
      );
      // Clear selectedTo
      this.selectedTo = [];
    },
    moveLeftAll() {
      this.employees = this.employees.concat(this.optionsTo); // Add selected options back to optionsFrom
      this.optionsTo = [];
    },
  },
  setup() {
    const schema = Yup.object().shape({
      leave_policy_name: Yup.string().required("Policy Name is required"),
      leave_policy_day: Yup.string().required("Policy Day is required"),
    });
    return {
      schema,
    }
  },
  async mounted()
  {
    await this.fetchEmployeeList();
  },
  watch:{
    clearData(newVal)
    {
      if(newVal)
      {
        this.clearFormData()
      }
    },
    closeModel(newVal)
    {
      if(newVal)
      {
        this.$refs.ref_delete_custom_policy.click();
        this.$refs.close_policy_ad_model.click();
        this.clearFormData()

      }
    },
    editFormData(newValue)
    {

      if(newValue != null)
      {
        this.selectedFrom = [];
        this.selectedTo = [];
        this.optionsTo = [];
        this.employees = [...this.employeesCopy];
        this.form = {
          leave_policy_name:newValue?.leave_policy_name,
          leave_policy_day:newValue?.leave_policy_day,
          leave_policy_employees:newValue?.leave_policy_employees,
          leave_type_policy_id:newValue?.leave_type_policy_id
        }

        if(newValue?.leave_policy_employees)
        {
          var userIdsArray = newValue?.leave_policy_employees.split(',').map(Number);
          this.selectedFrom = userIdsArray;
          this.moveRightSelected();
        }
      }
    }
  }
};
</script>
