<template>
    <div class="account-page" ref="formContainer">
      <div class="main-wrapper">
        <div class="account-content">
          <div class="container">
            <!-- Account Logo -->
            <div class="account-logo">
              <router-link to="admin-dashboard"
                ><img src="@/assets/img/logo2.png" alt="Workwise"
              /></router-link>
            </div>
            <!-- /Account Logo -->
  
            <div class="account-box">
              <div class="account-wrapper">
                <h3 class="account-title">Reset Your Password</h3>
                <!-- <p class="account-subtitle">
                 Reset Your Password.
                </p> -->
  
                <!-- Account Form -->
                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                    <div class="input-block mb-3">
                      <label for="new_password" class="col-form-label">New password</label>
                      <Field type="password" class="form-control" v-model="form.new_password" name="new_password" id="new_password" :class="{ 'is-invalid': errors.new_password }" />
                      <div class="invalid-feedback">{{ errors.new_password }}</div>
                      <div class="new_passwordshow text-danger" id="new_password"></div>
                    </div>
                    <div class="input-block mb-3">
                      <label for="confirm_password" class="col-form-label">Confirm password</label>
                      <Field type="password" class="form-control" v-model="form.confirm_password" name="confirm_password" id="confirm_password" :class="{ 'is-invalid': errors.confirm_password }" />
                      <div class="invalid-feedback"></div>
                      <div class="confirm_passwordshow text-danger" id="confirm_password"> {{ form.new_password != null && form.new_password != form.confirm_password ? 'confirm password must match' : '' }}</div>
                    </div>
                    <div class="submit-section mb-4">
                      <button class="btn btn-primary submit-btn">Update Password</button>
                    </div>
                  </Form>
                <!-- /Account Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import * as Yup from "yup";
  import { Form, Field } from "vee-validate";
  import axios from "axios";
  import { notification } from "ant-design-vue";
  export default {
    components:{
      Form,Field
    },
    data(){
      return {
        form:{
            new_password:null,
            confirm_password:null
        },
        successMessage:null,
        disabledButton:false
      }
    },
    setup() {
      const schema = Yup.object().shape({
        new_password:Yup.string().required('New Password is required').min(6, 'New Password must be at least 6 characters'),
        confirm_password: Yup.string().required('Confirm Password is required')
      })
  
      return {
          schema
      }
    },
    methods:{
      onSubmit()
      {
  
        this.disabledButton = true;
  
          let loader = this.$loading.show({
            container: this.$refs.formContainer,
            canCancel: false
          });
        
        if(this.form.new_password == this.form.confirm_password)
        {

            var form = new FormData();
            form.append('password',this.form.new_password);
            form.append('token',this?.$route?.params?.token);

  
            axios.post("/forgot-password-update",form)
            .then( (response) => {
                this.successMessage = response?.data?.message;
    
                loader.hide();
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                });
    
                 this.$router.push({ name: "login" }).catch((error) => {});
    
            }).catch(error => {
                loader.hide();
                if(error.response){
                var response = (error.response);
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
                }else{
                notification.open({
                    message: 'Server Error',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
                }

                this.$router.push({ name: "login" }).catch((error) => {});
            });
        }
  
          this.disabledButton = false;
      }
    },
    created()
    {
        if(!this?.$route?.params?.token || this?.$route?.params?.token == null)
        {
            notification.open({
              message: 'Invalid URL',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });

            this.$router.push({ name: "login" }).catch((error) => {});
        }
        else{

            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            axios.get("/check-expiration/"+this?.$route?.params?.token)
            .then( (response) => {
                loader.hide();
            }).catch(error => {
                loader.hide();
                if(error.response){
                var response = (error.response);
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
                }else{
                notification.open({
                    message: 'Server Error',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
                }

                this.$router.push({ name: "login" }).catch((error) => {});
            });

        }
    }
  }
  </script>
  