<template>
  <!-- Add Leave Modal -->
  <div id="add_leave" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Leave</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="addleaveclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Leave Type <span class="text-danger">*</span></label
              >
			  <Field
                    name="leave_type_id"
                    as="select"
                    class="form-control"
					v-model="form.leave_type_id"
					:class="{ 'is-invalid': errors.leave_type_id }"
                  >
				  <option value="">Select Leave Type</option>
				  <option v-for="leavetype in leavetypelist" :key="leavetype.id" :value="leavetype.id">{{ leavetype.type }}</option>
				  <div class="invalid-feedback">{{ errors.leave_type_id }}</div>
                  <div class="leave_type_idshow text-danger" id="leave_type_id"></div>
			  </Field>
			  <!--
              <vue-select
                :options="SelectType"
                id="medical-leave"
                placeholder="Select Leave Type"
              /> -->
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >From <span class="text-danger">*</span></label
              >
              <div class="cal-icon">
                <datepicker
                  v-model="createdfrom"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label">To <span class="text-danger">*</span></label>
              <div class="cal-icon">
                <datepicker
                  v-model="createdto"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
            </div>
			
			
			<div class="row" v-if="form.leavedetails">
			  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6" v-for="(item, index) in form.leavedetails" :key="item">
				  <div class="input-block mb-3" >
					<label class="col-form-label">{{item.leave_details_date}} <span class="text-danger"></span></label>
					<div class="form-check">
						<input type="radio" value="FULLDAY"  v-model="form.leavedetails[index]['leave_details_type']" /> Full Day 
					</div>	
					<div class="form-check">
					<input type="radio" value="HALFDAY"  v-model="form.leavedetails[index]['leave_details_type']"  /> Half Day
					</div>
				   </div>
			   </div>
			</div>
			
			<!---
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Number of days <span class="text-danger">*</span></label
              >
              <input class="form-control" readonly type="text" />
            </div> -->
			<!--
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Remaining Leaves <span class="text-danger">*</span></label
              >
              <input class="form-control" readonly value="12" type="text" />
            </div> -->
            
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Leave Reason <span class="text-danger">*</span></label
              >
              <Field
                    name="reason"
                    as="textarea"
                    value=""
                    class="form-control"
					v-model="form.reason"
					rows="4"
					:class="{ 'is-invalid': errors.reason }"
                  />
                  <div class="invalid-feedback">{{ errors.reason }}</div>
                  <div class="nameshow text-danger" id="reason"></div>
            </div>
			<div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Leave Modal -->

  <!-- Edit Leave Modal -->
  <div id="edit_leave" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Leave</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="editleaveclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="onUpdate" :validation-schema="editschema" v-slot="{ errors }">
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Leave Type <span class="text-danger">*</span></label
              >
			  <Field
                    name="leave_type_id"
                    as="select"
                    class="form-control"
					v-model="editform.leave_type_id"
					:class="{ 'is-invalid': errors.leave_type_id }"
                  >
				  <option value="">Select Leave Type</option>
				  <option v-for="leavetype in leavetypelist" :key="leavetype.id" :value="leavetype.id">{{ leavetype.type }}</option>
				  <div class="invalid-feedback">{{ errors.leave_type_id }}</div>
                  <div class="leave_type_idshow text-danger" id="leave_type_id"></div>
			  </Field>
			  <!--
              <vue-select
                :options="SelectType"
                id="medical-leave"
                placeholder="Select Leave Type"
              /> -->
            </div>
			<!--
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Leave Type <span class="text-danger">*</span></label
              >
			  
			  {{editform}}
              <vue-select
                :options="SelectLeave"
                id="casual"
                placeholder="Select Leave type"
              />
            </div>-->
            <div class="input-block mb-3">
              <label class="col-form-label"
                >From <span class="text-danger">*</span></label
              >
              <div class="cal-icon">
                <datepicker
                  v-model="editobjfrom"
                  placeholder="2023-01-01"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label">To <span class="text-danger">*</span></label>
              <div class="cal-icon">
                <datepicker
                  v-model="editobjto"
                  placeholder="2023-01-01"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
            </div>
			<div class="row" v-if="editform.leavedetails">
			  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6" v-for="(item, index) in editform.leavedetails" :key="item">
				<div class="input-block mb-3">
					<label class="col-form-label">{{item.leave_details_date}} <span class="text-danger"></span></label>
					<div class="form-check">
						<input type="radio" value="FULLDAY"  v-model="editform.leavedetails[index]['leave_details_type']" /> Full Day
					</div>
					<div class="form-check">
					<input type="radio" value="HALFDAY"  v-model="editform.leavedetails[index]['leave_details_type']"  /> Half Day
					</div>
				</div>
			  </div>
			</div>  
			
			<!--
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Number of days <span class="text-danger">*</span></label
              >
              <input class="form-control" readonly type="text" value="2" />
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Remaining Leaves <span class="text-danger">*</span></label
              >
              <input class="form-control" readonly value="12" type="text" />
            </div> -->
            <!-- <div class="input-block mb-3">
              <label class="col-form-label"
                >Leave Reason <span class="text-danger">*</span></label
              >
              <textarea rows="4" class="form-control">Going to hospital</textarea>
            </div> -->
			
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Leave Reason <span class="text-danger">*</span></label
              >
              <Field
                    name="reason"
                    as="textarea"
                    value=""
                    class="form-control"
					v-model="editform.reason"
					rows="4"
					:class="{ 'is-invalid': errors.reason }"
                  />
                  <div class="invalid-feedback">{{ errors.reason }}</div>
                  <div class="nameshow text-danger" id="reason"></div>
            </div>
			<div class="submit-section">
              <button class="btn btn-primary submit-btn">Save</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Leave Modal -->

  <!-- Approve Leave Modal -->
  <div class="modal custom-modal fade" id="approve_leave" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Update Leave</h3>
            <p>Are you sure want to update this leave?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="updateLeave"
                  >Update</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
				  ref="updateleaveclose"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Approve Leave Modal -->

  <!-- Delete Leave Modal -->
  <div class="modal custom-modal fade" id="delete_approve" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Leave</h3>
            <p>Are you sure want to delete this leave?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="deleteLeave"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn" ref="deleteleaveclose"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Leave Modal -->
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());

import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import moment from 'moment';


export default {
  emits: ["createLeave","updateLeave","updateLeavestatus","deleteLeave","createLeaveDates"],
  components: {
    Form,
    Field,
  },
  props: {
    form: [Array, Object],
	leavetypelist: [Array, Object],
	employeelist: [Array, Object],
	editform: [Array, Object],
	update_id: [Number,String],
	update_status: [Number,String],
	updateleavetype_id: [Number,String],
	delleave_id: [Number,String]
  },  
  data() {
    return {
      select2: null,
      SelectType: [
        "Select Leave Type",
        "Casual Leave 12 Days",
        "Medical Leave",
        "Loss of Pay",
      ],
	  createdfrom: currentDate,
	  createdto: currentDateOne,
      SelectLeave: ["Select Leave Type", "Casual Leave 12 Days"],
      startdate: currentDate,
      dateFormat: 'yyyy-MM-dd',
      startdateone: currentDateOne,
	  editobjfrom: '',
	  editobjto: '',
    };
  },
  methods: {
	seteditdates(editobjfrom,editobjto){
		
		this.editobjto = editobjto;
		this.editobjfrom = editobjfrom;
	  
	},
	onDateEditChange(){
		console.log('date change console');
		console.log(this.form);
		this.$emit("edit-leavedates",this.editobjfrom,this.editobjto);
	},
	onDateChange(){
		console.log('date change console');
		console.log(this.form);
		this.$emit("create-leavedates",this.createdfrom,this.createdto);
	},
	closeDialog(){
		this.$refs.addleaveclose.click();
		this.$refs.editleaveclose.click();
		this.$refs.updateleaveclose.click();
		this.$refs.deleteleaveclose.click();
	},
	onSubmit(values) {
	
		values.from = moment(this.form.from).format('YYYY-MM-DD');
		values.to = moment(this.form.to).format('YYYY-MM-DD');
		values.leave_data = this.form.leavedetails;
		this.$emit("create-leave",values);
	},
	closeDialog(){
		this.$refs.addleaveclose.click();
		this.$refs.editleaveclose.click();
		this.$refs.updateleaveclose.click();
		this.$refs.deleteleaveclose.click();
	},
	onUpdate(formval){
	
		console.log('Update Called!!');
		this.editform.from = moment(this.editobjfrom).format('YYYY-MM-DD');
		this.editform.to = moment(this.editobjto).format('YYYY-MM-DD');
		this.editform.leave_data = this.editform.leavedetails;
		
		
		
		//this.editform.leave_data = leavedata;
		
		console.log('update form called');
		console.log(this.editform);
		
		this.$emit("update-leave",this.editform);
		
	},
	updateLeave(){
		
		this.$emit("update-leavestatus",this.update_id,this.updateleavetype_id,this.update_status);
	},
	deleteLeave(){
		this.$emit("delete-leave",this.delleave_id);
	}
  },
  setup() {
    const schema = Yup.object().shape({
	  leave_type_id: Yup.string().required("Leave Type is required"),
      reason: Yup.string().required("Reason is required"),
	});
	
	const editschema = Yup.object().shape({
	  leave_type_id: Yup.string().required("Leave Type is required"),
      reason: Yup.string().required("Reason is required"),
	});
	
	return {
      schema,
	  editschema
	};
  },
  watch: {
    createdfrom(val){
		
		console.log('changes');
		console.log(val);
		this.onDateChange();
	},
	createdto(val){
		
		console.log('changes');
		console.log(val);
		this.onDateChange();
	},
	editobjto(val){
		
		console.log('changes');
		console.log(val);
		this.onDateEditChange();
	},
	editobjfrom(val){
		
		console.log('changes');
		console.log(val);
		this.onDateEditChange();
	}
  }
};
</script>
