<template>
  <!-- Create Project Modal -->
  <div id="create_project" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create Project</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Project Name</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Client</label>
                  <vue-select
                    :options="AddTasksClient"
                    id="addTasksClient"
                    placeholder="Global Technologies"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Start Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Start Date"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">End Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdateone"
                      placeholder="End Date"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="input-block mb-3">
                  <label class="col-form-label">Rate</label>
                  <input placeholder="$50" class="form-control" type="text" />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="input-block mb-3">
                  <label class="col-form-label">&nbsp;</label>
                  <vue-select
                    :options="AddTasksTime"
                    id="addTasksTime"
                    placeholder="Hourly"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Priority</label>
                  <vue-select
                    :options="AddTasksPriority"
                    id="addTasksPriority"
                    placeholder="High"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Add Project Leader</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Team Leader</label>
                  <div class="project-members">
                    <a
                      class="avatar"
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="Jeffery Lalor"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-16.jpg"
                        alt="User Image"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Add Team</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Team Members</label>
                  <div class="project-members">
                    <a
                      class="avatar"
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="John Doe"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-02.jpg"
                        alt="User Image"
                      />
                    </a>
                    <a
                      class="avatar"
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="Richard Miles"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-09.jpg"
                        alt="User Image"
                      />
                    </a>
                    <a
                      class="avatar"
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="John Smith"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-10.jpg"
                        alt="User Image"
                      />
                    </a>
                    <a
                      class="avatar"
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="Mike Litorus"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-05.jpg"
                        alt="User Image"
                      />
                    </a>
                    <span class="all-team">+2</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label">Description</label>
              <summernoteEditor
                v-model="myValue"
                @summernoteImageLinkInsert="summernoteImageLinkInsert"
              />
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label">Upload Files</label>
              <input class="form-control" type="file" />
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Project Modal -->

  <!-- Assignee Modal -->
  <div id="assignee" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Assign to this task</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="assign_task_modal_close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group m-b-30">
            <input
              placeholder="Search to add"
              class="form-control search-input"
              type="text"
              v-model="searchValue"
              @keyup.enter="fetchEmployee"
            />
            <button class="btn btn-primary" @click="fetchEmployee">Search</button>
          </div>
          <div>
            <ul class="chat-user-list" v-if="employees && employees?.length">
              <li v-for="emp in employees">
                <a href="javascript:void(0);" @click="assignEmployee(emp)">
                  <div class="chat-block d-flex">
                    <span class="avatar"
                      ><img v-if="emp?.avatar != null"
                      :src="getEmployeeAvatar(emp?.avatar)"
                      @error="handleImageError(emp)"
                        alt="User Image"
                    />
                    <img v-else
                    :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                        alt="User Image"
                    /></span>
                    <div class="media-body align-self-center text-nowrap">
                      <div class="user-name">{{ emp?.first_name}} {{ emp?.last_name }}</div>
                      <span class="designation">{{ (emp?.designation && emp?.designation.name) ? emp?.designation.name : '-' }}</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <!-- <div class="submit-section">
            <button class="btn btn-primary submit-btn">Assign</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Assignee Modal -->

  <!-- Task Followers Modal -->
  <div id="task_followers" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add followers to this task</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="add_follower_modal_close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group m-b-30">
            <input
              placeholder="Search to add"
              class="form-control search-input"
              type="text"
              v-model="searchValue"
              @keyup.enter="fetchEmployee"
            />
            <button class="btn btn-primary" @click="fetchEmployee">Search</button>
          </div>
          <div>
            <ul class="chat-user-list" v-if="employees && employees?.length">
              <li v-for="emp in employees">
                <a href="javascript:void(0);" @click="addFollower(emp)">
                  <div class="chat-block d-flex">
                    <span class="avatar"
                      ><img v-if="emp?.avatar != null"
                      :src="getEmployeeAvatar(emp?.avatar)"
                      @error="handleImageError(emp)"
                        alt="User Image"
                    />
                    <img v-else
                    :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                        alt="User Image"
                    /></span>
                    <div class="media-body align-self-center text-nowrap">
                      <div class="user-name">{{ emp?.first_name}} {{ emp?.last_name }}</div>
                      <span class="designation">{{ (emp?.designation && emp?.designation.name) ? emp?.designation.name : '-' }}</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <!-- <div class="submit-section">
            <button class="btn btn-primary submit-btn">Add to Follow</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Task Followers Modal -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import axios from 'axios';
import { notification } from "ant-design-vue";
export default {
  emits:['assign-employee','set-follower','fetch-messages'],
  data() {
    return {
      select2: null,
      AddTasksClient: ["Global Technologies", "Delta Infotech"],
      AddTasksTime: ["Hourly", "Fixed"],
      AddTasksPriority: ["High", "Medium", "Low"],
      startdate: currentDate,
      dateFormat: 'dd-MM-yyyy',
      startdateone: currentDateOne,
      myValue: "description",
      employees:[],
      searchValue:'',
      defaultAvatar:'avatar-02.jpg',
    };
  },
  props: {
    task_id: {
      type: Number,
      required: false,
      default: () => null,
    },
    reset_value: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  watch:{
    reset_value:{
      handler(newId) {
          if(newId)
          {
            this.searchValue = ''
            this.employees = []
          }
      },
      immediate: true
    }
  },
  methods: {
    addFollower(employee)
    {
      const employee_id = employee?.id || null;

      let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});

      if(!employee_id)
      {
        loader.hide()
        notification.open({  
                message: 'Employee not found!',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });

            return;
      }

      if(!this.task_id)
      {
        loader.hide()
        notification.open({  
                message: 'Task not found!',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            return;
      }

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const formData = new FormData();

      formData.append('employee_id',employee_id);


      axios.post('/project/task/'+this.task_id+'/add-follower',formData).then((response)=>{

        this.$refs?.add_follower_modal_close?.click()

        this.$emit('set-follower',response?.data?.data || [])


        notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

        loader.hide()
      }).catch(error=>{
        loader.hide()
        if(error.response){
          var response = (error.response);
            
          notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        
          this.errors = response?.data?.error
          
        }else{
          
          notification.open({
            message: 'Server Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
      })
    },
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(emp) {
      emp.avatar = null;
      emp.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
    summernoteChange(newValue) {
      // console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert(...args) {
      // console.log("summernoteImageLinkInsert()", args);
    },
    fetchEmployee(){

      if(this.searchValue == '' || this.searchValue == ' ')
      {
        this.employees = []
        return;
      }

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const params = {
        params:{
          name:this.searchValue,
          per_page:100
        }
      }

      axios.get('/employee',params).then((response)=>{
        this.employees = response?.data?.data?.record?.data || [];
      }).catch(error=>{
        this.employees = []    
            notification.open({  
                message: error?.data?.message || 'No records found!',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
      })
    },
    assignEmployee(employee)
    {
      const employee_id = employee?.id || null;

      let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});

      if(!employee_id)
      {
        loader.hide()
        notification.open({  
                message: 'Employee not found!',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });

            return;
      }

      if(!this.task_id)
      {
        loader.hide()
        notification.open({  
                message: 'Task not found!',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            return;
      }

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const formData = new FormData();

      formData.append('employee_id',employee_id);


      axios.post('/project/task/'+this.task_id+'/assign-employee',formData).then((response)=>{

        this.$refs?.assign_task_modal_close?.click()

        this.$emit('assign-employee',employee)

        notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

        loader.hide()
      }).catch(error=>{
        loader.hide()
        if(error.response){
          var response = (error.response);
            
          notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        
          this.errors = response?.data?.error
          
        }else{
          
          notification.open({
            message: 'Server Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
      })
    }
  },
};
</script>
