<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <inbox-header :title="title" :path="path" :text="text" :text1="text1" />
        <!-- /Page Header -->

        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePagination">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
            <div class="table-responsive">
              <a-table :columns="columns" :data-source="data" :pagination="pagination"
                  @change="handleTableChange">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'LeaveType'">
                    <td>{{ record.LeaveType }}</td>
                  </template>
                  <template v-else-if="column.key === 'Status'">
                    <div class="dropdown action-label">
                      <a
                        class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                        href="javascript:;"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i :class="record.status ? 'fa-regular fa-circle-dot text-success' : 'fa-regular fa-circle-dot text-danger'"></i> {{ record.status ?  'Active' : 'InActive' }}
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a v-if="!record.status" href="javascript:;" class="dropdown-item" @click="changeStatus(record?.id,1)"
                          ><i class="fa-regular fa-circle-dot text-success"></i> Active</a
                        >
                        <a v-else href="javascript:;" class="dropdown-item" @click="changeStatus(record?.id,0)"
                          ><i class="fa-regular fa-circle-dot text-danger"></i>
                          InActive</a
                        >
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#add_leavetype"
							@click="edit_form = record"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_leavetype"
							@click="delleavetypeid = record.id"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <leavetype-model :closeFormModel="closeFormModel"  :editform="edit_form"  @create-leavetype="createLeavetype" @update-leavetype="updateLeavetype" @delete-leavetype="deleteLeavetype" ref="leavetypemodel" ></leavetype-model>
    </div>
  </div>
  <leavemodel></leavemodel>
</template>

<script>
var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};

const columns = [
  {
	  title: "#",
	  dataIndex: "id",
	  key: "id",
	  customRender: ({ index }) => {
		return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
  },
  {
    title: "Leave Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Short Code",
    dataIndex: "short_code",
    key: "short_code",
  },
  {
    title: "Leave Days",
    dataIndex: "days",
    key: "days",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    sorter: false,
    key: "action",
    class: "text-end",
  },
];
const data = [];

import axios from 'axios';
import { notification } from "ant-design-vue";

export default {
  data() {
    return {
      title: "Leave Type",
      path: "Dashboard",
      text: "Leave Type",
      text1: "Add Leave Type",
      columns,
      data,
	  perpage: 10,
	  edit_form: { },
	  pagination,
	  delleavetypeid: null,
	  closeFormModel:false
    };
  },
  methods: {
	updatePagination()
	{
		var params = {
          params: { per_page: this.pagination.pageSize }
        };
        this.loadCommonData(params);
	},
	handleTableChange(pagesize){
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current }
		};
		
		this.loadCommonData(params);
		
	},
	deleteLeavetype(){
		
		if(this.delleavetypeid)
		{
			var token = window.localStorage.getItem("token");
		
			axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
			axios.defaults.headers.common["Authorization"] = "Bearer " + token;
			
			let loader = this.$loading.show({
					// Optional parameters
					container: this.$refs.formContainer,
					canCancel: false
			});
			
			axios.delete("/leavetypes/"+this.delleavetypeid, [])
			.then( (response) => {
					
				loader.hide();
				this.delleavetypeid = null;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						background: process.env.VUE_APP_SUCCESS_COLOR,
						},
					});

				this.$refs.leavetypemodel.closeDialog();
				
				var params = {
					params: { per_page: this.pagination.pageSize }
					};
					
				this.loadCommonData(params);
						
			}).catch(error => {
			
				loader.hide();

				this.delleavetypeid = null;
				
				if(error.response){
				
					var response = (error.response);
						
					notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				}else{
					notification.open({
						message: 'Server Error',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				}
				
			});
		}
		
	},
	updateLeavetype(formval){
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
			
		var postform = new FormData();
		postform.append('type_name',formval.name);
		
		axios.put("/leavetypes/"+formval.id, postform)
          .then( (response) => {
				
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.leavetypemodel.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	createLeavetype(formval){

		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});

			var httpRequest = null;

			if(formval?.id)
			{
				httpRequest = axios.put('/leavetypes/'+formval?.id, formval);
			}
			else
			{
				httpRequest = axios.post("/leavetypes", formval);
			}

		
			httpRequest.then( (response) => {

			  this.closeFormModel = false;
			  this.closeFormModel = true;
				
			  loader.hide();
			  
			  notification.open({
					message: response?.data?.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error?.response){
			
				var response = (error.response);
					
				notification.open({
					message: response?.data?.message || 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	loadCommonData(params = null){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;

		var param = {
          params: { per_page: this.pagination.pageSize }
        };

		if(params)
		{
			param = params;
		}
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/leavetypes", param)
			.then((response) => {
				
				
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				this.data = response.data.data.data;
				
				loader.hide();
				
				
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
	changeStatus(id = null,status = null)
	{
		if(id)
		{
			var token = window.localStorage.getItem("token");
		
			axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
			axios.defaults.headers.common["Authorization"] = "Bearer " + token;
			
			let loader = this.$loading.show({
					container: this.$refs.formContainer,
					canCancel: false
			});
			
			axios.put("/leavetypes/status-update/"+id, {status:status})
			.then( (response) => {
					
				loader.hide();

				var params = {
					params: { per_page: this.pagination.pageSize }
					};
					
				this.loadCommonData(params);
				
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						background: process.env.VUE_APP_SUCCESS_COLOR,
						},
					});
				
				
						
			}).catch(error => {
			
				loader.hide();
				
				if(error.response){
				
					var response = (error.response);
						
					notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				}else{
					notification.open({
						message: 'Server Error',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				}
				
			});
		}
	}
  },
  mounted() {
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };
	
	this.loadCommonData(params);
	
	
  }
};
</script>
