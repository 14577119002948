<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <inbox-header  :hasCreatePermission="hasCreatePermission" @clear-asset-data="setAssetFormClear" :title="title" :path="path" :text="text" :text1="text1" :hasImportPermission="hasImportPermission"/>
        <assetsearch @search-asset="searchAsset" />
        <assetstable :searchFiter="searchFiter" :reloadData="reloadData" :deleteAssetData="deleteAssetData" @close-delete-modal="closeDeleteModelOn" @asset-edit-data="editAssetData"/>
      </div>
      <!-- /Page Content -->
      <assetmodel :editData="editData" :closeDeleteModal="closeDeleteModal" :assetClear="assetClear" @reload-asset-data="reloadAssetData" @delete-asset="deleteAsset" />

      <!-- Import asset Modal -->
	  <div id="import_Asset" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title">Import Assets</h5>
        <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        ref="impassetclose"
        >
        <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
        <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
        <div class="input-block mb-3">
        <label class="col-form-label"
          >File <span class="text-danger">*</span>
        </label>
        <Field
          name="asset_import_file"
          type="file"
          class="form-control"
          :class="{ 'is-invalid': errors && errors?.asset_import_file }"
          @change="handleFileUpload($event)"
          accept=".xlsx,.xls"	
          ref="importFile"
          />
          <div class="invalid-feedback">{{ errors && errors?.asset_import_file }}</div>
          <div class="filesshow text-danger" id="file"></div>
          <p> <a :href="user_demo_file" target="_blank">Click here </a> for download Demo file </p>
        </div>
        <div class="submit-section">
        <button class="btn btn-primary submit-btn">Submit</button>
        </div>
        </Form>
        </div>
      </div>
      </div>
      </div>
      <!-- /Import asset Modal -->

    </div>
    
  </div>
  
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from 'axios';
import { notification } from "ant-design-vue";
export default {
  components: { Form,Field},
  data() {
    return {
      title: "Assets",
      path: "Dashboard",
      text: "Assets",
      text1: "Add Asset",
      assetClear : false,
      reloadData:false,
      deleteAssetData:false,
      closeDeleteModal:false,
      editData:null,
      searchFiter:null,
      hasCreatePermission:null,
      hasImportPermission:null,
      editfile:null,
	    errors:null,
    };
  },
  computed:{
    user_demo_file(){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}sample/asset.xlsx` 
    }
  },
  methods:{
    handleFileUpload(event){
      this.editfile = event.target.files[0];
    },
    onSubmit()
    {
    var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

    var form = new FormData();
    form.append('asset_import_file', this.editfile);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    };

    axios.post("/userasset/import", form,config).then(response => {

      this.editfile = null
      this.$refs.importFile.value = ''
      loader.hide();

      this.reloadAssetData()
      
      notification.open({
              message: response?.data?.message || 'Successfully Imported',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

      this.$refs.impassetclose.click();

    }).catch(error =>{
      
      loader.hide();
      if(error.response){
          
        var response = (error.response);
          
        notification.open({
          message: response?.data?.message || 'Error',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
          background: process.env.VUE_APP_WARNING_COLOR,
          },
        });
        
        }else{
        
        notification.open({
          message: 'Server Error',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
          background: process.env.VUE_APP_WARNING_COLOR,
          },
        });
      }
    })

    },
    setAssetFormClear()
    {
      this.assetClear = false;
      this.$nextTick(()=>{
        this.assetClear = true;
      })
    },
    reloadAssetData(){
      this.reloadData = false;
      this.$nextTick(()=>{
        this.reloadData = true;
      })
    },
    deleteAsset(){
      this.deleteAssetData = false;
      this.$nextTick(()=>{
        this.deleteAssetData = true;
      })
    },
    closeDeleteModelOn(){
      this.closeDeleteModal = false;
      this.$nextTick(()=>{
        this.closeDeleteModal = true;
      })
    },
    editAssetData(record = null)
    {
      this.editData = record;
    },
    searchAsset(searchData)
    {
      this.searchFiter = searchData;
    }

  },
  mounted()
  {
    this.$store.dispatch('userPermissions', { module: 'asset', action: 'create' }).then(response =>{
      this.hasCreatePermission=null;
      this.$nextTick(()=>{
        this.hasCreatePermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'asset', action: 'import' }).then(response =>{
      this.hasImportPermission=null;
      this.$nextTick(()=>{
        this.hasImportPermission = response;
      })
    })
  },
  setup() {
    const schema = Yup.object().shape({
      asset_import_file: Yup.string().required("File is required"),
    });
    return {
        schema
    }
  },
 
};
</script>