<template>
    <div class="tab-pane fade" id="userFaceRecognization" ref="formContainer">
        <div v-if="activeTab == 'userFaceRecognization'">
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex" style="padding-right: 10px;">
                        <label class="col-form-label ">Face Recognization Enabled :</label>
                        <div class="form-switch">
                            <input
                            @change="handleChangeEvent()"
                            v-model="is_face_recognization_enabled"
                            type="checkbox"
                            class="form-check-input"
                            id="customSwitch1"
                            value="true"
                            :checked="is_face_recognization_enabled"
                            />
                            <span class="text-danger" v-if="errors && errors?.is_face_recognization_enabled && errors?.is_face_recognization_enabled != null">{{ errors?.is_face_recognization_enabled[0]  }}</span>

                            <label class="form-check-label" for="customSwitch1"></label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    
                    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                        <div class="input-block mb-3">
                            <label class="col-form-label"
                                >Upload Face Image <span class="text-danger">*</span>
                            </label>
                            <Field
                                name="face_file"
                                type="file"
                                class="form-control"
                                :class="{ 'is-invalid': errors.face_file }"
                                @change="handleFileUpload($event)"
                                accept=".png,.jpg,'.jpeg"	
                                ref="importFile"
                            />
                            <div class="invalid-feedback">{{ errors.face_file }}</div>
                        </div>
                        <div class="submit-section">
                            <button class="btn btn-primary submit-btn">Submit</button>
                        </div>
                    </Form>
                </div>
                <div class="col-md-6 center-image-div">
                    <img v-if="image_path" :src="image_path" alt="Centered Image" class="center-image">
                    <p v-else  class="center-image" > image not uploaded! </p>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
export default {
    components: {
        Form,
        Field,
    },
    data(){
        return {
            face_file:null,
            image_path:null,
            is_face_recognization_enabled:null,
        }
    },
    watch:{
        employeeData(newVal){
            this.image_path = newVal?.face_file ?  process.env.VUE_APP_IMAGE_BASE_URL+newVal?.face_file : null;
            this.is_face_recognization_enabled = newVal?.is_face_recognization_enabled ? true : false;
        }
    },
    props:{
        employeeID: {
            type: Number,
            default: null,
            required:false
        },
        activeTab:{
            type: String,
            default: null,
            required:false 
        },
        employeeData: {
        type: Object,
        default: null,
        },
    },
    setup() {
    const schema = Yup.object().shape({
		face_file: Yup.string().required("The face file field must be required and must be .png or .jpg file extension."),
    });
	return {
      schema
	}
  },
  methods:{
    handleChangeEvent()
    {
        let is_face_recognization_enabled = (this?.is_face_recognization_enabled === true) ? 1 : this.is_face_recognization_enabled === false ? 0 : null

        if(is_face_recognization_enabled == null)
        {
            notification.open({
                message: 'Status is blank !',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
        }

        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        let loader = this.$loading.show({
            container: this.$refs.formContainer,
            canCancel: false
        });

        var user_id = this?.$route?.params?.user_id || '';

        axios.put("update-face-recognization-status/"+user_id, {'is_face_recognization_enabled' : is_face_recognization_enabled})
			.then((response) => {

                loader.hide();

                this.$emit('reload-data');

                notification.open({
                    message: response?.data?.message || 'Successful',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                });

                this.$emit('fetchEmp');

            }).catch(error => {

                this.is_face_recognization_enabled = !this?.is_face_recognization_enabled

                loader.hide();
			  
                var response = (error.response);
                
                if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
                    
                    localStorage.clear();
                    
                    notification.open({
                            message: 'Please Login',
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                        
                    this.$router.push({name: 'login'}).catch(error => {}) 
                    
                }else{

                    notification.open({
                            message: response?.data?.message,
                            placement: "topRight",
                            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                            style: {
                            background: process.env.VUE_APP_WARNING_COLOR,
                            },
                        });
                }

            })
    },
    handleFileUpload(event){
        this.face_file = event.target.files[0];
    },
    onSubmit()
	{
		var token = window.localStorage.getItem("token");
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
			container: this.$refs.formContainer,
			canCancel: false
		});

		var form = new FormData();

		form.append('face_file', this.face_file);

		const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };

        var user_id = this?.$route?.params?.user_id || '';


		axios.post("/employee/face-recognize-profile-upload/"+user_id, form,config)
          .then( (response) => {

			this.face_file = null
			this.$refs.importFile.value = ''

            this.image_path = response?.data?.employee?.face_file ?  process.env.VUE_APP_IMAGE_BASE_URL+response?.data?.employee?.face_file : null
				
			  loader.hide();
			  
			  notification.open({
					message: response?.data?.message || 'Successfully Imported',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});
			 this.face_file ={};

             this.face_file = null
			this.$refs.importFile.value = ''
          
					
		}).catch(error => {

			this.face_file = null
			this.$refs.importFile.value = ''
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response?.data?.message || 'Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
	},
  }
}
</script>
<style scoped>
.col-md-6.border-right {
    border-right: 1px solid #e5dcdc;
}
.center-image-div
{
    display: flex;
    justify-content: center; 
    align-items: center;  
    height: 40vh;
}

.center-image {
    width: 200px;
    height: 200px;
}
</style>