<template>
  <!-- Add Holiday Modal -->
  <div class="modal custom-modal fade" id="add_office" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Office</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="addofficeclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
		  <!--
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Company <span class="text-danger">*</span></label
              >
			  <Field
                    name="office_company_id"
                    as="select"
                    class="form-control"
					v-model="form.office_company_id"
					:class="{ 'is-invalid': errors.office_company_id }"
                  >
				  <option value="">Select Company</option>
				  <option v-for="singlecompany in companyList" :key="singlecompany.id" :value="singlecompany.id">{{ singlecompany.company_name }}</option>
				  <div class="invalid-feedback">{{ errors.office_company_id }}</div>
                  <div class="office_company_idshow text-danger" id="office_company_id"></div>
			  </Field>
			</div> -->
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Office Name <span class="text-danger">*</span></label
              >
              <Field
                    name="office_name"
                    type="text"
                    value=""
                    class="form-control"
					v-model="form.office_name"
					:class="{ 'is-invalid': errors.office_name }"
                  />
                  <div class="invalid-feedback">{{ errors.office_name }}</div>
                  <div class="office_nameshow text-danger" id="office_name"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Address <span class="text-danger">*</span></label
              >
              <Field
                    name="office_address"
                    type="text"
                    value=""
                    class="form-control"
					v-model="form.office_address"
					:class="{ 'is-invalid': errors.office_address }"
                  />
                  <div class="invalid-feedback">{{ errors.office_address }}</div>
                  <div class="office_addressshow text-danger" id="office_address"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >City <span class="text-danger">*</span></label
              >
              <Field
                    name="office_city"
                    type="text"
                    value=""
                    class="form-control"
					v-model="form.office_city"
					:class="{ 'is-invalid': errors.office_city }"
                  />
                  <div class="invalid-feedback">{{ errors.office_city }}</div>
                  <div class="office_cityshow text-danger" id="office_city"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >State <span class="text-danger">*</span></label
              >
              <Field
                    name="office_state"
                    type="text"
                    value=""
                    class="form-control"
					v-model="form.office_state"
					:class="{ 'is-invalid': errors.office_state }"
                  />
                  <div class="invalid-feedback">{{ errors.office_state }}</div>
                  <div class="office_stateshow text-danger" id="office_state"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Post Code <span class="text-danger">*</span></label
              >
              <Field
                    name="office_zip"
                    type="text"
                    value=""
                    class="form-control"
					v-model="form.office_zip"
					:class="{ 'is-invalid': errors.office_zip }"
                  />
                  <div class="invalid-feedback">{{ errors.office_zip }}</div>
                  <div class="office_zipshow text-danger" id="office_zip"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Lattitude</label
              >
              <Field
                    name="office_lattitude"
                    type="text"
                    value=""
                    class="form-control"
					v-model="form.office_lattitude"
					:class="{ 'is-invalid': errors.office_lattitude }"
                  />
                  <div class="invalid-feedback">{{ errors.office_lattitude }}</div>
                  <div class="office_lattitudeshow text-danger" id="office_lattitude"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Longitude</label
              >
              <Field
                    name="office_longitude"
                    type="text"
                    value=""
                    class="form-control"
					v-model="form.office_longitude"
					:class="{ 'is-invalid': errors.office_longitude }"
                  />
                  <div class="invalid-feedback">{{ errors.office_longitude }}</div>
                  <div class="office_longitudeshow text-danger" id="office_longitude"></div>
            </div>
			<div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Holiday Modal -->
  <!-- Edit Holiday Modal -->
  <div class="modal custom-modal fade" id="edit_office" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Office</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="editofficeclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="onUpdate" :validation-schema="editschema" v-slot="{ errors }">
		  <!--
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Company <span class="text-danger">*</span></label
              >
			  <Field
                    name="office_company_id"
                    as="select"
                    class="form-control"
					v-model="editform.office_company_id"
					:class="{ 'is-invalid': errors.office_company_id }"
                  >
				  <option value="">Select Company</option>
				  <option v-for="singlecompany in companyList" :key="singlecompany.id" :value="singlecompany.id">{{ singlecompany.company_name }}</option>
				  <div class="invalid-feedback">{{ errors.office_company_id }}</div>
                  <div class="office_company_idshow text-danger" id="office_company_id"></div>
			  </Field>
			</div> -->
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Office Name <span class="text-danger">*</span></label
              >
               <Field
                    name="office_name"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.office_name"
					:class="{ 'is-invalid': errors.office_name }"
                  />
                  <div class="invalid-feedback">{{ errors.office_name }}</div>
                  <div class="office_nameshow text-danger" id="office_name"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Address <span class="text-danger">*</span></label
              >
               <Field
                    name="office_address"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.office_address"
					:class="{ 'is-invalid': errors.office_address }"
                  />
                  <div class="invalid-feedback">{{ errors.office_address }}</div>
                  <div class="office_addressshow text-danger" id="office_address"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >City <span class="text-danger">*</span></label
              >
              <Field
                    name="office_city"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.office_city"
					:class="{ 'is-invalid': errors.office_city }"
                  />
                  <div class="invalid-feedback">{{ errors.office_city }}</div>
                  <div class="office_cityshow text-danger" id="office_city"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >State <span class="text-danger">*</span></label
              >
              <Field
                    name="office_state"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.office_state"
					:class="{ 'is-invalid': errors.office_state }"
                  />
                  <div class="invalid-feedback">{{ errors.office_state }}</div>
                  <div class="office_stateshow text-danger" id="office_state"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Post Code <span class="text-danger">*</span></label
              >
              <Field
                    name="office_zip"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.office_zip"
					:class="{ 'is-invalid': errors.office_zip }"
                  />
                  <div class="invalid-feedback">{{ errors.office_zip }}</div>
                  <div class="office_zipshow text-danger" id="office_zip"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Lattitude</label
              >
              <Field
                    name="office_lattitude"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.office_lattitude"
					:class="{ 'is-invalid': errors.office_lattitude }"
                  />
                  <div class="invalid-feedback">{{ errors.office_lattitude }}</div>
                  <div class="office_lattitudeshow text-danger" id="office_lattitude"></div>
            </div>
			<div class="input-block mb-3">
              <label class="col-form-label"
                >Longitude</label
              >
              <Field
                    name="office_longitude"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.office_longitude"
					:class="{ 'is-invalid': errors.office_longitude }"
                  />
                  <div class="invalid-feedback">{{ errors.office_longitude }}</div>
                  <div class="office_longitudeshow text-danger" id="office_longitude"></div>
            </div>
            <!--<div class="input-block mb-3">
              <label class="col-form-label"
                >Holiday Date <span class="text-danger">*</span>
				</label
              >
              <div class="cal-icon">
                <datepicker
                  v-model="editform.holiday_date"
                  placeholder="01-01-2023"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
            </div>
			
			<div class="input-block mb-3">
              <label class="col-form-label"
                >End Date <span class="text-danger">*</span>
				</label
              >
              <div class="cal-icon">
                <datepicker
                  v-model="editform.end_date"
                  placeholder="01-01-2023"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
            </div>-->
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Save</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Holiday Modal -->

  <!-- Delete Holiday Modal -->
  <div class="modal custom-modal fade" id="delete_office" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Office</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn" @click="deleteOffice"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn" ref="deleteofficeclose"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Holiday Modal -->
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import moment from 'moment';

import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
   emits: ["createOffice","updateOffice","deleteOffice"],
  components: {
    Form,
    Field,
  },
  props: {
    form: [Array, Object], 
    //companyList: [Array, Object], 
    editform: [Array, Object],
	office_id: [Number,String]
  },
  data() {
    return {
      startdateone: currentDate,
      dateFormat: 'yyyy-MM-dd',
      startdate: currentDateOne,
    };
  },
  methods: {
	deleteOffice(){
	  console.log('Delete Called');
	  this.$emit("delete-office",this.office_id);
	},
	onSubmit(values) {
		
		//values.holiday_date = moment(this.form.holiday_date).format('YYYY-MM-DD');
		//values.end_date = moment(this.form.holiday_date).format('YYYY-MM-DD');
		
		this.$emit("create-office",values);
	},
	onUpdate(values){
		console.log('Update Called!!');
		this.$emit("update-office",this.editform);
	},
	closeDialog(){
		this.$refs.addofficeclose.click();
		this.$refs.editofficeclose.click();
		this.$refs.deleteofficeclose.click();
	},
  },
 
  setup() {
    const schema = Yup.object().shape({
      //office_company_id: Yup.string().required("Company is required"),
      office_name: Yup.string().required("Office Name is required"),
      office_address: Yup.string().required("Office Address is required"),
      office_city: Yup.string().required("City is required"),
      office_state: Yup.string().required("State is required"),
      office_zip: Yup.string()
    .required("Post Code is required")
    .matches(/^\d+$/, "Post Code must contain only digits")
    .min(4, "Post Code must be at least 4 digits long")
    .max(6, "Post Code must be at most 6 digits long"),
     // office_lattitude: Yup.string().required("Lattitude is required"),
	 // office_longitude: Yup.string().required("Longitue is required"),
    });
	
	const editschema = Yup.object().shape({
	  //office_company_id: Yup.string().required("Company is required"),
      office_name: Yup.string().required("Office Name is required"),
      office_address: Yup.string().required("Office Address is required"),
      office_city: Yup.string().required("City is required"),
	  office_state: Yup.string().required("State is required"),
	  office_zip: Yup.string()
    .required("Post Code is required")
    .matches(/^\d+$/, "Post Code must contain only digits")
    .min(4, "Post Code must be at least 4 digits long")
    .max(6, "Post Code must be at most 6 digits long"),
	 // office_lattitude: Yup.string().required("Lattitude is required"),
	 // office_longitude: Yup.string().required("Longitue is required"),
	});
	
    return {
      schema,
	  editschema
    };
  },
};
</script>
