<template>
  <div class="chat-content-wrap">
    <div class="chat-wrap-inner">
      <div class="chat-box">
        <div class="chats">
          <div class="chat chat-right">
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>Hello. What can I do for you?</p>
                  <span class="chat-time">8:30 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="chat-line">
            <span class="chat-date">October 8th, 2023</span>
          </div>
          <div class="chat chat-left">
            <div class="chat-avatar">
              <router-link to="profile" class="avatar">
                <img src="@/assets/img/profiles/avatar-05.jpg" alt="User Image" />
              </router-link>
            </div>
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>I'm just looking around.</p>
                  <p>Will you tell me something about yourself?</p>
                  <span class="chat-time">8:35 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>Are you there? That time!</p>
                  <span class="chat-time">8:40 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="chat chat-right">
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>Where?</p>
                  <span class="chat-time">8:35 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>
                    OK, my name is Limingqiang. I like singing, playing basketballand so
                    on.
                  </p>
                  <span class="chat-time">8:42 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="chat chat-left">
            <div class="chat-avatar">
              <router-link to="profile" class="avatar">
                <img src="@/assets/img/profiles/avatar-05.jpg" alt="User Image" />
              </router-link>
            </div>
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>You wait for notice.</p>
                  <span class="chat-time">8:30 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>Consectetuorem ipsum dolor sit?</p>
                  <span class="chat-time">8:50 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>OK?</p>
                  <span class="chat-time">8:55 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="chat-bubble">
                <div class="chat-content img-content">
                  <div class="chat-img-group clearfix">
                    <p>Uploaded 3 Images</p>
                    <a class="chat-img-attach" href="javascript:;">
                      <img
                        width="182"
                        height="137"
                        src="@/assets/img/placeholder.jpg"
                        alt="Placeholder Image"
                      />
                      <div class="chat-placeholder">
                        <div class="chat-img-name">placeholder.jpg</div>
                        <div class="chat-file-desc">842 KB</div>
                      </div>
                    </a>
                    <a class="chat-img-attach" href="javascript:;">
                      <img
                        width="182"
                        height="137"
                        src="@/assets/img/placeholder.jpg"
                        alt="Placeholder Image"
                      />
                      <div class="chat-placeholder">
                        <div class="chat-img-name">842 KB</div>
                      </div>
                    </a>
                    <a class="chat-img-attach" href="javascript:;">
                      <img
                        width="182"
                        height="137"
                        src="@/assets/img/placeholder.jpg"
                        alt="Placeholder Image"
                      />
                      <div class="chat-placeholder">
                        <div class="chat-img-name">placeholder.jpg</div>
                        <div class="chat-file-desc">842 KB</div>
                      </div>
                    </a>
                  </div>
                  <span class="chat-time">9:00 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="chat chat-right">
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>OK!</p>
                  <span class="chat-time">9:00 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="chat chat-left">
            <div class="chat-avatar">
              <router-link to="profile" class="avatar">
                <img src="@/assets/img/profiles/avatar-05.jpg" alt="User Image" />
              </router-link>
            </div>
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>Uploaded 3 files</p>
                  <ul class="attach-list">
                    <li>
                      <i class="fa fa-file me-1"></i>
                      <a href="javascript:;">example.avi</a>
                    </li>
                    <li>
                      <i class="fa fa-file me-1"></i>
                      <a href="javascript:;">activity.psd</a>
                    </li>
                    <li>
                      <i class="fa fa-file me-1"></i>
                      <a href="javascript:;">example.psd</a>
                    </li>
                  </ul>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>Consectetuorem ipsum dolor sit?</p>
                  <span class="chat-time">8:50 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>OK?</p>
                  <span class="chat-time">8:55 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="chat chat-right">
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content img-content">
                  <div class="chat-img-group clearfix">
                    <p>Uploaded 6 Images</p>
                    <a class="chat-img-attach" href="javascript:;">
                      <img
                        width="182"
                        height="137"
                        src="@/assets/img/placeholder.jpg"
                        alt="Placeholder Image"
                      />
                      <div class="chat-placeholder">
                        <div class="chat-img-name">placeholder.jpg</div>
                        <div class="chat-file-desc">842 KB</div>
                      </div>
                    </a>
                    <a class="chat-img-attach" href="javascript:;">
                      <img
                        width="182"
                        height="137"
                        src="@/assets/img/placeholder.jpg"
                        alt="Placeholder Image"
                      />
                      <div class="chat-placeholder">
                        <div class="chat-img-name">842 KB</div>
                      </div>
                    </a>
                    <a class="chat-img-attach" href="javascript:;">
                      <img
                        width="182"
                        height="137"
                        src="@/assets/img/placeholder.jpg"
                        alt="Placeholder Image"
                      />
                      <div class="chat-placeholder">
                        <div class="chat-img-name">placeholder.jpg</div>
                        <div class="chat-file-desc">842 KB</div>
                      </div>
                    </a>
                    <a class="chat-img-attach" href="javascript:;">
                      <img
                        width="182"
                        height="137"
                        src="@/assets/img/placeholder.jpg"
                        alt="Placeholder Image"
                      />
                      <div class="chat-placeholder">
                        <div class="chat-img-name">placeholder.jpg</div>
                        <div class="chat-file-desc">842 KB</div>
                      </div>
                    </a>
                    <a class="chat-img-attach" href="javascript:;">
                      <img
                        width="182"
                        height="137"
                        src="@/assets/img/placeholder.jpg"
                        alt="Placeholder Image"
                      />
                      <div class="chat-placeholder">
                        <div class="chat-img-name">placeholder.jpg</div>
                        <div class="chat-file-desc">842 KB</div>
                      </div>
                    </a>
                    <a class="chat-img-attach" href="javascript:;">
                      <img
                        width="182"
                        height="137"
                        src="@/assets/img/placeholder.jpg"
                        alt="Placeholder Image"
                      />
                      <div class="chat-placeholder">
                        <div class="chat-img-name">placeholder.jpg</div>
                        <div class="chat-file-desc">842 KB</div>
                      </div>
                    </a>
                  </div>
                  <span class="chat-time">9:00 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="chat chat-left">
            <div class="chat-avatar">
              <router-link to="profile" class="avatar">
                <img src="@/assets/img/profiles/avatar-05.jpg" alt="User Image" />
              </router-link>
            </div>
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content">
                  <ul class="attach-list">
                    <li class="pdf-file">
                      <i class="fa-regular fa-file-pdf me-1"></i>
                      <a href="javascript:;">Document_2016.pdf</a>
                    </li>
                  </ul>
                  <span class="chat-time">9:00 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="chat chat-right">
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content">
                  <ul class="attach-list">
                    <li class="pdf-file">
                      <i class="fa-regular fa-file-pdf me-1"></i>
                      <a href="javascript:;">Document_2016.pdf</a>
                    </li>
                  </ul>
                  <span class="chat-time">9:00 am</span>
                </div>
                <div class="chat-action-btns">
                  <ul>
                    <li>
                      <a href="javascript:;" class="share-msg" title="Share"
                        ><i class="fa-solid fa-share-nodes"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="edit-msg"
                        ><i class="fa-solid fa-pencil"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="del-msg"
                        ><i class="fa-regular fa-trash-can"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="chat chat-left">
            <div class="chat-avatar">
              <router-link to="profile" class="avatar">
                <img src="@/assets/img/profiles/avatar-05.jpg" alt="User Image" />
              </router-link>
            </div>
            <div class="chat-body">
              <div class="chat-bubble">
                <div class="chat-content">
                  <p>Typing ...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
