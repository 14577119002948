<template>
  <div class="row">
    <div class="col-xxl-6">
      <div class="card">
        <div class="card-header align-items-center d-flex">
          <h4 class="card-title mb-0">Rounded Pill Badges with soft effect</h4>
        </div>
        <!-- end card header -->

        <div class="card-body">
          <p class="text-muted">
            Use the <code>rounded-pill badge-soft-</code> class with the below-mentioned
            variation to create a badge more rounded with a soft background.
          </p>
          <div class="d-flex flex-wrap gap-2">
            <span class="badge rounded-pill badge-soft-primary">Primary</span>
            <span class="badge rounded-pill badge-soft-secondary">Secondary</span>
            <span class="badge rounded-pill badge-soft-success">Success</span>
            <span class="badge rounded-pill badge-soft-info">Info</span>
            <span class="badge rounded-pill badge-soft-warning">Warning</span>
            <span class="badge rounded-pill badge-soft-danger">Danger</span>
            <span class="badge rounded-pill badge-soft-dark">Dark</span>
            <span class="badge rounded-pill badge-soft-light text-dark">Light</span>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>

    <div class="col-xxl-6">
      <div class="card">
        <div class="card-header align-items-center d-flex">
          <h4 class="card-title mb-0">Soft Border Badges</h4>
        </div>
        <!-- end card header -->

        <div class="card-body">
          <p class="text-muted">
            Use the <code>badge-border</code> and <code>badge-soft-</code> with below
            mentioned modifier classes to make badges with border & soft backgorund.
          </p>
          <div class="d-flex flex-wrap gap-2">
            <span class="badge badge-soft-primary badge-border">Primary</span>
            <span class="badge badge-soft-secondary badge-border">Secondary</span>
            <span class="badge badge-soft-success badge-border">Success</span>
            <span class="badge badge-soft-danger badge-border">Danger</span>
            <span class="badge badge-soft-warning badge-border">Warning</span>
            <span class="badge badge-soft-info badge-border">Info</span>
            <span class="badge badge-soft-dark badge-border">Dark</span>
            <span class="badge badge-soft-light badge-border text-dark">Light</span>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
  </div>
  <div class="row">
    <div class="col-xxl-6">
      <div class="card">
        <div class="card-header align-items-center d-flex">
          <h4 class="card-title mb-0">Outline Pill Badges</h4>
        </div>
        <!-- end card header -->

        <div class="card-body">
          <p class="text-muted">
            Use the <code>rounded-pill badge-outline-</code> class with the
            below-mentioned variation to create a outline Pill badge.
          </p>
          <div class="d-flex flex-wrap gap-2">
            <span class="badge rounded-pill badge-outline-primary">Primary</span>
            <span class="badge rounded-pill badge-outline-secondary">Secondary</span>
            <span class="badge rounded-pill badge-outline-success">Success</span>
            <span class="badge rounded-pill badge-outline-info">Info</span>
            <span class="badge rounded-pill badge-outline-warning">Warning</span>
            <span class="badge rounded-pill badge-outline-danger">Danger</span>
            <span class="badge rounded-pill badge-outline-dark">Dark</span>
            <span class="badge rounded-pill badge-outline-light text-dark">Light</span>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>

    <div class="col-xxl-6">
      <div class="card">
        <div class="card-header align-items-center d-flex">
          <h4 class="card-title mb-0">Label Badges</h4>
        </div>
        <!-- end card header -->

        <div class="card-body">
          <p class="text-muted">
            Use the <code>badge-label</code> class to create a badge with the label.
          </p>
          <div class="d-flex flex-wrap gap-2">
            <span class="badge badge-label bg-primary"
              ><i class="mdi mdi-circle-medium"></i> Primary</span
            >
            <span class="badge badge-label bg-secondary"
              ><i class="mdi mdi-circle-medium"></i> Secondary</span
            >
            <span class="badge badge-label bg-success"
              ><i class="mdi mdi-circle-medium"></i> Success</span
            >
            <span class="badge badge-label bg-danger"
              ><i class="mdi mdi-circle-medium"></i> Danger</span
            >
            <span class="badge badge-label bg-warning"
              ><i class="mdi mdi-circle-medium"></i> Warning</span
            >
            <span class="badge badge-label bg-info"
              ><i class="mdi mdi-circle-medium"></i> Info</span
            >
            <span class="badge badge-label bg-dark"
              ><i class="mdi mdi-circle-medium"></i> Dark</span
            >
            <span class="badge badge-label bg-light text-dark"
              ><i class="mdi mdi-circle-medium"></i> Light</span
            >
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
  </div>
</template>
