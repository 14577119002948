<template>
  <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <!--<h4>Expense Approval Settings</h4> -->
	<Form @submit="updateVisibilitySettings">		
	  <!--
	  <div class="input-block mb-3">
		<label class="col-form-label"
		  >Cron Key <span class="text-danger">*</span></label
		>
		<input type="text" class="form-control" value="" />
	  </div> -->
	  <div class="input-block mb-3">
		<label class="col-form-label"
		  >Holiday Visible <span class="text-danger">*</span></label
		><br />
		<label class="switch">
		  <input type="hidden" name="holiday_visible" v-model="form.holiday_visible" :true-value="1" :false-value="0" />
		  <input type="checkbox" name="holiday_visible" v-model="form.holiday_visible" :true-value="1" :false-value="0" />
		  <span></span>
		</label>
	  </div>
	  <div class="input-block mb-3">
		<label class="col-form-label"
		  >Birthday Visible <span class="text-danger">*</span></label
		><br />
		<label class="switch">
		  <input type="hidden" name="birthday_visible" v-model="form.birthday_visible" :true-value="1" :false-value="0" />
		  <input type="checkbox"  name="birthday_visible" v-model="form.birthday_visible" :true-value="1" :false-value="0" />
		  <span></span>
		</label>
	  </div>
	  <div class="submit-section">
		<button class="btn btn-primary submit-btn">Save Changes</button>
	  </div>
	</Form>
  </div>
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import { Form } from "vee-validate";
export default {
  components: {
    Form,
  },
  data() {
    return {
      select2: null,
      Approver: [
        "Select Approvers",
        "CEO",
        "Direct Manager",
        "Development Manager",
        "Finance",
      ],
      Approvers1: [
        "Select Approvers",
        "CEO",
        "Direct Manager",
        "Development Manager",
        "Finance",
      ],
      Approvers2: [
        "Select Approvers",
        "CEO",
        "Direct Manager",
        "Development Manager",
        "Finance",
      ],
	  form:{holiday_visible:0,birthday_visible:0}
    };
   },
   methods: {
	updateVisibilitySettings(){
		console.log('called update');
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.post("/globalsettings/updatevisibilitysettings", this.form)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

				
			 this.loadCommonData([]);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	loadCommonData(params){
		console.log('common data loading called..');
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/globalsettings/visibilitysettings", params)
			.then((response) => {
				
				if(response.data.data.length){
					
					for(let i=0;i<response.data.data.length;i++){
						this.form[response.data.data[i].global_settings_key] = response.data.data[i].global_settings_value;
					}
				}
				
				console.log(this.form);
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	}
   },
   mounted() {
	this.loadCommonData([]);
	
  },
};
</script>
