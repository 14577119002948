<template>
  <!-- Bank Statutory Tab -->
  <div class="tab-pane fade" id="bank_statutory">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Basic Salary Information</h3>
          <div class="row">
            <div class="col-sm-3">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Salary Type <span class="text-danger">*</span></label
                >
                <select  class="form-select form-select-lg" v-model="banksalary">
                  <option v-for="wages in wagesData" :key="'wages_option_'+wages.wages_id" :value="wages.wages_id">{{ wages?.wages_name }}</option>
                </select>
               <span class="text-danger">{{ errors?.banksalary && errors?.banksalary[0] && errors?.banksalary[0] != null  ? errors?.banksalary[0] : '' }}</span>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Salary amount <small class="text-muted">per month</small></label
                >
                <div class="input-group">
                  <span class="input-group-text">$</span>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Type your salary amount"
                    v-model="salary_amount"
                  />
                </div>
                <span class="text-danger">{{ errors?.salary_amount && errors?.salary_amount[0] && errors?.salary_amount[0] != null  ? errors?.salary_amount[0] : '' }}</span>

              </div>
            </div>
            <div class="col-sm-3">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Pay Frequency
                  </label
                >
                <div class="input-group">
                  <select  class="form-select form-select-lg" v-model="paid_on">
                    <option value="0">
                      Weekly
                    </option>
                    <option value="1">
                      Bi-Weekly
                    </option>
                    <option value="2">
                      Monthly
                    </option>
                  </select>
                </div>
                <span class="text-danger">{{ errors?.paid_on && errors?.paid_on[0] && errors?.paid_on[0] != null  ? errors?.paid_on[0] : '' }}</span>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="input-block mb-3">
                <label class="col-form-label">Payment Mode</label>

                <select  class="form-select form-select-lg" v-model="payment_type">
                  <option v-for="bnkpymnt in bankPaymentType" :key="bnkpymnt.payment_type_id" :value="bnkpymnt.payment_type_id">{{ bnkpymnt?.payment_type_name }}</option>
                </select>
                <span class="text-danger">{{ errors?.payment_type && errors?.payment_type[0] && errors?.payment_type[0] != null  ? errors?.payment_type[0] : '' }}</span>

                <!-- <vue-select
                  :options="bankPaymentType"
                  label="payment_type_name"
                  placeholder="Select"
                  id="bankpayment"
                /> -->
              </div>
            </div>
          </div>
          <hr />
          <!-- <h3 class="card-title">PF Information</h3>
          <div class="row">
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label">PF contribution</label>
                <vue-select :options="Bankpf" placeholder="Select" id="bankpf" />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >PF No. <span class="text-danger">*</span></label
                >
                <vue-select :options="Bankpfno" placeholder="Select" id="bankpfno" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label">Employee PF rate</label>
                <vue-select :options="Bankpfrate" placeholder="Select" id="bankpfrate" />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Additional rate <span class="text-danger">*</span></label
                >
                <vue-select :options="Bankrate" placeholder="Select" id="bankrate" />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label">Total rate</label>
                <input type="text" class="form-control" placeholder="N/A" value="11%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label">Employee PF rate</label>
                <vue-select
                  :options="Employeepfrate"
                  placeholder="Select"
                  id="employeepfrate"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Additional rate <span class="text-danger">*</span></label
                >
                <vue-select
                  :options="Employeerate"
                  placeholder="Select"
                  id="employeerate"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label">Total rate</label>
                <input type="text" class="form-control" placeholder="N/A" value="11%" />
              </div>
            </div>
          </div>

          <hr />
          <h3 class="card-title">ESI Information</h3>
          <div class="row">
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label">ESI contribution</label>

                <vue-select
                  :options="EmployeeEsi"
                  placeholder="Select"
                  id="employeeEsi"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >ESI No. <span class="text-danger">*</span></label
                >
                <vue-select
                  :options="EmployeeEsino"
                  placeholder="Select"
                  id="employeeEsino"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label">Employee ESI rate</label>
                <vue-select
                  :options="EmployeeEsirate"
                  placeholder="Select"
                  id="employeeEsirate"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Additional rate <span class="text-danger">*</span></label
                >
                <vue-select
                  :options="Employeeadditionalrate"
                  placeholder="Select"
                  id="employeeadditionalrate"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="input-block mb-3">
                <label class="col-form-label">Total rate</label>
                <input type="text" class="form-control" placeholder="N/A" value="11%" />
              </div>
            </div>
          </div> -->

          <div class="submit-section">
            <button class="btn btn-primary submit-btn" type="submit" @click="saveSalaryDetails">Save</button>
          </div>
      </div>
    </div>
  </div>
  <!-- /Bank Statutory Tab -->
</template>
<script>
import { mapActions } from 'vuex';
import { notification } from "ant-design-vue";
export default {
  data() {
    return {
      select2: null,
      SelectBanksalary: "Select salary basis type",
      SelectBankpayment: "Select payment type",
      SelectBankpf: "Select PF contribution",
      SelectBankpfno: "Select PF contribution",
      SelectBankpfrate: "Select PF contribution",
      SelectBankrate: "Select additional rate",
      SelectEmployeepfrate: "Select PF contribution",
      SelectEmployeerate: "Select additional rate",
      SelectEmployeeEsi: "Select ESI contribution",
      SelectEmployeeEsino: "Select ESI contribution",
      SelectEmployeeEsirate: "Select ESI contribution",
      SelectEmployeeadditionalrate: "Select additional rate",
      Banksalary: ["Hourly", "Daily", "Weekly", "Monthly"],
      Bankpayment: ["Select payment type", "Bank transfer", "Check", "Cash"],
      Bankpf: ["Select PF contribution", "Yes", "No"],
      Bankpfno: ["Select PF contribution", "Yes", "No"],
      Bankpfrate: ["Select PF contribution", "Yes", "No"],
      Bankrate: [
        "Select additional rate",
        "0%",
        "1%",
        "2%",
        "3%",
        "4%",
        "5%",
        "6%",
        "7%",
        "8%",
        "9%",
        "10%",
      ],
      Employeepfrate: ["Select PF contribution", "Yes", "No"],
      Employeerate: [
        "Select additional rate",
        "0%",
        "1%",
        "2%",
        "3%",
        "4%",
        "5%",
        "6%",
        "7%",
        "8%",
        "9%",
        "10%",
      ],
      EmployeeEsi: ["Select ESI contribution", "Yes", "No"],
      EmployeeEsino: ["Select ESI contribution", "Yes", "No"],
      EmployeeEsirate: ["Select ESI contribution", "Yes", "No"],
      Employeeadditionalrate: [
        "Select additional rate",
        "0%",
        "1%",
        "2%",
        "3%",
        "4%",
        "5%",
        "6%",
        "7%",
        "8%",
        "9%",
        "10%",
      ],
      bankPaymentType:null,
      payment_type:null,
      banksalary:null,
      salary_amount:null,
      user_id:null,
      paid_on:null,
      errors:{
        'banksalary': [],
        'payment_type':[],
        'salary_amount':[]
      },
      wagesData:[]
    };
  },
  props:{
    employeeData: {
      type: Object,
      default: null,
    },
  },
  name: "profilemodal",
  watch:{
    employeeData(newVal)
    {
      this.errors = null
      this.payment_type = newVal?.payment_type;
      this.banksalary = newVal?.salary_basis;
      this.salary_amount = newVal?.salary_amount;
      this.user_id = newVal?.id
      this.paid_on = newVal?.paid_on
    }
  },
  methods:{
    ...mapActions(['fetchAllWages','fetchPaymentType','updateEmployeeSalaryDetails']),
    async saveSalaryDetails(){

      this.errors = {
        'banksalary': [],
        'payment_type':[],
        'salary_amount':[],
        'paid_on':[]
      }

      let error = 0;

      if(this.banksalary == null)
      {
        this.errors.banksalary[0] = 'Please fill up this basis salary field';
        error = 1; 
      }

      if(this.payment_type == null)
      {
        this.errors.payment_type[0] = 'Please fill up this payment type field';

        error = 1; 
      }

      if(this.salary_amount != null && this.salary_amount < 0)
      {
        this.errors.salary_amount[0] = 'Negative salary amount not allowed';

        error = 1; 
      }

      if(this.paid_on != null && this.paid_on < 0)
      {
        this.errors.paid_on[0] = 'Paid on salary amount not allowed';

        error = 1; 
      }

      if(error)
      {
        notification.open({
          message: 'Please fillup mandatory field.',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
            background: process.env.VUE_APP_WARNING_COLOR,
          },
        });

        return false;
      }

      const data = {
        payment_type:this.payment_type,
        salary_basis:this.banksalary,
        salary_amount:this.salary_amount,
        user_id:this.employeeData?.id,
        paid_on:this.paid_on
      }
      
      const response = await this.updateEmployeeSalaryDetails(data);

      if(response?.status == 200)
      {
        notification.open({
          message: response?.data?.message,
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
            background: process.env.VUE_APP_SUCCESS_COLOR,
          },
        });
      }
      else
      {
        notification.open({
          message: 'Something went wrong, Please try again later',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
            background: process.env.VUE_APP_WARNING_COLOR,
          },
        });
      }
     
    }
  },
  async created(){
    this.wagesData = await this.fetchAllWages();
    this.bankPaymentType = await this.fetchPaymentType();
    
  }
};
</script>
