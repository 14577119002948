<template>
  <div class="col-xl-6">
    <div class="card">
      <div class="card-body">
        <h4 class="header-title mb-4">Tabs Vertical Right</h4>

        <div class="row">
          <div class="col-sm-9">
            <div class="tab-content" id="v-pills-tabContent">
              <div
                class="tab-pane fade active show"
                id="v-pills-home2"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab2"
              >
                <p>
                  Lorem ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups. Lorem
                  ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups. Lorem
                  ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups. Lorem
                  ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups.
                </p>
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-profile2"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab2"
              >
                <p>
                  Lorem ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups. Lorem
                  ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups. Lorem
                  ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups.Lorem
                  ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups.
                </p>
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-messages2"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab2"
              >
                <p>
                  Lorem ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups. Lorem
                  ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups.Lorem
                  ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups.
                </p>
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-settings2"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab2"
              >
                <p>
                  Lorem ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups. Lorem
                  ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups.Lorem
                  ipsum is placeholder text commonly used in the graphic, print, and
                  publishing industries for previewing layouts and visual mockups.
                </p>
              </div>
            </div>
          </div>
          <!-- end col -->
          <div class="col-sm-3">
            <div
              class="nav flex-column nav-pills nav-pills-tab"
              id="v-pills-tab2"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                class="nav-link active show mb-1"
                id="v-pills-home-tab2"
                data-bs-toggle="pill"
                href="#v-pills-home2"
                role="tab"
                aria-controls="v-pills-home2"
                aria-selected="true"
              >
                Home</a
              >
              <a
                class="nav-link mb-1"
                id="v-pills-profile-tab2"
                data-bs-toggle="pill"
                href="#v-pills-profile2"
                role="tab"
                aria-controls="v-pills-profile2"
                aria-selected="false"
              >
                Profile</a
              >
              <a
                class="nav-link mb-1"
                id="v-pills-messages-tab2"
                data-bs-toggle="pill"
                href="#v-pills-messages2"
                role="tab"
                aria-controls="v-pills-messages2"
                aria-selected="false"
              >
                Messages</a
              >
              <a
                class="nav-link mb-1"
                id="v-pills-settings-tab2"
                data-bs-toggle="pill"
                href="#v-pills-settings2"
                role="tab"
                aria-controls="v-pills-settings2"
                aria-selected="false"
              >
                Settings</a
              >
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row-->
      </div>
    </div>
    <!-- end card-->
  </div>
</template>
