<template>
  <!-- Search Filter -->
  <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
  <div class="row filter-row">
    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
        <input
          type="text"
          class="form-control floating"
          v-model="searchproject.projectName"
          @focus="isFocused = true"
          @blur="isFocused = searchproject.projectName !== ''"
        />
        <label class="focus-label">Project Name</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus" :class="{ focused: isFocusedOne }">
        <input
          type="text"
          class="form-control floating"
          v-model="searchproject.employeeName"
          @focus="isFocusedOne = true"
          @blur="isFocusedOne = searchproject.employeeName !== ''"
        />
        <label class="focus-label">Employee Name</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-2" v-if="isProjectListRoute">
      <!-- <div class="input-block mb-3 form-focus select-focus" v-if="isProjectRoute">
        <a-select
        v-model:value="value"
        show-search
        placeholder="Select a Designation"
        style="width: 100%;"
        :options="optionsEmpDesignation"
        @change="handleChange"
      >
      </a-select> -->
        <!-- <vue-select
          :options="designations_list"
          id="projectsDesignation"
		  placeholder="Select Designation"
		  v-model="searchproject.designation"
        />
        <label class="focus-label">Designation</label> -->
		
      <!-- </div> -->
      <div class="input-block mb-3 form-focus select-focus" v-if="isProjectListRoute">
        <vue-select :options="projectsRole" id="projectsRole" placeholder="Select Role" />
        <label class="focus-label">Role</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <!--<a href="javascript:;" class="btn btn-success w-100"> Search </a> -->
	  <button class="btn btn-success w-100 submit-btn">Search</button>
    </div>
    <div class="col-sm-6 col-md-2">
      <!--<a href="javascript:;" class="btn btn-success w-100"> Search </a> -->
	  <button @click.prevent="resetFilter" class="btn btn-danger w-100 submit-btn">Clear</button>
    </div>
  </div>
  <!-- Search Filter -->
  </Form>
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

export default {
  emits: ["searchProjectf"],
  components: {
    Form,
    Field,
  },
  props: {
    designations_list: [Array, Object],
    searchproject: [Array, Object]
  },
  data() {
    return {
      select2: null,
      projectsDesignation: [
        "Select Designation",
        "Web Developer",
        "Web Designer",
        "Android Developer",
        "Ios Developer",
      ],
      projectsRole: [
        "Select Role",
        "Web Developer",
        "Web Designer",
        "Android Developer",
        "Ios Developer",
      ],
      employeeName: "",
      projectName: "",
      isFocused: false,
      isFocusedOne: false,
      optionsEmpDesignation:[],
      value:null
    };
  },
  watch:{
    designations_list(newVal)
    {
      if(newVal && newVal?.length)
      {
        this.optionsEmpDesignation = newVal.map(designation => {
            return {
                label: designation.name,
                value: designation.id
            };
          });

          this.optionsEmpDesignation.unshift({label: "All", value: ''})
      }
    }
  },
  methods: {
  resetFilter()
  {
    this.$emit("search-projectf",{ projectName: "", employeeName: "", designation:"",reset : true});
  },
	onSubmit(values) {
		this.$emit("search-projectf",this.searchproject);
	},
  // handleChange(value)
  //   {
  //       this.$nextTick(()=>{
  //       this.searchproject.designation = value;
  //     })
  //   }
  },	
  computed: {
    isProjectRoute() {
      return this.$route.path === "/projects";
    },
    isProjectListRoute() {
      return this.$route.path === "/project-list";
    },
  },
  setup() {
    const schema = Yup.object().shape({});
	
	return {
      schema
	};
  },
};
</script>
