<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <!--<breadcrumb :title="title" :path="path" :text="text" /> -->
        <!-- /Page Header -->
		
		<reports-header :title="title" :path="path" :text="text" :text1="text1" :search_data="searchform" :columns="originalColumn" />
		
		<!-- Search Filter -->
        <!--<project-reports-filter /> -->
        <!-- /Search Filter -->
		
		<Form @submit="onSearch">
		  <div class="row filter-row">
			<div class="col-sm-6 col-md-3">
			  <div class="input-block mb-3 form-focus focused">
				<vue-select
				  :options="project_list"
				  id="projectreportname"
				  v-model="searchform.s_project_id"
				/>

				<label class="focus-label">Project Name</label>
			  </div>
			</div>

			<div class="col-sm-6 col-md-3">
			  <div class="input-block mb-3 form-focus focused">
				<vue-select
				  :options="projectreportstatus"
				  id="projectreportstatus"
				  v-model="searchform.s_project_status"
				/>

				<label class="focus-label">Status</label>
			  </div>
			</div>
			<div class="col-sm-6 col-md-3">
			 <!-- <a href="javascript:;" class="btn btn-success w-100"> Search </a>-->
			  <button class="btn btn-success w-100 submit-btn">Search</button>
			</div>
			<div class="col-sm-6 col-md-2">
			  <div class="d-grid">
				<a href="javascript:void(0)" class="btn btn-danger w-100 submit-btn" @click="clearForm">Clear</a>
			  </div>
			</div>
		  </div>
		</Form>
		
        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
				  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
            <div class="table-responsive">
		      <a-table
                class="stripped table-hover"
				:columns="originalColumn"
                :data-source="projects"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <th>#</th>
                <th>Project Title</th>
                <th>Client Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Team</th>
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'ProjectTitle'">
                    <router-link :to="'project-view/'+record?.project_id">{{ record.project_name }}</router-link>
                  </template>
                  <template v-if="column.key === 'start_date'">
                    {{ formatDate(record?.start_date) || record?.start_date }}
                  </template>
                  <template v-if="column.key === 'end_date'">
                    {{ formatDate(record?.end_date) || record?.end_date }}
                  </template>
                  <template v-else-if="column.key === 'ClientName'"> 
                    <div class="table-avatar" v-if="record.clientdetails">
					  
                      <router-link :to="{ path: '/profile/'+record.clientdetails.id}" class="avatar"
                        ><img v-if="record.clientdetails?.avatar != null"
                      :src="getEmployeeAvatar(record.clientdetails?.avatar)"
                      @error="handleImageError(record.clientdetails)"
                        alt="User Image"
                    />
                    <img v-else
                    :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                        alt="User Image"
                    /></router-link>
                      <router-link to="client-profile" v-if="record.clientdetails">{{
                        record.clientdetails.first_name
                      }} {{record.clientdetails.last_name}}</router-link>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'Status'">
                    <div class="dropdown action-label">
                      <a
						v-if="record.status == 1"
                        href="javascript:void(0);"
                        class="btn btn-white btn-sm btn-rounded"
                        ><i class="fa-regular fa-circle-dot text-success"></i>
						Active
                      </a>
					   <a
						v-if="record.status == 2"
                        href="javascript:void(0);"
                        class="btn btn-white btn-sm btn-rounded"
                        ><i class="fa-regular fa-circle-dot text-success"></i>
						Completed
                      </a>
					  <a
						v-if="record.status == 0"
                        href="javascript:void(0);"
                        class="btn btn-white btn-sm btn-rounded"
                        ><i class="fa-regular fa-circle-dot text-danger"></i>
						Rejected
                      </a>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'Team'">
					
					<ul class="team-members" v-if="record.teamdetails && record.teamdetails.members">
						<li v-for="item in record.teamdetails.members" :key="item.id">
							 <a
							  v-if="item.details"
							  href="javascript:void(0);"
							  title=""
							  data-bs-toggle="tooltip"
							  data-original-title="Test"
							  :title="item.details.first_name+' '+item.details.last_name"
							  >
								<img v-if="item.details?.avatar != null"
									  :src="getEmployeeAvatar(item.details?.avatar)"
									  @error="handleImageError(item.details)"
										alt="User Image"
								/>
								<img v-else
								:src="require(`@/assets/img/profiles/${defaultAvatar}`)"
									alt="User Image"
								/>
							  </a>
						</li>
					</ul>
					
				  <!--
                    <ul class="team-members">
                      <li>
                        <a
                          href="javascript:void(0);"
                          title=""
                          data-bs-toggle="tooltip"
                          data-original-title="John Doe"
                          ><img
                            :src="require(`@/assets/img/profiles/${record.Avatar_1}`)"
                            alt="User Image"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          title=""
                          data-bs-toggle="tooltip"
                          data-original-title="Richard Miles"
                          ><img
                            :src="require(`@/assets/img/profiles/${record.Avatar_2}`)"
                            alt="User Image"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          title=""
                          data-bs-toggle="tooltip"
                          data-original-title="John Smith"
                          ><img
                            :src="require(`@/assets/img/profiles/${record.Avatar_3}`)"
                            alt="User Image"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          title=""
                          data-bs-toggle="tooltip"
                          data-original-title="Mike Litorus"
                          ><img
                            :src="require(`@/assets/img/profiles/${record.Avatar_4}`)"
                            alt="User Image"
                        /></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="all-users">+15</a>
                      </li>
                    </ul>
					-->
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
	
	
	<div
     id="project_report_manage_colvis"
     class="modal custom-modal fade"
     role="dialog"
   >
     <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title">Manage Column Visibility</h5>
           <!-- <small>select the column from the default that you want to hide</small> -->
           <button
             type="button"
             class="btn-close"
             data-bs-dismiss="modal"
             aria-label="Close"
             ref="modal_team_information_close"
           >
             <i class="fas fa-times"></i>
           </button>
         </div>
         <div class="modal-body">
           <div class="row">
                 <div class="col-md-6" v-for="(column,key) in columns_" :key="column.key">
                  <div class="input-block mb-3 d-flex">
                    
                    <div class="form-switch" style="margin-right: 5px;">
                      <input
                        v-model="column.visibility"
                        type="checkbox"
                        class="form-check-input"
                        :id="'customSwitch_'+column.key"
                        value="false"
                        :checked="column.visibility"
                      />
                    </div>
                    <label class="col-form-label"> {{column?.title}} </label>
                  </div>
                 </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   
    <!-- /Page Wrapper -->
  </div>
</template>
<script>
const columns = [
  {
    title: "#",
	key: "id",
    dataIndex: "id",
    sorter: false,
	customRender: ({ index }) => {
		return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	},
	visibility:true,
  },
  {
    title: "Project Title",
    dataIndex: "ProjectTitle",
    key: "ProjectTitle",
    sorter: false,
	visibility:true,
  },
  {
    title: "Client Name",
    dataIndex: "ClientName",
    key: "ClientName",
    sorter: false,
	visibility:true,
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
    sorter: false,
	visibility:true,
  },
  {
    title: "End Date",
    dataIndex: "end_date",
	key: "end_date",
    sorter: false,
	visibility:true,
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: false,
	visibility:true,
  },
  {
    title: "Team",
    dataIndex: "Team",
    key: "Team",
    sorter: false,
	visibility:true,
  },
];

import axios from 'axios';
import { Form } from "vee-validate";
import { notification } from "ant-design-vue";

const data = [
  {
    id: "1",
    ProjectTitle: "Hospital Administration",
    ClientName: "Global Technologies",
    StartDate: "9 Jan 2023",
    EndDate: "10 Apr 2023",
    Status: "Active",
    image: "avatar-19.jpg",
    Name_1: "John Doe",
    Avatar_1: "avatar-02.jpg",
    Name_2: "Richard Miles",
    Avatar_2: "avatar-09.jpg",
    Name_3: "John Smith",
    Avatar_3: "avatar-10.jpg",
    Name_4: "Mike Litorus",
    Avatar_4: "avatar-05.jpg",
  },
  {
    id: "2",
    ProjectTitle: "Office Management",
    ClientName: "Delta Infotech",
    StartDate: "10 Dec 2023",
    EndDate: "2 May 2023",
    Status: "Active",
    image: "avatar-29.jpg",
    Name_1: "John Doe",
    Avatar_1: "avatar-02.jpg",
    Name_2: "Richard Miles",
    Avatar_2: "avatar-09.jpg",
    Name_3: "John Smith",
    Avatar_3: "avatar-10.jpg",
    Name_4: "Mike Litorus",
    Avatar_4: "avatar-05.jpg",
  },
];

var pagination = {total: 0,current: 1,pageSize: 10};
		
export default {
  components: {
    Form
  },
  data() {
    return {
      title: "Project Reports",
      path: "Dashboard",
      text: "Project Reports",
	  text1: "Export Excel",
      columns,
      data,
	  pagination: pagination,
	  projects: [],
	  defaultAvatar:'avatar-02.jpg',
	  select2: null,
      //projectreportname: [{id: "ab", text: 1}, {id: "bc", text: 2}],
      projectreportstatus: [{id: 1, text: "Active"}, {id: 2, text: "Completed"},{id: 0, text: "Rejected"}],
	  searchform: {s_project_id: "",s_project_status: ""},
	  project_list: [],
	  columns_:columns,
    };
  },
  name: "project-reports",
  methods: {
	clearForm(){
		
		this.searchform = {s_project_id: "",s_project_status: ""};
		
		this.onSearch();
	},
	onSelect(){
		console.log('asdad');
	},
	onSearch(){
		console.log('search called');
		console.log(this.searchform);
		
		var params = {
		   params: { per_page: this.pagination.pageSize,page:this.pagination.current, s_project_id: this.searchform.s_project_id, s_project_status: this.searchform.s_project_status }
		};	
		
		this.loadCommonData(params);
	
	},
	updatePerPage(){
		console.log(this.pagination.pageSize);
		var params = {
          params: { per_page: this.pagination.pageSize }
        };
        this.loadCommonData(params);
		
	},
	handleTableChange(pagesize){
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current }
		};
		
		this.loadCommonData(params);
		
	},
	handleImageError(emp) {
      emp.avatar = null;
      emp.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
	getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
					
		axios.get("/project", params)
			.then((response) => {
				
			//	console.log(response.data.data.data);
				
				this.projects = response.data.data.data;
				
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});			
					
	},
	loadAllProjects(params){
		
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/project/all", params)
			.then((response) => {
				
				console.log('all project loaded');
				//console.log(response.data);
				
				if(response.data.data.length){
				
					console.log(response.data.data);
					
					for(let i=0;i<response.data.data.length;i++){
						
						console.log(response.data.data[i]);
						
						this.project_list.push({"id":response.data.data[i].project_id,"text":response.data.data[i].project_name});
					}
				}
				//this.project_list = response.data.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	}
  },
  mounted() {
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };
	this.loadCommonData(params);
	
	this.loadAllProjects([]);
  },
  computed:{
    originalColumn()
    {
      return this.columns_.filter(column => column.visibility);
    }
  },
};
</script>
