<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <leaves-admin-header :title="title" :path="path" :text="text" :text1="text1" :hasCreatePermission="hasCreatePermission" :hasImportPermission="hasImportPermission" />
        <!-- /Page Header -->

        <!-- Leave Statistics -->
        <div class="row">
          <div class="col-md-3">
            <div class="stats-info">
              <h6>Annual Leave</h6>
              <h4>{{leavesdata.total_leaves}}</h4>
            </div>
          </div>
          <div class="col-md-3">
            <div class="stats-info">
              <h6>Medical Leave</h6>
              <h4>{{leavesdata.medical_leaves}}</h4>
            </div>
          </div>
          <div class="col-md-3">
            <div class="stats-info">
              <h6>Other Leave</h6>
              <h4>{{leavesdata.other_leaves}}</h4>
            </div>
          </div>
          <div class="col-md-3">
            <div class="stats-info">
              <h6>Remaining Leave</h6>
              <h4>{{leavesdata.remaining_leaves}}</h4>
            </div>
          </div>
        </div>
        <!-- /Leave Statistics -->

        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
				  <template v-if="column.key === 'LeaveType'">
                    <div class="text-left" v-if="record.leave_type">
                      {{record.leave_type.type}}
                    </div>
                  </template>
				  <template v-if="column.key === 'From'">
                    <div class="text-left">
                      {{record.from}}
                    </div>
                  </template>
				  <template v-if="column.key === 'To'">
                    <div class="text-left">
                      {{record.to}}
                    </div>
                  </template>
				  <template v-if="column.key === 'NoofDays'">
                    <div class="text-left">
					{{record.total_days}}
                    </div>
                  </template>
				  <template v-if="column.key === 'Reason'">
                    <div class="text-left">
					{{record.reason}}
                    </div>
                  </template>
				  <template v-if="column.key === 'Status'">
                    <div class="text-left">
                      <div class="action-label">
                        <a
                          class="btn btn-white btn-sm btn-rounded"
                          href="javascript:void(0);"
                        >
                          <i :class="record.Class"></i>
                          {{ record.status }}
                        </a>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'Approvedby'">
				  
					<div class="text-left" v-if='record.status == "Approved" && record.approvedby'>
						{{record.approvedby.first_name}} {{record.approvedby.last_name}}
					</div>
                    <h4 class="table-avatar" v-if='record.status == "Approved" && record.approvedby'>
						
					<!--
                      <router-link to="profile" class="avatar avatar-xs"
                        ><img
                          :src="require(`@/assets/img/profiles/${record.Image}`)"
                          alt="User Image"
                      /></router-link>-->
                      <!--<a href="javascript:;">{{ record.Approvedby }}</a> -->
                    </h4>
                  </template>
                  <template v-if="column.key === 'action'">
                    <div class="text-end" v-if="hasEditPermission || hasDeletePermission">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a  v-if="hasEditPermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_leave"
							@click="EditLeave(record)"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a v-if="hasDeletePermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_approve"
							@click="DeleteLeaved(record.id)"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

     <leaves-admin-model2 :form="create_form" :leavetypelist="leavetype_list" @create-leave="createLeave" :editform="edit_form" @create-leavedates="createLeaveDates" @update-leave="updateLeave" :delleave_id="delleaveid" @delete-leave="deleteLeave" @edit-leavedates="editLeaveDates" ref="leavesmodel2" ></leaves-admin-model2>
	  
    </div>
    <!-- /Page Wrapper -->
    <terminationmodel></terminationmodel>
  </div>
</template>

<script>

var pagination = {total: 0,current: 1,pageSize: 10};

const columns = [
  {
    title: "Leave Type",
    dataIndex: "LeaveType",
	key: "LeaveType",
    sorter: false
  },
  {
    title: "From",
    dataIndex: "From",
	key: "From",
    sorter: false
  },
  {
    title: "To",
    dataIndex: "To",
	key: "To",
    sorter: false
  },
  {
    title: "No of Days",
    dataIndex: "NoofDays",
	key: "NoofDays",
    sorter: false
  },
  {
    title: "Reason",
    dataIndex: "Reason",
	key: "Reason",
    sorter: false
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: false
  },
  {
    title: "Approved by",
    dataIndex: "Approvedby",
    key: "Approvedby",
    sorter: false
  },
  {
    title: "Action",
    sorter: false,
    key: "action",
    text: "end",
  },
];

const data = [
  /*{
    LeaveType: "Casual Leave",
    From: "8 Mar 2023",
    To: "9 Mar 2023",
    NoofDays: "2 days",
    Reason: "Going to Hospital",
    Status: "New",
    Class: "fa-regular fa-circle-dot text-purple",
    Image: "avatar-09.jpg",
    Approvedby: "Richard Miles",
  },
  {
    LeaveType: "Medical Leave",
    From: "27 Feb 2023",
    To: "27 Feb 2023",
    NoofDays: "1 day",
    Reason: "Going to Hospital",
    Status: "Approved",
    Class: "fa-regular fa-circle-dot text-success",
    Image: "avatar-09.jpg",
    Approvedby: "Richard Miles",
  },
  {
    LeaveType: "LOP",
    From: "24 Feb 2023",
    To: "25 Feb 2023",
    NoofDays: "2 days",
    Reason: "Personnal",
    Status: "Approved",
    Class: "fa-regular fa-circle-dot text-success",
    Image: "avatar-09.jpg",
    Approvedby: "Richard Miles",
  },
  {
    LeaveType: "Paternity Leave",
    From: "13 Feb 2023",
    To: "17 Feb 2023",
    NoofDays: "5 days",
    Reason: "Going to Hospital",
    Status: "Declined",
    Class: "fa-regular fa-circle-dot text-danger",
    Image: "avatar-09.jpg",
    Approvedby: "Richard Miles",
  },
  {
    LeaveType: "Casual Leave",
    From: "30 Jan 2023",
    To: "30 Jan 2023",
    NoofDays: "Second Half",
    Reason: "Going to Hospital",
    Status: "New",
    Class: "fa-regular fa-circle-dot text-purple",
    Image: "avatar-09.jpg",
    Approvedby: "Richard Miles",
  },
  {
    LeaveType: "Hospitalisation",
    From: "15 Jan 2023",
    To: "25 Jan 2023",
    NoofDays: "10 days",
    Reason: "Going to Hospital",
    Status: "Approved",
    Class: "fa-regular fa-circle-dot text-success",
    Image: "avatar-09.jpg",
    Approvedby: "Richard Miles",
  },
  {
    LeaveType: "Casual Leave",
    From: "13 Jan 2023",
    To: "14 Jan 2023",
    NoofDays: "2 days",
    Reason: "Going to Hospital",
    Status: "Approved",
    Class: "fa-regular fa-circle-dot text-success",
    Image: "avatar-09.jpg",
    Approvedby: "Richard Miles",
  },
  {
    LeaveType: "Casual Leave",
    From: "10 Jan 2023",
    To: "10 Jan 2023",
    NoofDays: "First Half",
    Reason: "Going to Hospital",
    Status: "Declined",
    Class: "fa-regular fa-circle-dot text-danger",
    Image: "avatar-09.jpg",
    Approvedby: "Richard Miles",
  },*/
];

import axios from 'axios';
import { notification } from "ant-design-vue";
import { ref } from "vue";
import moment from 'moment';
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());

export default {
  data() {
    return {
      columns,
      data,
      title: "Leaves",
      path: "Dashboard",
      text: "Leaves",
      text1: "Add Leave",
	  create_form: {  "leave_type_id": "", "from": currentDate, "to": currentDate,"reason": "",leavedetails:[]},
	  edit_form: {},
	  leavetype_list: {},
	  pagination: pagination,
	  delleaveid: "",
	  leavesdata : {"total_leaves":0,"medical_leaves":0,"other_leaves":0,"remaining_leaves":0},
      hasCreatePermission:null,
	  hasEditPermission:null,
	  hasDeletePermission:null,
	  hasImportPermission:null

    };
  },
  methods: {
	editDates(startdate,enddate){
		// console.log('create dates called');
		// console.log(startdate);
		// console.log(enddate);
		
		if(startdate == "" || enddate == ""){
			return;
		}
		
		var cFrom = new Date(startdate);
		var cTo = new Date(enddate);
		
		if(cFrom > cTo){
			return;
		}
		
		let daysArr = [{'leave_details_date':moment(new Date(cFrom)).format('YYYY-MM-DD'),'leave_details_type':'FULLDAY'}];
		let tempDate = cFrom;
	
		while (tempDate < cTo) {
			tempDate.setUTCDate(tempDate.getUTCDate() + 1);
			
			var getdate = moment(new Date(tempDate)).format('YYYY-MM-DD');
			var getdate2 = moment(new Date(enddate)).format('YYYY-MM-DD');
			
			if(getdate <= getdate2){
			
				// console.log(this.selectededitobj.leavedetails);
				var getleavetype = "FULLDAY";  //default will be full day if matched then halfday/fullday accordingly
				
				for(let i=0;i<this.selectededitobj.leavedetails.length;i++){
					// console.log(this.selectededitobj.leavedetails[0]);
					// console.log(this.selectededitobj.leavedetails[i]);
					
					if(this.selectededitobj.leavedetails[i].leave_details_date == getdate){
						getleavetype = this.selectededitobj.leavedetails[i].leave_details_type;
					}
					
				}
				
				daysArr.push({'leave_details_date':moment(new Date(tempDate)).format('YYYY-MM-DD'),'leave_details_type':getleavetype});
			}
			
		}

		// console.log(daysArr);
		
		return daysArr;
		
	},
	editLeaveDates(startdate,enddate){
	
		if(startdate > enddate){
			
			notification.open({
					message: 'To date must be greater than From',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			return;
				
		}
		
		var getdatesarr = this.editDates(startdate,enddate);
	
		// console.log('dates arr response..');
		// console.log(getdatesarr);
		
		this.edit_form.leavedetails = getdatesarr;
		
	},
	createLeaveDates(startdate,enddate){
		// console.log('parent createLeaveDates called');
		// console.log('startdate'+startdate);
		// console.log('enddate'+enddate);
		
		if(startdate > enddate){
			
			notification.open({
					message: 'To date must be greater than From',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			return;
				
		}
		
		
		var getdatesarr = this.createDates(startdate,enddate);
	
		// console.log('dates arr response..');
		// console.log(getdatesarr);
		
		this.create_form.leavedetails = getdatesarr;
	
		
	},
	createDates(startdate,enddate){
		// console.log('create dates called');
		// console.log(startdate);
		// console.log(enddate);
		
		if(startdate == "" || enddate == ""){
			return;
		}
		
		var cFrom = new Date(startdate);
		var cTo = new Date(enddate);
		
		if(cFrom > cTo){
			return;
		}
		
		let daysArr = [{'leave_details_date':moment(new Date(cFrom)).format('YYYY-MM-DD'),'leave_details_type':'FULLDAY'}];
		let tempDate = cFrom;
	
		while (tempDate < cTo) {
			tempDate.setUTCDate(tempDate.getUTCDate() + 1);
			
			var getdate = moment(new Date(tempDate)).format('YYYY-MM-DD');
			var getdate2 = moment(new Date(enddate)).format('YYYY-MM-DD');
			
			if(getdate <= getdate2){
			
				daysArr.push({'leave_details_date':moment(new Date(tempDate)).format('YYYY-MM-DD'),'leave_details_type':'FULLDAY'});
			}
			
		}

		// console.log(daysArr);
		
		return daysArr;
		
	},
	deleteLeave(leaveid){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.delete("/leaves/"+leaveid, [])
          .then( (response) => {
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.leavesmodel2.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			this.loadCommonData(params);
			
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	DeleteLeaved(leaveid){
		this.delleaveid = leaveid;
	},
	updateLeave(formval){
	
		console.log('formval');
		console.log(formval.leave_data);
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		/*
		var postform = new FormData();	
		postform.append('employee_id',formval.employee_id);
		postform.append('from',moment(formval.from).format('YYYY-MM-DD'));
		postform.append('to',moment(formval.to).format('YYYY-MM-DD'));
		postform.append('leave_type_id',formval.leave_type_id);
		postform.append('reason',formval.reason);
		postform.append('status',formval.status);
		//postform.append('leave_data',formval.leave_data);
		
		
		var leavedata = new Array();
		
		for(let i=0;i<formval.leave_data.length;i++){
			
			var singleleavedata = new Array();
			singleleavedata['leave_details_date'] = formval.leave_data[i].leave_details_date;
			singleleavedata['leave_details_type'] = formval.leave_data[i].leave_details_type;
			
			leavedata.push(singleleavedata);
		}
		
		postform.append('leave_data',leavedata);*/
		
		
		axios.put("/leaves/"+formval.id, formval)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.leavesmodel2.closeDialog();
			 this.edit_form ={};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
		
		
	},
	EditLeave(record){
		
		var editobj = Object.assign({}, record);
		
		if(editobj.holiday_date != ""){
			editobj.from = new Date(editobj.from);
		}
		
		if(editobj.end_date != ""){
			editobj.to = new Date(editobj.to);
		}
		
		this.$refs.leavesmodel2.seteditdates(editobj.from,editobj.to);
		
		this.edit_form = editobj;
		
		this.selectededitobj = record;
	},
	createLeave(formval){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.post("/leaves", formval)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.leavesmodel2.closeDialog();
			 
			 this.create_form =  { "leave_type_id": "", "from": currentDate, "to": currentDate,"reason": "",leavedetails:[]};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
			 		
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	updatePerPage(){
		
		var params = {
          params: { per_page: this.pagination.pageSize,page:this.pagination.current }
        };
        this.loadCommonData(params);
		
	},	
	handleTableChange(pagesize){
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current }
		};
		
		this.loadCommonData(params);
		
	},
	loadAllLeaveTypes(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/leavetypes/all", params)
			.then((response) => {
				
				this.leavetype_list = response.data.data;
				this.SelectOne = response.data.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/leaves", params)
			.then((response) => {
				
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				this.data = response.data.data.data;
				
				if(response.data.leavesdata){
					this.leavesdata = response.data.leavesdata;
				}
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
	
  },
  beforeMount() {
	
	var getdatesarr = this.createDates(this.create_form.from,this.create_form.to);
	
	// console.log('dates arr response..');
	// console.log(getdatesarr);
	
	this.create_form.leavedetails = getdatesarr;
  
  },
  mounted() {

    this.$store.dispatch('userPermissions', { module: 'leave', action: 'create' }).then(response =>{
		this.hasCreatePermission=null;
		this.$nextTick(()=>{
			this.hasCreatePermission = response;
		})
	})

	this.$store.dispatch('userPermissions', { module: 'leave', action: 'write' }).then(response =>{
		this.hasEditPermission=null;
		this.$nextTick(()=>{
			this.hasEditPermission = response;
		})
	})

	this.$store.dispatch('userPermissions', { module: 'leave', action: 'delete' }).then(response =>{
		this.hasDeletePermission=null;
		this.$nextTick(()=>{
			this.hasDeletePermission = response;
		})
	})
	
	this.$store.dispatch('userPermissions', { module: 'leave', action: 'import' }).then(response =>{
		this.hasImportPermission=null;
		this.$nextTick(()=>{
			this.hasImportPermission = response;
		})
	})  
	
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };	
	this.loadCommonData(params);
	this.loadAllLeaveTypes([]);
	
	
  }
};
</script>
