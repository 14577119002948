<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <projects-header :title="title" :path="path" :text="text" :text1="text1" />
        <!-- /Page Header -->
        <!-- Search Filter -->
        <!-- <projects-filter></projects-filter>-->
        <!-- Search Filter -->

        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
				<select v-model="pagination.pageSize" @change="updatePerPage">
				  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label
              >
            </div>
            <div class="table-responsive">
			
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Project'">
                    <router-link to="project-view">Office Management</router-link>
                  </template>

                  <template v-else-if="column.key === 'Leader'">
                    <ul class="team-members">
                      <li>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          title="Jeffery Lalor"
                          ><img
                            :src="require(`@/assets/img/profiles/${record.Image}`)"
                            alt="User Image"
                        /></a>
                      </li>
                    </ul>
                  </template>
                  <template v-else-if="column.key === 'Team'">
                    <ul class="team-members text-nowrap">
                      <li>
                        <a
                          href="javascript:void(0);"
                          title="John Doe"
                          data-bs-toggle="tooltip"
                          ><img
                            src="@/assets/img/profiles/avatar-02.jpg"
                            alt="User Image"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          title="Richard Miles"
                          data-bs-toggle="tooltip"
                          ><img
                            src="@/assets/img/profiles/avatar-09.jpg"
                            alt="User Image"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          title="John Smith"
                          data-bs-toggle="tooltip"
                          ><img
                            src="@/assets/img/profiles/avatar-10.jpg"
                            alt="User Image"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          title="Mike Litorus"
                          data-bs-toggle="tooltip"
                          ><img
                            src="@/assets/img/profiles/avatar-05.jpg"
                            alt="User Image"
                        /></a>
                      </li>
                      <li class="dropdown avatar-dropdown">
                        <a
                          href="javascript:void(0);"
                          class="all-users dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          >+15</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <div class="avatar-group">
                            <a class="avatar avatar-xs" href="javascript:void(0);">
                              <img
                                src="@/assets/img/profiles/avatar-02.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="javascript:void(0);">
                              <img
                                src="@/assets/img/profiles/avatar-09.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="javascript:void(0);">
                              <img
                                src="@/assets/img/profiles/avatar-10.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="javascript:void(0);">
                              <img
                                src="@/assets/img/profiles/avatar-05.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="javascript:void(0);">
                              <img
                                src="@/assets/img/profiles/avatar-11.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="javascript:void(0);">
                              <img
                                src="@/assets/img/profiles/avatar-12.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="javascript:void(0);">
                              <img
                                src="@/assets/img/profiles/avatar-13.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="javascript:void(0);">
                              <img
                                src="@/assets/img/profiles/avatar-01.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a class="avatar avatar-xs" href="javascript:void(0);">
                              <img
                                src="@/assets/img/profiles/avatar-16.jpg"
                                alt="User Image"
                              />
                            </a>
                          </div>
                          <div class="avatar-pagination">
                            <ul class="pagination">
                              <li class="page-item">
                                <a
                                  class="page-link"
                                  href="javascript:void(0);"
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true">«</span>
                                  <span class="visually-hidden">Previous</span>
                                </a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);">1</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);">2</a>
                              </li>
                              <li class="page-item">
                                <a
                                  class="page-link"
                                  href="javascript:void(0);"
                                  aria-label="Next"
                                >
                                  <span aria-hidden="true">»</span>
                                  <span class="visually-hidden">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </template>

                  <template v-else-if="column.key === 'priority'">
                    <div class="dropdown action-label">
                      <a
                        href="javascript:;"
                        class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
						v-if="record.priority == 'Low'"
                        ><!--<i :class="record.PriorityClass"></i>-->
						<i class="fa-regular fa-circle-dot text-success"></i>
                        Low
                      </a>
					  <a
                        href="javascript:;"
                        class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
						v-if="!record.priority"
                        ><!--<i :class="record.PriorityClass"></i>-->
						<i class="fa-regular fa-circle-dot text-success"></i>
                        Low
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="fa-regular fa-circle-dot text-danger"></i> High</a
                        >
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="fa-regular fa-circle-dot text-warning"></i> Medium</a
                        >
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="fa-regular fa-circle-dot text-success"></i> Low</a
                        >
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'status'">
                    <div class="dropdown action-label">
                      <a
                        href="javascript:;"
                        class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
						v-if="record.status == 1"
                        ><i class="fa-regular fa-circle-dot text-success"></i>
                        Active
                      </a>
					  <a
                        href="javascript:;"
                        class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
						v-if="record.status != 1"
                        ><i class="fa-regular fa-circle-dot text-danger"></i>
                        InActive
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="fa-regular fa-circle-dot text-success"></i> Active</a
                        >
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="fa-regular fa-circle-dot text-danger"></i>
                          Inactive</a
                        >
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'Action'">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:void(0);"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ><i class="material-icons">more_vert</i></a
                      >
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_project"
                          ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                        >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_project"
                          ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                        >
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- <project-list-model /> -->
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "Project",
    dataIndex: "project_name",
    key: "project_name",
    sorter: false,
  },
 {
    title: "Project Id",
    dataIndex: "project_id",
    dataIndex: "project_id",
	sorter: false,
    /*sorter: {
      compare: (a, b) => {
        a = a.ProjectId.toLowerCase();
        b = b.ProjectId.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },*/
  },
  {
    title: "Leader",
    key: "leader",
    sorter: false,
  },
   /*{
    title: "Team",
    key: "Team",
    sorter: true,
  },*/
  {
    title: "Deadline",
    dataIndex: "deadline",
    sorter: false,
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
    sorter: false,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: false,
  },
  {
    title: "Action",
    key: "Action",
    sorter: true,
  },
];
const data = [
  /*{
    Project: "Office Management",
    ProjectId: "PRO-0001",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "High",
    Status: "Active",
    StatusClass: "fa-regular fa-circle-dot text-success",
    PriorityClass: "fa-regular fa-circle-dot text-danger",
  },
  {
    Project: "Project Management",
    ProjectId: "PRO-0002",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "Medium",
    Status: "Inactive",
    StatusClass: "fa-regular fa-circle-dot text-danger",
    PriorityClass: "fa-regular fa-circle-dot text-warning",
  },
  {
    Project: "Video Calling App",
    ProjectId: "PRO-0003",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "Low",
    Status: "Active",
    StatusClass: "fa-regular fa-circle-dot text-success",
    PriorityClass: "fa-regular fa-circle-dot text-success",
  },
  {
    Project: "Hospital Administration",
    ProjectId: "PRO-0004",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "High",
    Status: "Active",
    StatusClass: "fa-regular fa-circle-dot text-success",
    PriorityClass: "fa-regular fa-circle-dot text-danger",
  },
  {
    Project: "Office Management",
    ProjectId: "PRO-0005",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "High",
    Status: "Active",
    StatusClass: "fa-regular fa-circle-dot text-success",
    PriorityClass: "fa-regular fa-circle-dot text-danger",
  },
  {
    Project: "Project Management",
    ProjectId: "PRO-0006",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "High",
    Status: "Active",
    StatusClass: "fa-regular fa-circle-dot text-success",
    PriorityClass: "fa-regular fa-circle-dot text-danger",
  },
  {
    Project: "Video Calling App",
    ProjectId: "PRO-0007",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "High",
    Status: "Active",
    StatusClass: "fa-regular fa-circle-dot text-success",
    PriorityClass: "fa-regular fa-circle-dot text-danger",
  },
  {
    Project: "Hospital Administration",
    ProjectId: "PRO-0008",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "High",
    Status: "Active",
    StatusClass: "fa-regular fa-circle-dot text-success",
    PriorityClass: "fa-regular fa-circle-dot text-danger",
  },
  {
    Project: "Office Management",
    ProjectId: "PRO-0009",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "High",
    Status: "Active",
    StatusClass: "fa-regular fa-circle-dot text-success",
    PriorityClass: "fa-regular fa-circle-dot text-danger",
  },
  {
    Project: "Project Management",
    ProjectId: "PRO-0010",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "High",
    Status: "Active",
    StatusClass: "fa-regular fa-circle-dot text-success",
    PriorityClass: "fa-regular fa-circle-dot text-danger",
  },
  {
    Project: "Video Calling App",
    ProjectId: "PRO-0011",
    Image: "avatar-16.jpg",
    Deadline: "17 Apr 2023",
    Priority: "High",
    Status: "Active",
    StatusClass: "fa-regular fa-circle-dot text-success",
    PriorityClass: "fa-regular fa-circle-dot text-danger",
  },*/
];

var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};

import axios from 'axios';
import { notification } from "ant-design-vue";

export default {
  data() {
    return {
      title: "Projects",
      path: "Dashboard",
      text: "Projects",
      text1: "Create Project",
      columns,
      data,
	  pagination: pagination,
    };
  },
  name: "project-list",
  methods: {
	handleTableChange(pagesize){
		
		console.log('adad');
		console.log(pagesize);
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current }
		};
		
		this.loadCommonData(params);
		
	},
	updatePerPage(){
		
		console.log(this.pagination.pageSize);
		var params = {
          params: { per_page: this.pagination.pageSize }
        };
        this.loadCommonData(params);
		
	},
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
					
		axios.get("/project", params)
			.then((response) => {
				
			
				
				this.data = response.data.data.data;
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});			
					
	}
  },
  mounted() {
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };
	this.loadCommonData(params);
  }
};
</script>
