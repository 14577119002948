<template>
  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-4 d-flex">
      <div class="card flex-fill dash-statistics">
        <div class="card-body">
          <h5 class="card-title">Statistics</h5>
          <div class="stats-list">
            <div class="stats-info">
              <p>
                Today Leave
                <strong
                  >{{ data?.today_leaves?.length || 0 }} <small>/ {{ data?.leaves_count || 0 }}</small></strong
                >
              </p>
              <div class="progress">
                <div
                  :class="['progress-bar', 'bg-primary']"
                  :style="{ width: todayLeavePercentage + '%' }"
                  role="progressbar"
                  :aria-valuenow="todayLeavePercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="stats-info">
              <p>
                Completed Projects
                <strong
                  >{{ completedProject?.length || 0 }} <small>/ {{ data?.projects?.length || 0 }}</small></strong
                >
              </p>
              <div class="progress">
                <div
                  :class="['progress-bar', 'bg-success']"
                  :style="{ width: (completedProject?.length || 0) + '%' }"
                  role="progressbar"
                  :aria-valuenow="todayLeavePercentage"
                  aria-valuemin="0"
                  :aria-valuemax="data?.projects?.length"
                ></div>
              </div>
            </div>

            <div v-for="stat in stats" :key="stat.id" class="stats-info">
              <p>
                {{ stat.title }}
                <strong
                  >{{ stat.value }} <small>/ {{ stat.total }}</small></strong
                >
              </p>
              <div class="progress">
                <div
                  :class="['progress-bar', stat.colorClass]"
                  :style="{ width: stat.progress + '%' }"
                  role="progressbar"
                  :aria-valuenow="stat.progress"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-4 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <h4 class="card-title">Task Statistics</h4>
          <div class="statistics">
            <div class="row">
              <div class="col-md-6 col-6 text-center">
                <div class="stats-box mb-4">
                  <p>Total Tasks</p>
                  <h3>{{ data?.task?.total_task || 0 }}</h3>
                </div>
              </div>
              <div class="col-md-6 col-6 text-center">
                <div class="stats-box mb-4">
                  <p>Overdue Tasks</p>
                  <h3>{{ data?.task?.overdue_task || 0 }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="progress mb-4">
            <div
              :class="['progress-bar', 'bg-purple']"
              :style="{width : getPercentage('completed_task') + '%'}"
              role="progressbar"
              :aria-valuenow="getPercentage('completed_task')"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{getPercentage('completed_task')}}%
            </div>
            <div
              :class="['progress-bar', 'bg-warning']"
              :style="{width : getPercentage('inprogress_task')+ '%'}"
              role="progressbar"
              :aria-valuenow="getPercentage('inprogress_task')"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{getPercentage('inprogress_task')}}%
            </div>
            <div
              :class="['progress-bar', 'bg-success']"
              :style="{width : getPercentage('onhold_task')+ '%'}"
              role="progressbar"
              :aria-valuenow="getPercentage('onhold_task')"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{getPercentage('onhold_task')}}%
            </div>
            <div
              :class="['progress-bar', 'bg-danger']"
              :style="{width : getPercentage('pending_task')+ '%'}"
              role="progressbar"
              :aria-valuenow="getPercentage('pending_task')"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{getPercentage('pending_task')}}%
            </div>
            <div
              :class="['progress-bar', 'bg-info']"
              :style="{width : getPercentage('review_task')+ '%'}"
              role="progressbar"
              :aria-valuenow="getPercentage('review_task')"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{getPercentage('review_task')}}%
            </div>
          </div>

          <div>
            <p>
              <i :class="['fa-regular', 'fa-circle-dot text-purple', 'me-2']"></i>
              Completed Tasks
              <span class="float-end">{{ data?.task?.completed_task || 0 }}</span>
            </p>
            <p>
              <i :class="['fa-regular', 'fa-circle-dot text-warning', 'me-2']"></i>
              Inprogress Tasks
              <span class="float-end">{{ data?.task?.inprogress_task || 0 }}</span>
            </p>
            <p>
              <i :class="['fa-regular', 'fa-circle-dot text-success', 'me-2']"></i>
              On Hold Tasks
              <span class="float-end">{{ data?.task?.onhold_task || 0 }}</span>
            </p>
            <p>
              <i :class="['fa-regular', 'fa-circle-dot text-danger', 'me-2']"></i>
              Pending Tasks
              <span class="float-end">{{ data?.task?.pending_task || 0 }}</span>
            </p>
            <p>
              <i :class="['fa-regular', 'fa-circle-dot text-info', 'me-2']"></i>
              Review Tasks
              <span class="float-end">{{ data?.task?.review_task || 0 }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-4 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <h4 class="card-title">
            Today Absent 
            <!-- <span class="badge bg-inverse-danger ms-2">5</span> -->
          </h4>
          <div class="leave-info-box" v-for="(item,key) in data?.today_leaves.slice(0, 3)" :key="'leave_'+key">
            <div class="media d-flex align-items-center">
              <router-link :to="'profile/'+item?.id" class="avatar"
                  ><img v-if="item?.employee?.avatar && item?.employee?.avatar != null"
                    :src="getEmployeeAvatar(item?.employee?.avatar)"
                    alt="User Image"
                    @error="handleImageError(item?.employee)"
                />
                <img v-else
                :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                    alt="User Image"
                />
              </router-link>
              <div class="media-body flex-grow-1">
                <div class="text-sm my-0">{{ item?.employee?.first_name }} {{ item?.employee?.last_name }}</div>
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div class="col-6">
                <h6 class="mb-0">{{ item?.from }}  {{ (item.to != item.from) ? item?.to : '' }} </h6>
                <span class="text-sm text-muted">Leave Date</span>
              </div>
              <div class="col-6 text-end">
                <span class="badge" :class="item?.status == 'Approved' ? 'bg-inverse-success' :'bg-inverse-danger' ">{{ item?.status }}</span>
              </div>
            </div>
          </div>
          <div class="load-more text-center" v-if="data?.today_leaves?.length > 3">
            <a class="text-dark" href="javascript:void(0);">Load More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    data: {
      default: null,
    },
  },
  data() {
    return {
      stats: [
        
        {
          id: 2,
          title: "Pending Invoice",
          value: 15,
          total: 92,
          progress: (15 / 92) * 100,
          colorClass: "bg-warning",
        },
        
        {
          id: 4,
          title: "Open Tickets",
          value: 190,
          total: 212,
          progress: (190 / 212) * 100,
          colorClass: "bg-danger",
        },
        {
          id: 5,
          title: "Closed Tickets",
          value: 22,
          total: 212,
          progress: (22 / 212) * 100,
          colorClass: "bg-info",
        },
      ],
    };
  },
  computed:{
    todayLeavePercentage()
    {
       const todayLeave = this.data?.today_leaves?.length || 0
       const totalLeave = this.data?.leaves_count || 0

       return (todayLeave * 100)/totalLeave;
    },
    completedProject()
    {
      const projects = this?.data?.projects || [];
      const filteredProjects = projects.filter(project => project.status == 2);
      return filteredProjects;
    }
  },
  methods:{
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(item) {
      item.avatar = null;
      item.avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    getPercentage(taskStatus)
    {
        const total = this.data?.task?.total_task;
        const count = this.data?.task[taskStatus];

        return Math.round((count / total) * 100) || 0;      
    }
  }
};
</script>
