<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid pb-0">
        <breadcrumb :title="title" :path="path" :text="text" :pathURL="'/employees'" /> 
        <profilehead  :employeeData="employeeData" @set-emp-data="setEmployee" />
        <div class="card tab-box">
          <div class="row user-tabs">
            <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
              <ul class="nav nav-tabs nav-tabs-bottom pt-3 pb-2">
                <li class="nav-item">
                  <a @click="setActiveTab('emp_profile')" href="#emp_profile" data-bs-toggle="tab" class="nav-link" :class="{'active': activeTab == 'emp_profile'}"
                    >Profile</a
                  >
                </li>
                <li class="nav-item">
                  <a @click="setActiveTab('emp_projects')" href="#emp_projects" data-bs-toggle="tab" class="nav-link" :class="{'active': activeTab == 'emp_projects'}"
                    >Projects</a
                  >
                </li>
                <li class="nav-item">
                  <a @click="setActiveTab('bank_statutory')" href="#bank_statutory" data-bs-toggle="tab" class="nav-link" :class="{'active': activeTab == 'bank_statutory'}"
                    >Bank & Statutory <small class="text-danger">(Admin Only)</small></a
                  >
                </li>
                <li class="nav-item">
                  <a @click="setActiveTab('emp_assets')" href="#emp_assets" data-bs-toggle="tab" class="nav-link" :class="{'active': activeTab == 'emp_assets'}">Assets</a>
                </li>
                <li class="nav-item"> 
                  <a @click="setActiveTab('incident')" href="#emp_incident" data-bs-toggle="tab" class="nav-link" :class="{'active': activeTab == 'incident'}">Incident</a>
                </li>
                <li class="nav-item"> 
                  <a @click="setActiveTab('bvg')" href="#emp_bvg" data-bs-toggle="tab" class="nav-link" :class="{'active': activeTab == 'bvg'}">BGV</a>
                </li>
                <li class="nav-item"> 
                  <a @click="setActiveTab('userOffices')" href="#userOffices" data-bs-toggle="tab" class="nav-link" :class="{'active': activeTab == 'userOffices'}">Offices</a>
                </li>
                <li class="nav-item" v-if="allowFaceTab"> 
                  <a @click="setActiveTab('userFaceRecognization')" href="#userFaceRecognization" data-bs-toggle="tab" class="nav-link" :class="{'active': activeTab == 'userFaceRecognization'}">Face Recognization</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="tab-content">
          <profiletab :employeeData="employeeData" @reload-data="fetchEmp"  @set-emp-data="setEmployee" />
          <projectstab :employeeID="employeeData?.id" />
          <banktab :employeeData="employeeData" />
          <profileassets :employeeID="employeeData?.id" :activeTab="activeTab"  />
          <emp-incident :employeeID="employeeData?.id" :activeTab="activeTab"  />
          <emp-bvg :employeeID="employeeData?.id" :activeTab="activeTab"  />
          <emp-office :employeeID="employeeData?.id" :employeeData="employeeData" :activeTab="activeTab" @reload-data="fetchEmp" />
          <emp-face-recognization :employeeID="employeeData?.id" :employeeData="employeeData" :activeTab="activeTab" @reload-data="fetchEmp" />
        </div>
      </div>
      <profilemodal :employeeData="employeeData" @reload-data="fetchEmp" />
    </div>
  </div> 
</template>
<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
export default {
  name: "profile",
  data() {
    return {
      title: "Profile",
      path: "Employee",
      text: "Profile",
      employeeData:null,
      empData:null,
      activeTab:'emp_profile',
    };
  },
  methods:{
    ...mapActions(['fetchEmployeeProfileData']),
    async fetchEmp() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: false
      });

      try {
       
        const data = await this.fetchEmployeeProfileData(this.$route.params.user_id);
        this.employeeData = null
        this.empData = null;
        this.$nextTick(()=>{
         this.employeeData = data;
         this.empData = data;
        })
      } catch (error) {
        console.error('Error fetching employee data:', error);
      } finally {
        loader.hide();
      }
    },
    setEmployee(){
      this.employeeData = null
      this.$nextTick(()=>{
        this.employeeData = this.empData
      })
    },
    setActiveTab(tab)
    {
      this.activeTab = tab;
    }
  },
  watch:{
   
    '$route.params.user_id': function(newParamValue, oldParamValue) {
      this.fetchEmp();
    }
  },
  async created(){

    this.fetchEmp();
   
  },
  computed:{
    allowFaceTab(){
        let role = localStorage.getItem('role') || null;
        if (typeof role === 'string') {
          if(role == 'HR' || role == 'Administrative / Super Admin')
          {
            return true;
          }

          return false;

        } else if (Array.isArray(role)) {
            if(role.includes('HR') || role.includes('Administrative / Super Admin'))
            {
              return true;
            }
            return false;
        }
        else
        {
          return false;
        }
    }
  }
};
</script>
