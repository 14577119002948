<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <projects-header :title="title" :path="path" :text="text" :text1="text1" />
        <!-- /Page Header -->
        <!-- Search Filter -->
        <projects-filter :designations_list="designations_list" :searchproject="searchproject" @search-projectf="searchProjectf"></projects-filter>
        <!-- Search Filter -->

        <div class="row">
          <project-card
            v-for="project in projects"
            :key="project.id"
            :project="project"
			@edit-project="editProject"
			@delete-project="deleteProject"
		  />
        </div>
		<div class="row">
			<div class="col-12 text-center">
			  <a-pagination @change="onChange" v-model:current="pagination.current"
			  v-model:pageSize="pagination.pageSize" :total="pagination.total" show-less-items></a-pagination>
			</div>
		</div>
      </div>
      <project-list-model :form="create_form" :clients_list="clients_list" :teams_list="teams_list" :team_leads="team_leads" :wages_list="wages_list" @create-project="createProject" :delprojid="delprojid" @update-project="updateProject" :editform="edit_form" ref="projectmodel" @delete-proj="deleteProj"/>
    </div>
  </div>
</template>
<script>
import ProjectCard from "./ProjectsContent.vue";

var pagination = {total: 0,
	    current: 1,
	    pageSize: 12};

import axios from 'axios';
import { notification } from "ant-design-vue";
import { ref } from "vue";		
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());

import moment from 'moment';
export default {
  components: {
    ProjectCard,
  },
  data() {
    return {
      title: "Projects",
      path: "Dashboard",
      text: "Projects",
      text1: "Create Project",
	  pagination: pagination,
	  searchproject: { projectName: "", employeeName: "", designation:""},
	  create_form: { "project_name": "","client_id":"","wages_id":"","start_date":currentDate,"end_date":currentDateOne,"rate":"","priority":"","leader":"","description":""},
	  edit_form: {},
	  clients_list: {},
	  team_leads: {},
	  wages_list: {},
	  delprojid: "",
	  projects: [
        /*{
          id: "1",
          title: "Office Management",
          openTasks: 1,
          completedTasks: 9,
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...",
          deadline: "17 Apr 2023",
          projectLeaders: [
            {
              id: "1",
              name: "Jeffery Lalor",
              avatar: "avatar-16.jpg",
            },
          ],
          teamMembers: [
            {
              id: "2",
              name: "John Doe",
              avatar: "avatar-02.jpg",
            },
            {
              id: "3",
              name: "Richard Miles",
              avatar: "avatar-09.jpg",
            },
            {
              id: "4",
              name: "John Smith",
              avatar: "avatar-10.jpg",
            },
            {
              id: "5",
              name: "Mike Litorus",
              avatar: "avatar-05.jpg",
            },
          ],
          progress: 40,
        },
        {
          id: "2",
          title: "Project Management",
          openTasks: 2,
          completedTasks: 5,
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...",
          deadline: "17 Apr 2023",
          projectLeaders: [
            {
              id: "1",
              name: "Jeffery Lalor",
              avatar: "avatar-16.jpg",
            },
          ],
          teamMembers: [
            {
              id: "2",
              name: "John Doe",
              avatar: "avatar-02.jpg",
            },
            {
              id: "3",
              name: "Richard Miles",
              avatar: "avatar-09.jpg",
            },
            {
              id: "4",
              name: "John Smith",
              avatar: "avatar-10.jpg",
            },
            {
              id: "5",
              name: "Mike Litorus",
              avatar: "avatar-05.jpg",
            },
          ],
          progress: 40,
        },
        {
          id: "3",
          title: "Video Calling App",
          openTasks: 3,
          completedTasks: 3,
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...",
          deadline: "17 Apr 2023",
          projectLeaders: [
            {
              id: "1",
              name: "Jeffery Lalor",
              avatar: "avatar-16.jpg",
            },
          ],
          teamMembers: [
            {
              id: "2",
              name: "John Doe",
              avatar: "avatar-02.jpg",
            },
            {
              id: "3",
              name: "Richard Miles",
              avatar: "avatar-09.jpg",
            },
            {
              id: "4",
              name: "John Smith",
              avatar: "avatar-10.jpg",
            },
            {
              id: "5",
              name: "Mike Litorus",
              avatar: "avatar-05.jpg",
            },
          ],
          progress: 40,
        },
        {
          id: "4",
          title: "Hospital Administration",
          openTasks: 12,
          completedTasks: 4,
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...",
          deadline: "17 Apr 2023",
          projectLeaders: [
            {
              id: "1",
              name: "Jeffery Lalor",
              avatar: "avatar-16.jpg",
            },
          ],
          teamMembers: [
            {
              id: "2",
              name: "John Doe",
              avatar: "avatar-02.jpg",
            },
            {
              id: "3",
              name: "Richard Miles",
              avatar: "avatar-09.jpg",
            },
            {
              id: "4",
              name: "John Smith",
              avatar: "avatar-10.jpg",
            },
            {
              id: "5",
              name: "Mike Litorus",
              avatar: "avatar-05.jpg",
            },
          ],
          progress: 40,
        },*/
      ],
	  designations_list: {},
	  teams_list: {},
	  
    };
  },
  name: "projects",
  methods: {
	deleteProj(id){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.delete("/project/"+id, [])
          .then( (response) => {
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.projectmodel.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	updateProject(formval){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		var postform = new FormData();		
		postform.append('project_name',formval.project_name);
		postform.append('start_date',moment(formval.start_date).format('YYYY-MM-DD'));
		postform.append('end_date',moment(formval.end_date).format('YYYY-MM-DD'));
		postform.append('client_id',formval.client_id);
		postform.append('rate',formval.rate);
		postform.append('wages_id',formval.wages_id);
		postform.append('priority',formval.priority);
		postform.append('description',formval.description);
		postform.append('leader',formval.leader);
		postform.append('teams',formval.teams);
		
		
		if(formval.file){
	    	postform.append('uploadfile',formval.file);
		}
		
		axios.post("/project/"+formval.project_id, postform, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
					})
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.projectmodel.closeDialog();
			 this.edit_form ={};
			 
			 
			var params = {
			   params: { per_page: this.pagination.pageSize }
			};
			this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
		
	},
	deleteProject(id){
		
		this.delprojid = id;
		
		
	},
	editProject(formval){
		
		if(formval.start_date != ""){
			formval.start_date = new Date(formval.start_date);
		}
		
		if(formval.end_date != ""){
			formval.end_date = new Date(formval.end_date);
		}
		
		this.edit_form = formval;
	},
    createProject(formval){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		//axios.post("/project", formval)
		axios.post("/project", formval, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
					})
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.projectmodel.closeDialog();
			 
			 this.create_form =  { "project_name": "","client_id":"","start_date":currentDate,"end_date":currentDateOne,"rate":"","priority":"High","leader":"","description":"","fee_type":"Hourly"};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	searchProjectf(formval){

		if(formval?.reset && formval?.reset == true)
		{
			this.searchproject = { projectName: "", employeeName: "", designation:""}
		}
		
		var params = {
          params: { projectName: formval.projectName,employeeName: formval.employeeName,designation: formval.designation,per_page: this.pagination.pageSize }
        };
		
		this.loadCommonData(params);
	},
	onChange(current)
    {
      this.pagination.current = current;

      var params = {
        params: { per_page: this.pagination.pageSize,page:current,projectName: this.searchproject.projectName,employeeName: this.searchproject.employeeName,designation: this.searchproject.designation }
      };
      
      this.loadCommonData(params);
    },
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
					
		axios.get("/project", params)
			.then((response) => {
				
				this.projects = response.data.data.data;
				
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});			
					
	},
	loadAllDesignations(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/designations/all", params)
			.then((response) => {
				
				this.designations_list = response.data.data;
				
				if(response.data.data.length){
					
					for(let i=0;i<response.data.data.length;i++){
						
						this.designations_list[i].text = response.data.data[i].name;
					}
				}
				
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});			
	},
	loadAllTeams(params){
	
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/team/all", params)
			.then((response) => {
				
				this.teams_list = response.data.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadAllClients(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/client", params)
			.then((response) => {
				
				this.clients_list = response.data.record.data;
				
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadAllTeamLeads(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/employees/all", params)
			.then((response) => {
				this.team_leads = response?.data?.data || [];
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadAllWages(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/wages/all", params)
			.then((response) => {
				
				this.wages_list = response?.data?.data || [];
					  
			}).catch((error) => {
			  
			  console.log('wages error');
			  console.log(error);
			  var response = error.response;
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	}
  },
  mounted() {
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };
	this.loadCommonData(params);
	
	this.loadAllDesignations([]);
	
	this.loadAllTeams([]);
	
	this.loadAllClients([]);
	
	this.loadAllTeamLeads([]);
	
	this.loadAllWages([]);
	
  }
};
</script>
