<template>
  <div class="tab-pane" id="results_users">
    <div class="table-responsive">
      <table class="table table-striped custom-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Company</th>
            <th>Created Date</th>
            <th>Role</th>
            <!-- <th class="text-end">Action</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in users" :key="item.id">
            <td>
              <h2 class="table-avatar">
                <router-link :to="'profile/'+item?.id" class="avatar"
                  >
                  <img v-if="item?.avatar && item?.avatar != null"
                    :src="getEmployeeAvatar(item?.avatar)"
                    alt="User Image"
                    @error="handleImageError(item)"
                  />
                  <img v-else
                  :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                      alt="User Image"
                  />
                </router-link>
                <router-link :to="'profile/'+item?.id">
                  {{ item.first_name }}  {{ item.last_name }}
                </router-link>
              </h2>
            </td>
            <td>{{ item.email }}</td>
            <td>{{ item.company.company_name }}</td>
            <td>{{ convertDate(item.created_at) }}</td>
            <td>
              <span class="badge" :class="item?.role_id == 3 ? 'bg-inverse-success' : 'bg-inverse-danger'">{{ item.role_name }}</span>
            </td>
            <!-- <td class="text-end">
              <div class="dropdown dropdown-action">
                <a
                  href="javascript:;"
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="material-icons">more_vert</i></a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" href="javascript:;"
                    ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                  >
                  <a class="dropdown-item" href="javascript:;"
                    ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                  >
                </div>
              </div>
            </td> -->
          </tr>
          <tr v-if="!users?.length">
            <td colspan="5" class="text-center">No records found !</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      defaultAvatar:'avatar-02.jpg',
    };
  },
  props:{
    users: {
        type: Array,
        default: [],
        required:false
    },
  },
  methods:{
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(item) {
      item.avatar = null;
      item.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
    convertDate(date)
    {
      return moment(date).format('Do MMMM, YYYY');
    },
  }
};
</script>
