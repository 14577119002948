<template>
  <!-- Search Filter -->
  <div class="row filter-row">
    <div class="col-sm-3">
      <div class="input-block mb-3 form-focus">
        <div class="cal-icon">
          <datepicker
            v-model="startdate"
            placeholder="Date"
            class="form-control floating datetimepicker"
            :editable="true"
            :clearable="false"
            :input-format="dateFormat"
          />
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="input-block mb-3 form-focus select-focus">
        <vue-select :options="Month" v-model="form_month" id="month-one" placeholder="All" />
        <label class="focus-label">Select Month</label>
      </div>
    </div>
    <div class="col-sm-2">
      <div class="input-block mb-3 form-focus select-focus">
        <vue-select :options="Year" v-model="form_year" id="year-one" placeholder="All" />
        <label class="focus-label">Select Year</label>
      </div>
    </div>
    <div class="col-sm-2">
      <div class="d-grid">
        <a href="javascript:;" class="btn btn-success  submit-btn" style="min-width:180px" @click="filterAttendance"> Search </a>
      </div>
    </div>
    <div class="col-sm-2">
      <div class="d-grid">
        <a href="javascript:;" class="btn btn-danger  submit-btn" style="min-width:180px" @click="clearFilter"> Clear </a>
      </div>
    </div>
  </div>
  <!-- /Search Filter -->
</template>
<script>
export default {
  emits: ["searchAttendance",'filter-attendance'],
  data() {
    return {
      startdate: null,
      form_month:null,
      form_year:null,
      Month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      Year: ["2024","2023", "2022", "2021", "2020", "2019"],
      dateFormat: 'dd-MM-yyyy',
    };
  },
  methods:{
    filterAttendance(){

      const options = { day: 'numeric', month: 'short', year: 'numeric' };

        const searchParam = {
          date: this.startdate != null ? isNaN(new Date(this.startdate).toLocaleDateString('en-US', options)) ? new Date(this.startdate).toLocaleDateString('en-US', options) : null : null ,
          month:this.form_month,
          year:this.form_year
        }

        this.$emit("filter-attendance",searchParam);
    },
    clearFilter(){
      this.startdate = '';
      this.form_month = null;
      this.form_year = null;
      this.$emit("filter-attendance",null);
    }
  }
};
</script>
