<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <breadcrumb :title="title" :path="path" :text="text" />
        <!-- /Page Header -->

        <div class="row">
          <div class="col-md-12" v-for="(leaveType,index) in leaveTypeData" :key="'leave_type_index_'+index" >
             <!-- Annual Leave -->
             <annual :editPolicyId="editPolicyId" :editPolicyData="editPolicyData" @onEditPolicyID="onEditPolicyID" @onEditLeaveTypePolicyData="onEditLeaveTypePolicyData"  :leaveType="{ ...leaveType}"  @reloadLeaveTypeData="reloadLeaveTypeData">
            </annual>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <!-- /Page Wrapper -->
    
  </div>
</template>

<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
export default {
  data() {
    return {
      title: "Leave Settings",
      path: "Dashboard",
      text: "Leave Settings",
      leaveTypeData : [],
      editPolicyId:null,
      editPolicyData:null
    };
  },
  methods:{
    onEditPolicyID(val){
      this.editPolicyId = null;
      this.$nextTick(()=>{
        this.editPolicyId = val;
      })
    },
    onEditLeaveTypePolicyData(val){
      this.editPolicyData = null;
      this.$nextTick(()=>{
        this.editPolicyData = val;
      })
    },
    reloadLeaveTypeData()
    {
      this.fetchData();
    },
    async fetchData(){
      var token = window.localStorage.getItem("token");
		  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
      await axios.get('/leave-settings').then(response => {
        this.leaveTypeData = [];
        this.leaveTypeData = response?.data?.data || [];
        loader.hide();
      }).catch(error => {
        loader.hide();

        if(error.response){
        
          var response = (error.response);
            
          notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
          
        }else{
          
          notification.open({
            message: 'Server Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
       })

       
    },
  },
  created()
  {
    this.fetchData();
  }

};
</script>
