<template>
  <!-- Create Project Modal -->
  <div id="create_project" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create Project</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="addprojectclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Project Name</label>
                  <!--<input class="form-control" type="text" /> -->
				   <Field
                    name="project_name"
                    type="text"
                    value=""
                    class="form-control"
					v-model="form.project_name"
					:class="{ 'is-invalid': errors.project_name }"
                  />
                  <div class="invalid-feedback">{{ errors.project_name }}</div>
                  <div class="project_nameshow text-danger" id="project_name"></div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Client</label>
				  <!--
				  <vue-select
                    :options="AddProjectsClient"
                    id="addprojectsclient"
                    placeholder="Global Technologies"
                  /> -->
				  
				  <Field
                    name="client_id"
                    as="select"
                    class="form-control"
					v-model="form.client_id"
					:class="{ 'is-invalid': errors.client_id }"
                  >
				  <option value="">Select Client</option>
				  <option v-for="client in clients_list	" :key="client.id" :value="client.id">{{ client.first_name }} {{client.last_name}}</option>
				  <div class="invalid-feedback">{{ errors.client_id }}</div>
                  <div class="client_idshow text-danger" id="client_id"></div>
				  </Field>
			  
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Start Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="form.start_date"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">End Date</label>
                  <div class="cal-icon"> {{ min_date }}
                    <datepicker
                      v-model="form.end_date"
                      class="form-control floating datetimepicker"
                      :minDate="min_date"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="input-block mb-3">
                  <label class="col-form-label">Rate</label>
                  <!--<input placeholder="$50" class="form-control" type="text" /> -->
				  
				  <Field
                    name="rate"
                    type="text"
                    value=""
                    class="form-control"
					v-model="form.rate"
					:class="{ 'is-invalid': errors.rate }"
                  />
                  <div class="invalid-feedback">{{ errors.rate }}</div>
                  <div class="rateshow text-danger" id="rate"></div>
				  
                </div>
              </div>
              <div class="col-sm-3">
                <div class="input-block mb-3">
                  <label class="col-form-label">&nbsp;</label>
                  <!--<vue-select
                    :options="AddProjectsTime"
                    id="addProjectsTime"
                    placeholder="Hourly"
                  /> -->
				  <Field
                    name="wages_id"
                    as="select"
                    class="form-control"
					v-model="form.wages_id"
					:class="{ 'is-invalid': errors.wages_id }"
                  >
					  <option value="">Select Wage</option>
					  <option v-for="wage in wages_list" :key="wage.wages_id" :value="wage.wages_id">{{ wage.wages_name }}</option>
					  <div class="invalid-feedback">{{ errors.wages_id }}</div>
					  <div class="wage_idshow text-danger" id="wages_id"></div>
				   </Field>
			  
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Priority</label>
                  <!--
				  <vue-select
                    :options="AddProjectsPriority"
                    id="addProjectsPriority"
                    placeholder="High"
                  /> -->
				  
				  <Field
                    name="priority"
                    as="select"
                    class="form-control"
					v-model="form.priority"
					:class="{ 'is-invalid': errors.priority }"
                  >	  <option value="">Select Priority</option>
					  <option value="1">High</option>
					  <option value="2">Medium</option>
					  <option value="3">Low</option>
					  <div class="invalid-feedback">{{ errors.priority }}</div>
					  <div class="priorityshow text-danger" id="priority"></div>
				   </Field>
				   
				   
				   
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Add Project Leader</label>
                 <!-- <input class="form-control" type="text" /> -->
				  <Field
                    name="leader"
                    as="select"
                    class="form-control"
					v-model="form.leader"
					:class="{ 'is-invalid': errors.leader }"
					@change="updateTeamLead"
                  >
				  <option value="">Select Project Leader</option>
				  <option v-for="lead in team_leads	" :key="lead.id" :value="lead.id">{{ lead.first_name }} {{lead.last_name}}</option>
				  <div class="invalid-feedback">{{ errors.leader }}</div>
                  <div class="leadershow text-danger" id="leader"></div>
				  </Field>
				  
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3" v-if="form.leader > 0">
				  <label class="col-form-label">Team Leader</label>
                  <div class="project-members">
                    <a v-if="teamleaddata?.avatar != null"
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    :title="teamleaddata.first_name+' '+teamleaddata.last_name"
                    class="avatar"
                  >
        
                    <img
                      :src="getEmployeeAvatar(teamleaddata?.avatar)"
                      alt="User Image"
                      @error="handleImageError(teamleaddata)"
                    />
                  </a>
                    <a v-else
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      :title="teamleaddata.first_name+' '+teamleaddata.last_name"
                      class="avatar"
                    >
					
                      <img
                        src="@/assets/img/profiles/avatar-16.jpg"
                        alt="User Image"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Add Team</label>
                  <!--<input class="form-control" type="text" /> -->
				  <Field
                    name="teams"
                    as="select"
                    class="form-control"
					v-model="form.teams"
					:class="{ 'is-invalid': errors.teams }"
					@change="updateTeamMembers"
				  >
				  <option value="">Select Team</option>
				  <option v-for="team in teams_list" :key="team.id" :value="team.id">{{team.team_name}}</option>
				  <div class="invalid-feedback">{{ errors.teams }}</div>
                  <div class="teamsshow text-danger" id="teams"></div>
				  </Field>
				  
                </div>
              </div>
              <div class="col-sm-6">
			    <div class="input-block mb-3" v-if="selectedteammembers.length">
                  <label class="col-form-label">Team Members</label>
                  <div class="project-members">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="John Doe"
                      class="avatar"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-16.jpg"
                        alt="User Image"
                      />
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="Richard Miles"
                      class="avatar"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-09.jpg"
                        alt="User Image"
                      />
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="John Smith"
                      class="avatar"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-10.jpg"
                        alt="User Image"
                      />
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="Mike Litorus"
                      class="avatar"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-05.jpg"
                        alt="User Image"
                      />
                    </a>
                    <span class="all-team">+2</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label">Description</label>
              <summernoteEditor
                v-model="form.description"
                @summernoteImageLinkInsert="summernoteImageLinkInsert"
              />
            </div>
			<!--
            <div class="input-block mb-3">
              <label class="col-form-label">Upload Files</label>
              <input class="form-control" type="file" />
            </div> -->
			<div class="input-block mb-3">
              <label class="col-form-label">Upload Files</label>
              <input class="form-control" type="file" @change="handleFileUploadCreate( $event )" />
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Project Modal -->

  <!-- Edit Project Modal -->
  <div id="edit_project" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Project</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="editprojectclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="onUpdate" :validation-schema="editschema" v-slot="{ errors }">
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Project Name</label>
                  <!--<input
                    class="form-control"
                    value="Project Management"
                    type="text"
                  /> -->
				  <Field
                    name="project_name"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.project_name"
					:class="{ 'is-invalid': errors.project_name }"
                  />
                  <div class="invalid-feedback">{{ errors.project_name }}</div>
                  <div class="project_nameshow text-danger" id="project_name"></div>
				  
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Client</label>
                  <!--<vue-select
                    :options="EditProjectsClient"
                    id="editProjectsClient"
                    placeholder="Global Technologies"
                  /> -->
				  
				   <Field
                    name="client_id"
                    as="select"
                    class="form-control"
					v-model="editform.client_id"
					:class="{ 'is-invalid': errors.client_id }"
                  >
				  <option value="">Select Client</option>
				  <option v-for="client in clients_list	" :key="client.id" :value="client.id">{{ client.first_name }} {{client.last_name}}</option>
				  <div class="invalid-feedback">{{ errors.client_id }}</div>
                  <div class="client_idshow text-danger" id="client_id"></div>
				  </Field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Start Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="editform.start_date"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">End Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="editform.end_date"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="input-block mb-3">
                  <label class="col-form-label">Rate</label>
                  <!--<input
                    placeholder="$50"
                    class="form-control"
                    value="$5000"
                    type="text"
                  /> -->
				  <Field
                    name="rate"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.rate"
					:class="{ 'is-invalid': errors.rate }"
                  />
                  <div class="invalid-feedback">{{ errors.rate }}</div>
                  <div class="rateshow text-danger" id="rate"></div>
				  
                </div>
              </div>
              <div class="col-sm-3">
                <div class="input-block mb-3">
                  <label class="col-form-label">&nbsp;</label>
                  <!--<vue-select
                    :options="EditProjectsTime"
                    id="editProjectsTime"
                    placeholder="Hourly"
                  /> -->
				  
				   <!--<Field
                    name="fee_type"
                    as="select"
                    class="form-control"
					v-model="editform.fee_type"
					:class="{ 'is-invalid': errors.fee_type }"
                  >
					  <option value="Hourly">Hourly</option>
					  <option value="Fixed">Fixed</option>
					  <div class="invalid-feedback">{{ errors.fee_type }}</div>
					  <div class="fee_typeshow text-danger" id="fee_type"></div>
				   </Field> -->
				   
				   <Field
                    name="wages_id"
                    as="select"
                    class="form-control"
					v-model="editform.wages_id"
					:class="{ 'is-invalid': errors.wages_id }"
                  >
					  <option value="">Select Wage</option>
					  <option v-for="wage in wages_list" :key="wage.wages_id" :value="wage.wages_id">{{ wage.wages_name }}</option>
					  <div class="invalid-feedback">{{ errors.wages_id }}</div>
					  <div class="wage_idshow text-danger" id="wages_id"></div>
				   </Field>
				   
				   
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Priority</label>
                  <!--<vue-select
                    :options="EditProjectsPriority"
                    id="editProjectsPriority"
                    placeholder="High"
                  /> -->
				  
				  <!--
				  <Field
                    name="priority"
                    as="select"
                    class="form-control"
					v-model="editform.priority"
					:class="{ 'is-invalid': errors.priority }"
                  >
					  <option value="High">High</option>
					  <option value="Medium">Medium</option>
					  <option value="Low">Low</option>
					  <div class="invalid-feedback">{{ errors.priority }}</div>
					  <div class="priorityshow text-danger" id="priority"></div>
				   </Field> -->
				   
				   <Field
                    name="priority"
                    as="select"
                    class="form-control"
					v-model="editform.priority"
					:class="{ 'is-invalid': errors.priority }"
                  >	  <option value="">Select Priority</option>
					  <option value="1">High</option>
					  <option value="2">Medium</option>
					  <option value="3">Low</option>
					  <div class="invalid-feedback">{{ errors.priority }}</div>
					  <div class="priorityshow text-danger" id="priority"></div>
				   </Field>
				   
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
              
                <div class="input-block mb-3">
                  <label class="col-form-label">Add Project Leader</label>
                  <!--<input class="form-control" type="text" /> -->
                  
				  <Field
                    name="leader"
                    as="select"
                    class="form-control"
					v-model="editform.leader"
					:class="{ 'is-invalid': errors.leader }"
					@change="updateeditTeamLead"
                  >
				  <option value="">Select Project Leader</option>
				  <option v-for="lead in team_leads" :key="lead.id" :value="lead.id">{{ lead.first_name }} {{lead.last_name}}</option>
				  <div class="invalid-feedback">{{ errors.leader }}</div>
                  <div class="leadershow text-danger" id="leader"></div>
				  </Field>
				  
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3" v-if="editform.leader > 0">
				  <label class="col-form-label">Team Leader</label>
                  <div class="project-members">

                    <a v-if="editteamleaddata?.avatar != null"
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    :title="editteamleaddata.first_name+' '+editteamleaddata.last_name"
                    class="avatar"
                  >
        
                    <img
                      :src="getEmployeeAvatar(editteamleaddata?.avatar)"
                      alt="User Image"
                      @error="handleImageError(editteamleaddata)"
                    />
                  </a>
                    <a v-else
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      :title="editteamleaddata.first_name+' '+editteamleaddata.last_name"
                      class="avatar"
                    >
					
                      <img
                        src="@/assets/img/profiles/avatar-16.jpg"
                        alt="User Image"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Add Team</label>
                  <!--<input class="form-control" type="text" /> -->
				  <Field
                    name="teams"
                    as="select"
                    class="form-control"
					v-model="editform.teams"
					:class="{ 'is-invalid': errors.teams }"
					@change="updateeditTeamMembers"
				  >
				  <option value="">Select Team</option>
				  <option v-for="team in teams_list" :key="team.id" :value="team.id">{{team.team_name}}</option>
				  <div class="invalid-feedback">{{ errors.teams }}</div>
                  <div class="teamsshow text-danger" id="teams"></div>
				  </Field>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3" v-if="selectededitteammembers.length">
                  <label class="col-form-label">Team Members</label>
                  <div class="project-members">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="John Doe"
                      class="avatar"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-16.jpg"
                        alt="User Image"
                      />
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="Richard Miles"
                      class="avatar"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-09.jpg"
                        alt="User Image"
                      />
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="John Smith"
                      class="avatar"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-10.jpg"
                        alt="User Image"
                      />
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      title="Mike Litorus"
                      class="avatar"
                    >
                      <img
                        src="@/assets/img/profiles/avatar-05.jpg"
                        alt="User Image"
                      />
                    </a>
                    <span class="all-team">+2</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label">Description</label>
              <!--<textarea
                rows="4"
                class="form-control"
                placeholder="Enter your message here"
				v-model="form.description"
              ></textarea> -->
			  
			  <summernoteEditor
                v-model="editform.description"
              />
			  
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label">Upload Files</label>
              <input class="form-control" type="file" @change="handleFileUpload( $event )" />
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Save</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Project Modal -->

  <!-- Delete Project Modal -->
  <div class="modal custom-modal fade" id="delete_project" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Project</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn"
				  @click="deleteProject"
				  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
				  ref="delprojectclose"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Project Modal -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import dayjs from 'dayjs';
import moment from 'moment';


export default {
  emits: ["createProject","updateProject","deleteProject","deleteProj",'create-project'],
  components: {
    Form,
    Field,
  },
  props: {
    form: [Array, Object], 
    editform: [Array, Object],
    clients_list: [Array, Object],
    team_leads: [Array, Object],
    teams_list: [Array, Object],
    wages_list: [Array, Object],
    delprojid: [String, Number],
  },
  data() {
    return {
      select2: null,
      AddProjectsClient: ["Global Technologies", "Delta Infotech"],
      AddProjectsTime: ["Hourly", "Fixed"],
      AddProjectsPriority: ["High", "Medium", "Low"],
      EditProjectsClient: ["Global Technologies", "Delta Infotech"],
      EditProjectsTime: ["Hourly", "Fixed"],
      EditProjectsPriority: ["High", "Medium", "Low"],
      startdate: currentDate,
      dateFormat: 'dd-MM-yyyy',
      startdateone: currentDateOne,
      myValue: "description",
	  teamleaddata: {},
	  editteamleaddata: {},
	  selectedteammembers: {},
	  selectededitteammembers: {},
	  editfile: '',
	  createfile: '',
	  min_date:null,
      value:null
    };
    
	
  },
  watch:{
    'form.start_date'(newDate, oldDate) {
      if(moment(this.form.start_date).isValid() && this.form.end_date != null && moment(this.form.end_date).isValid() && moment(this.form.end_date).isBefore(this.form.start_date))
      {
        this.form.end_date = null;
      }
    }, 
    'form.end_date'(newDate, oldDate) {
      if(moment(this.form.start_date).isValid() && this.form.end_date != null && moment(this.form.end_date).isValid() && moment(this.form.end_date).isBefore(this.form.start_date))
      {
        this.form.end_date = null;
      }
    }, 
    'editform.start_date'(newDate, oldDate) {
      if(moment(this.editform.start_date).isValid() && this.editform.end_date != null && moment(this.editform.end_date).isValid() && moment(this.editform.end_date).isBefore(this.editform.start_date))
      {
        this.editform.end_date = null;
      }
    }, 
    'editform.end_date'(newDate, oldDate) {
      if(moment(this.editform.start_date).isValid() && this.editform.end_date != null && moment(this.editform.end_date).isValid() && moment(this.editform.end_date).isBefore(this.editform.start_date))
      {
        this.editform.end_date = null;
      }
    }, 
  },
  methods: {
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(item) {
      item.avatar = null;
      item.avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    summernoteChange(newValue) {
    
    },
    summernoteImageLinkInsert(...args) {
     
    },
	onSubmit(values) {
		
		values.start_date = moment(this.form.start_date).format('YYYY-MM-DD');
		values.end_date = moment(this.form.end_date).format('YYYY-MM-DD');
		values.description = this.form.description;
		//values.deadline = moment(this.form.end_date).format('YYYY-MM-DD');
		
		if(this.createfile != ""){
			values.file = this.createfile;
		}
		
		this.$emit("create-project",values);
	},
	onUpdate(formval){
		
		if(this.editfile != ""){
			this.editform.file = this.editfile;
		}
		
		this.$emit("update-project",this.editform);
		
	},
	closeDialog(){
		this.$refs.addprojectclose.click();
		this.$refs.editprojectclose.click();
		this.$refs.delprojectclose.click();
	},
	deleteProject(){
		this.$emit("delete-proj",this.delprojid);
	},
	updateTeamMembers(){
		
		this.selectedteammembers = {};
		
		if(this.form.teams > 0){
		
			for(let i=0;i<this.teams_list.length;i++){
				
				if(this.teams_list[i].id = this.form.teams){
					
					if(this.teams_list[i].team_members){
						this.selectedteammembers = this.teams_list[i].team_members;
					}
				}
			}
		}else{
			
			this.selectedteammembers = {};
		}
		
	},
	updateeditTeamMembers(){
		
		/*this.selectededitteammembers = {};
		
		if(this.editform.teams > 0){
		
			for(let i=0;i<this.teams_list.length;i++){
				
				if(this.teams_list[i].id = this.editform.teams){
					
					if(this.teams_list[i].team_members){
						this.selectededitteammembers = this.teams_list[i].team_members;
					}
				}
			}
		}else{
			
			this.selectededitteammembers = {};
		}*/
		
	},
	handleFileUploadCreate(event){
		this.createfile = event.target.files[0];
		
		console.log(this.createfile);
	},
	handleFileUpload(event){
		this.editfile = event.target.files[0];
	},
	updateTeamLead(){

    let user = this.team_leads.find(member => member?.id === this.form?.leader);

    if(user)
    {
      this.teamleaddata = user
    }
    else
    {
      this.teamleaddata = {}
    }
	},
	updateeditTeamLead(){
		
    let user = this.team_leads.find(member => member?.id === this.editform?.leader);

    if(user)
    {
      this.editteamleaddata = user
    }
    else
    {
      this.editteamleaddata = {}
    }
  },
},
  setup() {
    const schema = Yup.object().shape({
	  project_name: Yup.string().required("Project Name is required"),
	  rate: Yup.string().required("Rate is required"),
	  client_id: Yup.string().required("Client is required"),
	  leader: Yup.string().required("Leader is required"),
	  teams: Yup.string().required("Team is required"),
	  priority: Yup.string().required("Team is required"),
	  wages_id: Yup.string().required("Wage is required"),
	});
	
	const editschema = Yup.object().shape({
	  project_name: Yup.string().required("Project Name is required"),
	  rate: Yup.string().required("Rate is required"),
	  client_id: Yup.string().required("Client is required"),
	  leader: Yup.string().required("Leader is required"),
	  teams: Yup.string().required("Team is required"),
	  priority: Yup.string().required("Team is required"),
	  wages_id: Yup.string().required("Wage is required"),
	});
	
	
	return {
      schema,
	  editschema
	};
  }
};
</script>
