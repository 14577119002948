<template>
  <!-- Profile Modal -->
  <div id="profile_info"   class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Profile Information</h5>
          <button
            ref="update_profile_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="profile-img-wrap edit-img">
                  <form ref="uploadImageFrm">
                  <img
                      class="inline-block"
                      :src="(selectedImage && selectedImage != null) ? selectedImage : avatarSrc"
                      alt="user"
                    />
                  <div class="fileupload btn">
                    <span class="btn-text">edit</span>
                    <input class="upload" name="avatar" type="file" @change="handleImageChange"/>
                    <input type="hidden" name="employee_id" :value="employeeData?.id" />
                  </div>
                  </form>
                </div>
                
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label">First Name</label>
                      <input type="text" class="form-control" v-model="employee_profile.first_name"   />
                      <span class="text-danger" v-if="employee_errors && employee_errors?.first_name && employee_errors?.first_name != null">{{ employee_errors?.first_name[0]  }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Last Name</label>
                      <input type="text" class="form-control" v-model="employee_profile.last_name" />
                      <span class="text-danger" v-if="employee_errors && employee_errors?.last_name && employee_errors?.last_name != null">{{ employee_errors?.last_name[0]  }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Birth Date <span class="text-danger">*</span></label>
                      <div class="cal-icon">
                        <datepicker
                          v-model="employee_profile.birth_date_dd"
                          placeholder="Choose Date"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          :input-format="dateFormat"
                        />
                      </div>
                      <span class="text-danger" v-if="employee_errors && employee_errors?.birth_date && employee_errors?.birth_date != null">{{ employee_errors?.birth_date[0]  }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Gender</label>
                      <select  class="form-select form-select-lg" v-model="employee_profile.gender">
                        <option v-for="gndr in genders" :key="gndr.key" :value="gndr.key">{{ gndr?.value }}</option>
                      </select>
                      <span class="text-danger" v-if="employee_errors && employee_errors?.gender && employee_errors?.gender != null">{{ employee_errors?.gender[0]  }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <label class="col-form-label">Address</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="employee_profile.address"
                  />
                  <span class="text-danger" v-if="employee_errors && employee_errors?.address && employee_errors?.address != null">{{ employee_errors?.address[0]  }}</span>

                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">State</label>
                  <input type="text" class="form-control" v-model="employee_profile.state_id" />
                  <span class="text-danger" v-if="employee_errors && employee_errors?.state_id && employee_errors?.state_id != null">{{ employee_errors?.state_id[0]  }}</span>

                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Country</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="employee_profile.country"
                  />
                  <span class="text-danger" v-if="employee_errors && employee_errors?.country && employee_errors?.country != null">{{ employee_errors?.country[0]  }}</span>

                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Pin Code</label>
                  <input type="text" class="form-control" v-model="employee_profile.pincode"  />
                  <span class="text-danger" v-if="employee_errors && employee_errors?.pincode && employee_errors?.pincode != null">{{ employee_errors?.pincode[0]  }}</span>

                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="employee_profile.contact_number"
                  />
                  <span class="text-danger" v-if="employee_errors && employee_errors?.contact_number && employee_errors?.contact_number != null">{{ employee_errors?.contact_number[0]  }}</span>

                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Department <span class="text-danger">*</span></label
                  >
                  <select  class="form-select form-select-lg" v-model="employee_profile.department_id">
                    <option v-for="dprtmnt in Departments" :key="dprtmnt.id" :value="dprtmnt.id">{{ dprtmnt?.name }}</option>
                  </select>
                  <span class="text-danger" v-if="employee_errors && employee_errors?.department_id && employee_errors?.department_id != null">{{ employee_errors?.department_id[0]  }}</span>

                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Designation <span class="text-danger">*</span></label
                  >
                  <select  class="form-select form-select-lg" v-model="employee_profile.designation_id">
                    <option v-for="desgntn in Designations" :key="desgntn.id" :value="desgntn.id">{{ desgntn?.name }}</option>
                  </select>
                  <span class="text-danger" v-if="employee_errors && employee_errors?.designation_id && employee_errors?.designation_id != null">{{ employee_errors?.designation_id[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Reports To <span class="text-danger">*</span></label
                  >
                  <select  class="form-select form-select-lg" v-model="employee_profile.reports_to">
                    <option v-for="rprtr in Reporters" :key="rprtr.id" :value="rprtr.id">{{ rprtr?.first_name }} {{ rprtr?.last_name }}</option>
                  </select>
                  <span class="text-danger" v-if="employee_errors && employee_errors?.reports_to && employee_errors?.reports_to != null">{{ employee_errors?.reports_to[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Status <span class="text-danger">*</span></label
                  >
                  <select  class="form-select form-select-lg" v-model="employee_profile.status">
                    <option value=''>Select Status</option>
                    <option value='1'>Active</option>
                    <option value='0'>Inactive</option>
                  </select>
                  <span class="text-danger" v-if="employee_errors && employee_errors?.status && employee_errors?.status != null">{{ employee_errors?.status[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Termination Date</label
                  >
                  <div class="cal-icon">
                    <datepicker
                      v-model="employee_profile.termination_date_dd"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                  <span class="text-danger" v-if="employee_errors && employee_errors?.termination_date && employee_errors?.termination_date != null">{{ employee_errors?.termination_date[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Relieving Date</label
                  >
                  <div class="cal-icon">
                    <datepicker
                      v-model="employee_profile.relieving_date_dd"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                  <span class="text-danger" v-if="employee_errors && employee_errors?.relieving_date && employee_errors?.relieving_date != null">{{ employee_errors?.relieving_date[0]  }}</span>
                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" :disabled="disableButton" @click="updateProfileData()">Submit</button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Profile Modal -->

  <!-- Personal Info Modal -->
  <div id="personal_info_modal" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Personal Information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="update_personal_profile_close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
               <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">SSN No.</label>
                  <input type="text" class="form-control" v-model="employee_personal_profile.ssn_number"/>
                  <span class="text-danger" v-if="employee_errors && employee_errors?.ssn_number && employee_errors?.ssn_number != null">{{ employee_errors?.ssn_number[0]  }}</span>

                </div>
              </div>
              <!--<div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Passport Expiry Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="employee_personal_profile.passport_expire_at_dd"
                      placeholder="Choose Date"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  <span class="text-danger" v-if="employee_errors && employee_errors?.passport_expire_at && employee_errors?.passport_expire_at != null">{{ employee_errors?.passport_expire_at[0]  }}</span>
                  </div>
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Tel</label>
                  <input class="form-control" type="text" v-model="employee_personal_profile.telephone_number" />
                  <span class="text-danger" v-if="employee_errors && employee_errors?.telephone_number && employee_errors?.telephone_number != null">{{ employee_errors?.telephone_number[0]  }}</span>

                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Nationality <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" v-model="employee_personal_profile.nationality" />
                  <span class="text-danger" v-if="employee_errors && employee_errors?.nationality && employee_errors?.nationality != null">{{ employee_errors?.nationality[0]  }}</span>

                </div>
              </div> -->
              <!-- <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Religion</label>
                  <div>
                    <input class="form-control" type="text" v-model="employee_personal_profile.religion" />
                  <span class="text-danger" v-if="employee_errors && employee_errors?.religion && employee_errors?.religion != null">{{ employee_errors?.religion[0]  }}</span>

                  </div>
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Marital status <span class="text-danger">*</span></label
                  >
                  <select  class="form-select form-select-lg" v-model="employee_personal_profile.marital_status">
                      <option value="">Select</option>  
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                  </select>
                  <span class="text-danger" v-if="employee_errors && employee_errors?.marital_status && employee_errors?.marital_status != null">{{ employee_errors?.marital_status[0]  }}</span>

                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Employment of spouse</label>
                  <input class="form-control" type="text" v-model="employee_personal_profile.employment_of_spouse"/>
                  <span class="text-danger" v-if="employee_errors && employee_errors?.employment_of_spouse && employee_errors?.employment_of_spouse != null">{{ employee_errors?.employment_of_spouse[0]  }}</span>

                </div>
              </div> -->
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">No. of children </label>
                  <input class="form-control" type="number" v-model="employee_personal_profile.no_of_child"/>
                  <span class="text-danger" v-if="employee_errors && employee_errors?.no_of_child && employee_errors?.no_of_child != null">{{ employee_errors?.no_of_child[0]  }}</span>

                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" @click="updatePersonalProfileData()">Submit</button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Personal Info Modal -->
  <!-- Personal Info Modal -->
  <div id="raise-issue" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Raise Issue</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <label class="col-form-label">Description</label>
                  <textarea rows="4" class="form-control"></textarea>
                </div>
              </div>
            </div>
            <div class="submit-section mt-0">
              <button class="btn btn-primary submit-btn w-100">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Personal Info Modal -->
  <!-- Family Info Modal -->
  <div id="family_info_modal" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Family Information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="family_data_close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
            <div class="form-scroll">
              <div class="card">
                <div class="card-body" v-for="(employee_family, index) in employee_family_data" :key="'family_form_'+index">
                  <h3 class="card-title">
                    Family Member
                    <a href="javascript:void(0);" class="delete-icon" @click="confirm_delete_family_data(employee_family?.id,index)"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </h3>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Name <span class="text-danger">*</span></label
                        >
                        <input class="form-control" type="text" v-model="employee_family.name"/>
                        <span class="text-danger" v-if="employee_errors && employee_errors['family_member_details.'+index+'.name'] && employee_errors['family_member_details.'+index+'.name'] != null">{{ employee_errors['family_member_details.'+index+'.name'][0]  }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Relationship
                          <span class="text-danger">*</span></label
                        >
                        <input class="form-control" type="text" v-model="employee_family.relationship" />
                        <span class="text-danger" v-if="employee_errors && employee_errors['family_member_details.'+index+'.relationship'] && employee_errors['family_member_details.'+index+'.relationship'] != null">{{ employee_errors['family_member_details.'+index+'.relationship'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Date of birth
                          <span class="text-danger">*</span></label
                        >
                        <div class="cal-icon">
                        <datepicker
                          v-model="employee_family.birth_date_dd"
                          placeholder="Choose Date"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          :input-format="dateFormat"
                        />
                      </div>
                        <span class="text-danger" v-if="employee_errors && employee_errors['family_member_details.'+index+'.birth_date'] && employee_errors['family_member_details.'+index+'.birth_date'] != null">{{ employee_errors['family_member_details.'+index+'.birth_date'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Phone <span class="text-danger">*</span></label
                        >
                        <input class="form-control" type="text" v-model="employee_family.contact_number" />
                        <span class="text-danger" v-if="employee_errors && employee_errors['family_member_details.'+index+'.contact_number'] && employee_errors['family_member_details.'+index+'.contact_number'] != null">{{ employee_errors['family_member_details.'+index+'.contact_number'][0]  }}</span>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                    <div class="add-more">
                    <a href="javascript:void(0);"
                      @click="addNewFamily()"><i class="fa fa-plus-circle"></i> Add More</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" :disabled="disableButton" @click="updateFamilyMembersData()">Submit</button>
            </div>
        
        </div>
      </div>
    </div>
  </div>
  <!-- /Family Info Modal -->

  <!-- Emergency Contact Modal -->
  <div
    id="emergency_contact_modal"
    class="modal custom-modal fade"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Emergency Contact Information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="update_emergency_contact_close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title">Primary Contact</h3>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"
                        >Name <span class="text-danger">*</span></label
                      >
                      <input type="text" class="form-control" v-model="employee_emergency_contact.primary_contact.name"/>
                      <span class="text-danger" v-if="employee_errors && employee_errors['primary_contact.name'] && employee_errors['primary_contact.name'] != null">{{ employee_errors['primary_contact.name'][0]  }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"
                        >Relationship <span class="text-danger">*</span></label
                      >
                      <input class="form-control" type="text" v-model="employee_emergency_contact.primary_contact.relationship" />
                      <span class="text-danger" v-if="employee_errors && employee_errors['primary_contact.relationship'] && employee_errors['primary_contact.relationship'] != null">{{ employee_errors['primary_contact.relationship'][0]  }}</span>

                      
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"
                        >Phone <span class="text-danger">*</span></label
                      >
                      <input class="form-control" type="text" v-model="employee_emergency_contact.primary_contact.contact_number_1" />
                      <span class="text-danger" v-if="employee_errors && employee_errors['primary_contact.contact_number_1'] && employee_errors['primary_contact.contact_number_1'] != null">{{ employee_errors['primary_contact.contact_number_1'][0]  }}</span>

                      
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Phone 2</label>
                      <input class="form-control" type="text" v-model="employee_emergency_contact.primary_contact.contact_number_2" />
                      <span class="text-danger" v-if="employee_errors && employee_errors['primary_contact.contact_number_2'] && employee_errors['primary_contact.contact_number_2'] != null">{{ employee_errors['primary_contact.contact_number_2'][0]  }}</span>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h3 class="card-title">Secondary Contact</h3>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"
                        >Name <span class="text-danger">*</span></label
                      >
                      <input type="text" class="form-control" v-model="employee_emergency_contact.secondary_contact.name"/>
                      <span class="text-danger" v-if="employee_errors && employee_errors['secondary_contact.name'] && employee_errors['secondary_contact.name'] != null">{{ employee_errors['secondary_contact.name'][0]  }}</span>

                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"
                        >Relationship <span class="text-danger">*</span></label
                      >
                      <input class="form-control" type="text" v-model="employee_emergency_contact.secondary_contact.relationship" />
                      <span class="text-danger" v-if="employee_errors && employee_errors['secondary_contact.relationship'] && employee_errors['secondary_contact.relationship'] != null">{{ employee_errors['secondary_contact.relationship'][0]  }}</span>

                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"
                        >Phone <span class="text-danger">*</span></label
                      >
                      <input class="form-control" type="text" v-model="employee_emergency_contact.secondary_contact.contact_number_1" />
                      <span class="text-danger" v-if="employee_errors && employee_errors['secondary_contact.contact_number_1'] && employee_errors['secondary_contact.contact_number_1'] != null">{{ employee_errors['secondary_contact.contact_number_1'][0]  }}</span>

                      
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Phone 2</label>
                      <input class="form-control" type="text" v-model="employee_emergency_contact.secondary_contact.contact_number_2" />
                      <span class="text-danger" v-if="employee_errors && employee_errors['secondary_contact.contact_number_2'] && employee_errors['secondary_contact.contact_number_2'] != null">{{ employee_errors['secondary_contact.contact_number_2'][0]  }}</span>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" @click="updateEmployeeContactData()">Submit</button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Emergency Contact Modal -->

  <!-- Education Modal -->
  <div id="education_info" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Education Informations</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="update_edu_close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
            <div class="form-scroll">
              <div class="card" v-for="(edu_emp, index) in education_information" :key="'edu_emp_'+index">
                <div class="card-body">
                  <h3 class="card-title">
                    Education Informations 
                    <a  @click="confirm_delete_education_data(edu_emp?.id,index)" href="javascript:void(0);" class="delete-icon"><i class="far fa-trash-alt"></i
                    ></a>
                  </h3>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="input-block mb-3 form-focus focused">
                        <input
                          type="text"
                          v-model="edu_emp.institution"
                          class="form-control floating"

                        />
                        <label class="focus-label">Institution</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['education_information.'+index+'.institution'] && employee_errors['education_information.'+index+'.institution'] != null">{{ employee_errors['education_information.'+index+'.institution'][0]  }}</span>


                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <select v-model="edu_emp.subject" class="form-select form-select-lg">
                            <option value="10th (Secondary and Higher Secondary Education)">10th (Secondary and Higher Secondary Education)</option>
                            <option value="12th (Secondary and Higher Secondary Education)">12th (Secondary and Higher Secondary Education)</option>
                            <option value="Graduation">Graduation</option>
                            <option value="Post Gratuation">Post Gratuation</option>
                            <option value="Other">Other</option>
                        </select>
                        <label class="focus-label">Subject {{edu_emp.subject}}</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['education_information.'+index+'.subject'] && employee_errors['education_information.'+index+'.subject'] != null">{{ employee_errors['education_information.'+index+'.subject'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6" v-if="edu_emp.subject == 'Other'">
                      <div class="input-block mb-3 form-focus focused">
                        <input
                          type="text"
                          v-model="edu_emp.other_subject_name"
                          class="form-control floating"
                        />
                        <label class="focus-label">Other Subject Name</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['education_information.'+index+'.other_subject_name'] && employee_errors['education_information.'+index+'.other_subject_name'] != null">{{ employee_errors['education_information.'+index+'.other_subject_name'][0]  }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <div class="cal-icon">
                          <datepicker
                            v-model="edu_emp.starting_date_dd"
                            Value="01/06/2022"
                            class="form-control floating datetimepicker"
                            :editable="true"
                            :clearable="false"
                            :input-format="dateFormat"
                          />
                        </div>
                        <label class="focus-label">Starting Date</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['education_information.'+index+'.starting_date'] && employee_errors['education_information.'+index+'.starting_date'] != null">{{ employee_errors['education_information.'+index+'.starting_date'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <div class="cal-icon">
                          <datepicker
                           v-model="edu_emp.complete_date_dd"
                            Value="31/05/2023"
                            class="form-control floating datetimepicker"
                            :editable="true"
                            :clearable="false"
                            :input-format="dateFormat"
                          />
                        </div>
                        <label class="focus-label">Complete Date</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['education_information.'+index+'.complete_date'] && employee_errors['education_information.'+index+'.complete_date'] != null">{{ employee_errors['education_information.'+index+'.complete_date'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <input
                          type="text"
                          v-model="edu_emp.degree"
                          class="form-control floating"
                        />
                        <label class="focus-label">Degree</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['education_information.'+index+'.degree'] && employee_errors['education_information.'+index+'.degree'] != null">{{ employee_errors['education_information.'+index+'.degree'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <input
                          type="text"
                          v-model="edu_emp.grade"
                          class="form-control floating"
                        />
                        <label class="focus-label">Grade</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['education_information.'+index+'.grade'] && employee_errors['education_information.'+index+'.grade'] != null">{{ employee_errors['education_information.'+index+'.grade'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                          data-buttontext="Choose File"
                          data-icon="false"
                          class="form-control floating"
                          @change="handleFileUpload($event,index)"
                        />
                        <label class="focus-label">Document</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['education_information.'+index+'.document'] && employee_errors['education_information.'+index+'.document'] != null">{{ employee_errors['education_information.'+index+'.document'][0]  }}</span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="add-more">
                <a href="javascript:void(0);"
                @click="addMoreEducation()"
                  ><i class="fa fa-plus-circle"></i> Add More</a
                >
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" @click="updateEducationData()">Submit</button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Education Modal -->

  <!-- Experience Modal -->
  <div id="experience_info" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Experience Information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="experience_model_close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
         
            <div class="form-scroll">
              <div class="card"  v-for="(experience_dtl,index) in experience_information" :key="'experience_dtl_'+experience_dtl.key">
                <div class="card-body">
                  <h3 class="card-title">
                    Experience Information
                    <a  href="javascript:void(0);" class="delete-icon"
                    @click="delete_experience(experience_dtl?.id,index)"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </h3>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <input
                          type="text"
                          class="form-control floating"
                          v-model="experience_dtl.company_name"
                        />
                        <label class="focus-label floating">Company Name</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['experience_information.'+index+'.company_name'] && employee_errors['experience_information.'+index+'.company_name'] != null">{{ employee_errors['experience_information.'+index+'.company_name'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <input
                          type="text"
                          class="form-control floating"
                          v-model="experience_dtl.location"
                        />
                        <label class="focus-label">Location</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['experience_information.'+index+'.location'] && employee_errors['experience_information.'+index+'.location'] != null">{{ employee_errors['experience_information.'+index+'.location'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <input
                          type="text"
                          class="form-control floating"
                          v-model="experience_dtl.job_position"
                        />
                        <label class="focus-label">Job Position</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['experience_information.'+index+'.job_position'] && employee_errors['experience_information.'+index+'.job_position'] != null">{{ employee_errors['experience_information.'+index+'.job_position'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <div class="cal-icon">
                          <datepicker
                            v-model="experience_dtl.job_period_from_dd"
                            class="form-control floating datetimepicker"
                            :editable="true"
                            :clearable="false"
                            :input-format="dateFormat"
                          />
                        </div>
                        <label class="focus-label">Period From</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['experience_information.'+index+'.job_period_from'] && employee_errors['experience_information.'+index+'.job_period_from'] != null">{{ employee_errors['experience_information.'+index+'.job_period_from'][0]  }}</span>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3 form-focus focused">
                        <div class="cal-icon">
                          <datepicker
                            v-model="experience_dtl.job_period_to_dd"
                            class="form-control floating datetimepicker"
                            :editable="true"
                            :clearable="false"
                            :input-format="dateFormat"
                          />
                        </div>
                        <label class="focus-label">Period To</label>
                        <span class="text-danger" v-if="employee_errors && employee_errors['experience_information.'+index+'.job_period_to'] && employee_errors['experience_information.'+index+'.job_period_to'] != null">{{ employee_errors['experience_information.'+index+'.job_period_to'][0]  }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="add-more">
                <a href="javascript:void(0);"
                @click="addMoreExperience()"
                  ><i class="fa fa-plus-circle"></i> Add More</a
                >
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" @click="updateExpericeData()">Submit</button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Experience Modal -->

  <!-- Bank Detail Modal -->
  <div
    id="employee_bank_modal"
    class="modal custom-modal fade"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bank Information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="update_bank_information_close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Bank Name<span class="text-danger">*</span></label
                >
                <input type="text" class="form-control" v-model="employee_bank_detail.bank_name"/>
                <span class="text-danger" v-if="employee_errors && employee_errors?.bank_name && employee_errors?.bank_name != null">{{ employee_errors?.bank_name[0]  }}</span>

              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Bank Account <span class="text-danger">*</span></label
                >
                <input class="form-control" type="text" v-model="employee_bank_detail.bank_account_number" />
                <span class="text-danger" v-if="employee_errors && employee_errors?.bank_account_number && employee_errors?.bank_account_number != null">{{ employee_errors?.bank_account_number[0]  }}</span>

              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >IFSC Code <span class="text-danger">*</span></label
                >
                <input class="form-control" type="text" v-model="employee_bank_detail.IFSC_code" />
                <span class="text-danger" v-if="employee_errors && employee_errors?.IFSC_code && employee_errors?.IFSC_code != null">{{ employee_errors?.IFSC_code[0]  }}</span>

              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block mb-3">
                <label class="col-form-label">PAN No <span class="text-danger">*</span></label>
                <input class="form-control" type="text" v-model="employee_bank_detail.pan_number" />
                <span class="text-danger" v-if="employee_errors && employee_errors?.pan_number && employee_errors?.pan_number != null">{{ employee_errors?.pan_number[0]  }}</span>
              </div>
            </div>
          </div>
          <div class="submit-section">
            <button class="btn btn-primary submit-btn" @click="updateBankDetails()" :disabled="disableButton">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Bank Detail Modal -->

  <div
    class="modal custom-modal fade"
    id="add-assign"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Assign Asset</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-md-12 selectnew">
                <div class="input-block mb-3">
                  <label class="col-form-label">Department</label>
                  <vue-select
                    :options="AddassetsDepartment"
                    id="AddassetsDepartment"
                    placeholder="Department 1"
                  />
                </div>
              </div>
              <div class="col-md-12 selectnew">
                <div class="input-block mb-3">
                  <label class="col-form-label">Assign to</label>
                  <vue-select
                    :options="Addassetsassign"
                    id="Addassetsassign"
                    placeholder="Customer"
                  />
                </div>
              </div>
              <div class="submit-section mt-3">
                <button class="btn btn-primary submit-btns w-100">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ref } from "vue";
import { notification } from "ant-design-vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());
const currentDateThree = ref(new Date());
export default {
  emits:['reload-data'],
  data() {
    return {
      imageFile:null,
      select2: null,
      Profilegender: ["Male", "Female"],
      Profiledepartment: [
        "Select Department",
        "Web Development",
        "IT Management",
        "Marketing",
      ],
      Profiledesignation: [
        "Select Designation",
        "Web Designer",
        "Web Developer",
        "Android Developer",
      ],
      Profilereports: ["-", "Wilmer Deluna", "Lesley Grauer", "Jeffery Lalor"],
      Profilestatus: ["Single", "Married"],
      AddassetsDepartment: ["Department 1", "Department 2"],
      Addassetsassign: ["Customer", "Client"],
      startdate: currentDate,
      startdateone: currentDateOne,
      startdateTwo: currentDateTwo,
      startdateThree: currentDateThree,
      dateFormat: 'dd-MM-yyyy',
      genders:[{
        key:'f',
        value:'Female'
      },{
        key:'m',
        value:'Male'
      },
      {
        key:'o',
        value:'Others'
      }],
      employee_emergency_contact:{
        id:null,
        primary_contact:{
          id:null,
          name:'',
          relationship:'',
          contact_number_1:'',
          contact_number_2:'',
          contact_type:'p'
        },
        secondary_contact:{
          id:null,
          name:'',
          relationship:'',
          contact_number_1:'',
          contact_number_2:'',
          contact_type:'s'
        },
      },
      employee_bank_detail:{
        id:null,
        bank_name:'',
        bank_account_number:'',
        IFSC_code:'',
        pan_number:''
      },
      selectedImage:null,
      disableButton:false,
      employee_errors:{},
      employee_profile:{
        first_name:null,
        last_name:null,
        gender:null, 
        department_id:null,
        designation_id:null,
        birth_date:null,
        address:null,
        state:null,
        country:null,
        pincode:null,
        contact_number:null,
        birth_date_dd:null,
        reports_to:null,
        status:'',
        termination_date_dd:null,
        relieving_date_dd:null
      },
      employee_personal_profile:{
       ssn_number:null,
       // passport_expire_at:null,
       // passport_expire_at_dd:null,
        telephone_number:null,
      //  nationality:null,
      //  religion:null,
        marital_status:null,
      //  employment_of_spouse:null,
        no_of_child:null
      },
      
      employee_family_data:[
        {
          id:null,
          name:null,
          relationship:null,
          birth_date:null,
          contact_number:null,
          birth_date_dd:null
        }
      ],

      education_information:[
        {
          id:null, 
          institution:'',
          subject:'',
          other_subject_name:'',
          starting_date:null,
          starting_date_dd:null,
          complete_date:null,
          complete_date_dd:null,
          grade:'',
          degree:'',
          file:null
        }
      ],
      experience_information:[
        {
          id:null,
          company_name:'',
          location:'',
          job_position:'',
          job_period_from:'',
          job_period_from_dd:'',
          job_period_to:'',
          job_period_to_dd:'',
          user_id:''
        }
      ]
    };
    
  },
  name: "profilemodal",
  props:{
    employeeData: {
      type: Object,
      default: null,
      required:false
    },
  },
  computed: {
    ...mapGetters(['getCompanies','getDesignations','getDepartments','getReporter']),
    Companies() {
      return this.getCompanies?.record ? this.getCompanies.record : []; 
    },
    Designations() {
      return this.getDesignations ? this.getDesignations : [];
    },
    Departments() {
      return this.getDepartments ? this.getDepartments : [];
    },
    avatarSrc() {
    return this.employeeData?.avatar
      ? process.env.VUE_APP_IMAGE_BASE_URL+this.employeeData?.avatar
      : require('@/assets/img/profiles/avatar-02.jpg');
    },
    Reporters() {
      return this?.getReporter ? this?.getReporter : [];
    }
  },
  methods: {
    ...mapActions(['fetchReporters','updateProfile','fetchEmployeeProfileData',
    'getDateFormate','updateEmployeeProfile','fetchDepartments','fetchDesignations',
    'updatePersonalEmployeeProfile','updateEmployeeContact','updateEmployeeBankDetail',
  'updateFamilyMembersDetail','updateEducationDetail','updateExperienceDetail','deleteFamilyDetails',
  'deleteEducationDetails','deleteExperienceDetails']),


  handleFileUpload(event, index){
    const file = event.target.files[0] || null; 
    this.education_information[index].file = file;
  },

  async delete_experience(emp_exp_id,index)
  {
        this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
        }).then(async (t) =>  {

          if(t.value)
          {
            if(emp_exp_id && emp_exp_id != null)
            {
              const response = await this.deleteExperienceDetails(emp_exp_id);
              if(response.status == 200)
              {
                this.$emit('reload-data')
                notification.open({
                    message: 'Successfully Data deleted.',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                      background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                  });
              }
              else
              {
                this.selectedImage = null;
                notification.open({
                    message: response?.message ?  response?.message : 'Something went wrong, Please try again later',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                      background: process.env.VUE_APP_WARNING_COLOR,
                    },
                  });
              }
            }
            else if(index != null)
            {

              this.experience_information.splice(index, 1);
              notification.open({
                    message: 'Successfully Data deleted.',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                      background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                  });
            }

            if(this.experience_information.length == 0)
            {
              this.experience_information.push({
                id:null,
                company_name:'',
                location:'',
                job_position:'',
                job_period_from:'',
                job_period_from_dd:'',
                job_period_to:'',
                job_period_to_dd:'',
                user_id:''
              })
            }
          }
        });
  },

  async confirm_delete_education_data(emp_edu_id,index)
  {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async (t) =>  {

        if(t.value)
        {
          if(emp_edu_id && emp_edu_id != null)
          {
            const response = await this.deleteEducationDetails(emp_edu_id);
            if(response.status == 200)
            {
              this.$emit('reload-data');
              notification.open({
                  message: 'Successfully Data deleted.',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                  },
                });
            }
            else
            {
              this.selectedImage = null;
              notification.open({
                  message: response?.message ?  response?.message : 'Something went wrong, Please try again later',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                  },
                });
            }
          }
          else if(index != null)
          {

            this.education_information.splice(index, 1);
            notification.open({
                  message: 'Successfully Data deleted.',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                  },
                });
          }

          if(this.education_information.length == 0)
          {
            this.education_information.push({
              id:null, 
              institution:'',
              subject:'',
              other_subject_name:'',
              starting_date:null,
              starting_date_dd:null,
              complete_date:null,
              complete_date_dd:null,
              grade:'',
              degree:'',
              file:null
            })
          }
        }
      });
  },

  async confirm_delete_family_data(emp_family_id,index){
    
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async (t) =>  {

        if(t.value)
        {
          if(emp_family_id && emp_family_id != null)
          {
            const response = await this.deleteFamilyDetails(emp_family_id);
            if(response.status == 200)
            {
              this.$emit('reload-data');
              notification.open({
                  message: 'Successfully Data deleted.',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                  },
                });
            }
            else
            {
              this.selectedImage = null;
              notification.open({
                  message: response?.message ?  response?.message : 'Something went wrong, Please try again later',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                  },
                });
            }
          }
          else if(index != null)
          {

            this.employee_family_data.splice(index, 1);
            notification.open({
                  message: 'Successfully Data deleted.',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                  },
                });
          }

          if(this.employee_family_data.length == 0)
          {
            this.employee_family_data.push({
              id:null,
              name:null,
              relationship:null,
              birth_date:null,
              contact_number:null,
              birth_date_dd:null
            })
          }
        }
      });
   
    },
    async handleImageChange() {

      const formData = new FormData(this.$refs.uploadImageFrm);
      const response = await this.updateProfile({data:formData,id:this?.employeeData?.id});
      if(response.status == 200)
      {
        if(response?.data?.employee?.avatar != null)
        {
          this.selectedImage =  process.env.VUE_APP_IMAGE_BASE_URL+response?.data?.employee?.avatar;
        }

        await this.fetchEmployeeProfileData(this?.employeeData?.id);
        this.$emit('reload-data');
        notification.open({
            message: 'Successfully Profile Changed',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });
      }
      else
      {
        this.selectedImage = null;
        notification.open({
            message: response?.message ?  response?.message : 'Something went wrong, Please try again later',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
    },
    async updateProfileData(){
      this.disableButton = true;
      this.employee_errors = null;
      this.employee_profile.birth_date = await this.getDateFormate(this.employee_profile?.birth_date_dd);
      this.employee_profile.termination_date = this.employee_profile?.termination_date_dd ? await this.getDateFormate(this.employee_profile?.termination_date_dd) : '';
      this.employee_profile.relieving_date = this.employee_profile?.relieving_date_dd ? await this.getDateFormate(this.employee_profile?.relieving_date_dd) : '';

      const data = await this.updateEmployeeProfile(this.employee_profile);
     
      if(data?.errors && data.errors != null)
      {
          this.employee_errors = data.errors;
          this.disableButton = false;
          
          
          notification.open({
            message: 'Please fill up the mandatory fields.',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
      else
      {
        await this.fetchEmployeeProfileData(this.employee_profile?.id);
        this.$emit('reload-data')
        this.$refs.update_profile_close.click();
        this.employee_errors = null;
        notification.open({
            message: 'Successfully Employee Updated',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });
          this.disableButton = false;
      }
    },
    async updatePersonalProfileData(){
      this.disableButton = true;
      this.employee_errors = null;
     // this.employee_personal_profile.passport_expire_at = await this.getDateFormate(this.employee_profile?.passport_expire_at_dd);
      const data = await this.updatePersonalEmployeeProfile(this.employee_profile);
     
      if(data?.errors && data.errors != null)
      {
          this.employee_errors = data.errors;
          this.disableButton = false;
          
          notification.open({
            message: 'Please fill up the mandatory fields.',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
      else
      {
        this.$emit('reload-data')
        this.$refs.update_personal_profile_close.click();
        await this.fetchEmployeeProfileData(this.employee_profile?.id)
        this.employee_errors = null;
        notification.open({
            message: 'Successfully Employee Personal Details Updated',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });
          this.disableButton = false;
      }
    },
    async updateEmployeeContactData(){
      this.disableButton = true;
      this.employee_errors = null;

      this.employee_emergency_contact.id = this.employeeData?.id;

      const data = await this.updateEmployeeContact(this.employee_emergency_contact);

      if(data?.errors && data.errors != null)
      {
          this.employee_errors = data.errors;
          this.disableButton = false;
          
          notification.open({
            message: 'Please fill up the mandatory fields.',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
      else
      {
        this.disableButton = false;
        this.$refs.update_emergency_contact_close.click();
        
        await this.fetchEmployeeProfileData(this.employee_profile?.id)
        this.employee_errors = null;
        notification.open({
            message: 'Successfully Employee Emergency Contact details Updated',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });
      }
    },
    async updateBankDetails(){
      this.disableButton = true;
      this.employee_errors = null;

      this.employee_bank_detail['user_id'] = this?.employeeData?.id;

      const data = await this.updateEmployeeBankDetail(this.employee_bank_detail);
     
      if(data?.errors && data.errors != null)
      {
          this.employee_errors = data.errors;
          this.disableButton = false;
          notification.open({
            message: 'Please fill up the mandatory fields.',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
      else
      {
        if(data.status == 200)
        {
          this.$emit('reload-data')
          this.$refs.update_bank_information_close.click();
          this.employee_errors = null;
          await this.fetchEmployeeProfileData(this.employee_profile?.id);
          notification.open({
              message: 'Successfully Employee\'s Bank Detail Updated',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });
            this.disableButton = false;
        }
        else
        {

          this.disableButton = false;
          notification.open({
            message: data?.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
        
      }
    },

    async updateExpericeData(){
      this.disableButton = true;
      this.employee_errors = null;

      this.experience_information['user_id'] = this?.employeeData?.id;

      for (let i = 0; i < this.experience_information.length; i++) {

        this.experience_information[i]['job_period_from'] = await this.getDateFormate(this?.experience_information[i]['job_period_from_dd']);
        this.experience_information[i]['job_period_to'] = await this.getDateFormate(this?.experience_information[i]['job_period_to_dd']);
      }

      const data = await this.updateExperienceDetail(this.experience_information);

      if(data?.errors && data.errors != null)
      {
        console.log( data.errors)
          this.employee_errors = data.errors;
          this.disableButton = false;
          
          notification.open({
            message: 'Please fill up the mandatory fields.',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
      else
      {
        this.disableButton = false;
        this.$refs.experience_model_close.click();
        this.$emit('reload-data')
       // await this.fetchEmployeeProfileData(this.employee_profile?.id)
        this.employee_errors = null;
        notification.open({
            message: 'Successfully Employee Experience details Updated',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });
      }
    },
    addNewFamily()
    {
      if(this.employee_family_data.length < 3)
      {

        const newArray = [...this.employee_family_data];

        newArray.push({
          name: null,
          id: null,
          relationship: null,
          birth_date_dd: null,
          birth_date: null,
          contact_number: null
        });

        this.employee_family_data = newArray;
      }
      else
      {
        notification.open({
            message: 'Max 3 Family member\'s details you can add!',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
      
    },
    addMoreEducation(){
      if(this.education_information.length < 5)
      {

        const newArray = [...this.education_information];
        newArray.push({
          id:null, 
          institution:'',
          subject:'',
          other_subject_name:'',
          starting_date:null,
          starting_date_dd:null,
          complete_date:null,
          complete_date_dd:null,
          grade:'',
          degree:'',
          file:null
        });

        this.education_information = newArray
      }
      else
      {
        notification.open({
            message: 'Max 5 Eductaion details you can add!',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
    },
    addMoreExperience(){
      if(this.experience_information.length < 2)
      {

        const newArray = [...this.experience_information];
        newArray.push( {
          id:null,
          company_name:'',
          location:'',
          job_position:'',
          job_period_from:'',
          job_period_from_dd:'',
          job_period_to:'',
          job_period_to_dd:'',
          user_id:''
        });

        this.experience_information = newArray
      }
      else
      {
        notification.open({
            message: 'Max 2 Experience details you can add!',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
    },
    async updateFamilyMembersData(){
      this.disableButton = true;
      this.employee_errors = null;
      this.employee_family_data['user_id'] = this?.employeeData?.id;

      for (let i = 0; i < this.employee_family_data.length; i++) {
        this.employee_family_data[i]['birth_date'] = this?.employee_family_data[i]['birth_date_dd'] ? await this.getDateFormate(this?.employee_family_data[i]['birth_date_dd']) : null; 
       
      }

      const data = await this.updateFamilyMembersDetail(this.employee_family_data);

      if(data?.errors && data.errors != null)
      {
          this.employee_errors = data.errors;
          this.disableButton = false;
          notification.open({
            message: 'Please fill up the mandatory fields.',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
      else
      {
        if(data.status == 200)
        {
          this.$emit('reload-data')
          this.$refs.family_data_close.click();
          this.employee_errors = null;
          await this.fetchEmployeeProfileData(this.employee_profile?.id);
          notification.open({
              message: 'Successfully Employee\'s Family Detail Updated',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });
            this.disableButton = false;
        }
        else
        {
          notification.open({
              message: 'Something went wrong',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            this.disableButton = false;
        }

      }

    },

    async updateEducationData(){
      this.disableButton = true;
      this.employee_errors = null;
      this.education_information['user_id'] = this?.$route?.params?.user_id;
      
      for (let i = 0; i < this.education_information.length; i++) {
        this.education_information[i]['starting_date'] = await this.getDateFormate(this.education_information[i]['starting_date_dd']); 
        this.education_information[i]['complete_date'] = await this.getDateFormate(this.education_information[i]['complete_date_dd']); 
        this.education_information[i]['user_id'] = this?.$route?.params?.user_id;
      }

      const data = await this.updateEducationDetail(this.education_information);

      if(data?.errors && data.errors != null)
      {
          this.employee_errors = data.errors;
          this.disableButton = false;
          notification.open({
            message: 'Please fill up the mandatory fields.',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
      else
      {
        if(data.status == 200)
        {
          this.$emit('reload-data')
          this.$refs.update_edu_close.click();
          this.employee_errors = null;
          await this.fetchEmployeeProfileData(this.employee_profile?.id);
          notification.open({
              message: 'Successfully Employee\'s Education Detail Updated',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });
            this.disableButton = false;
        }
      }

    }


  },
  watch:{
    employeeData:{
      handler(newVal){
        this.employee_errors = null;
        const employeeDataObject = Object.assign({}, newVal);

        if(employeeDataObject && employeeDataObject != 1)
        {
          this.employee_profile = employeeDataObject;
          if(employeeDataObject?.employee_bank_detail && employeeDataObject?.employee_bank_detail != null && employeeDataObject?.employee_bank_detail.length)
          {
            this.employee_bank_detail = employeeDataObject?.employee_bank_detail[0];
          }
          
          this.employee_profile.department_id = employeeDataObject?.department?.id ? employeeDataObject?.department?.id : null
          this.employee_profile.designation_id = employeeDataObject?.designation?.id ? employeeDataObject?.designation?.id : null 
          this.employee_profile.birth_date_dd = employeeDataObject?.birth_date ? new Date(employeeDataObject?.birth_date) : null
          this.employee_profile.passport_expire_at_dd = employeeDataObject?.passport_expire_at ? new Date(employeeDataObject?.passport_expire_at) : null        
          this.employee_personal_profile = employeeDataObject;
          
          this.employee_profile.termination_date_dd = employeeDataObject?.termination_date ? new Date(employeeDataObject?.termination_date) : null
          this.employee_profile.relieving_date_dd = employeeDataObject?.relieving_date ? new Date(employeeDataObject?.relieving_date) : null

          if(employeeDataObject?.employee_contact && employeeDataObject?.employee_contact != null && employeeDataObject?.employee_contact.length )
          {
            var primary_data = employeeDataObject?.employee_contact.find(contact => contact.contact_type === 'p') || null;

            if(primary_data)
            {
                primary_data = JSON.parse(JSON.stringify(primary_data))
                this.employee_emergency_contact.primary_contact = JSON.parse(JSON.stringify({
                  id: primary_data?.id,
                  name: primary_data?.name,
                  contact_type:'p',
                  contact_number_1:primary_data?.contact_number_1,
                  contact_number_2:primary_data?.contact_number_2,
                  relationship:primary_data?.relationship,
                  user_id:primary_data?.user_id
                }))
            
            }
            var secondary_data = employeeDataObject?.employee_contact.find(contact => contact.contact_type === 's') || null;
            if(secondary_data)
            { 
                secondary_data = JSON.parse(JSON.stringify(secondary_data))
                this.employee_emergency_contact.secondary_contact = JSON.parse(JSON.stringify({
                  id: secondary_data?.id,
                  name: secondary_data?.name,
                  contact_type:'p',
                  contact_number_1:secondary_data?.contact_number_1,
                  contact_number_2:secondary_data?.contact_number_2,
                  relationship:secondary_data?.relationship,
                  user_id:secondary_data?.user_id
                }))
            }
          }

          if(employeeDataObject?.employee_family_detail && employeeDataObject?.employee_family_detail != null && employeeDataObject?.employee_family_detail.length > 0)
          {
            const empData = employeeDataObject?.employee_family_detail;
            this.employee_family_data = empData

            for (let i = 0; i < this.employee_family_data.length; i++) {
              this.employee_family_data[i]['birth_date_dd'] = new Date(this.employee_family_data[i]['birth_date']) ? new Date(this.employee_family_data[i]['birth_date']) : null; 
            }
          }
          else
          {
            this.employee_family_data = [
              {
                id:null,
                name:null,
                relationship:null,
                birth_date:null,
                contact_number:null,
                birth_date_dd:null
              }
            ];
          }

          if(employeeDataObject?.education_information && employeeDataObject?.education_information != null && employeeDataObject?.education_information.length > 0)
          {
            this.education_information = employeeDataObject?.education_information;

            for (let i = 0; i < this.education_information.length; i++) {
              this.education_information[i]['starting_date_dd'] = new Date(this.education_information[i]['starting_date']) ? new Date(this.education_information[i]['starting_date']) : null; 
              this.education_information[i]['complete_date_dd'] = new Date(this.education_information[i]['complete_date']) ? new Date(this.education_information[i]['complete_date']) : null; 
            }
          }
          else
          {
            this.education_information =   [
                  {
                    id:null, 
                    institution:'',
                    subject:'',
                    other_subject_name:'',
                    starting_date:null,
                    starting_date_dd:null,
                    complete_date:null,
                    complete_date_dd:null,
                    grade:'',
                    degree:'',
                    file:null
                  }
                ]
          }

          if(employeeDataObject?.employee_experience && employeeDataObject?.employee_experience != null && employeeDataObject?.employee_experience.length > 0)
          {
            this.experience_information =employeeDataObject?.employee_experience;

            for (let i = 0; i < this.experience_information.length; i++) {
              this.experience_information[i]['job_period_from_dd'] = new Date(this.experience_information[i]['job_period_from']) ? new Date(this.experience_information[i]['job_period_from']) : null; 
              this.experience_information[i]['job_period_to_dd'] = new Date(this.experience_information[i]['job_period_to']) ? new Date(this.experience_information[i]['job_period_to']) : null;
            } 
          }
          else
          {
            this.experience_information = [
                  {
                    id:null,
                    company_name:'',
                    location:'',
                    job_position:'',
                    job_period_from:'',
                    job_period_from_dd:'',
                    job_period_to:'',
                    job_period_to_dd:'',
                    user_id:''
                  }
                ]
          }
        }
      },
      immediate: true
    }
  },
  async created(){
    await this.fetchDepartments();
    await this.fetchDesignations();
    await this.fetchReporters();
  },
};
</script>
