<template>
  <div class="main-warpper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <breadcrumb :title="title" :path="path" :text="text" />
        <!-- /Page Header -->

        <!-- Content Starts -->
        <div class="row">
          <div class="col-lg-8">
            <div class="card">
              <div class="card-body">
                <article class="post">
                  <h1 class="post-title">Lorem ipsum dolor sit amet</h1>
                  <ul class="meta">
                    <li><span>Created :</span> Feb, 04, 2023</li>
                    <li>
                      <span>Category:</span> <a href="javascript:void(0);">Category 1</a>,
                      <a href="javascript:void(0);">Category 2</a>
                    </li>
                    <li><span>Last Updated:</span> April, 15, 2023</li>
                    <li><span>Comments :</span> <a href="javascript:void(0);">5</a></li>
                    <li><span>Views :</span> 1198</li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat
                  </p>
                  <h2>Sed ut perspiciatis unde omnis iste</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat
                  </p>
                  <h2>Sed ut perspiciatis unde omnis iste</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat
                  </p>
                </article>
                <div class="feedback">
                  <h3>Was this article helpful to you?</h3>
                  <p>
                    <a href="javascript:void(0);" class="btn btn-success"
                      ><i class="fa-solid fa-thumbs-up"></i
                    ></a>
                    <a href="javascript:void(0);" class="btn btn-danger ms-1"
                      ><i class="fa-solid fa-thumbs-down"></i
                    ></a>
                  </p>
                  <p>29 found this helpful</p>
                </div>

                <div class="comment-section">
                  <div class="comments-area clearfix">
                    <h3>( 2 ) Comments</h3>
                    <ul class="comment-list">
                      <li>
                        <div class="comment">
                          <div class="comment-author">
                            <img
                              width="86"
                              height="86"
                              class="avatar avatar-86 photo"
                              src="@/assets/img/user.jpg"
                              alt="User Image"
                            />
                          </div>
                          <div class="comment-details">
                            <div class="author-name">
                              <a class="url" href="javascript:void(0);">John Doe</a>
                              <span class="commentmetadata ms-1 ms-1"
                                >October 25, 2023 at 01:10 pm</span
                              >
                            </div>
                            <div class="comment-body">
                              <p>
                                Integer id dolor libero. Cras in turpis nulla. Vivamus at
                                tellus erat. Nulla ligula sem, eleifend vitae semper et,
                                blandit a elit. Nam et ultrices lectus. Ut sit amet risus
                                eget neque scelerisque consectetur.
                              </p>
                            </div>
                            <div class="reply">
                              <a href="javascript:void(0);" class="comment-reply-link" rel="nofollow"
                                ><i class="fa-solid fa-reply"></i> Reply</a
                              >
                            </div>
                          </div>
                        </div>
                        <ul class="children">
                          <li>
                            <div class="comment">
                              <div class="comment-author">
                                <img
                                  width="86"
                                  height="86"
                                  class="avatar avatar-86 photo"
                                  src="@/assets/img/user.jpg"
                                  alt="User Image"
                                />
                              </div>
                              <div class="comment-details">
                                <div class="author-name">
                                  <a href="javascript:void(0);" class="comment-reply-link"></a
                                  ><a class="url" href="javascript:void(0);">Astin Robert</a>
                                  <span class="commentmetadata ms-1"
                                    >October 25, 2023 at 01:10 pm</span
                                  >
                                </div>
                                <div class="comment-body">
                                  <p>
                                    Mauris hendrerit consequat quam, sit amet fermentum
                                    metus cursus in. Nunc ac justo suscipit erat sagittis
                                    maximus a at tellus. Pellentesque congue nisi a nisl
                                    volutpat tempor.
                                  </p>
                                </div>
                                <div class="reply">
                                  <a href="javascript:void(0);" class="comment-reply-link"
                                    ><i class="fa-solid fa-reply"></i> Reply</a
                                  >
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div class="comment-reply">
                      <h3 class="comment-reply-title">Leave a Reply</h3>
                      <form>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="input-block mb-3">
                              <label for="name">Name</label>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="input-block mb-3">
                              <label for="email">Email</label>
                              <input
                                type="email"
                                id="email"
                                name="email"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="input-block mb-3">
                              <label for="website">Website</label>
                              <input
                                type="text"
                                id="website"
                                name="website"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="input-block mb-3">
                          <label for="comment">Comment *</label>
                          <textarea
                            id="comment"
                            name="comment"
                            class="form-control"
                            rows="3"
                            cols="5"
                          ></textarea>
                        </div>
                        <div class="submit-section">
                          <button type="submit" class="btn btn-primary submit-btn">
                            Post Comment
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="w-sidebar">
              <div class="widget widget-category">
                <h4 class="widget-title">
                  <i class="fa-regular fa-folder"></i> Categories
                </h4>
                <ul>
                  <li>
                    <a href="javascript:void(0);">Categories 1</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Categories 2</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Categories 3</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Categories 4</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Categories 5</a>
                  </li>
                </ul>
              </div>

              <div class="widget widget-category">
                <h4 class="widget-title">
                  <i class="fa-regular fa-folder"></i> Popular Articles
                </h4>
                <ul>
                  <li><a href="javascript:void(0);"> Installation &amp; Activation </a></li>
                  <li><a href="javascript:void(0);"> Premium Members Features </a></li>
                  <li><a href="javascript:void(0);"> API Usage &amp; Guide lines </a></li>
                  <li><a href="javascript:void(0);"> Getting Started &amp; What is next. </a></li>
                  <li><a href="javascript:void(0);"> Installation &amp; Activation </a></li>
                  <li><a href="javascript:void(0);"> Premium Members Features </a></li>
                  <li><a href="javascript:void(0);"> API Usage &amp; Guide lines </a></li>
                  <li><a href="javascript:void(0);"> Getting Started &amp; What is next. </a></li>
                </ul>
              </div>

              <div class="widget widget-category">
                <h4 class="widget-title">
                  <i class="fa-regular fa-folder"></i> Latest Articles
                </h4>
                <ul>
                  <li><a href="javascript:void(0);"> Installation &amp; Activation </a></li>
                  <li><a href="javascript:void(0);"> Premium Members Features </a></li>
                  <li><a href="javascript:void(0);"> API Usage &amp; Guide lines </a></li>
                  <li><a href="javascript:void(0);"> Getting Started &amp; What is next. </a></li>
                  <li><a href="javascript:void(0);"> Installation &amp; Activation </a></li>
                  <li><a href="javascript:void(0);"> Premium Members Features </a></li>
                  <li><a href="javascript:void(0);"> API Usage &amp; Guide lines </a></li>
                  <li><a href="javascript:void(0);"> Getting Started &amp; What is next. </a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Content End -->
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Knowledgebase",
      path: "Dashboard",
      text: "Knowledgebase",
    };
  },
};
</script>
