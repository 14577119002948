<template>
    <!-- Add Leavetype Modal -->
    <div id="add_leavetype" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ leaveTypeForm?.id ? 'Edit' : 'Add' }} Leave Type</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="addleavetpclose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Leave Type Name <span class="text-danger">*</span>
                </label>
                <Field
                      name="type"
                      type="text"
                      class="form-control"
                      v-model="leaveTypeForm.type"
                      :class="{ 'is-invalid': errors.type }"
                    />
                    <div class="invalid-feedback">{{ errors.type }}</div>
                    <div class="typeshow text-danger" id="type"></div>
              </div>
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Leave Type Short Code <span class="text-danger">*</span>
                </label>
                <Field
                      name="short_code"
                      type="text"
                      class="form-control"
                      v-model="leaveTypeForm.short_code"
                      :class="{ 'is-invalid': errors.short_code }"
                    />
                    <div class="invalid-feedback">{{ errors.short_code }}</div>
                    <div class="short_codeshow text-danger" id="short_code"></div>
              </div>
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Status <span class="text-danger">*</span>
                </label>
                <select
                      name="status"
                      class="form-control"
                      v-model="leaveTypeForm.status"
                      :class="{ 'is-invalid': errors.status }"
                    >
                    <option value="1">
                      Active
                    </option>
                    <option value="0">
                      InActive
                    </option>
                </select>
                    <div class="invalid-feedback">{{ errors.status }}</div>
                    <div class="statusshow text-danger" id="status"></div>
              </div>
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Leave Days <span class="text-danger">*</span>
                </label>
                <Field
                      name="days"
                      type="number"
                      class="form-control"
                      v-model="leaveTypeForm.days"
                      :class="{ 'is-invalid': errors.days }"
                    />
                    <div class="invalid-feedback">{{ errors.days }}</div>
                    <div class="daysshow text-danger" id="days"></div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
           </Form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Leave Types Modal -->
  
    <!-- Delete Leave Types Modal -->
    <div class="modal custom-modal fade" id="delete_leavetype" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Leave Types</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="deleteLeavetp"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn" ref="deleteleavetpclose"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Leave Types Modal -->
  </template>
  
  
  <script>
  import { Form, Field } from "vee-validate";
  import * as Yup from "yup";
  import axios from 'axios';
  import { notification } from "ant-design-vue";
  
  export default {
    emits: ["createLeavetype","updateLeavetype","deleteLeavetype"], // <--- add this line
    components: {
      Form,
      Field,
    },
    props: {
      editform: [Array, Object],
      delleavetype_id:[Number,String],
      closeFormModel:[Boolean]
    },
    data() {
      return {
        leaveTypeForm:{
          type:null,
          status:1,
          days:null,
          short_code:null,
          id:null
        }
      };
    },
    watch:{
      closeFormModel(val)
      {
        if(val)
        {
            this.closeDialog()
            this.clearForm()
        }
      },
      editform(val)
      {
        this.leaveTypeForm = {...val}
      }
    },
    methods: {
      clearForm(){
        this.leaveTypeForm={
          type:null,
          status:1,
          days:null,
          short_code:null
        }
      },
      onSubmit() {
          this.$emit("create-leavetype",this.leaveTypeForm);
      },
      closeDialog(){
          this.$refs.addleavetpclose.click();
          this.$refs.deleteleavetpclose.click();
      },
      onUpdate(values){
          console.log('Update Called!!');
          this.$emit("update-leavetype",this.editform);
      },
      deleteLeavetp(){
          console.log('Delete Called');
          this.$emit("delete-leavetype",this.delleavetype_id);
      }
    }, 
    setup() {
      const schema = Yup.object().shape({
        type: Yup.string().required("Leave Type Name is required"),
        days: Yup.string().required("Days is required"),
        short_code: Yup.string().required("Short Code Name is required"),
      });
      
      const editschema = Yup.object().shape({
        type: Yup.string().required("Leave Types Name is required"),
        days: Yup.string().required("Days is required"),
        short_code: Yup.string().required("Short Code Name is required"),
      });
      
      return {
        schema,
        editschema
      };
    },
    beforeMount() {},
    mounted() {},
    computed: {}
  };
  </script>
  