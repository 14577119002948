<template>
  <div class="card mb-0">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="profile-view">
            <div class="profile-img-wrap">
              <div class="profile-img" v-if="clientdata && clientdata.id">
                <a href="javascript:;">
                  <!--<img
                    src="@/assets/img/profiles/avatar-19.jpg"
                    alt="User Image"
                  /> -->
				  
				  <img v-if="clientdata?.avatar != null"
						  :src="getEmployeeAvatar(clientdata?.avatar)"
						  @error="handleImageError(clientdata)"
							alt="User Image"
						/>
				 <img v-else
					:src="require(`@/assets/img/profiles/${defaultAvatar}`)"
						alt="User Image"
					/>
                </a>
              </div>
            </div>
            <div class="profile-basic">
              <div class="row">
                <div class="col-md-5">
                  <div class="profile-info-left" v-if="clientdata && clientdata.id">
				    <h3 class="user-name m-t-0" v-if="clientdata && clientdata.company && clientdata.company.company_name">{{clientdata.company.company_name}}</h3>
                    <h5 class="company-role m-t-0 mb-0">{{clientdata.first_name}} {{clientdata.last_name}}</h5>
                    <!--<small class="text-muted">CEO</small> -->
                    <div class="staff-id">Client ID : {{clientdata.client_id}}</div>
                    <!--<div class="staff-msg">
                      <router-link to="chat" class="btn btn-custom"
                        >Send Message</router-link
                      >
                    </div> -->
                  </div>
                </div>
                <div class="col-md-7" v-if="clientdata && clientdata.id">
                  <ul class="personal-info">
                    <li>
                      <span class="title">Phone:</span>
                      <span class="text"><a href="javascript:void(0);">{{clientdata.contact_number}}</a></span>
                    </li>
                    <li>
                      <span class="title">Email:</span>
                      <span class="text"
                        ><a href="javascript:void(0);">{{clientdata.email}}</a></span
                      >
                    </li>
					<!--
                    <li>
                      <span class="title">Birthday:</span>
                      <span class="text">2nd August</span>
                    </li>
                    <li>
                      <span class="title">Address:</span>
                      <span class="text"
                        >5754 Airport Rd, Coosada, AL, 36020</span
                      >
                    </li> -->
                    <li>
                      <span class="title">Gender:</span>
                      <span class="text" v-if="clientdata.gender == 'm'">Male</span>
					  <span class="text" v-else-if="clientdata.gender == 'f'">Female</span>
					  <span class="text" v-else-if="clientdata.gender == 'o'">Other</span>
					  <span class="text" v-else></span>
                    </li>
					<li></li>
					<li></li>
                  </ul>
                </div>
              </div>
            </div>
			<div class="pro-edit">
              <a
                data-bs-target="#profile_info"
                data-bs-toggle="modal"
                class="edit-icon"
                href="javascript:;"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
	props: {
		clientdata: [Array, Object], 
	},
	data() {
		return {
			defaultAvatar:'avatar-02.jpg',
			avatar: "avatar-02.jpg",
		}
	},
	methods: {
		handleImageError(emp) {
		  emp.avatar = null;
		  emp.avatar = `assets/img/profiles/${this.defaultAvatar}`;
		},
		getEmployeeAvatar(avatar){
		  return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
		},
	}
}	
</script>