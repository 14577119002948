<template>
  <!-- Add Today Work Modal -->
  <div id="add_todaywork" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Today Work details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="adddtimesheetclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
           <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
            <div class="row">
			  <div class="input-block mb-3 col-sm-6">
              <label class="col-form-label"
                >Employee <span class="text-danger">*</span></label
              >
			  <Field
                    name="employee_id"
                    as="select"
                    class="form-control"
					v-model="form.employee_id"
					:class="{ 'is-invalid': errors.employee_id }"
					@change="updateProjects"
                  >
				  <option value="">Select Employee</option>
				  <option v-for="employee in employeelist" :key="employee.id" :value="employee.id">{{ employee.first_name }} {{employee.last_name}}</option>
				  <div class="invalid-feedback">{{ errors.employee_id }}</div>
                  <div class="employee_idshow text-danger" id="employee_id"></div>
			  </Field>
			</div>	
              <div class="input-block mb-3 col-sm-6">
                <label class="col-form-label"
                  >Project <span class="text-danger">*</span></label
                >
				
				<Field
                    name="project_id"
                    as="select"
                    class="form-control"
					v-model="form.project_id"
					:class="{ 'is-invalid': errors.project_id }"
					@change="updateProject"
                  >
				  <option value="">Select Project</option>
				  <option v-for="project in projectlist" :key="project.project_id" :value="project.project_id">{{ project.project_name }} </option>
				  <div class="invalid-feedback">{{ errors.project_id }}</div>
                  <div class="employee_idshow text-danger" id="project_id"></div>
				</Field>
			  
                <!--<vue-select
                  :options="Project"
                  id="project"
                  placeholder="Office Management"
                />-->
              </div>
            </div>
			<div class="row">
              <div class="input-block mb-3 col-sm-4">
				
                <label class="col-form-label"
                  >Deadline <span class="text-danger">*</span></label
                >
                <div class="cal-icon">
                 <input class="form-control" v-model="selectedproject.end_date" type="text" readonly />
			    </div>
              </div>
              <div class="input-block mb-3 col-sm-4">
                <label class="col-form-label"
                  >Total Hours <span class="text-danger">*</span></label
                >
                <input class="form-control" type="text" v-model="selectedproject.total_hours" readonly />
              </div>
              <div class="input-block mb-3 col-sm-4">
                <label class="col-form-label"
                  >Remaining Hours <span class="text-danger">*</span></label
                >
                <input class="form-control" type="text" v-model="selectedproject.pending_hours" readonly />
              </div>
            </div>
            <div class="row">
              <div class="input-block mb-3 col-sm-6">
                <label class="col-form-label"
                  >Date <span class="text-danger">*</span></label
                >
                <div class="cal-icon">
                  <datepicker
                    v-model="startdate"
                    placeholder="Choose Date"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                  />
                </div>
              </div>
              <div class="input-block mb-3 col-sm-6">
                <label class="col-form-label"
                  >Hours <span class="text-danger">*</span></label
                >
                <!--<input class="form-control" type="text" v-model="form.hours" /> -->
				<Field
                    name="hours"
                    type="text"
                    value=""
                    class="form-control"
					v-model="form.hours"
					:class="{ 'is-invalid': errors.hours }"
                  />
                  <div class="invalid-feedback">{{ errors.hours }}</div>
                  <div class="hourssshow text-danger" id="hours"></div>
				  
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Description <span class="text-danger">*</span></label
              >
			  <Field
                    name="description"
                    as="textarea"
                    value=""
                    class="form-control"
					v-model="form.description"
					rows="4"
					:class="{ 'is-invalid': errors.description }"
                  />
                  <div class="invalid-feedback">{{ errors.description }}</div>
                  <div class="descriptionshow text-danger" id="description"></div>
				  
             <!-- <textarea rows="4" class="form-control" v-model="form.description" ></textarea> -->
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Today Work Modal -->

  <!-- Edit Today Work Modal -->
  <div id="edit_todaywork" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Work Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="edittimesheetclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="onUpdate" :validation-schema="editschema" v-slot="{ errors }">
            <div class="row">
				<div class="input-block mb-3 col-sm-6">
              <label class="col-form-label"
                >Employee <span class="text-danger">*</span></label
              >
			  <Field
                    name="employee_id"
                    as="select"
                    class="form-control"
					v-model="editform.employee_id"
					:class="{ 'is-invalid': errors.employee_id }"
                  >
				  <option value="">Select Employee</option>
				  <option v-for="employee in employeelist" :key="employee.id" :value="employee.id">{{ employee.first_name }} {{employee.last_name}}</option>
				  <div class="invalid-feedback">{{ errors.employee_id }}</div>
                  <div class="employee_idshow text-danger" id="employee_id"></div>
			  </Field>
			</div>
			
			
				<div class="input-block mb-3 col-sm-6">
                <label class="col-form-label"
                  >Project <span class="text-danger">*</span></label
                >
				
				<Field
                    name="project_id"
                    as="select"
                    class="form-control"
					v-model="editform.project_id"
					:class="{ 'is-invalid': errors.project_id }"
					@change="updateProject2"
                  >
				  <option value="">Select Project</option>
				  <option v-for="project in projectlist" :key="project.project_id" :value="project.project_id">{{ project.project_name }} </option>
				  <div class="invalid-feedback">{{ errors.project_id }}</div>
                  <div class="employee_idshow text-danger" id="project_id"></div>
				</Field>
				
				<!--
                <vue-select
                  :options="Project"
                  id="project-one"
                  placeholder="Office Management"
                /> -->
              </div>
			 
            </div>
            <div class="row">
              <div class="input-block mb-3 col-sm-4">
			    <label class="col-form-label"
                  >Deadline <span class="text-danger">*</span></label
                >
				<div class="cal-icon">
				 <!-- <input class="form-control" type="text" value="5 May 2023" readonly /> -->
				  <input class="form-control" v-model="selectededitproject.end_date" type="text" readonly />
                </div>
              </div>
              <div class="input-block mb-3 col-sm-4">
                <label class="col-form-label"
                  >Total Hours <span class="text-danger">*</span></label
                >
               <!-- <input class="form-control" type="text" value="100" readonly /> -->
				
				<input class="form-control" type="text" v-model="selectededitproject.total_hours" readonly />
              </div>
              <div class="input-block mb-3 col-sm-4">
                <label class="col-form-label"
                  >Remaining Hours <span class="text-danger">*</span></label
                >
				<input class="form-control" type="text" v-model="selectededitproject.pending_hours" readonly />
               <!-- <input class="form-control" type="text" value="60" readonly /> -->
              </div>
            </div>
            <div class="row">
              <div class="input-block mb-3 col-sm-6">
                <label class="col-form-label"
                  >Date <span class="text-danger">*</span></label
                >
                <div class="cal-icon">
                  <datepicker
                    v-model="editform.date"
                    placeholder="2023-08-29"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                  />
                </div>
              </div>
              <div class="input-block mb-3 col-sm-6">
                <label class="col-form-label"
                  >Hours <span class="text-danger">*</span></label
                >
                <!-- <input class="form-control" type="text" value="9" />-->
				
				<Field
                    name="hours"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.hours"
					:class="{ 'is-invalid': errors.hours }"
                  />
                  <div class="invalid-feedback">{{ errors.hours }}</div>
                  <div class="hourssshow text-danger" id="hours"></div>
				  
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Description <span class="text-danger">*</span></label
              >
             <!-- <textarea rows="4" class="form-control">
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</textarea
              > -->
			  
			  <Field
                    name="description"
                    as="textarea"
                    value=""
                    class="form-control"
					v-model="editform.description"
					rows="4"
					:class="{ 'is-invalid': errors.description }"
                  />
                  <div class="invalid-feedback">{{ errors.description }}</div>
                  <div class="descriptionshow text-danger" id="description"></div>
				  
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Save</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Today Work Modal -->

  <!-- Delete Today Work Modal -->
  <div class="modal custom-modal fade" id="delete_workdetail" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Work Details</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="deleteTimeSheet"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
				  ref="deletetimesheetclose"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete Today Work Modal -->
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());

import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import moment from 'moment';

export default {
  emits: ["createTimesheet","updateTimesheet","deleteTimesheet","updateprojectlist"],
  components: {
    Form,
    Field,
  },
  props: {
    form: [Array, Object],
    projectlist: [Array, Object],
    employeelist: [Array, Object],
    editform: [Array, Object],
	timesheet_id: [Number,String],
	selectedmode: String,
	//selectedproject: [Array, Object],
  },
  data() {
    return {
      select2: null,
      startdate: currentDate,
      dateFormat: 'yyyy-MM-dd',
      Project: [
        "Office Management",
        "Project Management",
        "Video Calling App",
        "Hospital Administration",
      ],
	  selectedproject: {"total_hours":"","end_date": "","pending_hours": ""},
	  selectededitproject: {"total_hours":"","end_date": "","pending_hours": ""},
    };
  },
  methods: {
    updateProjects(){
		console.log('Update Project Called');
		if(this.form.employee_id != ""){
			this.$emit("updateprojectlist",this.form);
		}
		
	},
	deleteTimeSheet(){
		  this.$emit("delete-timesheet",this.timesheet_id);
	},
	onSubmit(values) {
		console.log('on submit called');
		console.log(values);
		values.date = moment(this.startdate).format('YYYY-MM-DD');
		
		this.$emit("create-timesheet",values);
	},
	onUpdate(formval){
		
		console.log('Update Called!!');
		this.$emit("update-timesheet",this.editform);
		
	},
	resetproject(){
		
		this.selectedproject = {"total_hours":"","end_date": "","pending_hours": ""};
		
	},
	closeDialog(){
		this.$refs.adddtimesheetclose.click();
		this.$refs.edittimesheetclose.click();
		this.$refs.deletetimesheetclose.click();
	},
	updateProject(){
	  console.log('update project called');
	  
	  this.selectedproject = {"total_hours":"","end_date": "","pending_hours": ""};
	  if(this.form.project_id != ""){
		
		for(let i=0;i<this.projectlist.length;i++){
			
			if(this.projectlist[i].project_id == this.form.project_id){
				console.log(this.projectlist[i]);
				this.form.deadline = this.projectlist[i].end_date;
				
				this.selectedproject = this.projectlist[i];
			}
		}
		
	  }
	  
	},
	updateProject2(){
	  console.log('update project called');
	  
	  this.selectededitproject = {"total_hours":"","end_date": "","pending_hours": ""};
	  if(this.editform.project_id != ""){
		
		for(let i=0;i<this.projectlist.length;i++){
			
			if(this.projectlist[i].project_id == this.editform.project_id){
				console.log(this.projectlist[i]);
				this.editform.deadline = this.projectlist[i].end_date;
				
				this.selectededitproject = this.projectlist[i];
			}
		}
		
	  } 
	},
	seteditContent(){
		
	   setTimeout(() => {
		
		this.updateProject2();
	  
      }, 1000);
	  
	  
		
	}
  },
  setup() {
    const schema = Yup.object().shape({
	  employee_id: Yup.string().required("Employee is required"),
	  project_id: Yup.string().required("Project is required"),
	  description: Yup.string().required("Description is required"),
	  hours: Yup.string().required("Hours are required")
	});
	
	const editschema = Yup.object().shape({
	  employee_id: Yup.string().required("Employee is required"),
	  project_id: Yup.string().required("Project is required"),
	  description: Yup.string().required("Description is required"),
	  hours: Yup.string().required("Hours are required")
	});
	
	return {
      schema,
	  editschema
	};
  },
};
</script>
